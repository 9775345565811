import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";
import { default as Swal, default as SweetAlert } from "sweetalert2";
import { getGaragesByGivenToken } from "../../api/garages";
import Loader from "../../layout/Loader";
import { BACKEND_API } from "../../utils/backend";
import { getPermissions } from "../../utils/helperFunctions";

const LoginWithJwt = () => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  const searchKey = searchParams.get("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${searchKey}`,
    },
  };
  useEffect(() => {
    if (searchKey) {
      axios
        .get(`${BACKEND_API}/v1.0/user`, config)
        .then((response) => {
          if (
            response?.data?.roles[0]?.name === "superadmin" ||
            response?.data?.roles[0]?.name === "data_collector"
          ) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem(
              "permissions",
              JSON.stringify(getPermissions(response.data))
            );
            window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
          } else {
            getGaragesByGivenToken(response.data.token).then((res) => {
              console.log({ garage: res });
              // GET ALL GARAGES BY OWNER
              if (res) {
                setIsLoading(false);
              }
              if (res?.length === 1) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem(
                  "permissions",
                  JSON.stringify(getPermissions(response.data))
                );
                localStorage.setItem("garageId", res[0]?.id);
                localStorage.setItem("currency", res[0]?.currency);
                window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
              } else {
                Swal.fire({
                  icon: "question",
                  iconHtml: "?",
                  title: "Select A Garage",
                  input: "select",
                  inputOptions: res.reduce((options, garage) => {
                    options[garage.id] = garage.name;
                    return options;
                  }, {}),
                  inputPlaceholder: "Select One",
                  inputValidator: (value) => {
                    if (!value) {
                      // IF USER NOT SELECT ANY GARAGE
                      return "You need to select at least a Garage!";
                    } else {
                      // IF USER SELECT A GARAGE
                      localStorage.setItem("token", response.data.token);
                      localStorage.setItem(
                        "user",
                        JSON.stringify(response.data)
                      );
                      localStorage.setItem(
                        "permissions",
                        JSON.stringify(getPermissions(response.data))
                      );
                      localStorage.setItem("garageId", value);
                      window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
                    }
                  },
                });
              }
            });
            return true;
          }
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            SweetAlert.fire({
              title: "Oops!",
              html: `
              <div style="display:flex;justify-content:center;align-items:center;width:100%;flex-direction:column;" >
                  <h2 style="font-weight:bold">Your email is not verified yet!</h2>
                  <p style="text-align:center">
                        Please active your account by verifing your email address. then come back and try to login again.<br>
                       <div STYLE="background-color:#eeeeee; margin-top:5px; border-radius:10px; padding:5px 10px;">
                        <span style="color:#333333; font-size:0.8rem;">
                            NOTE: If you can't find the email in your inbox then please check to the spam folder.
                        </span>
                       </div>
                  </p>
              </div>
              `,
              icon: "warning",
            });
            setIsLoading(false);
          }
        });
    } else {
    }
  }, [searchKey]);
  return <div>{isLoading && <Loader />}</div>;
};

export default LoginWithJwt;
