import React, { useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Input,
  Label,
} from "reactstrap";
import {
  AccordionWithIconOnTitle,
  Accordioncode,
  Accordionspan,
  Add,
  CollapsibleGroupItem,
  ColorPara,
  Next,
  Previous,
} from "../../../../constant";
import { useEffect } from "react";
import {
  couponValidation,
  getGaragePackages,
} from "../../../../api/garage_bookings";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../../utils/apiClient";
import { BACKEND_API } from "../../../../utils/backend";

const PackageDetails = ({
  setSteps,
  packages,
  bookingData,
  setBookingData,
  garageId,
}) => {
  const garage_id = JSON.parse(localStorage.getItem("garageId"));
  const currency = localStorage.getItem("currency");
  const [isOpen, setIsOpen] = useState(null);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const handlePackageChange = (id, data) => {
    if (bookingData.booking_garage_package_ids.includes(id)) {
      setBookingData({
        ...bookingData,
        packages: bookingData?.packages.filter((res) => res.id !== id),
        booking_garage_package_ids:
          bookingData.booking_garage_package_ids.filter((res) => res !== id),
      });
    } else {
      setBookingData({
        ...bookingData,
        packages: [...bookingData?.packages, data],
        booking_garage_package_ids: [
          ...bookingData?.booking_garage_package_ids,
          id,
        ],
      });
    }
    toggle(id);
  };
  const handleBookingChange = (value) => {
    let totalAmount = 0;
    for (let index = 0; index < bookingData?.packages.length; index++) {
      totalAmount = totalAmount + bookingData?.packages[index]?.price * 1;
    }

    couponValidation(garageId, value, totalAmount).then((res) => {
      if (res?.success) {
        if (res?.discount_type === "percentage") {
          setBookingData({
            ...bookingData,
            currentAmount: totalAmount,
            discountAmount:
              totalAmount -
              (totalAmount * 1 * (res?.discount_amount * 1)) / 100,
          });
        } else {
          setBookingData({
            ...bookingData,
            current_price: totalAmount,
            discount_price: totalAmount - res?.discount_amount * 1,
          });
        }
      } else {
        SweetAlert.fire({
          title: "Error!",
          text: `${res?.message}`,
          icon: "warning",
        });
        setBookingData({
          ...bookingData,
          coupon_code: "",
          current_price: totalAmount,
          discount_price: 0,
        });
      }
    });
  };
  const [couponList, setCouponList] = useState([]);
  useEffect(() => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/coupons/${garage_id}/0`)
      .then((res) => {
        setCouponList(res?.data?.filter((fil) => fil?.is_active === 1));
      });
  }, []);

  // VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate price
    if (!bookingData?.price) {
      newErrors.price = "Price is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Check your input fields",
        icon: "warning",
      });
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Col sm="12">
      <Accordion defaultActiveKey="0">
        <Card>
          <CardBody>
            <div className="default-according" id="accordion1">
              {packages?.map((accordian, i) => (
                <Card key={i}>
                  <CardHeader className="bg-primary">
                    <div className="d-flex align-items-center">
                      <div>
                        <Input
                          type="checkbox"
                          name="package_ids"
                          value="option1"
                          onChange={() =>
                            handlePackageChange(accordian.id, accordian)
                          }
                          checked={bookingData.booking_garage_package_ids.includes(
                            accordian.id
                          )}
                          className="custom-radio"
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => toggle(accordian.id)}
                        className="d-flex justify-content-between w-100"
                      >
                        <p className="fw-bold">{accordian?.name}</p>
                        <p>
                          <span className="fw-bold">{currency}</span>{" "}
                          {accordian?.price}
                        </p>
                        {isOpen === accordian.id ? (
                          <BiSolidUpArrow />
                        ) : (
                          <BiSolidDownArrow />
                        )}
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={isOpen === accordian.id}>
                    <CardBody>
                      <p className="fw-bold">Services:</p>
                      {accordian?.garage_package_sub_services?.map((res, i) => (
                        <>
                          <p>
                            <span className="fw-bold">{i + 1}.</span>{" "}
                            {res?.sub_service?.name}
                          </p>
                        </>
                      ))}
                    </CardBody>
                  </Collapse>
                </Card>
              ))}
            </div>
          </CardBody>
        </Card>
      </Accordion>

      <div className="mb-2">
        {/* COUPON  */}
        <label htmlFor="">Coupon</label>
        <select
          displayText={`Status*`}
          onChange={(e) =>
            setBookingData({ ...bookingData, coupon_code: e.target.value })
          }
          onBlur={(e) => handleBookingChange(e.target.value)}
          value={bookingData.coupon_code}
          required={false}
          className={"form-control border-secondary"}
        >
          <option value={""} disabled>
            Select Coupon
          </option>
          {couponList?.map((res, i) => (
            <option key={i} value={res?.code}>
              {res?.code}
            </option>
          ))}
        </select>
      </div>

      {/* PRICE  */}
      <div className="mb-5">
        <label htmlFor="">Price*</label>
        <input
          type="number"
          value={bookingData.price}
          onChange={(e) =>
            setBookingData({ ...bookingData, price: e.target.value })
          }
          //   onBlur={(e) => handleBookingChange(e.target.value)}
          className="form-control border-secondary"
          placeholder="Price*"
        />
        {errors?.price && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {errors?.price}
          </div>
        )}
      </div>
      <div className="text-end btn-mb">
        <Button
          color="primary"
          className="me-3"
          onClick={() => setSteps((prev) => prev - 1)}
        >
          {Previous}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (validateForm()) {
              setSteps((prev) => prev + 1);
            }
          }}
        >
          {Next}
        </Button>
      </div>
    </Col>
  );
};

export default PackageDetails;
