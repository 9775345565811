import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import { apiClient } from "../../../utils/apiClient";
import {
  getServiceList,
  getSubServiceList,
  getSubServiceListByGarageId,
} from "../../../api/findJobs";
import PackageServiceDropdown from "./PackageServiceDropdown";
import Switch from "react-switch";

const PackageForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  packageUpdateData,
  setIsOpen,
  garageId,
  currency,
}) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([
    packageUpdateData?.garage_package_sub_services
      ? packageUpdateData?.garage_package_sub_services?.map(
          (res) => res?.sub_service?.id
        )
      : [],
  ]);
  const [searchData, setSearchData] = useState({
    serviceIds: packageUpdateData?.garage_package_sub_services
      ? packageUpdateData?.garage_package_sub_services?.map(
          (res) => res?.sub_service?.id
        )
      : [],
    makeIds: "",
    town_or_postal_code: "",
  });
  const [data, setData] = useState(
    type === "update"
      ? {
          id: packageUpdateData?.id,
          garage_id: garageId,
          name: packageUpdateData?.name ? packageUpdateData?.name : "",
          description: packageUpdateData?.description
            ? packageUpdateData?.description
            : "",
          price: packageUpdateData?.price ? packageUpdateData?.price : "",
          sub_service_ids: packageUpdateData?.garage_package_sub_services
            ? packageUpdateData?.garage_package_sub_services?.map(
                (res) => res?.sub_service?.id
              )
            : [],
          is_active: packageUpdateData?.is_active
            ? packageUpdateData?.is_active
            : false,
        }
      : {
          garage_id: garageId,
          name: "",
          description: "",
          price: "",
          sub_service_ids: [],
          is_active: false,
        }
  );

  //   VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    // Validate garage_id
    if (!data?.garage_id) {
      newErrors.garage_id = "Invalid Garage is required.";
    }

    // Validate PACKAGE NAME
    if (!data?.name || data.name.trim() === "") {
      newErrors.name = "Package name is required.";
    }
    console.log({ price: data.price });
    // Validate PRICE
    if (
      !data?.price ||
      parseInt(data?.price) === 0 ||
      parseInt(data?.price) < 0
    ) {
      console.log("true");
      newErrors.price = "Price is required.";
    }

    // Validate description
    if (!data?.description || data?.description.trim() === "") {
      newErrors.description = "Description is required.";
    }

    // Validate sub_service_ids
    if (searchData?.serviceIds.length === 0) {
      newErrors.sub_service_ids = "Service is required.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      setServerSideErrors(null);
      if (type === "update") {
        const alldata = {
          ...data,
          garage_id: garageId,
          sub_service_ids: searchData.serviceIds,
        };
        apiClient()
          .put(`${BACKEND_API}/v1.0/garage-packages`, alldata)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              SweetAlert.fire({
                title: "Success",
                text: "Package Updated Successfully!",
                icon: "success",
              });
              fetchData(perPage);
              toggleModal();
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response?.status === 422) {
              setServerSideErrors(error.response.data.errors);
              const errors = error?.response?.data?.errors;
              Object.keys(errors).forEach((field) => {
                errors[field].forEach((errorMessage, index) =>
                  SweetAlert.fire({
                    title: errorMessage,
                    text: "Please Try Again",
                    icon: "warning",
                  })
                );
              });
            } else if (error.response?.status === 401) {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Hello!!! You do not have permission.",
                icon: "warning",
              });
            } else {
              SweetAlert.fire({
                title: "something went wrong!!",
                text: "Please Try Again",
                icon: "warning",
              });
            }
          });
      }

      if (type === "create") {
        const alldata = {
          ...data,
          garage_id: garageId,
          sub_service_ids: searchData.serviceIds,
        };
        apiClient()
          .post(`${BACKEND_API}/v1.0/garage-packages`, alldata)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              SweetAlert.fire({
                title: "Success",
                text: "Package Created Successfully!",
                icon: "success",
              });
              fetchData(perPage);
              toggleModal();
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response?.status === 422) {
              setServerSideErrors(error.response.data.errors);
              const errors = error?.response?.data?.errors;
              Object.keys(errors).forEach((field) => {
                errors[field].forEach((errorMessage, index) =>
                  SweetAlert.fire({
                    title: errorMessage,
                    text: "Please Try Again",
                    icon: "warning",
                  })
                );
              });
            } else if (error.response?.status === 401) {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Hello!!! You do not have permission.",
                icon: "warning",
              });
            } else {
              SweetAlert.fire({
                title: "something went wrong!!",
                text: "Please Try Again",
                icon: "warning",
              });
            }
          });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getSubServiceListByGarageId(garageId)
      .then((res) => {
        setServiceList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Row>
      <Col sm="12">
        {isLoading ? (
          <CustomLoadingSpiner />
        ) : (
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {loading ? (
              <CustomLoadingSpiner />
            ) : (
              <Container>
                <Container className="form-row mb-2">
                  {/* FUEL STATION NAME  */}
                  <Col sm="12 mb-3">
                    {type === "update" && (
                      <Label
                        className="text-uppercase fw-bolder"
                        htmlFor="name"
                      >
                        Package Name*
                      </Label>
                    )}
                    <input
                      placeholder="Package Name*"
                      id="name"
                      className="form-control border-secondary"
                      data-testid="package_name"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      defaultValue={
                        packageUpdateData?.name ? packageUpdateData?.name : ""
                      }
                      required
                    />
                    {errors?.name && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors?.name}
                      </div>
                    )}
                  </Col>
                  <Col sm="12">
                    <div>
                      {type === "update" && (
                        <Label
                          className="text-uppercase fw-bolder"
                          htmlFor="name"
                        >
                          Price*
                        </Label>
                      )}
                      <input
                        placeholder="Price*"
                        id="price"
                        style={{ paddingLeft: "45px" }}
                        className="form-control border-secondary"
                        data-testid="price"
                        name="price"
                        type="number"
                        onChange={handleChange}
                        defaultValue={
                          packageUpdateData?.price
                            ? packageUpdateData?.price
                            : ""
                        }
                        required
                      />
                      <span className="currency_input fw-bold">{currency}</span>
                    </div>

                    {errors?.price && (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: "block",
                          marginTop: "-20px",
                          marginBottom: "10px",
                        }}
                      >
                        {errors?.price}
                      </div>
                    )}
                  </Col>
                  <Col sm="12 mb-3">
                    {type === "update" && (
                      <Label
                        className="text-uppercase fw-bolder"
                        htmlFor="name"
                      >
                        Services*
                      </Label>
                    )}
                    <PackageServiceDropdown
                      serviceList={serviceList}
                      setSearchData={setSearchData}
                      searchData={searchData}
                    />
                    {errors?.sub_service_ids && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors?.sub_service_ids}
                      </div>
                    )}
                  </Col>
                  <Col sm="12 mb-3">
                    {type === "update" && (
                      <Label
                        className="text-uppercase fw-bolder"
                        htmlFor="name"
                      >
                        Description*
                      </Label>
                    )}
                    <textarea
                      placeholder="Description*"
                      id="description"
                      className="form-control border-secondary"
                      data-testid="description"
                      name="description"
                      type="text"
                      onChange={handleChange}
                      defaultValue={
                        packageUpdateData?.description
                          ? packageUpdateData?.description
                          : ""
                      }
                      required
                      rows="4"
                    />
                    {errors?.description && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors?.description}
                      </div>
                    )}
                  </Col>
                  <Col sm="12 mb-3">
                    <Row>
                      <Col md="6">
                        <Col md="6">
                          <div className="d-flex align-items-center">
                            <Switch
                              checked={data.is_active}
                              onChange={() => {
                                setData({
                                  ...data,
                                  is_active: !data.is_active,
                                });
                              }}
                              className="react-switch"
                            />
                            <span>Active</span>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Container>
              </Container>
            )}

            <Row className="mb-3">
              <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                <Button
                  color="primary"
                  className="w-100 py-2 rounded-3"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="py-2 w-100 cancel-button rounded-3 text-secondary"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  color=""
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default PackageForm;
