import { City, Country } from "country-state-city";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import AutoComplete from "../../AutoComplete/AutoComplete";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import CustomTimeSelector from "../components/CustomTImeSelector";
import moment from "moment";

const FuelStationForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  fuelStationUpdateData,
  setIsOpen,
}) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useGeoLocation();
  const [options, setOptions] = useState([]);
  const [distanceError, setDistanceError] = useState("");

  const [firstLoad, setFirstLoad] = useState(true);

  const [placeAutoComplete, setPlaceAutoComplete] = useState({});

  const [data, setData] = useState(
    type === "update"
      ? {
          id: fuelStationUpdateData?.id,
          name: fuelStationUpdateData?.name ? fuelStationUpdateData?.name : "",
          address: fuelStationUpdateData?.address
            ? fuelStationUpdateData?.address
            : "",
          opening_time: fuelStationUpdateData?.opening_time
            ? fuelStationUpdateData?.opening_time.slice(0, 5)
            : "",
          closing_time: fuelStationUpdateData?.closing_time
            ? fuelStationUpdateData?.closing_time.slice(0, 5)
            : "",
          description: fuelStationUpdateData?.description
            ? fuelStationUpdateData?.description
            : "",
          lat: fuelStationUpdateData?.lat ? fuelStationUpdateData?.lat : "",
          long: fuelStationUpdateData?.long ? fuelStationUpdateData?.long : "",
          country: fuelStationUpdateData?.country
            ? fuelStationUpdateData?.country
            : "",
          city: fuelStationUpdateData?.city ? fuelStationUpdateData?.city : "",
          postcode: fuelStationUpdateData?.postcode
            ? fuelStationUpdateData?.postcode
            : "",
          address_line_1: fuelStationUpdateData?.address_line_1
            ? fuelStationUpdateData?.address_line_1
            : "",
          address_line_2: fuelStationUpdateData?.address_line_2
            ? fuelStationUpdateData?.address_line_2
            : "",
          additional_information: fuelStationUpdateData?.additional_information
            ? fuelStationUpdateData?.additional_information
            : "",
          options: fuelStationUpdateData?.options
            ? fuelStationUpdateData?.options
            : [],
        }
      : {
          name: "",
          address: "",
          opening_time: "",
          closing_time: "",
          description: "",
          lat: "",
          long: "",
          country: "",
          city: "",
          postcode: "",
          address_line_1: "",
          address_line_2: "",
          additional_information: "",
          options: [],
        }
  );
  useEffect(() => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/fuel-station-services/get/all`)
      .then((res) => {
        setOptions(
          res?.data.map((item) => {
            return { value: item?.id, label: item?.name };
          })
        );
      })
      .catch((err) => {
        SweetAlert.fire({
          icon: "error",
          title: "API errors",
          text: "Something went wrong!",
        });
      });
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      // AUTO COMPLETE ADDRESS LINE 1 CHANGE THEN IT SHOULD REPLACE THE POSTCODE AND THE ADDRESS LINE 1
      const dis = distance(
        location?.coordinates.lat,
        location?.coordinates.lon,
        placeAutoComplete?.coordinates?.lat,
        placeAutoComplete?.coordinates?.lng
      );
      if (
        dis < 500 ||
        isNaN(dis) ||
        JSON.parse(localStorage.getItem("user"))?.roles[0]?.name ===
          "superadmin"
      ) {
        setData({
          ...data,
          address: placeAutoComplete?.full_address,
          lat: placeAutoComplete?.coordinates?.lat.toString(),
          long: placeAutoComplete?.coordinates?.lng.toString(),
          country: placeAutoComplete?.country,
          city: placeAutoComplete?.locality,
          postcode: placeAutoComplete?.postal_code,
          address_line_1: placeAutoComplete?.full_address,
        });
        setDistanceError("");
      } else {
        placeAutoComplete?.full_address &&
          setDistanceError("You are in a wrong place.");
      }
    }
  }, [placeAutoComplete]);

  const onSubmit = () => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      apiClient()
        .put(`${BACKEND_API}/v1.0/fuel-station`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Fuel Station Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }

    if (type === "create") {
      apiClient()
        .post(`${BACKEND_API}/v1.0/fuel-station`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Fuel Station Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "closing_time") {
      if (
        moment(data?.opening_time, "HH:mm").isBefore(moment(value, "HH:mm"))
      ) {
        setData({ ...data, closing_time: value });
      } else {
        if (data?.opening_time === "") {
          setData({ ...data, closing_time: value });
        } else {
          SweetAlert.fire(
            "Error!",
            `Job End Time cannot be less than start time!.`,
            "error"
          );
        }
      }
    }
    if (name === "opening_time") {
      if (
        moment(value, "HH:mm").isBefore(moment(data?.closing_time, "HH:mm"))
      ) {
        setData({ ...data, opening_time: value });
      } else {
        if (data?.closing_time === "") {
          setData({ ...data, opening_time: value });
        } else {
          SweetAlert.fire(
            "Error!",
            `Closing Time cannot be less than Opening time!.`,
            "error"
          );
        }
      }
    }
    setData({ ...data, [name]: value });
  };

  // CALCULATE THE DISTANCE BETWEEN ADDRESS AND LIVE LOCATION
  const distance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // km (change this constant to get miles)
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };

  return (
    <Row>
      <Col sm="12">
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <Container
              style={{
                width: "100%",
              }}
            >
              <Container
                style={{
                  width: "100%",
                }}
                className="form-row mb-2"
              >
                {type === "update" && (
                  <input
                    style={{
                      width: "100%",
                    }}
                    className="form-control border-secondary"
                    data-testid="fuel_station_id"
                    name="id"
                    type="hidden"
                    value={fuelStationUpdateData?.id}
                  />
                )}
                {/* lat of fuel station  */}
                <input
                  style={{
                    width: "100%",
                  }}
                  className="form-control border-secondary"
                  name="lat"
                  type="hidden"
                  data-testid="fuel_station_lat"
                  value={data?.lat}
                />
                {/* long of fuel station  */}
                <input
                  style={{
                    width: "100%",
                  }}
                  className="form-control border-secondary"
                  name="long"
                  type="hidden"
                  data-testid="fuel_station_long"
                  defaultValue={data?.long}
                />
                {/* FUEL STATION NAME  */}
                <Col style={{ width: "100%" }} sm="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Station Name*
                    </Label>
                  )}
                  <input
                    style={{
                      width: "100%",
                    }}
                    placeholder="Station Name*"
                    id="name"
                    className="form-control border-secondary"
                    data-testid="fuel_station_name"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    defaultValue={data?.name}
                  />

                  {serverSideErrors &&
                    (!serverSideErrors?.name ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.name[0]}
                      </div>
                    ))}
                </Col>

                {/* FUEL STATION ADDRESS  */}
                <Col style={{ width: "100%" }} sm="12 mb-3">
                  {type === "update" && (
                    <Label
                      className="text-uppercase fw-bolder"
                      htmlFor="address_line_1"
                    >
                      Address *
                    </Label>
                  )}
                  <input
                    style={{
                      width: "100%",
                    }}
                    id="address"
                    className="form-control border-secondary"
                    data-testid="fuel_station_address"
                    name="address"
                    type="text"
                    hidden
                    value={data?.address}
                  />

                  <AutoComplete
                    style={{
                      width: "100%",
                    }}
                    defaultValue={data?.address_line_1}
                    setPlaceAutoComplete={setPlaceAutoComplete}
                    class_Name={"form-control border-secondary"}
                    name="address_line_1"
                    id={"address_line_1"}
                    placeholder="Address *"
                  />
                  {distanceError !== "" && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {distanceError}
                    </div>
                  )}
                  {serverSideErrors &&
                    (!serverSideErrors?.address ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.address[0]}
                      </div>
                    ))}
                </Col>

                {/* FUEL STATION COUNTRY  */}
                <Col style={{ width: "100%" }} sm="12 mb-3">
                  {type === "update" && (
                    <Label className=" fw-bolder" htmlFor="country">
                      Country*
                    </Label>
                  )}
                  <Input
                    style={{
                      width: "100%",
                    }}
                    placeholder="Country*"
                    type="text"
                    className="custom-select border border-secondary"
                    data-testid="fuel_station_country"
                    name="country"
                    onChange={handleChange}
                    value={data?.country}
                  />

                  {serverSideErrors &&
                    (!serverSideErrors?.country ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.country[0]}
                      </div>
                    ))}
                </Col>
                {/* FUEL STATION CITY  */}
                <Col style={{ width: "100%" }} className="mb-3" sm="12">
                  {type === "update" && (
                    <Label className=" fw-bolder" htmlFor="city">
                      City*
                    </Label>
                  )}
                  <Input
                    style={{
                      width: "100%",
                    }}
                    placeholder=" City*"
                    type="text"
                    data-testid="fuel_station_city"
                    className="custom-select border border-secondary"
                    name="city"
                    value={data?.city}
                    onChange={handleChange}
                  />

                  {serverSideErrors &&
                    (!serverSideErrors?.city ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.city[0]}
                      </div>
                    ))}
                </Col>

                {/* FUEL STATION POST CODE */}
                <Col style={{ width: "100%" }} sm="12 mb-3">
                  {type === "update" && (
                    <Label className=" fw-bolder" htmlFor="postcode">
                      Postcode*
                    </Label>
                  )}
                  <Input
                    style={{
                      width: "100%",
                    }}
                    placeholder="Postcode*"
                    className="form-control border border-secondary w-100"
                    data-testid="fuel_station_postcode"
                    name="postcode"
                    type="text"
                    value={data.postcode}
                    onChange={handleChange}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors?.postcode ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.postcode[0]}
                      </div>
                    ))}
                </Col>

                {/* FUEL STATION DESCRIPTION  */}
                <Col style={{ width: "100%" }} sm="12 mb-3">
                  {type === "update" && (
                    <Label
                      className="text-uppercase fw-bolder"
                      htmlFor="description"
                    >
                      Description
                    </Label>
                  )}
                  <textarea
                    style={{
                      width: "100%",
                    }}
                    placeholder="Description"
                    defaultValue={data?.description}
                    data-testid="fuel_station_description"
                    id="description"
                    className="form-control border-secondary"
                    name="description"
                    rows={5}
                    onChange={handleChange}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors?.description ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.description[0]}
                      </div>
                    ))}
                </Col>
              </Container>
              <Container
                style={{ width: "100%" }}
                sm={12}
                className="form-row mb-2"
              >
                {/* FUEL STATION OPENNING TIME  */}
                <Col style={{ width: "50%" }} sm="6 mb-3">
                  {type === "update" && (
                    <Label
                      className="text-uppercase fw-bolder"
                      htmlFor="opening_time"
                    >
                      Opening Time*
                    </Label>
                  )}
                  <CustomTimeSelector
                    style={{
                      width: "100%",
                    }}
                    displayText={`Opening Time*`}
                    onChange={handleChange}
                    dataTestid="fuel_station_opening_time"
                    name={"opening_time"}
                    id={"opening_time"}
                    value={data?.opening_time}
                    required={false}
                    className={"form-control border-secondary"}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors?.opening_time ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.opening_time[0]}
                      </div>
                    ))}
                </Col>
                {/* FUEL STATION CLOSING TIME  */}
                <Col style={{ width: "50%" }} sm="6 mb-3">
                  {type === "update" && (
                    <Label
                      className="text-uppercase fw-bolder"
                      htmlFor="closing_time"
                    >
                      Closing Time*
                    </Label>
                  )}
                  <CustomTimeSelector
                    displayText={`Closing Time*`}
                    value={data?.closing_time}
                    onChange={handleChange}
                    dataTestid="fuel_station_closing_time"
                    className={"form-control border-secondary"}
                    name={"closing_time"}
                    id={"closing_time"}
                    required={false}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors?.closing_time ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.closing_time[0]}
                      </div>
                    ))}
                </Col>

                {/* FUEL STATION OPTIONS  */}
                <Col style={{ width: "100%" }} sm={12}>
                  {type === "update" && (
                    <Label
                      className="text-uppercase fw-bolder"
                      htmlFor="options"
                    >
                      Options
                    </Label>
                  )}
                  {console.log({ fs: fuelStationUpdateData?.options })}
                  <Select
                    placeholder="Select Services"
                    id="options"
                    defaultValue={
                      fuelStationUpdateData?.options
                        ? fuelStationUpdateData?.options.map((item) => {
                            return {
                              value: item?.option_id,
                              label: item?.option?.name,
                            };
                          })
                        : []
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        options: e.map((item) => {
                          return {
                            option_id: item?.value,
                            is_active: true,
                          };
                        }),
                      });
                    }}
                    isMulti
                    options={options}
                  />
                </Col>
              </Container>
            </Container>
          )}

          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button
                color="primary"
                className="w-100 py-2 rounded-3"
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                className="py-2 w-100 cancel-button rounded-3 text-secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
                color=""
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default FuelStationForm;
