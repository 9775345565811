import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import {
  COUPON_CREATE,
  COUPON_DELETE,
  QUIESTIONS_CREATE,
  QUIESTIONS_DELETE,
  QUIESTIONS_UPDATE,
  QUIESTIONS_VIEW,
} from "../../../../constant/permissions";
import Error401 from "../../../../pages/errors/Error401";
import { apiClient } from "../../../../utils/apiClient";
import { BACKEND_API } from "../../../../utils/backend";
import { checkPermissions } from "../../../../utils/helperFunctions";
import CustomLoadingSpiner from "../../../CustomLoadingSpiner/CustomLoadingSpiner";
import EditQuestion from "../EditQuestion/EditQuestion";
import ViewQuestion from "../EditQuestion/ViewQuestion";
import EditAdminQuestion from "../EditQuestion/EditAdminQuestion";
import ViewAdminQuestion from "../EditQuestion/ViewAdminQuestion";
import CreateQuestionAdminModal from "./CreateQuestionAdminModal";
// import CouponView from './CouponView';
// import CreateCoupon from './CreateCoupon';
// import FuelStationForm from './forms/FuelStationForm';
// import FuelStationView from './vews/FuelStationView';

const QuistionsList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [data, setData] = useState([]);
  const [couponViewData, setCouponViewData] = useState(null);
  const [couponViewModal, setCouponViewModal] = useState(false);
  const [couponUpdateData, setCouponUpdateData] = useState(null);
  const [couponUpdateModal, setCouponUpdateModal] = useState(false);
  const [couponCreateModal, setCouponCreateModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const garage_id = JSON.parse(localStorage.getItem("garageId"));

  // modal
  const couponCreateModaltoggle = () =>
    setCouponCreateModal(!couponCreateModal);
  const couponUpdateModaltoggle = () =>
    setCouponUpdateModal(!couponUpdateModal);

  const editForm = (el) => {
    setCouponUpdateData(el);
    console.log(el);
    couponUpdateModaltoggle();
  };
  const couponViewModaltoggle = () => setCouponViewModal(!couponViewModal);

  const viewForm = (el) => {
    couponViewModaltoggle();
    setCouponViewData(el);
  };

  // end modal
  const fetchData = () => {
    if (garage_id) {
      setLoading(true);
      apiClient()
        .get(
          `${BACKEND_API}/review-new/get/questions-all?garage_id=${garage_id}`
        )
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        });
    } else {
      setLoading(true);
      apiClient()
        .get(`${BACKEND_API}/review-new/get/questions-all`)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        });
    }
  };

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/review-new/delete/questions/${id}`)
          .then((response) => {
            if (response.data?.message === "ok") {
              fetchData();
              SweetAlert.fire(
                "Deleted!",
                "Question has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Question is safe");
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  if (!permissions.includes(QUIESTIONS_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* =========================== ALL MODALS =============================  */}
      {/* <Modal isOpen={couponCreateModal} toggle={couponCreateModaltoggle} size="md" className='rounded-xl'>
                <Row className='m-0 p-0 border-0 position-relative'>
                    <Col className='p-30 12 d-flex justify-content-center align-items-center flex-column w-100'>
                        <h4 className='fw-bolder text-black-50'>View Question</h4>
                    </Col>
                </Row>
                <ModalBody>

                </ModalBody>
            </Modal> */}
      <Modal
        isOpen={couponUpdateModal}
        toggle={couponUpdateModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <h4 className="fw-bolder text-secondary">Edit Question</h4>
          </Col>
        </Row>

        <ModalBody>
          {garage_id ? (
            <EditQuestion
              toggleModal={couponUpdateModaltoggle}
              fetchData={fetchData}
              setIsOpen={setCouponUpdateModal}
              garage_id={garage_id}
              editQuestion={couponUpdateData}
              edit_id={couponUpdateData?.id}
            />
          ) : (
            <EditAdminQuestion
              toggleModal={couponUpdateModaltoggle}
              fetchData={fetchData}
              setIsOpen={setCouponUpdateModal}
              editQuestion={couponUpdateData}
              edit_id={couponUpdateData?.id}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={couponViewModal}
        toggle={couponViewModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <h4 className="fw-bolder text-secondary">Question Details</h4>
          </Col>
        </Row>
        <ModalBody>
          {garage_id ? (
            <ViewQuestion
              toggleModal={couponViewModaltoggle}
              setIsOpen={setCouponViewModal}
              garage_id={garage_id}
              editQuestion={couponViewData}
              edit_id={couponViewData?.id}
            />
          ) : (
            <ViewAdminQuestion
              toggleModal={couponViewModaltoggle}
              setIsOpen={setCouponViewModal}
              garage_id={garage_id}
              editQuestion={couponViewData}
              edit_id={couponViewData?.id}
            />
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={couponCreateModal}
        toggle={couponCreateModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <h4 className="fw-bolder text-secondary">Create Question</h4>
          </Col>
        </Row>
        <ModalBody>
          <CreateQuestionAdminModal
            toggleModal={couponCreateModaltoggle}
            setIsOpen={setCouponCreateModal}
            fetchData={fetchData}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="Fuel Station Management / Fuel Stations" title="Manage Fuel Station" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <h5>Question Management</h5>
                  <span> Manage Quesions</span>
                </div>
                {checkPermissions([QUIESTIONS_CREATE], permissions) && (
                  <Button color="primary" onClick={couponCreateModaltoggle}>
                    Create Question
                  </Button>
                )}
              </CardHeader>
              <div className="table-responsive px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Question"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col">{"Options"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.length === 0 ? (
                          <td colSpan={7}>
                            <p>No Data Available</p>
                          </td>
                        ) : (
                          data.map((el, i) => {
                            return (
                              <tr className="Dashed" key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{el.question}</td>
                                <td>
                                  {el.is_active === 1 ? "Public" : "Private"}
                                </td>
                                <td>
                                  <div className="text-start d-flex gap-1 justify-content-start">
                                    {checkPermissions(
                                      [QUIESTIONS_VIEW],
                                      permissions
                                    ) && (
                                      <Button
                                        title="View Question"
                                        onClick={() => viewForm(el)}
                                        color="success"
                                        size="sm"
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Button>
                                    )}
                                    {checkPermissions(
                                      [QUIESTIONS_UPDATE],
                                      permissions
                                    ) && (
                                      <Button
                                        title="Update Quistion"
                                        onClick={() => editForm(el)}
                                        color="primary"
                                        size="sm"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Button>
                                    )}
                                    {checkPermissions(
                                      [QUIESTIONS_DELETE],
                                      permissions
                                    ) && (
                                      <Button
                                        onClick={() => deleteFunc(el.id)}
                                        color="danger"
                                        size="sm"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default QuistionsList;
