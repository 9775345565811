import { combineReducers } from 'redux'
import Common from '../redux/common/common'
import ChatApp from './chap-app/reducer'
import Customizer from './customizer/reducer'
import Filters from './ecommerce/Filter/reducer'
import Ecommerce from './ecommerce/Product/reducer'
import Wishlist from './ecommerce/Wishlist/reducer'
import Cart from './ecommerce/cart/reducer'
import EmailApp from './email/reducer'
import Todoapp from './todo/reducer'


import Auth from "./auth/reducer"

const reducers = combineReducers({
    Todoapp,
    data:Ecommerce,
    Common,
    filters:Filters,
    Wishlistdata:Wishlist,
    Cartdata:Cart,
    ChatApp,
    EmailApp,
    Customizer,


    Auth,
});

export default reducers;