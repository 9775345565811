import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import CustomTimeSelector from "../../fuel_station_management/components/CustomTImeSelector";

const ChangeStatus = ({
  setIsOpen,
  garageId,
  bookingId,
  fetchData,
  toggleModal,
  perPage,
  bookingDetails,
  setBookingDetails,
}) => {
  let currency = localStorage.getItem("currency");
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(bookingDetails?.job_start_date);
  const [endDate, setEndDate] = useState(bookingDetails?.job_end_time);

  // VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate job_start_date
    if (
      !bookingDetails?.job_start_date ||
      bookingDetails?.job_start_date === "Invalid Date"
    ) {
      newErrors.job_start_date = "Job start date is required.";
    }

    // Validate price
    if (!bookingDetails?.price) {
      newErrors.price = "Price is required.";
    }

    // Validate job_start_time
    if (
      !bookingDetails?.job_start_time ||
      bookingDetails?.job_start_time.trim() === ""
    ) {
      newErrors.job_start_time = "Job start time is required.";
    }

    // Validate job_start_time
    if (
      !bookingDetails?.job_end_time ||
      bookingDetails?.job_end_time === "Invalid Date"
    ) {
      newErrors.job_end_time = "Job end time is required.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      setServerSideErrors(null);
      const submitData = {
        ...bookingDetails,
        job_start_date: moment(startDate).format("YYYY-MM-DD"),
        id: bookingId,
        garage_id: garageId,
      };
      apiClient()
        .put(`${BACKEND_API}/v1.0/bookings/confirm`, submitData)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Booking Confirmed Successfully!",
              icon: "success",
            });
            fetchData(garageId, perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "job_end_time") {
      if (
        moment(bookingDetails?.job_start_time, "HH:mm").isBefore(
          moment(value, "HH:mm")
        )
      ) {
        setBookingDetails({ ...bookingDetails, job_end_time: value });
      } else {
        SweetAlert.fire(
          "Error!",
          `Job End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
    if (name === "job_start_time") {
      if (
        moment(value, "HH:mm").isBefore(
          moment(bookingDetails?.job_end_time, "HH:mm")
        )
      ) {
        setBookingDetails({ ...bookingDetails, job_start_time: value });
      } else {
        SweetAlert.fire(
          "Error!",
          `Job End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
    setBookingDetails({ ...bookingDetails, [name]: value });
  };

  return (
    <Row>
      <Col sm="12">
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <Container>
              <Container className="form-row mb-2">
                <Col sm="12 mb-3">
                  <Label className="text-uppercase" htmlFor="job_start_date">
                    Job Start Date*
                  </Label>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control digits ml-md-2 border-secondary"
                    showIcon
                    name="job_start_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  {errors?.job_start_date && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.job_start_date}
                    </div>
                  )}
                </Col>

                <Col sm="12">
                  <Label className="text-uppercase" htmlFor="price">
                    Price*
                  </Label>
                  <input
                    placeholder="Price*"
                    id="price"
                    style={{
                      paddingLeft: "40px",
                    }}
                    className="form-control border-secondary"
                    data-testid="price"
                    name="price"
                    type="number"
                    value={bookingDetails?.price}
                    onChange={handleChange}
                    required
                  />
                  <span className="currency_input fw-bold">{currency}</span>

                  {errors?.price && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.price}
                    </div>
                  )}
                </Col>
                <Col sm="6">
                  <Label className="text-uppercase" htmlFor="discount_type">
                    Discount Type
                  </Label>
                  <select
                    role="button"
                    id="discount_type"
                    name="discount_type"
                    value={bookingDetails?.discount_type}
                    data-testid="booking_discount_type"
                    className={"form-control border-secondary w-100"}
                    onChange={handleChange}
                  >
                    <option>Select Discount Type</option>
                    {console.log({ bookingDetails })}
                    <option value={`percentage`}>Percentage</option>
                    <option value={`fixed`}>Fixed</option>
                  </select>

                  {errors?.discount_type && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.discount_type}
                    </div>
                  )}
                </Col>
                <Col sm="6">
                  <Label className="text-uppercase" htmlFor="discount_amount">
                    Discount Amount
                  </Label>
                  <input
                    placeholder="Discount amount*"
                    id="discount_amount"
                    style={{
                      paddingLeft: "40px",
                    }}
                    className="form-control border-secondary"
                    data-testid="booking_discount_amount"
                    name="discount_amount"
                    type="number"
                    value={bookingDetails?.discount_amount}
                    onChange={handleChange}
                    required
                  />
                  <span className="currency_input fw-bold">{currency}</span>
                  {errors?.discount_amount && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.discount_amount}
                    </div>
                  )}
                </Col>
                <Col sm="6 mb-3">
                  <Label className="text-uppercase" htmlFor="job_start_time">
                    Start Time*
                  </Label>
                  <CustomTimeSelector
                    displayText={`Start Time*`}
                    onChange={handleChange}
                    dataTestid="fuel_station_job_start_time"
                    name={"job_start_time"}
                    id={"job_start_time"}
                    defaultValue={bookingDetails?.job_start_time}
                    required={false}
                    className={"form-control border-secondary"}
                  />
                  {errors?.job_start_time && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.job_start_time}
                    </div>
                  )}
                </Col>
                <Col sm="6 mb-3">
                  <Label className="text-uppercase" htmlFor="job_end_time">
                    End Time*
                  </Label>
                  <CustomTimeSelector
                    displayText={`End Time*`}
                    defaultValue={endDate}
                    onChange={handleChange}
                    dataTestid="fuel_station_job_end_time"
                    className={"form-control border-secondary"}
                    name={"job_end_time"}
                    id={"job_end_time"}
                    required={false}
                  />
                  {errors?.job_end_time && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.job_end_time}
                    </div>
                  )}
                </Col>
              </Container>
            </Container>
          )}

          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button
                color="primary"
                className="w-100 py-2 rounded-3"
                onClick={() => {
                  onSubmit();
                }}
              >
                Confirm
              </Button>
              <Button
                className="py-2 w-100 cancel-button rounded-3 text-secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
                color=""
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ChangeStatus;
