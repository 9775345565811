import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { apiClient } from '../../../utils/apiClient';
import { BACKEND_API } from '../../../utils/backend';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';


const ServiceForm = ({ toggleModal, fetchData, perPage, type, serviceUpdateData, setIsOpen }) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [automobileCategories, setAutomobileCategories] = useState([]);
  const formValues = watch();

  useEffect(() => {
    loadAutomobileCategories()
    if (type === "update") {
      const fields = ['id', 'name', 'description', 'image', 'automobile_category_id'];
      fields.forEach(field => setValue(field, serviceUpdateData[field]));
      setValue("category", serviceUpdateData.category?.name)
    }
    return () => {

    };
  }, []);

  const loadAutomobileCategories = () => {
    apiClient().get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then(response => {
        setAutomobileCategories(response.data)
      })
      .catch(error => {
        if (error.response?.status === 401) {
          SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
        }
        else {
          SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      })
  }

  const onSubmit = data => {
    setLoading(true)
    setServerSideErrors(null)
    if (type === "update") {
      apiClient().put(`${BACKEND_API}/v1.0/services`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Service Updated Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })

    }
    if (type === "create") {
      apiClient().post(`${BACKEND_API}/v1.0/services`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Service Created Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })

    }
  };

  return (
    <Row>
      <Container>
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
          {loading ? (
            <CustomLoadingSpiner />
          ) :
            <div>
              <Row className='mb-1'>
                <Col md="12">
                  <FormGroup className="">
                  {type === "update" && <Label className='text-uppercase' htmlFor="automobile_category_id">Select Automobile Category*</Label>}
                    <select
                      id='automobile_category_id'
                      type="select"
                      className={`form-control border-secondary custom-select ${errors?.firstName && 'is-invalid'}`}
                      name="automobile_category_id"
                      {...register('automobile_category_id', { required: false })}
                      value={formValues.automobile_category_id ? formValues.automobile_category_id : serviceUpdateData?.automobile_category_id}
                      onChange={(e) => setValue("automobile_category_id", e.target.value)}
                    >
                      <option value="">{"Select Automobile Category*"}</option>
                      {automobileCategories.map(el => {
                        return (
                          <option value={el.id} key={el.id} >{el.name}</option>
                        )
                      })}
                    </select>
                    {serverSideErrors && (
                      !serverSideErrors.automobile_category_id ? (
                        <div className="valid-feedback" style={{ display: "block" }}>
                          Looks good!
                        </div>
                      ) : (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {serverSideErrors.automobile_category_id[0]}
                        </div>
                      )
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row className='mb-1'>
                <input
                  name="id"
                  type="hidden"
                  className={`form-control custom-select border-secondary ${errors?.firstName && 'is-invalid'}`}
                  {...register('id', { required: false })}
                  value={formValues.id ? formValues.id : serviceUpdateData?.id}
                  onChange={(e) => setValue("id", e.target.value)}
                />
                <Col md="12 mb-1">
                  <FormGroup className="mb-1">
                    {type === "update" && <Label className='text-uppercase' htmlFor="name">Name *</Label>}
                    <input
                      id='name'
                      placeholder='Name *'
                      {...register('name', { required: false })}
                      className="form-control border-secondary"
                      name="name"
                      type="text"
                    />
                    <span>{errors?.name && 'Please provide First name'}</span>
                    {serverSideErrors && (
                      !serverSideErrors?.name ? (
                        <div className="valid-feedback" style={{ display: "block" }}>
                          Looks good!
                        </div>
                      ) : (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {serverSideErrors?.name[0]}
                        </div>
                      )
                    )}
                  </FormGroup>
                </Col>

                <Col md="12 mb-1">
                  <FormGroup className="mb-3">
                    {type === "update" && <Label className='text-uppercase' htmlFor="description">Description</Label>}
                    <textarea
                      rows={5}
                      placeholder='Description'
                      id='description'
                      className="form-control border-secondary"
                      name="description"
                      type="textarea"
                      {...register('description', { required: false })}
                    />
                    <span>{errors?.description && 'Please provide Description'}</span>

                    {serverSideErrors && (
                      !serverSideErrors?.description ? (
                        <div className="valid-feedback" style={{ display: "block" }}>
                          Looks good!
                        </div>
                      ) : (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {serverSideErrors?.description[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>
            </div>}

          <Row className='mb-3'>
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button color='primary' className='w-100 py-2 rounded-3'>Submit</Button>
              <Button className='py-2 w-100 cancel-button rounded-3 text-secondary' onClick={() => { setIsOpen(false) }} color=''>Cancel</Button>
            </Col>
          </Row>
        </Form>

      </Container>
    </Row>
  )
}

export default ServiceForm