import React, { useState } from "react";
import { LogOut, Users } from "react-feather";
import { MdOutlineChangeCircle, MdOutlinePassword } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getGaragesByGivenToken } from "../../api/garages";
import { Admin, LogOuts, Profile } from "../../constant";
import useOutsideDropdown from "../../services/useOutsideDropdown";
import { encryptionID } from "../../utils/encription";

const UserActivity = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const Logout = () => {
    localStorage.clear();
    navigate(`${process.env.PUBLIC_URL}/login`);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideDropdown(false);

  const token = localStorage.getItem("token");

  const handleSwitchGarage = () => {
    getGaragesByGivenToken(token).then((res) => {
      // GET ALL GARAGES BY OWNER
      Swal.fire({
        icon: "question",
        iconHtml: "?",
        title: "Select A Garage",
        input: "select",
        inputOptions: res.reduce((options, garage) => {
          options[garage.id] = garage.name;
          return options;
        }, {}),
        inputPlaceholder: "Select One",
        inputValidator: (value) => {
          if (!value) {
            // IF USER NOT SELECT ANY GARAGE
            return "You need to select at least a Garage!";
          } else {
            // IF USER SELECT A GARAGE
            localStorage.setItem("garageId", value);
            window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
          }
        },
      });
    });
  };
  return (
    <div
      title={user?.name}
      ref={ref}
      className={`dropdown  ${isComponentVisible && "show"}`}
    >
      <span
        role="button"
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        <span className="media user-header">
          <img
            className="me-2 rounded-circle img-35"
            src={
              user?.image ? user?.image : "https://i.ibb.co/02q5FpM/avater.png"
            }
            alt="user-header"
          />
          <span className="media-body">
            <span className="f-12 f-w-600">
              {user?.first_Name} {user?.last_Name}
            </span>
            <span className="d-block">
              {user?.roles[0]?.name.split("_").join(" ")}
            </span>
          </span>
        </span>
      </span>
      <div
        style={{ right: "0px" }}
        className={`p-0 dropdown-menu ${isComponentVisible && "show"}`}
      >
        <ul className="profile-dropdown">
          <li className="gradient-primary-1">
            <h6 className="mb-0 text-capitalize">
              {user?.first_Name} {user?.last_Name}
            </h6>
            <span className="text-capitalize">
              {user?.roles[0]?.name.split("_").join(" ")}
            </span>
          </li>
          <li style={{ cursor: "pointer" }}>
            <Link to={`/profile/${encryptionID(user?.id)}`}>
              <Users />
              {Profile}
            </Link>
          </li>
          {user.roles[0]?.name === "garage_owner" && (
            <li role="button" onClick={handleSwitchGarage}>
              <MdOutlineChangeCircle /> Switch Garage
            </li>
          )}
          <li style={{ cursor: "pointer" }}>
            <Link to={`/change-password/${encryptionID(user?.id)}`}>
              <MdOutlinePassword /> Change Password
            </Link>
          </li>
          <li style={{ cursor: "pointer" }} onClick={Logout}>
            <LogOut />
            {LogOuts}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserActivity;
