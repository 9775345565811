import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { applyJobData, editJobBidData } from "../../../api/findJobs";
import CustomTimeSelector from "../../fuel_station_management/components/CustomTImeSelector";
import axios from "axios";
import { BACKEND_API } from "../../../utils/backend";
import { getGarageTiming } from "../../../utils/GetGarageTimingFromDateSelection";
import CustomTImeSelectorTenMinInterval from "../../fuel_station_management/components/CustomTImeSelectorTenMinInterval";

function EditJobdBid({
  modal,
  toggleModal,
  garageId,
  jobBidId,
  fetchData,
  perPage,
  jobBidDetails,
}) {
  let currency = localStorage.getItem("currency");
  // const externalCloseBtn = (
  //   <button
  //     type="button"
  //     className="close"
  //     style={{ position: "absolute", top: "15px", right: "15px" }}
  //     onClick={toggleModal}
  //   >
  //     &times;
  //   </button>
  // );
  const [submitData, setSubmitData] = useState({
    id: jobBidId,
    garage_id: garageId,
    pre_booking_id: jobBidDetails?.pre_booking?.id,
    price: jobBidDetails?.price,
    job_start_date: jobBidDetails?.job_start_date,
    job_start_time: moment(jobBidDetails?.job_start_time, "HH:mm:ss").format(
      "HH:mm"
    ),
    job_end_time: moment(jobBidDetails?.job_end_time, "HH:mm:ss").format(
      "HH:mm"
    ),
    offer_template: jobBidDetails?.offer_template,
  });
  const [startDate, setstartDate] = useState(
    new Date(submitData?.job_start_date)
  );
  const startTime = (e) => {
    if (
      moment(e.target.value, "HH:mm").isBefore(
        moment(submitData?.job_end_time, "HH:mm")
      )
    ) {
      setSubmitData({ ...submitData, job_start_time: e.target.value });
    } else {
      SweetAlert.fire(
        "Error!",
        `Job End Time cannot be less than start time!.`,
        "error"
      );
    }
  };
  const endTime = (e) => {
    if (
      moment(submitData?.job_start_time, "HH:mm").isBefore(
        moment(e.target.value, "HH:mm")
      )
    ) {
      setSubmitData({ ...submitData, job_end_time: e.target.value });
    } else {
      SweetAlert.fire(
        "Error!",
        `Job End Time cannot be less than start time!.`,
        "error"
      );
    }
  };

  // VALIDATE TIME FUNCTIONALITY
  const [timeRange, setTimeRange] = useState({
    startingTime: "",
    closingTime: "",
    isClosed: 0,
  });

  useEffect(() => {
    setTimeRange(
      getGarageTiming(
        moment(jobBidDetails?.job_start_date).format("ddd MMM DD YYYY")
      )
    );
    console.log({ date: jobBidDetails?.job_start_date });
    console.log({ fromList: getGarageTiming(jobBidDetails?.job_start_date) });
  }, [startDate]);
  // VALIDATE
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // Validate price
    if (!submitData?.price) {
      newErrors.price = "Price is required.";
    } else if (submitData.price.length === 0) {
      newErrors.price = "Price is required.";
    }
    // Validate START DATE
    if (!submitData?.job_start_date) {
      newErrors.job_start_date = "Job start date is required.";
    } else if (submitData.job_start_date === "") {
      newErrors.job_start_date = "Job start date is required.";
    } else if (timeRange?.isClosed) {
      newErrors.job_start_date = "Selected day is offday for your garage.";
    }
    // Validate START TIME
    if (!submitData?.job_start_time) {
      newErrors.job_start_time = "Job start time is required.";
    } else if (submitData.job_start_time === "") {
      newErrors.job_start_time = "Job start time is required.";
    }
    // Validate END TIME
    if (!submitData?.job_end_time) {
      newErrors.job_end_time = "Job end time is required.";
    } else if (submitData.job_end_time === "") {
      newErrors.job_end_time = "Job end time is required.";
    }
    // Validate END TIME
    if (
      !submitData?.offer_template ||
      submitData?.offer_template.trim() === ""
    ) {
      newErrors.offer_template = "Note is required.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const applyJobSubmit = () => {
    if (validateForm()) {
      editJobBidData(submitData)
        .then((res) => {
          fetchData(garageId, perPage);
          SweetAlert.fire("Applied!", `Successfully Edited qoute.`, "success");
          toggleModal();
        })
        .catch((err) => {
          SweetAlert.fire({
            title: err.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        // external={externalCloseBtn}
      >
        <ModalHeader>Edit Quote</ModalHeader>
        <ModalBody>
          <Container className="form-row mb-2">
            {/* START DATE  */}
            <Col md="6 w-100 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="closing_time"
              >
                Job Start Date
              </Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control digits border-secondary "
                selected={startDate}
                onChange={(date) => {
                  setstartDate(date);
                  setSubmitData({
                    ...submitData,
                    job_start_date: moment(date).format("YYYY-MM-DD"),
                    job_start_time: "",
                    job_end_time: "",
                  });
                }}
                selectsStart
                startDate={startDate}
              />
              {errors?.job_start_date && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_start_date}
                </p>
              )}
            </Col>

            {/* JOB START TIME  */}
            <Col sm="6 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="job_start_time"
              >
                Job Start Time
              </Label>
              <CustomTImeSelectorTenMinInterval
                restrictionTime={timeRange}
                onChange={startTime}
                dataTestid="job_start_time"
                name={"job_start_time"}
                id={"job_start_time"}
                required={false}
                className={"form-control border-secondary"}
                value={submitData?.job_start_time}
              />
              {errors?.job_start_time && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_start_time}
                </p>
              )}
            </Col>

            {/* JOB CLOSING TIME  */}
            <Col sm="6 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="job_end_time"
              >
                Job End Time
              </Label>
              <CustomTImeSelectorTenMinInterval
                restrictionTime={timeRange}
                onChange={endTime}
                dataTestid="job_end_time"
                className={"form-control border-secondary"}
                name={"job_end_time"}
                id={"job_end_time"}
                required={false}
                value={submitData?.job_end_time}
              />
              {errors?.job_end_time && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_end_time}
                </p>
              )}
            </Col>

            {/* PRICE  */}
            <Col className="w-100" md="6">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="closing_time"
              >
                Quote Price
              </Label>
              <input
                value={submitData?.price}
                onChange={(e) =>
                  setSubmitData({ ...submitData, price: e.target.value })
                }
                placeholder="Enter Job Price"
                className="w-100 ps-5 pe-3 py-2 rounded border border-1 border-secondary"
                type="number"
              />
              <span className="currency_input_apply_job fw-bold">
                {currency}
              </span>
              {errors?.price && (
                <p className="text-danger -mt-25 text-left">{errors?.price}</p>
              )}
            </Col>

            {/* NOTE  */}
            <Col className="w-100" md="12">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="offer_template"
              >
                Note
              </Label>
              <textarea
                name="offer_template"
                id="offer_template"
                rows="3"
                value={submitData?.offer_template}
                onChange={(e) =>
                  setSubmitData({
                    ...submitData,
                    offer_template: e.target.value,
                  })
                }
                placeholder="Enter Notes"
                className="px-3 py-2 rounded border border-1 border-secondary"
                style={{
                  width: "100%",
                }}
              />
              {errors?.offer_template && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.offer_template}
                </p>
              )}
            </Col>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={applyJobSubmit}>
            Save Quote
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditJobdBid;
