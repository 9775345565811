import { apiClient } from '../utils/apiClient';
import { BACKEND_API } from '../utils/backend';
import { getPermissions } from '../utils/helperFunctions';

export const handleResponse = async () => {
  return await apiClient().get(`${BACKEND_API}/v1.0/user`)
    .then(response => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data));
      localStorage.setItem('permissions', JSON.stringify(getPermissions(response.data)));
      return true;
    })
    .catch(err => {
      console.log(err.response)
      localStorage.clear();
      return false;
    })
}
