import React from "react";
import { FaStar } from "react-icons/fa";
import styles from "./ReviewSummary.module.css";

export default function ReviewSummary({
  restaurantName,
  averageRating,
  ratingLength,
}) {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="bg-gradient-primary rounded-xl w-full md:w-10/12">
        <div className={styles.viewRatingContainer1}>
          <div className={styles.reviewChild1}>
            <h2 className={`${styles.restairantName} text-white font-semibold`}>
              {restaurantName}
            </h2>
            <div className={styles.middle}>
              <div
                className={`d-flex justify-content-start align-items-center flex-column`}
              >
                <h3 className="text-white">
                  {averageRating?.total_rating?.toFixed(1)}{" "}
                  <FaStar className="text-warning ml-[10px!important]" />
                </h3>
                <span className="based_on_ratings bg-white">
                  Based on {ratingLength} ratings
                </span>
              </div>
            </div>
          </div>
          <div className={styles.reviewChild2}>
            <div className={styles.reviewC}>
              <div className={styles.review}>
                <p className={styles.starLabel}>5 stars</p>
                <div className={styles.w3_light_grey}>
                  <div
                    className="w3-container w3-green w3-center"
                    style={{
                      width: `${
                        (100 / ratingLength) *
                        averageRating?.star_5_selected_count
                      }%`,
                    }}
                  >
                    500
                  </div>
                </div>
                <p className={styles.starNumber}>
                  {averageRating?.star_5_selected_count}{" "}
                </p>
              </div>
            </div>
            <br />

            <div className={styles.reviewC}>
              <div className={styles.review}>
                <p className={styles.starLabel}>4 stars</p>
                <div className={styles.w3_light_grey}>
                  <div
                    className="w3-container w3-green w3-center"
                    style={{
                      width: `${
                        (100 / ratingLength) *
                        averageRating?.star_4_selected_count
                      }%`,
                    }}
                  >
                    250
                  </div>
                </div>
                <p className={styles.starNumber}>
                  {averageRating?.star_4_selected_count}{" "}
                </p>
              </div>
            </div>
            <br />

            <div className={styles.reviewC}>
              <div className={styles.review}>
                <p className={styles.starLabel}>3 stars</p>
                <div className={styles.w3_light_grey}>
                  <div
                    className="w3-container w3-green w3-center"
                    style={{
                      width: `${
                        (100 / ratingLength) *
                        averageRating?.star_3_selected_count
                      }%`,
                    }}
                  >
                    100
                  </div>
                </div>
                <p className={styles.starNumber}>
                  {averageRating?.star_3_selected_count}{" "}
                </p>
              </div>
            </div>
            <br />

            <div className={styles.reviewC}>
              <div className={styles.review}>
                <p className={styles.starLabel}>2 stars</p>
                <div className={styles.w3_light_grey}>
                  <div
                    className="w3-container w3-green w3-center"
                    style={{
                      width: `${
                        (100 / ratingLength) *
                        averageRating?.star_2_selected_count
                      }%`,
                    }}
                  >
                    125
                  </div>
                </div>
                <p className={styles.starNumber}>
                  {averageRating?.star_2_selected_count}{" "}
                </p>
              </div>
            </div>
            <br />
            <div className={styles.reviewC}>
              <div className={styles.review}>
                <p className={styles.starLabel}>1 stars</p>
                <div className={styles.w3_light_grey}>
                  <div
                    className="w3-container w3-green w3-center"
                    style={{
                      width: `${
                        (100 / ratingLength) *
                        averageRating?.star_1_selected_count
                      }%`,
                    }}
                  >
                    125
                  </div>
                </div>
                <p className={styles.starNumber}>
                  {averageRating?.star_1_selected_count}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
