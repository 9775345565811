import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { BiPackage } from "react-icons/bi";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import {
  GARAGE_PACKAGE_CREATE,
  GARAGE_PACKAGE_DELETE,
  GARAGE_PACKAGE_UPDATE,
  GARAGE_PACKAGE_VIEW,
} from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import { checkPermissions } from "../../../utils/helperFunctions";
import setLinksView from "../../../utils/pagination";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import PackageForm from "./PackageForm";
import PackageView from "./PackageView";

const PackageList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));
  let currency = localStorage.getItem("currency");
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [packageViewData, setPackageViewData] = useState(null);
  const [packageViewModal, setPackageViewModal] = useState(false);
  const [packageUpdateData, setPackageUpdateData] = useState(null);
  const [packageUpdateModal, setPackageUpdateModal] = useState(false);
  const [packageCreateModal, setPackageCreateModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const garage_id = JSON.parse(localStorage.getItem("garageId"));

  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/garage-packages/${garage_id}/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/garage-packages/${garage_id}/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  // modal
  const packageCreateModaltoggle = () =>
    setPackageCreateModal(!packageCreateModal);
  const packageUpdateModaltoggle = () =>
    setPackageUpdateModal(!packageUpdateModal);

  const editForm = (el) => {
    setPackageUpdateData(el);
    packageUpdateModaltoggle();
  };
  const packageViewModaltoggle = () => setPackageViewModal(!packageViewModal);

  const viewForm = (el) => {
    packageViewModaltoggle();
    setPackageViewData(el);
  };

  // end modal
  const fetchData = (urlOrPerPage) => {
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/garage-packages/${garage_id}/${urlOrPerPage}`;
    }
    apiClient()
      .get(url)
      .then((response) => {
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(
            `${BACKEND_API}/v1.0/garage-packages/single/${garage_id}/${id}`
          )
          .then((response) => {
            if (response.status === 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire(
                "Deleted!",
                "Package has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Package is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/garage-packages/${garage_id}/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchData(perPage);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (!permissions.includes(GARAGE_PACKAGE_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* =========================== ALL MODALS =============================  */}
      <Modal
        isOpen={packageCreateModal}
        toggle={packageCreateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <BiPackage className="icon-filter fs-1 mb-2 text-primary"></BiPackage>
            <h4 className="fw-bolder text-black-50">Create Package</h4>
          </Col>
        </Row>
        <ModalBody>
          <PackageForm
            toggleModal={packageCreateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="create"
            setIsOpen={setPackageCreateModal}
            garageId={garage_id}
            currency={currency}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={packageUpdateModal}
        toggle={packageUpdateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <BiPackage className="icon-filter fs-1 mb-2 text-primary"></BiPackage>
            <h4 className="fw-bolder text-secondary">Update Package</h4>
          </Col>
        </Row>

        <ModalBody>
          <PackageForm
            toggleModal={packageUpdateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            packageUpdateData={packageUpdateData}
            setIsOpen={setPackageUpdateModal}
            garageId={garage_id}
            currency={currency}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={packageViewModal}
        toggle={packageViewModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <BiPackage className="icon-filter fs-1 mb-2 text-primary" />
            <h4 className="fw-bolder text-secondary">Package Details</h4>
          </Col>
        </Row>

        <ModalBody>
          <PackageView
            viewData={packageViewData}
            setIsOpen={setPackageViewModal}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="Fuel Station Management / Fuel Stations" title="Manage Fuel Station" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <h5>Package Management</h5>
                  <span> Manage Packages</span>
                </div>

                {checkPermissions([GARAGE_PACKAGE_CREATE], permissions) ? (
                  <Button color="primary" onClick={packageCreateModaltoggle}>
                    Create Package
                  </Button>
                ) : null}
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Package Name"}</th>
                      <th scope="col">{"Services"}</th>
                      <th scope="col">{"Price"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col" className="text-center">
                        {"Action"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, i) => {
                          return (
                            <tr className="Dashed" key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{el.name}</td>
                              <td>
                                <ul>
                                  {el.garage_package_sub_services?.map(
                                    (res, index) => (
                                      <li>
                                        {index + 1}. {res?.sub_service?.name}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </td>
                              <td>
                                <span className="fw-bold">{currency}</span>{" "}
                                {el.price}
                              </td>
                              <td>{el.description}</td>
                              <td>
                                {el.is_active === 1 ? "Active" : "Not Active"}
                              </td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [GARAGE_PACKAGE_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      title="View Package"
                                      onClick={() => viewForm(el)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [GARAGE_PACKAGE_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      title="Edit Package"
                                      onClick={() => editForm(el)}
                                      color="primary"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [GARAGE_PACKAGE_DELETE],
                                    permissions
                                  ) && (
                                    <Button
                                      title="Delete Package"
                                      onClick={() => deleteFunc(el.id)}
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links &&
                        links.map((el, index, arr) =>
                          setLinksView(
                            el,
                            index,
                            arr,
                            fetchData,
                            current_page,
                            lastPage
                          )
                        )}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PackageList;
