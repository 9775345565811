import React from "react";

export default function CustomTimeSelector({
  className,
  name,
  defaultValue,
  id,
  register,
  required,
  dataTestid,
  onChange,
  displayText,
  value,
  everyClass,
}) {
  return (
    <>
      {value === undefined ? (
        <select
          data-testid={dataTestid}
          className={className}
          defaultValue={defaultValue}
          name={name}
          id={id}
          onChange={onChange}
        >
          <option className={`${everyClass}`} value="">
            {displayText ? displayText : "Select Time"}
          </option>

          <option className={`${everyClass}`} value="00:00">
            12:00 AM
          </option>
          <option className={`${everyClass}`} value="00:15">
            12:15 AM
          </option>
          <option className={`${everyClass}`} value="00:30">
            12:30 AM
          </option>
          <option className={`${everyClass}`} value="00:45">
            12:45 AM
          </option>

          <option className={`${everyClass}`} value="01:00">
            01:00 AM
          </option>
          <option className={`${everyClass}`} value="01:15">
            01:15 AM
          </option>
          <option className={`${everyClass}`} value="01:30">
            01:30 AM
          </option>
          <option className={`${everyClass}`} value="01:45">
            01:45 AM
          </option>

          <option className={`${everyClass}`} value="02:00">
            02:00 AM
          </option>
          <option className={`${everyClass}`} value="02:15">
            02:15 AM
          </option>
          <option className={`${everyClass}`} value="02:30">
            02:30 AM
          </option>
          <option className={`${everyClass}`} value="02:45">
            02:45 AM
          </option>

          <option className={`${everyClass}`} value="03:00">
            03:00 AM
          </option>
          <option className={`${everyClass}`} value="03:15">
            03:15 AM
          </option>
          <option className={`${everyClass}`} value="03:30">
            03:30 AM
          </option>
          <option className={`${everyClass}`} value="03:45">
            03:45 AM
          </option>

          <option className={`${everyClass}`} value="04:00">
            04:00 AM
          </option>
          <option className={`${everyClass}`} value="04:15">
            04:15 AM
          </option>
          <option className={`${everyClass}`} value="04:30">
            04:30 AM
          </option>
          <option className={`${everyClass}`} value="04:45">
            04:45 AM
          </option>

          <option className={`${everyClass}`} value="05:00">
            05:00 AM
          </option>
          <option className={`${everyClass}`} value="05:15">
            05:15 AM
          </option>
          <option className={`${everyClass}`} value="05:30">
            05:30 AM
          </option>
          <option className={`${everyClass}`} value="05:45">
            05:45 AM
          </option>

          <option className={`${everyClass}`} value="06:00">
            06:00 AM
          </option>
          <option className={`${everyClass}`} value="06:15">
            06:15 AM
          </option>
          <option className={`${everyClass}`} value="06:30">
            06:30 AM
          </option>
          <option className={`${everyClass}`} value="06:45">
            06:45 AM
          </option>

          <option className={`${everyClass}`} value="07:00">
            07:00 AM
          </option>
          <option className={`${everyClass}`} value="07:15">
            07:15 AM
          </option>
          <option className={`${everyClass}`} value="07:30">
            07:30 AM
          </option>
          <option className={`${everyClass}`} value="07:45">
            07:45 AM
          </option>

          <option className={`${everyClass}`} value="08:00">
            08:00 AM
          </option>
          <option className={`${everyClass}`} value="08:15">
            08:15 AM
          </option>
          <option className={`${everyClass}`} value="08:30">
            08:30 AM
          </option>
          <option className={`${everyClass}`} value="08:45">
            08:45 AM
          </option>

          <option className={`${everyClass}`} value="09:00">
            09:00 AM
          </option>
          <option className={`${everyClass}`} value="09:15">
            09:15 AM
          </option>
          <option className={`${everyClass}`} value="09:30">
            09:30 AM
          </option>
          <option className={`${everyClass}`} value="09:45">
            09:45 AM
          </option>

          <option className={`${everyClass}`} value="10:00">
            10:00 AM
          </option>
          <option className={`${everyClass}`} value="10:15">
            10:15 AM
          </option>
          <option className={`${everyClass}`} value="10:30">
            10:30 AM
          </option>
          <option className={`${everyClass}`} value="10:45">
            10:45 AM
          </option>

          <option className={`${everyClass}`} value="11:00">
            11:00 AM
          </option>
          <option className={`${everyClass}`} value="11:15">
            11:15 AM
          </option>
          <option className={`${everyClass}`} value="11:30">
            11:30 AM
          </option>
          <option className={`${everyClass}`} value="11:45">
            11:45 AM
          </option>

          <option className={`${everyClass}`} value="12:00">
            12:00 PM
          </option>
          <option className={`${everyClass}`} value="12:15">
            12:15 PM
          </option>
          <option className={`${everyClass}`} value="12:30">
            12:30 PM
          </option>
          <option className={`${everyClass}`} value="12:45">
            12:45 PM
          </option>

          <option className={`${everyClass}`} value="13:00">
            1:00 PM
          </option>
          <option className={`${everyClass}`} value="13:15">
            1:15 PM
          </option>
          <option className={`${everyClass}`} value="13:30">
            1:30 PM
          </option>
          <option className={`${everyClass}`} value="13:45">
            1:45 PM
          </option>

          <option className={`${everyClass}`} value="14:00">
            2:00 PM
          </option>
          <option className={`${everyClass}`} value="14:15">
            2:15 PM
          </option>
          <option className={`${everyClass}`} value="14:30">
            2:30 PM
          </option>
          <option className={`${everyClass}`} value="14:45">
            2:45 PM
          </option>

          <option className={`${everyClass}`} value="15:00">
            3:00 PM
          </option>
          <option className={`${everyClass}`} value="15:15">
            3:15 PM
          </option>
          <option className={`${everyClass}`} value="15:30">
            3:30 PM
          </option>
          <option className={`${everyClass}`} value="15:45">
            3:45 PM
          </option>

          <option className={`${everyClass}`} value="16:00">
            4:00 PM
          </option>
          <option className={`${everyClass}`} value="16:15">
            4:15 PM
          </option>
          <option className={`${everyClass}`} value="16:30">
            4:30 PM
          </option>
          <option className={`${everyClass}`} value="16:45">
            4:45 PM
          </option>

          <option className={`${everyClass}`} value="17:00">
            5:00 PM
          </option>
          <option className={`${everyClass}`} value="17:15">
            5:15 PM
          </option>
          <option className={`${everyClass}`} value="17:30">
            5:30 PM
          </option>
          <option className={`${everyClass}`} value="17:45">
            5:45 PM
          </option>

          <option className={`${everyClass}`} value="18:00">
            6:00 PM
          </option>
          <option className={`${everyClass}`} value="18:15">
            6:15 PM
          </option>
          <option className={`${everyClass}`} value="18:30">
            6:30 PM
          </option>
          <option className={`${everyClass}`} value="18:45">
            6:45 PM
          </option>

          <option className={`${everyClass}`} value="19:00">
            7:00 PM
          </option>
          <option className={`${everyClass}`} value="19:15">
            7:15 PM
          </option>
          <option className={`${everyClass}`} value="19:30">
            7:30 PM
          </option>
          <option className={`${everyClass}`} value="19:45">
            7:45 PM
          </option>

          <option className={`${everyClass}`} value="20:00">
            8:00 PM
          </option>
          <option className={`${everyClass}`} value="20:15">
            8:15 PM
          </option>
          <option className={`${everyClass}`} value="20:30">
            8:30 PM
          </option>
          <option className={`${everyClass}`} value="20:45">
            8:45 PM
          </option>

          <option className={`${everyClass}`} value="21:00">
            9:00 PM
          </option>
          <option className={`${everyClass}`} value="21:15">
            9:15 PM
          </option>
          <option className={`${everyClass}`} value="21:30">
            9:30 PM
          </option>
          <option className={`${everyClass}`} value="21:45">
            9:45 PM
          </option>

          <option className={`${everyClass}`} value="22:00">
            10:00 PM
          </option>
          <option className={`${everyClass}`} value="22:15">
            10:15 PM
          </option>
          <option className={`${everyClass}`} value="22:30">
            10:30 PM
          </option>
          <option className={`${everyClass}`} value="22:45">
            10:45 PM
          </option>

          <option className={`${everyClass}`} value="23:00">
            11:00 PM
          </option>
          <option className={`${everyClass}`} value="23:15">
            11:15 PM
          </option>
          <option className={`${everyClass}`} value="23:30">
            11:30 PM
          </option>
          <option className={`${everyClass}`} value="23:45">
            11:45 PM
          </option>
        </select>
      ) : (
        <select
          data-testid={dataTestid}
          className={className}
          defaultValue={defaultValue}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
        >
          <option className={`${everyClass}`} value="">
            {displayText ? displayText : "Select Time"}
          </option>

          <option className={`${everyClass}`} value="00:00">
            12:00 AM
          </option>
          <option className={`${everyClass}`} value="00:15">
            12:15 AM
          </option>
          <option className={`${everyClass}`} value="00:30">
            12:30 AM
          </option>
          <option className={`${everyClass}`} value="00:45">
            12:45 AM
          </option>

          <option className={`${everyClass}`} value="01:00">
            01:00 AM
          </option>
          <option className={`${everyClass}`} value="01:15">
            01:15 AM
          </option>
          <option className={`${everyClass}`} value="01:30">
            01:30 AM
          </option>
          <option className={`${everyClass}`} value="01:45">
            01:45 AM
          </option>

          <option className={`${everyClass}`} value="02:00">
            02:00 AM
          </option>
          <option className={`${everyClass}`} value="02:15">
            02:15 AM
          </option>
          <option className={`${everyClass}`} value="02:30">
            02:30 AM
          </option>
          <option className={`${everyClass}`} value="02:45">
            02:45 AM
          </option>

          <option className={`${everyClass}`} value="03:00">
            03:00 AM
          </option>
          <option className={`${everyClass}`} value="03:15">
            03:15 AM
          </option>
          <option className={`${everyClass}`} value="03:30">
            03:30 AM
          </option>
          <option className={`${everyClass}`} value="03:45">
            03:45 AM
          </option>

          <option className={`${everyClass}`} value="04:00">
            04:00 AM
          </option>
          <option className={`${everyClass}`} value="04:15">
            04:15 AM
          </option>
          <option className={`${everyClass}`} value="04:30">
            04:30 AM
          </option>
          <option className={`${everyClass}`} value="04:45">
            04:45 AM
          </option>

          <option className={`${everyClass}`} value="05:00">
            05:00 AM
          </option>
          <option className={`${everyClass}`} value="05:15">
            05:15 AM
          </option>
          <option className={`${everyClass}`} value="05:30">
            05:30 AM
          </option>
          <option className={`${everyClass}`} value="05:45">
            05:45 AM
          </option>

          <option className={`${everyClass}`} value="06:00">
            06:00 AM
          </option>
          <option className={`${everyClass}`} value="06:15">
            06:15 AM
          </option>
          <option className={`${everyClass}`} value="06:30">
            06:30 AM
          </option>
          <option className={`${everyClass}`} value="06:45">
            06:45 AM
          </option>

          <option className={`${everyClass}`} value="07:00">
            07:00 AM
          </option>
          <option className={`${everyClass}`} value="07:15">
            07:15 AM
          </option>
          <option className={`${everyClass}`} value="07:30">
            07:30 AM
          </option>
          <option className={`${everyClass}`} value="07:45">
            07:45 AM
          </option>

          <option className={`${everyClass}`} value="08:00">
            08:00 AM
          </option>
          <option className={`${everyClass}`} value="08:15">
            08:15 AM
          </option>
          <option className={`${everyClass}`} value="08:30">
            08:30 AM
          </option>
          <option className={`${everyClass}`} value="08:45">
            08:45 AM
          </option>

          <option className={`${everyClass}`} value="09:00">
            09:00 AM
          </option>
          <option className={`${everyClass}`} value="09:15">
            09:15 AM
          </option>
          <option className={`${everyClass}`} value="09:30">
            09:30 AM
          </option>
          <option className={`${everyClass}`} value="09:45">
            09:45 AM
          </option>

          <option className={`${everyClass}`} value="10:00">
            10:00 AM
          </option>
          <option className={`${everyClass}`} value="10:15">
            10:15 AM
          </option>
          <option className={`${everyClass}`} value="10:30">
            10:30 AM
          </option>
          <option className={`${everyClass}`} value="10:45">
            10:45 AM
          </option>

          <option className={`${everyClass}`} value="11:00">
            11:00 AM
          </option>
          <option className={`${everyClass}`} value="11:15">
            11:15 AM
          </option>
          <option className={`${everyClass}`} value="11:30">
            11:30 AM
          </option>
          <option className={`${everyClass}`} value="11:45">
            11:45 AM
          </option>

          <option className={`${everyClass}`} value="12:00">
            12:00 PM
          </option>
          <option className={`${everyClass}`} value="12:15">
            12:15 PM
          </option>
          <option className={`${everyClass}`} value="12:30">
            12:30 PM
          </option>
          <option className={`${everyClass}`} value="12:45">
            12:45 PM
          </option>

          <option className={`${everyClass}`} value="13:00">
            1:00 PM
          </option>
          <option className={`${everyClass}`} value="13:15">
            1:15 PM
          </option>
          <option className={`${everyClass}`} value="13:30">
            1:30 PM
          </option>
          <option className={`${everyClass}`} value="13:45">
            1:45 PM
          </option>

          <option className={`${everyClass}`} value="14:00">
            2:00 PM
          </option>
          <option className={`${everyClass}`} value="14:15">
            2:15 PM
          </option>
          <option className={`${everyClass}`} value="14:30">
            2:30 PM
          </option>
          <option className={`${everyClass}`} value="14:45">
            2:45 PM
          </option>

          <option className={`${everyClass}`} value="15:00">
            3:00 PM
          </option>
          <option className={`${everyClass}`} value="15:15">
            3:15 PM
          </option>
          <option className={`${everyClass}`} value="15:30">
            3:30 PM
          </option>
          <option className={`${everyClass}`} value="15:45">
            3:45 PM
          </option>

          <option className={`${everyClass}`} value="16:00">
            4:00 PM
          </option>
          <option className={`${everyClass}`} value="16:15">
            4:15 PM
          </option>
          <option className={`${everyClass}`} value="16:30">
            4:30 PM
          </option>
          <option className={`${everyClass}`} value="16:45">
            4:45 PM
          </option>

          <option className={`${everyClass}`} value="17:00">
            5:00 PM
          </option>
          <option className={`${everyClass}`} value="17:15">
            5:15 PM
          </option>
          <option className={`${everyClass}`} value="17:30">
            5:30 PM
          </option>
          <option className={`${everyClass}`} value="17:45">
            5:45 PM
          </option>

          <option className={`${everyClass}`} value="18:00">
            6:00 PM
          </option>
          <option className={`${everyClass}`} value="18:15">
            6:15 PM
          </option>
          <option className={`${everyClass}`} value="18:30">
            6:30 PM
          </option>
          <option className={`${everyClass}`} value="18:45">
            6:45 PM
          </option>

          <option className={`${everyClass}`} value="19:00">
            7:00 PM
          </option>
          <option className={`${everyClass}`} value="19:15">
            7:15 PM
          </option>
          <option className={`${everyClass}`} value="19:30">
            7:30 PM
          </option>
          <option className={`${everyClass}`} value="19:45">
            7:45 PM
          </option>

          <option className={`${everyClass}`} value="20:00">
            8:00 PM
          </option>
          <option className={`${everyClass}`} value="20:15">
            8:15 PM
          </option>
          <option className={`${everyClass}`} value="20:30">
            8:30 PM
          </option>
          <option className={`${everyClass}`} value="20:45">
            8:45 PM
          </option>

          <option className={`${everyClass}`} value="21:00">
            9:00 PM
          </option>
          <option className={`${everyClass}`} value="21:15">
            9:15 PM
          </option>
          <option className={`${everyClass}`} value="21:30">
            9:30 PM
          </option>
          <option className={`${everyClass}`} value="21:45">
            9:45 PM
          </option>

          <option className={`${everyClass}`} value="22:00">
            10:00 PM
          </option>
          <option className={`${everyClass}`} value="22:15">
            10:15 PM
          </option>
          <option className={`${everyClass}`} value="22:30">
            10:30 PM
          </option>
          <option className={`${everyClass}`} value="22:45">
            10:45 PM
          </option>

          <option className={`${everyClass}`} value="23:00">
            11:00 PM
          </option>
          <option className={`${everyClass}`} value="23:15">
            11:15 PM
          </option>
          <option className={`${everyClass}`} value="23:30">
            11:30 PM
          </option>
          <option className={`${everyClass}`} value="23:45">
            11:45 PM
          </option>
        </select>
      )}
    </>
  );
}
