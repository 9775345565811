/* eslint-disable array-callback-return */
import React, { Fragment, useState } from 'react';
import { Button, Col, Container, Form, Label, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { apiClient } from '../../../utils/apiClient';
import { BACKEND_API } from '../../../utils/backend';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';


const RoleForm = ({ toggleModal, fetchData, perPage, type, roleUpdateData, initialRolePermissions, setIsOpen }) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(type === 'update' ? {
    id: roleUpdateData?.id ? roleUpdateData?.id : "",
    name: roleUpdateData?.name ? roleUpdateData?.name : "",
    permissions: roleUpdateData?.permissions ? roleUpdateData?.permissions.map(permission => (permission?.name)) : []
  } : {
    name: "",
    permissions: []
  });


  const onSubmit = () => {
    setLoading(true)
    setServerSideErrors(null)
    if (type === "update") {
      apiClient().put(`${BACKEND_API}/v1.0/roles`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Role Updated Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })
    }
    if (type === "create") {
      apiClient().post(`${BACKEND_API}/v1.0/roles`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Role Created Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // Update the checkedItems state based on checkbox state change
    if (checked) {
      const updatedPermissions = [...data.permissions, value];
      setData(prevData => ({ ...prevData, permissions: updatedPermissions }))
    } else {
      const updatedPermissions = data.permissions.filter((item) => item !== value);
      setData(prevData => ({ ...prevData, permissions: updatedPermissions }))
    }
  };
  return (
    <Fragment>
      <Row className='px-3'>
        <Col xs="12">
          <Form className="needs-validation" noValidate="" onSubmit={(e) => e.preventDefault()}>
            {
              loading ? (
                <CustomLoadingSpiner />
              ) : (
                <div style={{ height: '380px', overflowY: 'auto', overflowX: 'hidden' }} className=''>
                  <div className="form-row mb-2 px-1">
                    <input
                      className="form-control"
                      name="id"
                      type="hidden"
                      value={data?.id}
                    />
                    <Col sm="12 mb-3">
                      {type === "update" && <Label className='text-uppercase' htmlFor="name">Name*</Label>}
                      <input
                        placeholder='Name*'
                        className={`form-control border-secondary ${type !== "update" &&"mt-2"}`}
                        name="name"
                        type="text"
                        defaultValue={data?.name}
                        disabled={type === "update"}
                        onChange={handleChange}
                      />
                      {serverSideErrors && (
                        !serverSideErrors.name ? (
                          <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                        ) : (
                          <div className="invalid-feedback" style={{ display: "block" }}>{serverSideErrors.name[0]}</div>
                        )
                      )}
                    </Col>
                  </div>
                  <span className='text-uppercase fw-bold' htmlFor='permissions'>Permissions</span>
                  <br />
                  {serverSideErrors && (
                    !serverSideErrors.permissions ? (
                      <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                    ) : (
                      <div className="invalid-feedback" style={{ display: "block" }}>{serverSideErrors.permissions[0]}</div>
                    )
                  )}
                  <br />
                  <Container>
                    {initialRolePermissions.map((el, index) => {
                      return (
                        <div className="form-row mb-2" key={index}>
                          <Col sm="12 mb-3">
                            <Label className='text-capitalize text-primary fw-bolder' htmlFor='permissions'> Role : {el.role.split("_").join(" ")}</Label>
                          </Col>
                          <br />
                          {
                            el.permissions.map((el2, index2) => {
                              return (
                                <Col sm="6 mb-3" xs="12" key={index2}>
                                  <Label className="d-block " for="chk-ani">
                                    <input
                                      className="checkbox_animated  border-secondary"
                                      id={'permissions'}
                                      value={el2}
                                      checked={data.permissions.includes(el2)}
                                      onChange={handleCheckboxChange}
                                      type="checkbox"
                                      name='permissions'
                                    />
                                    <span
                                      style={{ fontSize: '12px', color: 'black' }}
                                      className='text-capitalize'>
                                      {el2.split("_").join(" ")}
                                    </span>
                                  </Label>
                                </Col>
                              )
                            })
                          }
                        </div>
                      )
                    })}
                  </Container>
                </div>
              )
            }

            <Row className='my-3'>
              <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                <Button color='primary' className='w-100 py-2 rounded-3' onClick={() => { onSubmit() }}>Submit</Button>
                <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }} color=''>Cancel</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}

export default RoleForm