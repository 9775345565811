// ===================================
// ID: 1106
// ===================================

// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import SearchIcon from '@mui/icons-material/Search';
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Loading from '../../components/Loading/Loading';
// import RatingTableUpdate from '../../components/RatingTable/RatingTableUpdate';
import styles from "./showReviews.module.css";
import ReviewSummary from "../ReviewSummary/ReviewSummary";
import { garageRattingReport } from "../../../api/review";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import RatingTableUpdate from "../review_management/Review/RatingTable/RatingTableUpdate";
import moment from "moment";

const ShowReviews = () => {
  const [reviewDetails, setReviewDetails] = useState(0);
  const [ratingLength2, setRatingLength2] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [isRemarksOpen, setIsRemarksOpen] = useState(false);

  const garageId = localStorage.getItem("garageId");

  const fetchRatingData = () => {
    setIsLoading(true);
    garageRattingReport(
      garageId,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    )
      .then((res) => {
        console.log({ d: res?.data });
        setReviewDetails(res?.data);
        setRatingLength2(
          res?.data?.part1?.star_1_selected_count +
            res?.data?.part1?.star_2_selected_count +
            res?.data?.part1?.star_3_selected_count +
            res?.data?.part1?.star_4_selected_count +
            res?.data?.part1?.star_5_selected_count
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // toast.error(`ID: 1106 - Status: ${err?.response?.status} - ${err?.response?.data?.message ? err?.response?.data?.message : "Operation Failed, something is wrong!"} - Please call to the customer care.`);
      });
  };
  useEffect(() => {
    fetchRatingData();
  }, []);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="bg-gradient-primary px-5 py-2 rounded-md text-white w-100 border-0 d-flex justify-content-center align-items-center"
      onClick={onClick}
      ref={ref}
    >
      <MdDateRange />
      {value}
    </button>
  ));
  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <button
      className="bg-gradient-primary px-5 py-2 rounded-md text-white w-100 border-0 d-flex justify-content-center align-items-center"
      onClick={onClick}
      ref={ref}
    >
      <MdDateRange />
      {value}
    </button>
  ));

  return (
    <div style={{ position: "relative" }}>
      {isLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <div>
          <h1 style={{ fontWeight: 800 }} className={styles.title}>
            Review Report
          </h1>
          <div className={styles.reviwContainer}>
            <div className={styles.restaurantRatingContainer}>
              {/* FILTER  */}
              <div className="my-2 d-flex justify-content-end align-items-center w-100  px-5 py-1">
                <div className="">
                  <p className="mb-2 fw-bold">Start Date</p>
                  <DatePicker
                    dateFormat={"dd-MM-yyyy"}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                <p className="mx-1 mx-md-5 mt-0 mt-md-5 fw-bold">To</p>
                <div>
                  <p className=" mb-2 fw-bold">End Date</p>
                  <DatePicker
                    dateFormat={"dd-MM-yyyy"}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={<ExampleCustomInput2 />}
                  />
                </div>
                <div>
                  <button
                    onClick={() => fetchRatingData()}
                    className="bg-gradient-primary d-flex justify-content-center align-items-center px-5 py-2 rounded-md text-white ms-5 search-review-button border-0"
                  >
                    <AiOutlineSearch className=" text-white" />
                    Search
                  </button>
                </div>
              </div>

              {/* REVIEW SUMMARY  */}
              <ReviewSummary
                averageRating={reviewDetails?.part1}
                ratingLength={ratingLength2}
                restaurantName={"Data checking"}
                // restaurantName={user.restaurant[0]?.Name}
              />
            </div>

            {/* RATING TABLE  */}
            <div className={styles.itemRatingContainer}>
              <div className={styles.itemRatingHeaderContainer}>
                {/* <h1 className={`${styles.title} mb-5`}>Item rattings</h1> */}
                {/* <span>Based on past 3 months</span> */}
              </div>
              {/* <ItemRatingChart /> */}
              <div className="w-full flex justify-center items-center">
                <div className="w-full md:w-10/12">
                  <RatingTableUpdate
                    allReviewData={reviewDetails?.part2?.filter(
                      (i) => i?.is_active === 1
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* REMARKS  */}
          <div className="mx-auto py-3 d-flex justify-content-center align-items-center">
            <button
              onClick={() => setIsRemarksOpen(!isRemarksOpen)}
              className="px-3 bg-gradient-primary rounded text-white justify-content-center align-items-center py-2 border-0"
            >
              {isRemarksOpen ? "Show remarks" : "Hide remarks"}
            </button>
          </div>

          {isRemarksOpen ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="w-100 bg-gradient-primary rounded-2  p-3">
                {reviewDetails?.part1?.total_comment.map((remark) => (
                  <div className="p-3 shadow-lg rounded-3 my-2 bg-white">
                    <h2 className="text-lg fw-bold text-primary">
                      {remark?.user?.first_Name}
                    </h2>
                    <span className="text-black">Remarks:</span>
                    <p className="ms-2 text-black">{remark?.comment}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowReviews;
