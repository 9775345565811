import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Label, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { apiClient } from '../../../utils/apiClient';
import { BACKEND_API } from '../../../utils/backend';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';

const AutomobileCategoryForm = ({
  toggleModal,
  fetchData,
  perPage,
  setIsOpen,
  type,
  automobileCategoryUpdateData
}) => {
  const { register, handleSubmit, setValue, errors } = useForm();
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "update") {
      const fields = ['id', 'name',];
      fields.forEach(field => setValue(field, automobileCategoryUpdateData[field]));
    }
  }, []);

  const onSubmit = data => {
    setLoading(true)
    setServerSideErrors(null)
    if (type === "update") {
      apiClient().put(`${BACKEND_API}/v1.0/automobile-categories`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Automobile Category Updated Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })
    }
    if (type === "create") {
      apiClient().post(`${BACKEND_API}/v1.0/automobile-categories`, data)
        .then(response => {
          setLoading(false)
          if (response.data) {
            SweetAlert.fire({ title: "Success", text: "Automobile Category Created Successfully!", icon: "success" });
            fetchData(perPage)
            toggleModal();
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors)
            SweetAlert.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
          }
          else if (error.response?.status === 401) {
            SweetAlert.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Container>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
            {
              loading ? (
                <CustomLoadingSpiner />
              ) : (
                <div className="form-row mb-2">
                  {
                    type === 'update' &&
                    <input
                      className="form-control"
                      name="id"
                      type="hidden"
                      {...register('id', { required: false })}
                    />
                  }
                  <Col md="12 mb-3">
                  {type === 'update' && <Label className='text-uppercase' htmlFor="name">Category name</Label>}
                    <input
                      placeholder='Category name *'
                      id='name'
                      className="form-control border-secondary"
                      name="name"
                      type="text"
                      {...register('name', { required: false })} />
                    <span>{errors?.name && 'Please provide name'}</span>

                    {serverSideErrors && (
                      !serverSideErrors?.name ? (
                        <div className="valid-feedback" style={{ display: "block" }}>Looks good!</div>
                      ) : (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {serverSideErrors?.name[0]}
                        </div>
                      ))}
                  </Col>
                </div>
              )}

            <Row className='mb-3'>
              <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                <Button color='primary' className='w-100 py-2 rounded-3'>Submit</Button>
                <Button className='py-2 w-100 cancel-button rounded-3 text-secondary' onClick={() => { setIsOpen(false) }} color=''>Cancel</Button>
              </Col>
            </Row>

          </Form>
        </Container>
      </Col>
    </Row>
  )
}

export default AutomobileCategoryForm