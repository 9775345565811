import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { MdLocalGasStation } from "react-icons/md";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import {
  FUEL_STATION_CREATE,
  FUEL_STATION_DELETE,
  FUEL_STATION_UPDATE,
  FUEL_STATION_VIEW,
} from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import { checkPermissions } from "../../utils/helperFunctions";
import setLinksView from "../../utils/pagination";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import FuelStationServiceForm from "./forms/FuelStationServiceForm";

const FuelStationServicesList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [fuelStationViewData, setFuelStationViewData] = useState(null);
  const [fuelStationViewModal, setFuelStationViewModal] = useState(false);
  const [fuelStationUpdateData, setFuelStationUpdateData] = useState(null);
  const [fuelStationUpdateModal, setFuelStationUpdateModal] = useState(false);
  const [fuelStationCreateModal, setFuelStationCreateModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);

  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/fuel-station-services/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/fuel-station-services/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  // modal
  const fuelStationCreateModaltoggle = () =>
    setFuelStationCreateModal(!fuelStationCreateModal);
  const fuelStationUpdateModaltoggle = () =>
    setFuelStationUpdateModal(!fuelStationUpdateModal);

  const editForm = (el) => {
    setFuelStationUpdateData(el);
    fuelStationUpdateModaltoggle();
  };
  const fuelStationViewModaltoggle = () =>
    setFuelStationViewModal(!fuelStationViewModal);

  const viewForm = (el) => {
    fuelStationViewModaltoggle();
    setFuelStationViewData(el);
  };

  // end modal
  const fetchData = (urlOrPerPage) => {
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/fuel-station-services/${urlOrPerPage}`;
    }
    apiClient()
      .get(url)
      .then((response) => {
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/fuel-station-services/${id}`)
          .then((response) => {
            if (response.status === 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire(
                "Deleted!",
                `Fuel station's service has been deleted.`,
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire(`Fuel station's service is safe`);
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/fuel-station-services/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    fetchData(perPage);
  }, []);

  if (!permissions.includes(FUEL_STATION_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* =========================== ALL MODALS =============================  */}
      <Modal
        isOpen={fuelStationCreateModal}
        toggle={fuelStationCreateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <MdLocalGasStation className="icon-filter fs-1 mb-2 text-primary" />
            <h4 className="fw-bolder text-black-50">
              Create Fuel Station Service
            </h4>
          </Col>
        </Row>
        <ModalBody>
          <FuelStationServiceForm
            toggleModal={fuelStationCreateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="create"
            setIsOpen={setFuelStationCreateModal}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={fuelStationUpdateModal}
        toggle={fuelStationUpdateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <i className="icon-filter fs-1 mb-2 text-primary"></i>
            <h4 className="fw-bolder text-secondary">
              Update Fuel Station Service
            </h4>
          </Col>
        </Row>

        <ModalBody>
          <FuelStationServiceForm
            toggleModal={fuelStationUpdateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            fuelStationUpdateData={fuelStationUpdateData}
            setIsOpen={setFuelStationUpdateModal}
          />
        </ModalBody>
      </Modal>

      {/* <Modal isOpen={fuelStationViewModal} toggle={fuelStationViewModaltoggle} size="md" className='rounded-xl'>
                <Row className='m-0 p-0 border-0 position-relative'>
                    <Col className='p-30 12 d-flex justify-content-center align-items-center flex-column w-100'>
                        <i className="icon-filter fs-1 mb-2 text-primary"></i>
                        <h4 className='fw-bolder text-secondary'>Fuel Station Details</h4>
                    </Col>
                </Row>

                <ModalBody>
                    <FuelStationView
                        toggleModal={fuelStationViewModaltoggle}
                        fuelStationViewData={fuelStationViewData}
                        setIsOpen={setFuelStationViewModal}
                    />
                </ModalBody>
            </Modal> */}

      {/* <BreadCrumb parent="Home" subparent="Fuel Station Management / Fuel Stations" title="Manage Fuel Station" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <h5>Fuel Station's Service Management</h5>
                  <span> Manage fuel station's services</span>
                </div>

                {checkPermissions([FUEL_STATION_CREATE], permissions) ? (
                  <Button
                    color="primary"
                    onClick={fuelStationCreateModaltoggle}
                  >
                    Create Fuel Station's Service
                  </Button>
                ) : null}
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Name"}</th>
                      <th scope="col" className="text-center">
                        {"Icon"}
                      </th>
                      <th scope="col" className="text-center">
                        {"Action"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, i) => {
                          return (
                            <tr className="Dashed" key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{el?.name}</td>
                              <td className="text-center">
                                <i
                                  className={`${el?.icon} fs-5  text-black-50`}
                                ></i>
                              </td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [FUEL_STATION_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => editForm(el)}
                                      color="primary"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                  {JSON.parse(localStorage.getItem("user"))
                                    ?.roles[0]?.name === "superadmin" &&
                                    checkPermissions(
                                      [FUEL_STATION_DELETE],
                                      permissions
                                    ) && (
                                      <Button
                                        onClick={() => deleteFunc(el.id)}
                                        color="danger"
                                        size="sm"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links &&
                        links.map((el, index, arr) =>
                          setLinksView(
                            el,
                            index,
                            arr,
                            fetchData,
                            current_page,
                            lastPage
                          )
                        )}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FuelStationServicesList;
