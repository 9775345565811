import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getGarageList, getSingleJob } from "../../../api/findJobs";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  NavLink,
} from "reactstrap";
import {
  AiFillCar,
  AiFillTool,
  AiOutlineBarcode,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineGlobal,
  AiOutlineUser,
} from "react-icons/ai";
import { MdArrowBackIos, MdArrowDropDown, MdLocationOn } from "react-icons/md";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import ApplyJobModal from "../garage_apply_jobs/ApplyJobModal";
import { BACKEND } from "../../../utils/backend";
import ImageGalleryForJobs from "../../gallery/ImageGalleryForJobs";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import { decryptionID } from "../../../utils/encription";

const ViewJob = () => {
  const { string_garage_id, string_id } = useParams();
  const id = decryptionID(string_id);
  const garage_id = decryptionID(string_garage_id);

  const [viewJobDetails, setViewJobDetails] = useState({});
  const [garageList, setgarageList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getSingleJob(garage_id, id).then((res) => {
      getGarageList().then((res2) => {
        setViewJobDetails(res);
        setgarageList(res2);
        setIsLoading(false);
      });
    });
  }, []);

  const [showService, setShowService] = useState(false);
  function getDistanceFromLatLonInKm(lat2, lon2) {
    const earthRadiusKm = 6371;

    const dLat = deg2rad(lat2 * 1 - garageList[0]?.lat * 1);
    const dLon = deg2rad(lon2 * 1 - garageList[0]?.long * 1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(garageList[0]?.lat * 1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadiusKm * c;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const [location, setLocation] = React.useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          console.error(error);
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const defaultProps = {
    center: {
      lat: location?.latitude,
      lng: location?.longitude,
    },
    zoom: 13,
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [jobId, setJobId] = useState("");
  const onApplyJobClick = (id) => {
    setJobId(id);
    if (id) {
      toggle();
    }
  };

  const AnyReactComponent = ({ text }) => (
    <div>
      <div
        style={{
          color: "blue",
          background: "white",
          borderRadius: "50px",
          padding: "5px",
          fontWeight: "bold",
          display: "inline-flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          transform: "translate(-50%, -50%)",
          fontSize: "15px",
          border: "1px solid green",
        }}
        onClick={() => setShowDetails(!showDetails)}
      >
        <AiFillCar className="fs-4" />
      </div>
      {showDetails && <p>{text}</p>}
    </div>
  );
  return (
    <>
      {isLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <div>
          <ApplyJobModal
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            jobId={viewJobDetails?.id}
            jobDataForApplingJob={viewJobDetails}
            garageId={garageList[0]?.id}
            isFrom="view"
          />
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="8">
                <div className="col">
                  <Card className="my-2 job-card" color="secondary" outline>
                    <CardHeader className="job-card-header">
                      <div className="job-card-main-head py-3 pb-1">
                        <h6 className="text-center fw-bold ">
                          {viewJobDetails?.automobile_make?.name} -{" "}
                          {viewJobDetails?.automobile_model?.name}
                        </h6>
                        <p className="text-center fw-bold ">
                          {moment(viewJobDetails?.job_start_date).calendar(
                            null,
                            {
                              sameDay: "ll [Today]",
                              nextDay: "ll [Tomorrow]",
                              nextWeek: "ll dddd",
                              lastDay: "ll [Yesterday]",
                              lastWeek: "ll dddd",
                              sameElse: "ll dddd",
                            }
                          )}
                        </p>
                      </div>
                      <div className="d-flex">
                        {viewJobDetails?.garage_applied === 1 ? (
                          <span className=" d-flex align-items-center gap-2new-job-noti bg-secondary">
                            <AiFillTool style={{ fontSize: 15 }} /> YOU ALREADY
                            APPLIED
                          </span>
                        ) : viewJobDetails?.job_bids_count === 0 ? (
                          <span className=" d-flex align-items-center gap-2 new-job-noti bg-success">
                            <AiFillTool style={{ fontSize: 15 }} /> NEW JOB -
                            APPLY NOW
                          </span>
                        ) : (
                          <span className=" d-flex align-items-center gap-2 new-job-noti bg-warning">
                            <AiFillTool style={{ fontSize: 15 }} />{" "}
                            {viewJobDetails?.job_bids_count} GARAGES HAVE
                            APPLIED
                          </span>
                        )}
                        <div class="triangle-left"></div>
                      </div>
                      <div className="fw-bold my-3">
                        <p className="mb-0 d-flex justify-content-center align-items-center apply-job-title">
                          TO START{" "}
                          {console.log({
                            jobDate: viewJobDetails.job_end_date,
                          })}
                          <AiOutlineClockCircle className="fs-6 mx-2" />{" "}
                          {viewJobDetails.job_end_date
                            ? moment(viewJobDetails.job_end_date).format("ll")
                            : "NO_DEADLINE"}{" "}
                        </p>
                        {/* <p className='mb-0  d-flex justify-content-center align-items-center apply-job-title'>COMMISSION <AiFillTool className='fs-6 mx-2' /> £12.50 + £2.50 VAT</p> */}
                      </div>
                    </CardHeader>
                    <CardBody className="job-card-body">
                      <div className="d-flex justify-content-center">
                        <table>
                          <tbody>
                            <tr>
                              <th className="th-Align">CUSTOMER</th>
                              <td className="id-icon-w">
                                <AiOutlineUser />
                              </td>
                              <td>
                                {viewJobDetails?.customer?.first_Name}{" "}
                                {viewJobDetails?.customer?.last_Name}
                              </td>
                            </tr>
                            <tr>
                              <th className="th-Align">SERVICE</th>
                              <td className="id-icon-w">
                                <AiFillTool />
                              </td>
                              <td>
                                {showService ? (
                                  <>
                                    {viewJobDetails?.pre_booking_sub_services
                                      .length > 0 &&
                                      viewJobDetails?.pre_booking_sub_services?.map(
                                        (viewJobDetails, ind) => (
                                          <>
                                            {ind === 0 ? (
                                              <p className="d-flex align-items-center mb-0">
                                                {
                                                  viewJobDetails?.sub_service
                                                    ?.name
                                                }{" "}
                                                <MdArrowDropDown
                                                  onClick={() =>
                                                    setShowService(false)
                                                  }
                                                  className="fs-4"
                                                />
                                              </p>
                                            ) : (
                                              <p className="mb-0">
                                                {
                                                  viewJobDetails?.sub_service
                                                    ?.name
                                                }
                                              </p>
                                            )}
                                          </>
                                        )
                                      )}
                                  </>
                                ) : (
                                  <p className="d-flex align-items-center">
                                    {viewJobDetails?.pre_booking_sub_services
                                      ?.length > 0 &&
                                      viewJobDetails?.pre_booking_sub_services?.slice(
                                        0,
                                        1
                                      )[0]?.sub_service?.name}{" "}
                                    <MdArrowDropDown
                                      onClick={() => setShowService(true)}
                                      className="fs-4"
                                    />
                                  </p>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th className="th-Align">REGISTRATION</th>
                              <td className="id-icon-w">
                                <AiOutlineBarcode />
                              </td>
                              <td>{viewJobDetails?.car_registration_no}</td>
                            </tr>
                            <tr>
                              <th className="th-Align">MAKE & MODEL</th>
                              <td className="id-icon-w">
                                <AiFillCar />
                              </td>
                              <td>
                                {viewJobDetails?.automobile_make?.name} -{" "}
                                {viewJobDetails?.automobile_model?.name}
                              </td>
                            </tr>

                            <tr>
                              <th className="th-Align">LOCATION</th>
                              <td className="id-icon-w">
                                <MdLocationOn />
                              </td>
                              <td>
                                {viewJobDetails?.city},{viewJobDetails?.country}
                              </td>
                            </tr>
                            {console.log(
                              viewJobDetails?.images !== undefined &&
                                JSON.parse(viewJobDetails?.images)
                            )}

                            <tr>
                              <th className="th-Align">DISTANCE</th>
                              <td className="id-icon-w">
                                <AiOutlineGlobal />
                              </td>
                              <td>
                                {getDistanceFromLatLonInKm(
                                  viewJobDetails?.lat,
                                  viewJobDetails?.long
                                ).toFixed(2)}{" "}
                                Km
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    {viewJobDetails?.images !== null && (
                      <ImageGalleryForJobs
                        imageAllData={
                          viewJobDetails?.images !== undefined &&
                          JSON.parse(viewJobDetails?.images)
                        }
                      />
                    )}
                    {viewJobDetails?.videos !== null &&
                      viewJobDetails?.videos !== undefined && (
                        <Row className="m-2">
                          {JSON.parse(viewJobDetails?.videos).length > 0 && (
                            <>
                              <h5 className="fw-bold">Videos:</h5>
                              {JSON.parse(viewJobDetails?.videos)?.map(
                                (res) => (
                                  <Col xs lg="6">
                                    {console.log(res)}
                                    <video
                                      width={"100%"}
                                      playsInline=""
                                      autoPlay={true}
                                      muted=""
                                      loop=""
                                      controls
                                    >
                                      <source
                                        src={`${BACKEND}${res}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </Col>
                                )
                              )}
                            </>
                          )}
                        </Row>
                      )}

                    <CardFooter className="applyJob-card-footer">
                      {viewJobDetails?.garage_applied === 0 && (
                        <div className="d-flex justify-content-center">
                          <button
                            onClick={() => onApplyJobClick(viewJobDetails?.id)}
                            className="w-100 apply-job-btn me-2"
                          >
                            Apply For Quote
                          </button>
                        </div>
                      )}
                      <div className="d-flex justify-content-center mt-1">
                        <Button
                          onClick={() => navigate(-1)}
                          className="me-2 w-100 justify-content-center d-flex align-items-center"
                        >
                          <MdArrowBackIos /> GO BACK
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </Col>

              <Col xs lg="4">
                {defaultProps.center.lat !== undefined &&
                  defaultProps.center.lng !== undefined && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        border: 0,
                        position: "absolute",
                        top: "0px",
                        marginBottom: "100px",
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: `${process.env.REACT_APP_GOOGLE_MAP_API}`,
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                      >
                        <AnyReactComponent
                          lat={viewJobDetails?.lat}
                          lng={viewJobDetails?.long}
                          text="My home"
                        />
                      </GoogleMapReact>
                    </div>
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ViewJob;
