import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getNotification = async (garageId, perPage, pageNo) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/notifications/${garageId}/${perPage}?page=${pageNo}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        })
}
export const getNotificationForSuperAdmin = async (garageId, perPage, pageNo) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/notifications/${perPage}?page=${pageNo}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        })
}
export const deleteNotification = async (id) => {
    return await apiClient().delete(`${BACKEND_API}/v1.0/notifications/${id}`)
        .then(response => {
            return response;
        }).catch(error => {
            throw error;
        })
}
export const putNotification = async (notification_ids) => {
    return await apiClient().put(`${BACKEND_API}/v1.0/notifications/change-status`, notification_ids)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        })
}