import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";

import { BACKEND_API } from "../../../utils/backend";

import setLinksView from "../../../utils/pagination";

import moment from "moment";
import DatePicker from "react-datepicker";
import { Briefcase } from "react-feather";
import SweetAlert from "sweetalert2";
import { getGarageList } from "../../../api/findJobs";
import {
  JOB_BIDS_DELETE,
  JOB_BIDS_UPDATE,
  JOB_BIDS_VIEW,
} from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { checkPermissions } from "../../../utils/helperFunctions";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import ChangeStatus from "./ChangeStatus";
import JobBidView from "./JobBidView";
import EditJobdBid from "./EditJobdBid";
// import BookingView from './BookingView';
// import ChangeStatus from './ChangeStatus';

const JobBidsList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));
  let currency = localStorage.getItem("currency");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [garageId, setGarageId] = useState("");
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/job-bids/${garageId}/${perPage}?start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/job-bids/${garageId}/${perPage}?start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  // modal
  const [jobBidsViewData, setJobBidsViewData] = useState(null);
  const [jobBidsViewModal, setJobBidsViewModal] = useState(false);
  const [jobBidEdit, setJobBidEdit] = useState(false);

  const jobBidsViewModaltoggle = () => setJobBidsViewModal(!jobBidsViewModal);
  const jobBidEditModaltoggle = () => setJobBidEdit(!jobBidEdit);

  const viewForm = (el) => {
    setJobBidsViewData(el);
    jobBidsViewModaltoggle();
  };

  // end modal
  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.value) {
          apiClient()
            .delete(`${BACKEND_API}/v1.0/job-bids/${garageId}/${id}`)
            .then((response) => {
              fetchData(garageId, perPage);
              SweetAlert.fire(
                "Deleted!",
                "Quotes has been deleted.",
                "success"
              );
            })
            .catch((error) => {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
            });
        } else {
          SweetAlert.fire("Garage is safe");
        }
      })
      .catch((err) => {
        SweetAlert.fire({
          title: err?.response?.data?.message,
          text: "You already applied this job.",
          icon: "warning",
        });
      });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (garageId, urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/job-bids/${garageId}/${urlOrPerPage}`;
    }

    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const [jobBidId, setJobBidId] = useState({});
  const handleEditBid = (bid) => {
    setJobBidId(bid);
    jobBidEditModaltoggle();
  };
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/job-bids/${garageId}/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    getGarageList().then((res) => {
      setGarageId(res[0]?.id);
      fetchData(res[0]?.id, perPage);
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  console.log(jobBidId);

  if (!permissions.includes(JOB_BIDS_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      <Modal
        isOpen={jobBidsViewModal}
        toggle={jobBidsViewModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <Briefcase className="icon-filter fs-1 mb-2 text-primary"></Briefcase>
            <h4 className="fw-bolder text-black-50">Quote Details Preview</h4>
          </Col>
        </Row>
        <ModalBody>
          <JobBidView
            toggleModal={jobBidsViewModaltoggle}
            viewData={jobBidsViewData}
            setIsOpen={setJobBidsViewModal}
          />
        </ModalBody>
      </Modal>

      {jobBidEdit && (
        <EditJobdBid
          modal={jobBidEdit}
          fetchData={fetchData}
          perPage={perPage}
          jobBidId={jobBidId.id}
          jobBidDetails={jobBidId}
          garageId={garageId}
          toggleModal={jobBidEditModaltoggle}
        />
      )}

      {/* <BreadCrumb parent="Home" subparent="Garage Management / Garages" title="Manage Garages" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Quotes Management</h5>
                    <span> Manage your Quotes </span>
                  </div>
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Customer Name"}</th>
                      <th scope="col">{"Services"}</th>
                      <th scope="col">{"Start Date"}</th>
                      <th scope="col">{"Start Time"}</th>
                      <th scope="col">{"End Time"}</th>
                      <th scope="col">{"Price"}</th>
                      <th scope="col">{"Status"}</th>
                      <th className="text-center" scope="col">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, index) => {
                          return (
                            <tr className="Dashed" key={el?.id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {el?.pre_booking?.customer?.first_Name}{" "}
                                {el?.pre_booking?.customer?.last_Name}
                              </td>
                              <td>
                                <ul>
                                  {el?.pre_booking?.pre_booking_sub_services?.map(
                                    (res, index) => (
                                      <li>
                                        {index + 1}. {res?.sub_service?.name}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </td>
                              <td>{el?.job_start_date}</td>
                              <td>
                                {moment(el?.job_start_time, "HH:mm:ss").format(
                                  "h:mm A"
                                )}
                              </td>
                              <td>
                                {moment(el?.job_end_time, "HH:mm:ss").format(
                                  "h:mm A"
                                )}
                              </td>
                              <td>
                                {currency}
                                {el?.price}
                              </td>
                              <td>{el?.status}</td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [JOB_BIDS_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      title="View Job"
                                      onClick={() => viewForm(el)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [JOB_BIDS_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => handleEditBid(el)}
                                      color="primary"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [JOB_BIDS_DELETE],
                                    permissions
                                  ) && (
                                    <Button
                                      title="Delete Job"
                                      onClick={() => deleteFunc(el?.id)}
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default JobBidsList;
