import { City, Country } from "country-state-city";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import AutoComplete from "../../AutoComplete/AutoComplete";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";

const UserForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  userUpdateData,
  setIsOpen,
}) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});
  const [data, setData] = useState(
    type === "update"
      ? {
          id: userUpdateData?.id,
          first_Name: userUpdateData?.first_Name
            ? userUpdateData?.first_Name
            : "",
          last_Name: userUpdateData?.last_Name ? userUpdateData?.last_Name : "",
          email: userUpdateData?.email ? userUpdateData?.email : "",
          password: userUpdateData?.password ? userUpdateData?.password : "",
          password_confirmation: userUpdateData?.password_confirmation
            ? userUpdateData?.password_confirmation
            : "",
          phone: userUpdateData?.phone ? userUpdateData?.phone : "",
          address_line_1: userUpdateData?.address_line_1
            ? userUpdateData?.address_line_1
            : "",
          address_line_2: userUpdateData?.address_line_2
            ? userUpdateData?.address_line_2
            : "",
          country: userUpdateData?.country ? userUpdateData?.country : "",
          city: userUpdateData?.city ? userUpdateData?.city : "",
          postcode: userUpdateData?.postcode ? userUpdateData?.postcode : "",
          role:
            userUpdateData?.roles?.length > 0
              ? userUpdateData?.roles[0]?.name
              : "",
          lat: userUpdateData?.lat ? userUpdateData?.lat : "",
          long: userUpdateData?.long ? userUpdateData?.long : "",
        }
      : {
          first_Name: "",
          last_Name: "",
          email: "",
          password: "",
          password_confirmation: "",
          phone: "",
          address_line_1: "",
          address_line_2: "",
          country: "",
          city: "",
          postcode: "",
          lat: "",
          long: "",
        }
  );

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    setData({
      ...data,
      country: placeAutoComplete?.country,
      city: placeAutoComplete?.locality,
      postcode: placeAutoComplete?.postal_code,
      address_line_1: placeAutoComplete?.full_address,
      lat: `${placeAutoComplete?.coordinates?.lat}`,
      long: `${placeAutoComplete?.coordinates?.lng}`,
    });
  }, [placeAutoComplete]);

  useEffect(() => {
    setData({
      ...data,
      address_line_2: placeAutoComplete?.full_address,
    });
  }, [placeAutoComplete2]);

  const loadRoles = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/roles/get/all`)
      .then((response) => {
        setRoles(response.data.roles);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    console.log({ data });
  }, [data]);

  const onSubmit = () => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      apiClient()
        .put(`${BACKEND_API}/v1.0/users`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "User Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
    if (type === "create") {
      apiClient()
        .post(`${BACKEND_API}/v1.0/users`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "User Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <Row>
      <Col>
        <Container>
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {loading ? (
              <CustomLoadingSpiner />
            ) : (
              <div>
                <Row className="form-row mb-2">
                  <input
                    onChange={handleChange}
                    className="form-control border-secondary"
                    name="id"
                    type="hidden"
                    value={userUpdateData?.id}
                  />

                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="first_Name">
                        First Name*
                      </Label>
                    )}
                    <input
                      placeholder="First Name*"
                      onChange={handleChange}
                      id="first_Name"
                      className="form-control border-secondary"
                      name="first_Name"
                      type="text"
                      value={
                        userUpdateData?.first_Name
                          ? userUpdateData?.first_Name
                          : data?.first_Name
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.first_Name ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.first_Name[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="last_Name">
                        Last Name*
                      </Label>
                    )}
                    <input
                      placeholder="Last Name*"
                      onChange={handleChange}
                      id="last_Name"
                      className="form-control border-secondary"
                      name="last_Name"
                      type="text"
                      value={
                        userUpdateData?.last_Name
                          ? userUpdateData?.last_Name
                          : data?.last_Name
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.last_Name ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.last_Name[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="email">
                        Email*
                      </Label>
                    )}
                    <input
                      placeholder="Email*"
                      onChange={handleChange}
                      id="email"
                      className="form-control border-secondary"
                      name="email"
                      type="email"
                      value={
                        userUpdateData?.email
                          ? userUpdateData?.email
                          : data?.email
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.email ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.email[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="phone">
                        Phone*
                      </Label>
                    )}
                    <input
                      placeholder="Phone*"
                      onChange={handleChange}
                      id="phone"
                      className="form-control border-secondary"
                      name="phone"
                      type="text"
                      value={
                        userUpdateData?.phone
                          ? userUpdateData?.phone
                          : data?.phone
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.phone ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.phone[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
                <Row className="form-row mb-2">
                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="password">
                        Password*
                      </Label>
                    )}
                    <input
                      placeholder="Password*"
                      onChange={handleChange}
                      id="password"
                      className="form-control border-secondary"
                      name="password"
                      type="password"
                      value={
                        userUpdateData?.password
                          ? userUpdateData?.password
                          : data?.password
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.password ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.password[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col md="6 mb-3" sm="12">
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="password_confirmation"
                      >
                        Confirm Password*
                      </Label>
                    )}
                    <input
                      placeholder="Confirm Password*"
                      onChange={handleChange}
                      className="form-control border-secondary"
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      value={
                        userUpdateData?.password_confirmation
                          ? userUpdateData?.password_confirmation
                          : data?.password_confirmation
                      }
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.password ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.password[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
                <Col md="12 mb-3" sm="12">
                  {type === "update" && (
                    <Label className="text-uppercase" htmlFor="address_line_11">
                      Address Line 1*
                    </Label>
                  )}
                  <AutoComplete
                    placeholder="Address Line 1*"
                    defaultValue={
                      userUpdateData?.address_line_1
                        ? userUpdateData?.address_line_1
                        : data?.address_line_1
                    }
                    setPlaceAutoComplete={setPlaceAutoComplete}
                    class_Name={"form-control border-secondary"}
                    name="address_line_1"
                    id={"address_line_11"}
                  />
                  {serverSideErrors ? (
                    !serverSideErrors.address_line_1 ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors.address_line_1[0]}
                      </div>
                    )
                  ) : null}
                </Col>
                <Row className="form-row mb-2">
                  <Col md="6 mb-3" sm="12">
                    <FormGroup>
                      {type === "update" && (
                        <Label className="text-uppercase" htmlFor="country">
                          COUNTRY*
                        </Label>
                      )}
                      <Input
                        type="text"
                        className="custom-select border border-secondary"
                        data-testid="fuel_station_country"
                        name="country"
                        placeholder="Coutry"
                        onChange={handleChange}
                        value={
                          userUpdateData?.country
                            ? userUpdateData?.country
                            : data?.country
                        }
                      />

                      {serverSideErrors ? (
                        !serverSideErrors.country ? (
                          <div
                            className="valid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Looks good!"}
                          </div>
                        ) : (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {serverSideErrors.country[0]}
                          </div>
                        )
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6 mb-3" sm="12">
                    <FormGroup>
                      {type === "update" && (
                        <Label className="text-uppercase" htmlFor="city">
                          CITY*
                        </Label>
                      )}
                      <Input
                        data-testid="fuel_station_city"
                        className="custom-select border border-secondary"
                        name="city"
                        type="text"
                        placeholder="City"
                        value={
                          userUpdateData?.city
                            ? userUpdateData?.city
                            : data?.city
                        }
                        onChange={handleChange}
                      />

                      {serverSideErrors ? (
                        !serverSideErrors.city ? (
                          <div
                            className="valid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Looks good!"}
                          </div>
                        ) : (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {serverSideErrors.city[0]}
                          </div>
                        )
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="12 mb-3" sm="12">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="postcode">
                        Postcode*
                      </Label>
                    )}
                    <Input
                      placeholder="Postcode*"
                      className="form-control border border-secondary w-100"
                      data-testid="fuel_station_postcode"
                      name="postcode"
                      type="text"
                      value={
                        userUpdateData?.postcode
                          ? userUpdateData?.postcode
                          : data?.postcode
                      }
                      onChange={handleChange}
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.postcode ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.postcode[0]}
                        </div>
                      )
                    ) : null}
                  </Col>

                  <Col md="12 mb-3" sm="12">
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="address_line_22"
                      >
                        Address Line 2
                      </Label>
                    )}
                    <AutoComplete
                      placeholder="Address Line 2"
                      defaultValue={
                        userUpdateData?.address_line_2
                          ? userUpdateData?.address_line_2
                          : data?.address_line_2
                      }
                      setPlaceAutoComplete={setPlaceAutoComplete2}
                      class_Name={"form-control border-secondary"}
                      name="address_line_2"
                      id={"address_line_22"}
                    />

                    {serverSideErrors ? (
                      !serverSideErrors.address_line_2 ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          {"Looks good!"}
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.address_line_2[0]}
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
                <Row className="form-row mb-2">
                  <Col md="12 mb-3" sm="12">
                    <FormGroup>
                      {type === "update" && (
                        <Label className="text-uppercase" htmlFor="role">
                          Role*
                        </Label>
                      )}
                      <Input
                        id="role"
                        type="select"
                        className="custom-select border-secondary"
                        name="role"
                        onChange={handleChange}
                        value={
                          userUpdateData?.role
                            ? userUpdateData?.role
                            : data?.role
                        }
                      >
                        <option value="">{"Select A Role*"}</option>
                        {roles.map((el, i) => {
                          return (
                            <option value={el.name} key={i}>
                              {el.name.split("_").join(" ").toUpperCase()}
                            </option>
                          );
                        })}
                      </Input>
                      {serverSideErrors ? (
                        !serverSideErrors.role ? (
                          <div
                            className="valid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Looks good!"}
                          </div>
                        ) : (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {serverSideErrors.role[0]}
                          </div>
                        )
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
            <Row className="mb-3">
              <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                <Button
                  color="primary"
                  className="w-100 py-2 rounded-3"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="py-2 w-100 cancel-button rounded-3"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  color=""
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
};

export default UserForm;
