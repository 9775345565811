import React from 'react';
import styles from '../../../showReviews/showReviews.module.css';
import RatingTableUpdate from './RatingTableUpdate';


export default function SingleUserRating({ reviewDetails }) {
    return (
        <div className={styles.itemRatingContainer}>
            <div className='w-full flex justify-center items-center'>
                <div className='w-full'>
                    <RatingTableUpdate allReviewData={reviewDetails?.review_info?.part2?.filter(i => i?.is_active === 1)} />
                </div>
            </div>
        </div>
    )
}
