import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader";
import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";
import { getPermissions } from "../utils/helperFunctions";
import { getGarageTimingUsingToken } from "../api/garage_timing";

const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const garageId = localStorage.getItem("garageId");
  const navigate = useNavigate();

  const validateJWT = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/user`)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem(
          "permissions",
          JSON.stringify(getPermissions(response.data))
        );
        setAuthenticated(true);
      })
      .catch((err) => {
        localStorage.clear();
        setAuthenticated(false);
      });
  };

  useEffect(() => {
    if (isFirstRendering) {
      setIsLoading(true);
      apiClient()
        .get(`${BACKEND_API}/v1.0/user`)
        .then((response) => {
          if (garageId !== null) {
            getGarageTimingUsingToken(garageId, response.data.token)
              .then((time) => {
                if (time?.length !== 0) {
                  localStorage.setItem(
                    "timing",
                    JSON.stringify(time?.sort((a, b) => a.day - b.day))
                  );
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("user", JSON.stringify(response.data));
                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(getPermissions(response.data))
                  );
                  setAuthenticated(true);
                  setIsLoading(false);
                } else {
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("user", JSON.stringify(response.data));
                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(getPermissions(response.data))
                  );
                  setAuthenticated(true);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                localStorage.clear();
                setAuthenticated(false);
                window.location.href =
                  "https://garage-dashboard.quickreview.app/home/true";
              });
          } else {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem(
              "permissions",
              JSON.stringify(getPermissions(response.data))
            );
            setAuthenticated(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          localStorage.clear();
          setAuthenticated(false);
          window.location.href =
            "https://garage-dashboard.quickreview.app/home/true";
          setIsLoading(false);
        });
      setIsFirstRendering(false);
    } else {
      validateJWT();
    }
  }, [navigate]);

  return (
    <>
      {!isLoading ? (
        <>
          {/* <Outlet /> */}
          {authenticated ? (
            <Outlet />
          ) : (
            <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default PrivateRoute;
