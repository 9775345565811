import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { MoreHorizontal } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input, Label } from "reactstrap";
import logo_light from "../assets/images/creative-logo.png";
import creativeLogo from "../assets/images/creative-logo1.png";
import {
  MobileRightToggle,
  SearchBarToggle,
  SwitchToggle,
} from "../redux/common/actions";
import Rightbar from "./header/Rightbar";
import { MENUITEMS } from "./sidebar/menu";

export const Header = () => {
  const configDB = useSelector((content) => content.Customizer.customizer);
  const sidebar_background_color = configDB.settings.sidebar_background_setting;
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState("");
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const dispatch = useDispatch();
  const searchTog = useSelector((state) => state.Common.searchToggle);
  const mobileRightTog = useSelector((state) => state.Common.mobileRightToggle);
  const switchToggle = useSelector((state) => state.Common.switchToggle);
  const width = useWindowSize();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue("");
    }
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (width <= 991) {
      document.querySelector(".page-main-header").className =
        "page-main-header open";
      document.querySelector(".page-sidebar").className = "page-sidebar open";
      if (localStorage.getItem("layout_version") === "dark-only") {
        document.querySelector(".header-logo").className = "header-logo light";
      } else {
        document.querySelector(".header-logo").className = "header-logo normal";
      }
    } else {
      document.querySelector(".page-main-header").className =
        "page-main-header ";
      document.querySelector(".page-sidebar").className =
        "page-sidebar " + sidebar_background_color;
    }
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction, width, sidebar_background_color]);

  const handleSearchKeyword = (k) => {
    let keyword = k.toLowerCase();
    keyword ? addFix() : removeFix();
    const items = [];
    setsearchValue(keyword);

    mainmenu.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(keyword) &&
        menuItems.type === "link" &&
        menuItems.show
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;

      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(keyword) &&
          subItems.type === "link" &&
          subItems.show
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;

        subItems.children.filter((suSubItems) => {
          if (
            suSubItems.title.toLowerCase().includes(keyword) &&
            suSubItems.show
          ) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
          return suSubItems;
        });
        return subItems;
      });
      checkSearchResultEmpty(items);
      setsearchValue(items);
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      setSearchResultEmpty(false);
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector(".Typeahead-menu").classList.add("is-open");
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove("is-open");
  };

  return (
    <div className={`page-main-header ${switchToggle ? "open" : ""}`}>
      <div className="main-header-right row">
        <div className="main-header-left d-lg-none col-auto p-0">
          <div className="logo-wrapper header-logo normal">
            <a href="#javascript">
              <img
                className="normallogo"
                width={"170px"}
                src={creativeLogo}
                alt=""
              />
              <img
                className="lightlogo"
                width={"147"}
                src={logo_light}
                alt=""
              />
            </a>
          </div>
        </div>

        <div className="mobile-sidebar d-block col-auto ps-0">
          <div className="media-body text-end switch-sm">
            <Label className="switch">
              <Input
                type="checkbox"
                onChange={() => dispatch(SwitchToggle(switchToggle))}
                checked={!switchToggle}
              />
              <span className="switch-state"></span>
            </Label>
          </div>
        </div>
        {/* <Leftbar /> */}
        <Rightbar />
        <form
          className={`form-inline search-full p-0 ${searchTog ? "open" : ""}`}
        >
          <div className="form-group form-control-plaintext">
            <input
              type="search"
              id="search"
              placeholder="Search..."
              defaultValue={searchValue}
              onChange={(e) => handleSearchKeyword(e.target.value)}
            />
            <i
              onClick={() => dispatch(SearchBarToggle(searchTog))}
              className="icon-close close-search"
            ></i>

            <div className="Typeahead-menu custom-scrollbar" id="search-outer">
              {searchValue
                ? searchValue.map((data, index) => {
                    return (
                      <div className="ProfileCard u-cf" key={index}>
                        <div className="ProfileCard-avatar">
                          <data.icon />
                        </div>
                        <div className="ProfileCard-details">
                          <div className="ProfileCard-realName">
                            <Link
                              to={data.path}
                              className="realname"
                              onClick={removeFix}
                            >
                              {data.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="Typeahead-menu empty-menu">
              <div className="tt-dataset tt-dataset-0">
                <div className="EmptyMessage">
                  {"Opps!! There are no result found."}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="d-lg-none mobile-toggle pull-right col-auto p-0">
          <MoreHorizontal
            onClick={() => dispatch(MobileRightToggle(mobileRightTog))}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
