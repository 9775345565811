import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "../EditQuestion/EditQuestion.module.css";
import { apiClient } from "../../../../utils/apiClient";
import { Button } from "reactstrap";
import { BACKEND_API } from "../../../../utils/backend";
import SweetAlert from "sweetalert2";

const animatedComponents = makeAnimated();

export default function CreateQuestionAdminModal({
  toggleModal,
  setIsOpen,
  fetchData,
}) {
  // DATA FROM API

  // STAR & TAGS

  // QUESTION
  const [questionDetails, setQuestionDetails] = useState();
  const [questionStatus, setQuestionStatus] = useState(1);
  // LOADING
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

  const [errors, setErrors] = useState({});
  // VALIDATION
  const validateForm = (formData) => {
    const newErrors = {};

    // Validate data
    if (!formData.question || formData.question.trim() === "") {
      newErrors.question = "Question is required";
    }
    // ````VALIDATE is_active
    if (!formData.is_active === "") {
      newErrors.is_active = "Status is required";
    }
    setErrors(newErrors);
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // UPDATE BUTTON HANDLER
  const handleSubmit = () => {
    const questionData = {
      question: questionDetails,
      is_active: questionStatus,
    };
    if (validateForm(questionData)) {
      setIsSubmissionLoading(true);
      apiClient()
        .post(`${BACKEND_API}/review-new/create/questions`, questionData)
        .then((res) => {
          SweetAlert.fire({
            title: "Success!",
            text: `Question Create Successfully!`,
            icon: "success",
          });
          setTimeout(() => {
            toggleModal();
            fetchData();
          }, 1000);
          setIsSubmissionLoading(false);
        })
        .catch((err) => {
          setIsSubmissionLoading(false);
          SweetAlert.fire({
            title: "Error!",
            text: `ID: 1023 - Status: ${err?.response?.status} - ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Operation Failed, something is wrong!"
            } - Please call to the customer care.`,
            icon: "warning",
          });
        });
    }
  };

  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupMain}>
        <div>
          {/* QUESTION INFORMATIONS  */}
          <div className={styles.questionPopupTableRowContainer}>
            <div className={styles.questionPopupLeft}>Title</div>
            <div className={styles.questionPopupRight}>
              <input
                onChange={(e) => setQuestionDetails(e.target.value)}
                className={styles.questionPopupQuestionInput}
                type="text"
                name="question"
                placeholder="Question"
              />
              {errors.question && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.question}
                </div>
              )}
            </div>
          </div>
          <div className={styles.questionPopupTableRowContainer}>
            <div className={styles.questionPopupLeft}>Status</div>
            <div className={styles.questionPopupRight}>
              <Select
                required
                onChange={(e) => setQuestionStatus(e.value)}
                name="status"
                isSearchable
                styles={{ outline: "none" }}
                components={animatedComponents}
                defaultValue={{
                  value: questionStatus,
                  label: questionStatus === 1 ? "Public" : "Private",
                }}
                options={[
                  { value: 0, label: "Private" },
                  { value: 1, label: "Public" },
                ]}
              />
              {errors.is_active && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.is_active}
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          color="primary"
          className="w-100 my-5"
          disabled={isSubmissionLoading}
          onClick={handleSubmit}
        >
          {isSubmissionLoading ? "Loading..." : "Create"}
        </Button>
      </div>
    </div>
  );
}
