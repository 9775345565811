import { css } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  Row,
  Table,
} from "reactstrap";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import setLinksView from "../../utils/pagination";

import SweetAlert from "sweetalert2";

import {
  ROLE_CREATE,
  ROLE_DELETE,
  ROLE_UPDATE,
  ROLE_VIEW,
} from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";
import { checkPermissions } from "../../utils/helperFunctions";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import RoleForm from "./forms/RoleForm";
import RoleView from "./vews/RoleView";

const RoleList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [initialRolePermissions, setInitialRolePermission] = useState(null);

  // modal
  const [roleCreateModal, setRoleCreateModal] = useState(false);
  const roleCreateModaltoggle = () => setRoleCreateModal(!roleCreateModal);

  const [roleUpdateData, setRoleUpdateData] = useState(null);
  const [roleUpdateModal, setRoleUpdateModal] = useState(false);
  const roleUpdateModaltoggle = () => setRoleUpdateModal(!roleUpdateModal);
  const editForm = (el) => {
    setRoleUpdateData(el);
    roleUpdateModaltoggle();
  };

  const [roleViewData, setRoleViewData] = useState(null);
  const [roleViewModal, setRoleViewModal] = useState(false);
  const roleViewModaltoggle = () => setRoleViewModal(!roleViewModal);

  const viewForm = (el) => {
    roleViewModaltoggle();
    setRoleViewData(el);
  };

  // end modal

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/roles/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire("Deleted!", "Role has been deleted.", "success");
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Role is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };
  const fetchInitialRolePermissions = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/initial-role-permissions`)
      .then((response) => {
        setInitialRolePermission(response.data);
      })
      .catch((error) => {});
  };
  const fetchData = (urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }

    //  setData(null)
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
      // url = urlOrPerPage.replace("http", http);
    } else {
      url = `${BACKEND_API}/v1.0/roles/${urlOrPerPage}`;
    }

    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/roles/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchData(perPage);
    fetchInitialRolePermissions();
  }, []);

  const override = css`
    border-color: black;
  `;

  if (!permissions.includes(ROLE_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* ============================================ ALL MODALS ============================================  */}

      {/* USER CREATE MODAL  */}
      <Modal
        isOpen={roleCreateModal}
        toggle={roleCreateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <ModalHeader toggle={roleCreateModaltoggle} className="text-center">
          Create Role
        </ModalHeader>
        <ModalBody>
          <RoleForm
            toggleModal={roleCreateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="create"
            initialRolePermissions={initialRolePermissions}
            setIsOpen={setRoleCreateModal}
          />
        </ModalBody>
      </Modal>

      {/* USER UPDATE MODAL  */}
      <Modal
        isOpen={roleUpdateModal}
        toggle={roleUpdateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <ModalHeader toggle={roleUpdateModaltoggle} className="text-center">
          Update Role
        </ModalHeader>
        <ModalBody>
          <RoleForm
            toggleModal={roleUpdateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            roleUpdateData={roleUpdateData}
            initialRolePermissions={initialRolePermissions}
            setIsOpen={setRoleUpdateModal}
          />
        </ModalBody>
      </Modal>

      {/* ROLE VIEW MODAL  */}
      <Modal
        isOpen={roleViewModal}
        toggle={roleViewModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <ModalHeader toggle={roleViewModaltoggle} className="text-center">
          Role Details
        </ModalHeader>
        <ModalBody>
          <RoleView
            toggleModal={roleViewModaltoggle}
            roleViewData={roleViewData}
            initialRolePermissions={initialRolePermissions}
            setIsOpen={setRoleViewModal}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="User Management / Roles" title="Manage Roles" /> */}
      <Container fluid={true}>
        <Row className="mb-3">
          <Col sm="9" />
          <Col sm="3" />
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Role Management</h5>
                    <span> Manage your Roles </span>
                  </div>
                  {checkPermissions([ROLE_CREATE], permissions) && (
                    <Button color="primary" onClick={roleCreateModaltoggle}>
                      Create Role
                    </Button>
                  )}
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive  px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th className="col1" scope="col">
                        {"#"}
                      </th>
                      <th className="col-md-9 col-8" scope="col">
                        {"Name"}
                      </th>
                      <th className="col-md-2 col-3 text-center" scope="col">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, index) => {
                          return (
                            <tr className="Dashed" key={el.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{el.name}</td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [ROLE_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => viewForm(el)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [ROLE_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => editForm(el)}
                                      color="primary"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [ROLE_DELETE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => deleteFunc(el.id)}
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RoleList;
