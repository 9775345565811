// Job Search
import CardView from "../components/jobSearch/CardView";
import JobApply from "../components/jobSearch/Job-apply";
import JobDetail from "../components/jobSearch/Job-detail";
// Users
import UserEdit from "../components/users/UserEdit";
import UserProfile from "../components/users/UserProfile";
// Support Ticket
import AutomobileCategoryList from "../components/automobile_management/AutomobileCategoryList";
import AutomobileFuelTypeList from "../components/automobile_management/AutomobileFuelTypeList";
import AutomobileMakeList from "../components/automobile_management/AutomobileMakeList";
import AutomobileModelList from "../components/automobile_management/AutomobileModelList";
import AutomobileModelVariantList from "../components/automobile_management/AutomobileModelVariantList";
import EmailTemplateList from "../components/email_template_management/views/EmailTemplateList";
import EmailTemplateUpdate from "../components/email_template_management/views/EmailTemplateUpdate";
import FuelStationList from "../components/fuel_station_management/FuelStationList";
import FuelStationServicesList from "../components/fuel_station_management/FuelStationServicesList";
import CouponList from "../components/garage_component/coupon_management/CouponList";
import ApplyJobs from "../components/garage_component/garage_apply_jobs/ApplyJobs";
import GarageBookings from "../components/garage_component/garage_bookings/GarageBookings";
import JobList from "../components/garage_component/garage_job/JobList";
import PackageList from "../components/garage_component/package_management/PackageList";
import QuistionsList from "../components/garage_component/review_management/quistions/QuistionsList";
import LabelList from "../components/garage_component/review_management/tags/LabelList";
import ViewJob from "../components/garage_component/view_job/ViewJob";
import GarageCreate from "../components/garage_management/GarageCreate";
import GarageList from "../components/garage_management/GarageList";
import GarageUpdate from "../components/garage_management/GarageUpdate";
import Schedule from "../components/garage_timing/Schedule";
import ServiceList from "../components/service_management/ServiceList";
import SubServiceList from "../components/service_management/SubServiceList";
import RoleList from "../components/user_management/RoleList";
import UserList from "../components/user_management/UserList";
import ChangePass from "../components/users/ChangePass";
import Dashboard from "../pages/Dashboard/Dashboard";
import GarageUpdateByOwner from "../components/garage_component/garageOwnerUpdateGarage/GarageUpdateByOwner";
import JobBidsList from "../components/garage_component/garage_bids/JobBidsList";
import ShowReviews from "../components/garage_component/showReviews/ShowReviews";
import UserReview from "../components/garage_component/userReview/UserReview";
import JobPayment from "../components/garage_component/garage_apply_jobs/JobPayment";
import JobPayments from "../components/garage_component/garage_apply_jobs/JobPayments";
import PaymentTypes from "../components/PaymentType/PaymentTypes";

export const routes = [
  { path: "/dashboard", Component: Dashboard },

  { path: "/profile/:string_id", Component: UserProfile },
  { path: "/profile/edit/:string_id", Component: UserEdit },
  { path: "/change-password/:string_id", Component: ChangePass },
  { path: "/garages/update/:string_id", Component: GarageUpdate },
  { path: "/garage/update/owner/:string_id", Component: GarageUpdateByOwner },
  {
    path: "/automobile-category/single/:string_id",
    Component: AutomobileMakeList,
  },
  {
    path: "/automobile-make/single/:string_id",
    Component: AutomobileModelList,
  },
  {
    path: "/automobile-model/single/:string_id",
    Component: AutomobileModelVariantList,
  },
  {
    path: "/automobile-model-variant/single/:string_id",
    Component: AutomobileFuelTypeList,
  },
  { path: "/services/single/:string_id", Component: SubServiceList },
  { path: "/email_template/update/:string_id", Component: EmailTemplateUpdate },
  { path: "/view_job/:string_garage_id/:string_id", Component: ViewJob },

  { path: "/garages/list", Component: GarageList },
  { path: "/garages/create", Component: GarageCreate },
  { path: "/garages/job/list", Component: JobList },
  { path: "/garages/job/quotes/list", Component: JobBidsList },
  { path: "/garage/package", Component: PackageList },
  { path: "/automobile-categories/list", Component: AutomobileCategoryList },
  { path: "/automobile-makes/list", Component: AutomobileMakeList },
  { path: "/automobile-models/list", Component: AutomobileModelList },
  { path: "/services/list", Component: ServiceList },
  { path: "/fuel_station/list", Component: FuelStationList },
  { path: "/fuel_station/list/service", Component: FuelStationServicesList },
  { path: "/users/list", Component: UserList },
  { path: "/users/list/roles/list", Component: RoleList },
  { path: "/payment-type-list", Component: PaymentTypes },
  { path: "/email_template/list", Component: EmailTemplateList },
  { path: "/garage/timing", Component: Schedule },
  { path: "/bookings/list", Component: GarageBookings },
  { path: "/garage/find-jobs", Component: ApplyJobs },
  { path: "/garage/job-payment-list", Component: JobPayments },
  { path: "/garage/coupon", Component: CouponList },
  { path: "/review/question/list", Component: QuistionsList },
  { path: "/review/label/list", Component: LabelList },
  { path: "/review/report/list", Component: ShowReviews },
  { path: "/review/user/list", Component: UserReview },
  { path: "/review/linked-question/list", Component: CouponList },
  { path: "/jobSearch/cardView", Component: CardView },
  { path: "/jobSearch/job-list", Component: JobList },
  { path: "/jobSearch/job-detail", Component: JobDetail },
  { path: "/jobSearch/job-apply", Component: JobApply },
];
