import React, { Fragment, useEffect, useState } from "react";
import CKEditors from "react-ckeditor-component";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { EMAIL_TEMPLATE_UPDATE } from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import { decryptionID } from "../../../utils/encription";

const EmailTemplateUpdate = () => {
  const { string_id } = useParams();
  const id = decryptionID(string_id);

  const [content, setContent] = useState(null);
  const [types, setTypes] = useState([]);
  const [singleTypes, setSingleTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/v1.0/email-templates/single/${id}`)
      .then((res) => {
        apiClient()
          .get(`${BACKEND_API}/v1.0/email-template-types`)
          .then((res2) => {
            setTypes(res2.data);
            setContent(JSON.parse(res.data.template));
            setSingleTypes(res.data.type);
            setIsLoading(false);
          });
      });
  }, [id]);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const handleChangeType = (e) => {
    setSingleTypes(e.target.value);
  };

  const handleSubmit = () => {
    apiClient()
      .post(`${BACKEND_API}/v1.0/email-templates`, {
        type: singleTypes,
        template: JSON.stringify(content),
        is_active: 1,
      })
      .then((res) => {
        Swal.fire({
          title: "Success",
          text: "Template Created Successfully!",
          icon: "success",
        });
        navigate("/email_template/list");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error.response);
        if (error.response?.status === 422) {
          Swal.fire({
            title: error.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        } else if (error.response?.status === 401) {
          Swal.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          Swal.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  // IF HAVE NO PERMISSION OF CREATE GARAGE
  if (!permissions.includes(EMAIL_TEMPLATE_UPDATE)) {
    return <Error401 />;
  }
  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <div className="w-100 d-flex justify-content-start align-items-center flex-direction-column">
                        <div>
                          <h3 className="text-weight-bold">Update Template</h3>
                          <p>Update your email template</p>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="d-flex justify-content-end">
                      <div>
                        <Button onClick={handleSubmit} color="primary">
                          Update
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="my-2">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="types">Select Type :</Label>
                            <Input
                              onChange={handleChangeType}
                              id="types"
                              type="select"
                              name="select"
                              className="form-control digits"
                            >
                              {types?.map((type, i) => (
                                <option
                                  data-testid={`email-emplate-${type}`}
                                  key={i}
                                  value={type}
                                >
                                  {type}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <CKEditors
                    activeclassName="p10"
                    content={content}
                    events={{
                      change: onChange,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default EmailTemplateUpdate;
