import * as React from 'react';
import RatingTagCardWithCounter from '../ItemRattingChart/RatingTagCardWithCounter';
import styles from "./RatingTable.module.css";
import { Col, Collapse, Row, Table } from 'reactstrap';
import { BiDownArrow } from 'react-icons/bi';
import { AiFillStar } from 'react-icons/ai';


function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function RowData(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <tr className={'bg-gradient-info'}>
        <td style={{ padding: '20px' }} align='center'>
          <BiDownArrow style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} className='text-white' />
        </td>

        <td style={{ padding: '20px' }} className={'text-white'} align="left">
          {row.Item}
        </td>

        <td style={{ padding: '20px' }} className={'text-white text-center'} align='center'>
          <span className={''}>{row?.Ratings ? row?.Ratings?.toFixed(2) : "0.00"}</span>
        </td>

        <td style={{ padding: '20px' }} align="left">
          <Row container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            {row?.Issues?.map((tag, j) => (
              <Col key={j} xs={12} sm={6} md={4}>
                <RatingTagCardWithCounter tag={tag?.tag} count={tag?.count} />
              </Col>
            ))}
          </Row>
        </td>
      </tr>

      <tr>
        <td className='py-0' colSpan={6}>
          <Collapse isOpen={open}>
            <div>
              <p className='fw-bold px-4'>
                Ratings
              </p>

              <div className='w-100 p-4'>
                {row?.Stars?.map((star, i) => (
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div className={styles.ratingsContainer}>
                        <span className={styles.rate2}>
                          <span className='fw-bold text-secondary'>{star.value}</span>
                          <AiFillStar className="fs-3 text-black ms-2" />
                        </span>
                        <div className="d-flex justify-content-start align-items-center " style={{ width: '500px' }}>
                          <div className={styles.loaderMainContainer}>
                            <div
                              className={styles.loaderFront}
                              style={{ width: `${row.Ratings * star?.stars_count}%` }}
                            />
                            <div className={styles.loaderContainer} ></div>
                          </div>
                          <p style={{ marginBottom: '-1px' }} className='ms-2 fw-bold text-secondary'>{star?.stars_count} </p>
                        </div>
                      </div>
                    </div>

                    <div className='w-100'>
                      <Row container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {star?.tag_ratings?.map((tag, j) => (
                          <Col key={j} xs={12} sm={12} md={4}>
                            <div className='bg-primary text-white px-3 py-1 rounded-4 d-flex justify-content-between align-items-center'>
                              <span className='block'>{tag?.tag}</span> <span className='text-primary  rounded-4  w-25 h-100 bg-white block px-2 text-center'>{tag?.total.tr}</span> {star?.tag_ratings?.length < j && ","}
                            </div>
                            {/* <RatingTagCardWithCounter tag={tag?.tag} count={tag?.total} /> */}
                          </Col >
                        ))}
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
        </td>

      </tr>
    </React.Fragment>
  );
}



export default function RatingTableUpdate({ allReviewData }) {
  const rows = allReviewData?.map(i => ({ Item: i?.question, Ratings: i?.rating, Comments: 0, Issues: i?.tags_rating, Stars: i?.stars }));
  return (
    <table width={'100%'}>
      <thead>
        <tr className='bg-primary'>
          <td />
          <td style={{ padding: '20px' }} className='font-semibold text-white text-xl' align="left" >Question</td>
          <td style={{ padding: '20px' }} className='font-semibold text-white text-xl' align="center" >Avrg Ratings</td>
          <td style={{ padding: '20px' }} className='font-semibold text-white text-xl' align="center" >Tags</td>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <RowData key={i} row={row} />
        ))}
      </tbody>
    </table>
  );
}