import { Country } from 'country-state-city';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { AiOutlineDelete, AiOutlineFieldTime, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiCloudUpload } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { Gallery, Item } from 'react-photoswipe-gallery';
import { Badge, Button, Col, Container, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { apiClient } from '../../../utils/apiClient';
import { BACKEND, BACKEND_API } from '../../../utils/backend';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';
import { getGarageTiming } from '../../../api/garage_timing';


const GarageView = ({ garageViewData, setIsOpen }) => {
  const [stepNo, setStepNo] = useState(1);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changed, setChanged] = useState(Math.random());
  const [subStep, setSubStep] = useState(0);

  useEffect(() => {
    apiClient().get(`${BACKEND_API}/v1.0/garage-galleries/${garageViewData?.id}`)
      .then(res => {
        setImages(res?.data?.garage_galleries)
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        Swal.fire({ title: "API Error", text: 'Some API errors occurs.', icon: 'error' })
      })
  }, [garageViewData, subStep, changed])

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = ({ meta, file }, status) => {
  }

  const onSubmit = (files, allFiles) => {
    setFormSubmitLoading(true)
    let data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append("images[]", files[i]?.file, files[i]?.file?.name);
    }
    apiClient().post(`${BACKEND_API}/v1.0/garage-galleries/${garageViewData?.id}`, data, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }).then((response) => {
      Swal.fire({ title: 'Success', text: 'Image uploaded successfully!', icon: 'success' });
      setFormSubmitLoading(false)
      setSubStep(0)

    }).catch((error) => {
      setFormSubmitLoading(false)
      Swal.fire({ title: 'Failed', text: 'Image upload failed!', icon: 'error' })
    });
    allFiles.forEach(f => f.remove())
  }

  const handleDelete = (imageId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient().delete(`${BACKEND_API}/v1.0/garage-galleries/${garageViewData?.id}/${imageId}`).then(res => {
          Swal.fire({ title: 'Success', text: 'Image deleted successfully!', icon: 'success' });
          setChanged(Math.random())
        }).catch(err => {
          Swal.fire({ title: 'Failed', text: 'Image deletion failed!', icon: 'error' })
        })
      }
    })
  }
  const [times, setTimes] = useState([]);
  useEffect(() => {
    getGarageTiming(garageViewData?.id)
      .then(time => {
        if (time?.length !== 0) {
          setTimes(time);
        }
      })
  }, [])
  return (
    <Row className=''>
      <Col sm="12">
        {/* TAB BUTTONS CONTAINER  */}
        <Row>
          <Container className='d-flex justify-content-start align-items-center gap-2 w-100 p-0'>
            <div
              onClick={() => setStepNo(1)}
              role='button'
              className={`py-2 px-3 border-bottom  ${stepNo === 1 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <AiOutlineInfoCircle /> Details
            </div>
            <div
              onClick={() => { setStepNo(2); setSubStep(0) }}
              role='button'
              className={`py-2 px-3 border-bottom ${stepNo === 2 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <AiOutlineFieldTime /> Schedule
            </div>
            <div
              onClick={() => { setStepNo(3); setSubStep(0) }}
              role='button'
              className={`py-2 px-3 border-bottom ${stepNo === 3 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <TfiGallery /> Gallery
            </div>
          </Container>
        </Row>

        {/* DETAILS TAB  */}
        {stepNo === 1 &&
          <Container className='tab-1 mt-3'>
            <Row className="form-row mb-2">
              {garageViewData?.logo && <Row className='mb-5'>
                <Col className='d-flex justify-content-center'>
                  <img className='garageDetailsLogo' src={garageViewData?.logo} alt={garageViewData?.name} />
                </Col>
              </Row>}
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="garage_Name">Garage Name</Label>
                <p>{garageViewData?.name}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="owner_Name">Owner Name</Label>
                <p>{garageViewData?.owner?.first_Name} {garageViewData?.owner?.last_Name}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="email">Email</Label>
                <p>{garageViewData?.email}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Phone</Label>
                <p>{garageViewData?.phone}</p>
              </Col>
            </Row>

            <div className='form-row mb-2'>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="country">Country</Label>
                <p>{Country.getCountryByCode(garageViewData?.country)?.name}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="city">City</Label>
                <p>{garageViewData?.city}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="postcode">Postcode</Label>
                <p>{garageViewData?.postcode}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Address Line 1</Label>
                <p>{garageViewData?.address_line_1}</p>
              </Col>
              {garageViewData?.address_line_2 && <Col md="6 mb-3">
                <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Address Line 2</Label>
                <p>{garageViewData?.address_line_2}</p>
              </Col>}
            </div>
          </Container>}

        {/* SCHEDULE TAB  */}
        {stepNo === 2 &&
          <Container className='my-3'>

            <hr color='muted' />
            <Row className="my-2">
              <Col className='fw-bolder'>Days</Col>
              <Col className='fw-bolder text-center'>Open At</Col>
              <Col className='fw-bolder text-center'>Close At</Col>
              <Col className='fw-bolder text-end'>Status</Col>
            </Row>
            <hr color='muted' />
            {
              times?.sort((a, b) => a.day - b.day)?.map(res => (
                <>
                  <Row className="">
                    <Col className='fw-bolder'>{res.day === 0 ? "Mon" : res.day === 1 ? "Tue" : res.day === 2 ? "Wed" : res.day === 3 ? "Thur" :
                      res.day === 4 ? "Fri" : res.day === 5 ? "Sat" : "Sun"}</Col>
                    <Col className=' text-center'>{res?.opening_time}</Col>
                    <Col className=' text-center'>{res?.closing_time}</Col>
                    <Col className=' text-end'>
                      {
                        res?.is_closed === 1 ?
                          <Badge role='button' className='fs-7 my-1' color={'danger'}>
                            Closed
                          </Badge> :
                          <Badge role='button' className='fs-7 my-1' color={'success'}>
                            Open
                          </Badge>
                      }

                    </Col>
                  </Row>
                  <hr color='muted' />
                </>
              ))
            }


            {/* <Row className="">
              <Col className='fw-bolder'>Sun</Col>
              <Col className=' text-center'>10:00 AM</Col>
              <Col className=' text-center'>10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'success'}>
                  Open
                </Badge>
              </Col>
            </Row>
            <hr color='muted' />

            <Row className="">
              <Col className='fw-bolder'>Mon</Col>
              <Col className=' text-center' >10:00 AM</Col>
              <Col className=' text-center' >10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'success'}>
                  Open
                </Badge>
              </Col>
            </Row>
            <hr color='muted' />

            <Row className="">
              <Col className='fw-bolder'>Tue</Col>
              <Col className=' text-center'>10:00 AM</Col>
              <Col className=' text-center'>10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'success'}>
                  Open
                </Badge>
              </Col>
            </Row>
            <hr color='muted' />

            <Row className="">
              <Col className='fw-bolder'>Wed</Col>
              <Col className=' text-center'>10:00 AM</Col>
              <Col className=' text-center'>10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'danger'}>
                  Closed
                </Badge>
              </Col>
            </Row>
            <hr color='muted' />

            <Row className="">
              <Col className='fw-bolder'>Thur</Col>
              <Col className=' text-center'>10:00 AM</Col>
              <Col className=' text-center'>10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'danger'}>
                  Closed
                </Badge>
              </Col>
            </Row>
            <hr color='muted' />

            <Row className="">
              <Col className='fw-bolder'>Fri</Col>
              <Col className=' text-center'>10:00 AM</Col>
              <Col className=' text-center'>10:00 PM</Col>
              <Col className=' text-end'>
                <Badge role='button' className='fs-7 my-1' color={'danger'}>
                  Closed
                </Badge>
              </Col>
            </Row>
            <hr color='muted' /> */}

          </Container>}

        {/* GALLERY TAB  */}
        {stepNo === 3 &&
          <Container className='tab-2 p-0 mt-3 min-h-300' >
            <div className='w-100 h-25 d-flex justify-content-between align-items-center py-0 '>
              <Button onClick={() => setSubStep(1)} color='primary' className=' d-flex justify-content-center align-items-center'>
                <BiCloudUpload className='fs-5' /> Upload Image
              </Button>
              <Button onClick={() => setSubStep(subStep === 2 ? 0 : 2)} color='danger' className=' d-flex justify-content-center align-items-center'>
                <MdDelete className='fs-5' />{subStep === 2 ? 'Cancel Delete' : 'Delete Image'}
              </Button>
            </div>
            {isLoading ? <CustomLoadingSpiner />
              :
              <>
                {/* IMAGE VIEW CONTAINER  */}
                {subStep === 0 &&
                  <div className="px-4 my-3 min-h-300">
                    <Row className='garage-gallery-container' >
                      {images.length > 0 ? (
                        <Col sm="12">
                          <div className="row my-gallery">
                            <div className="pswp-gallery row mx-0 p-0" id="my-test-gallery">
                              <Gallery className="gallery-grid">
                                {images.map((data, key) => (
                                  <Item
                                    key={key}
                                    original={`${BACKEND}${data?.image}`}
                                    thumbnail={`${BACKEND}${data?.image}`}
                                    id={`image-${key}`}
                                  >
                                    {({ ref, open }) => (
                                      <figure className="col-xl-3 col-sm-6 gallery-image-figure"

                                        key={key}>
                                        <div className='gallery-image-container'>
                                          <img
                                            ref={ref}
                                            onClick={open}
                                            src={`${BACKEND}${data?.image}`}
                                            alt="Gallery"
                                            className="img-thumbnail image-object-fit-cover h-100"
                                          />
                                        </div>
                                      </figure>
                                    )}
                                  </Item>
                                ))}
                              </Gallery>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Container className='d-flex justify-content-center align-items-center h-50'>
                          <h3>No Image Found!</h3>
                        </Container>
                      )}
                    </Row>
                  </div>}

                {/* IMAGE DROPZONE  */}
                {subStep === 1 &&
                  <Col xs="12 d-flex align-items-center justify-content-center min-h-300 my-3">
                    {formSubmitLoading ?
                      <CustomLoadingSpiner /> :
                      <div className="dz-message needsclick mb-3 w-100">
                        <Dropzone
                          inputContent="Drop Images Here"
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          onSubmit={onSubmit}
                          accept="image/*"
                        />
                      </div>
                    }
                  </Col>}

                {/* IMAGE DELETE CONTAINER  */}
                {subStep === 2 &&
                  <Col className="px-4 my-3">
                    {isLoading ?
                      (
                        <CustomLoadingSpiner />
                      )
                      :
                      <Row>
                        <Col sm="12">
                          <div id="aniimated-thumbnials" className="row my-gallery gallery">
                            <Row className='h-300 overflowY-scroll mb-4'>
                              {images.length > 0 ? images.map((image, i) => (
                                <Col
                                  sm="12"
                                  md="6"
                                  role='button'
                                  className='d-flex justify-content-center align-items-center overflow-hidden my-2'
                                >
                                  <div className='galleryImageContainer position-relative d-flex justify-content-center align-items-center'>
                                    <div
                                      className='galleryImageButton position-absolute w-100 h-100 d-flex justify-content-center align-items-center '
                                    >
                                      <button
                                        onClick={() => handleDelete(image?.id)}
                                        className='gallery-image-delete-button'>
                                        <AiOutlineDelete className='fs-4 text-light' />
                                      </button>
                                    </div>
                                    <img
                                      className='image-object-fit-cover'
                                      src={`${BACKEND}${image?.image}`}
                                      alt="" />
                                  </div>
                                </Col>
                              )) : (
                                <Col className="d-flex justify-content-center align-items-center h-50 w-100">
                                  <h3>No Image Found!</h3>
                                </Col>
                              )}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    }
                  </Col>}
              </>}
          </Container>}

        <Row className='mb-3'>
          <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
            <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Okay</Button>
          </Col>
        </Row>
      </Col>

    </Row>
  )
}

export default GarageView