import React from 'react'
import { Button, Col, Container, Label, Row } from 'reactstrap'


const SubServiceView = ({ subServiceViewData, setIsOpen }) => {

  return (
    <Row>
      <Col sm="12">
        <Container>
          <Row className='form-row mb-2'>
            <Col md="12">
              <Label className={"text-uppercase fw-bolder"} htmlFor="name">Sub Service Name</Label>
              <p>{subServiceViewData?.name}</p>
            </Col>

          </Row>
          <Row className="form-row mb-2">
            <Col md="6">
              <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Sub Service</Label>
              <p>{subServiceViewData?.service?.name}</p>
            </Col>
            <Col md="12">
              <Label className={"text-uppercase fw-bolder"} htmlFor="description">Description</Label>
              <p>{subServiceViewData?.description ? subServiceViewData?.description : 'N/A'}</p>
            </Col>
            <Col md="12">
              <Label className={"text-uppercase fw-bolder"} htmlFor="description">Price Type</Label>
              <p>{subServiceViewData?.is_fixed_price ? 'Fixed' : 'Not Fixed'}</p>
            </Col>
          </Row>

          <Row>
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Close</Button>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

export default SubServiceView