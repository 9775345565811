import React from "react";

import { Col, Input, Row } from "reactstrap";
import { iconArray } from "../../../data/FontAwsomeIcon";


const IconSelector = ({ name, defaultValue, dataTestid, onChange }) => {

  return (
    <Row className="w-100">
      {iconArray.map((iconName, i) => (
        <Col key={i} xs="2" className="icon-radio-icon d-flex justify-content-center align-items-center">
          {console.log({ defaultValue, iconName })}
          <Input
            checked={defaultValue.split(" ")[1] === iconName}
            data-testid={dataTestid}
            onChange={onChange}
            className="icoRadio"
            value={`fa ${iconName}`}
            name={name}
            id={iconName}
            type="radio" />
          <label
            role="button"
            className="iconLable border border-1 w-1 h-1 d-flex justify-content-center align-items-center rounded-xl"
            htmlFor={iconName}
            value={`fa ${iconName}`}
          >
            <i className={`fs-6 fa ${iconName}`}></i>
          </label>
        </Col>
      ))}
    </Row>
  );
};

export default IconSelector;