import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import Formstep1 from '../../components/forms/FormLayout/GarageForm/Formstep1';
import Formstep2 from '../../components/forms/FormLayout/GarageForm/Formstep2';
import Formstep3 from '../../components/forms/FormLayout/GarageForm/Formstep3';
import { apiClient } from "../../utils/apiClient";
import { BACKEND, BACKEND_API } from "../../utils/backend";
import { getPermissions } from "../../utils/helperFunctions";

const SignUp = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [distanceError, setDistanceError] = useState('');
  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});
  // IMAGE UPLOAD 
  const [imageUrl, setImageUrl] = useState();
  const [formImageUrl, setFormImageUrl] = useState('');
  const [placeholderImage, setPlaceholderImage] = useState('')

  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    currentStep: 1,
  });
  const [user, setUser] = useState({
    first_Name: '',
    last_Name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    image: '',
    address_line_1: 'N/A',
    address_line_2: 'N/A',
    country: 'N/A',
    city: 'N/A',
    postcode: 'N/A',
  });
  const [garage, setGarage] = useState({
    name: '',
    about: '',
    web_page: '',
    phone: '',
    email: '',
    additional_information: '',
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    postcode: '',
    logo: '',
    is_mobile_garage: '1',
    wifi_available: '1',
    labour_rate: '',
    average_time_slot: '',
  });

  const [automobileCategories, setAutomobileCategories] = useState([]);

  const [service, setService] = useState([
    {
      automobile_category_id: "1",
      services: [],
      automobile_makes: []
    }
  ]);
  const loadAutomobileMakes = () => {
    apiClient().get(`${BACKEND_API}/v1.0/automobile-makes-all/${1}`)
      .then(response => {
        let makes = response.data
        loadServices(makes)
      })
      .catch(error => {
        if (error.response?.status === 401) {
          Swal.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
        }
        else {
          Swal.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      })
  }
  useEffect(() => {
    loadAutomobileCategories()
    loadAutomobileMakes()
  }, []);

  // AUTO COMPLETE ADDRESS LINE 1 CHANGE THEN IT SHOULD REPLACE THE POSTCODE AND THE ADDRESS LINE 1
  useEffect(() => {
    setGarage({
      ...garage,
      city: placeAutoComplete?.locality,
      country: placeAutoComplete?.country,
      address_line_1: placeAutoComplete?.full_address,
      postcode: placeAutoComplete?.postal_code
    })
  }, [placeAutoComplete])

  const loadAutomobileCategories = () => {
    apiClient().get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then(response => {
        let categories = response.data
        setAutomobileCategories(categories)
      })
      .catch(error => {
        if (error.response?.status === 401) {
          Swal.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
        }
        else {
          Swal.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      })
  }

  const loadServices = (makes) => {
    apiClient().get(`${BACKEND_API}/v1.0/services-all/${1}`)
      .then(response => {

        let services = response.data
        // setAutomobileCategories(categories)
        let tempServices = JSON.parse(JSON.stringify(service))

        tempServices[0] = {
          ...tempServices[0],

          services: [...services.map(el => {
            el.checked = true;
            el.sub_services.map(sub_el => {
              sub_el.checked = true;
              return sub_el;
            })
            return el;
          })],
          automobile_makes: [...makes.map(el => {
            el.checked = true;
            el.models.map(model => {
              model.checked = true;
              return model;
            })
            return el;
          })]

        };

        setService(tempServices)
      })
      .catch(error => {
        if (error.response?.status === 401) {
          Swal.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
        }
        else {
          Swal.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      })
  }

  const addCategory = () => {
    let tempServices = JSON.parse(JSON.stringify(service))
    tempServices.push({
      automobile_category_id: "1",
      services: [
      ],
      automobile_makes: [
      ]
    });
    setService(tempServices)
  }

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  const handleGarageChange = (e) => {
    const { name, value } = e.target;
    setGarage({ ...garage, [name]: value });
  }

  const _next = () => {
    let currentStep = state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    setState({
      ...state,
      currentStep: currentStep
    });
  }

  const _prev = () => {
    let currentStep = state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    setState({
      ...state,
      currentStep: currentStep
    });
  }
  const previousButton = () => {
    let currentStep = state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button color="secondary float-start" onClick={_prev}>
          Previous
        </Button>
      );
    }

    // ...else return nothing
    return null;
  }

  const nextButton = () => {
    let currentStep = state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 3) {
      return (
        <Button color="primary float-end" onClick={handleSubmitStep}>
          Next
        </Button>
      );
    }
    // ...else render nothing
    return null;
  }
  const submitButton = () => {
    let currentStep = state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 2) {
      return (
        <>
          {
            loading ?
              <Button color="primary float-end" type='button' disabled>Loading..</Button>
              :
              <Button color="primary float-end" type='button' onClick={handleSubmitStep}>Submit</Button>
          }
        </>

      )
    }
    // ...else render nothing
    return null;
  }

  const handleCategoryChange = (e) => {
    let index = e.target.name.split("-")[1];
    let name = e.target.name.split("-")[2];

    let tempServices = JSON.parse(JSON.stringify(service))
    tempServices[index] = {
      ...tempServices[index],
      [name]: e.target.value
    };
    setService(tempServices)
  }

  const handleMakeChange = (e) => {
    const { name } = e.target
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];

    let tempServices = JSON.parse(JSON.stringify(service))

    tempServices[index].automobile_makes[makeIndex].checked = !tempServices[index].automobile_makes[makeIndex].checked;

    tempServices[index].automobile_makes[makeIndex].models.forEach(element => {
      element.checked = tempServices[index].automobile_makes[makeIndex].checked;
      return element
    });

    setService(tempServices)
  }

  const handleModelChange = (e) => {
    const { name } = e.target
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let modelIndex = name.split("-")[5];

    let tempServices = JSON.parse(JSON.stringify(service))

    tempServices[index].automobile_makes[makeIndex].models[modelIndex].checked = !tempServices[index].automobile_makes[makeIndex].models[modelIndex].checked;
    setService(tempServices)
  }

  const handleServiceChange = (e) => {
    const { name } = e.target
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];

    let tempServices = JSON.parse(JSON.stringify(service))

    tempServices[index].services[serviceIndex].checked = !tempServices[index].services[serviceIndex].checked;
    tempServices[index].services[serviceIndex].sub_services.forEach(element => {
      element.checked = tempServices[index].services[serviceIndex].checked;
      return element
    });
    setService(tempServices)
  }

  const handleSubServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];
    let subServiceIndex = name.split("-")[5];

    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].services[serviceIndex].sub_services[subServiceIndex].checked = !tempServices[index].services[serviceIndex].sub_services[subServiceIndex].checked;
    setService(tempServices)
  }

  const handleServiceChangeAll = (e) => {
    const { checked } = e.target
    let tempServices = JSON.parse(JSON.stringify(service))
    tempServices.map(services => {
      services.services.map(service => {
        service.checked = checked;
        service.sub_services.map(sub_service => {
          sub_service.checked = checked
          return sub_service;
        })
        return service;
      })
      return services;
    })
    setService(tempServices)
  }

  const handleMakeChangeAll = (e) => {
    const { checked } = e.target
    let tempServices = JSON.parse(JSON.stringify(service))
    tempServices.map(automobile_makes => {
      automobile_makes.automobile_makes.map(make => {
        make.checked = checked;
        make.models.map(model => {
          model.checked = checked;
          return model;
        })
        return make;
      })
    })
    setService(tempServices)
  }


  
  const onFileChange = (e) => {
    setFormImageUrl(e.target.files[0]);
  };


  // HANDLE FORM SUBMISSION 
  const handleSubmitStep = () => {
    let currentStep = state.currentStep;
    if (formImageUrl) {
      var Data = new FormData();
      Data.append('image', formImageUrl, formImageUrl.name);
      apiClient().post(`${BACKEND_API}/v1.0/garage-image`, Data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
        },
      }).then(res => {
        garage.logo = `${BACKEND}${res.data?.full_location}`;
        
        apiClient().post(`${BACKEND_API}/v1.0/auth/user-register-with-garage`, {
          user: user,
          garage: garage,
          service: currentStep === 3 ? service : null
        }).then(res => {
          localStorage.setItem('token', res?.data?.user?.token);
          Swal.fire({
            title: "Success",
            html: `
                    <div style="display:flex;justify-content:center;align-items:center;width:100%;flex-direction:column;" >
                        <h2 style="font-weight:bold">Congratulation!</h2>
                        <p style="text-align:center">
                            Your account is created successfully. We send a email to: <strong> ${user?.email}</strong>. Please active your account by verifing your email address.<br>
                            <span style="color:#ff8787;">
                                NOTE: If you can't find the email in your inbox then please check to the spam folder.
                            </span>
                        </p>
                    </div>
                  `,
            icon: "success"
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem('user', JSON.stringify(res.data.user));
              localStorage.setItem('permissions', JSON.stringify(getPermissions(res.data.user)));
              window.location.href = `${process.env.PUBLIC_URL}/dashboard`
            }
          });
        }).catch(error => {
          setLoading(false)
          if (error.response?.status === 422) {
            const { errors } = error.response.data
            setServerSideErrors(errors)
            if (
              (currentStep >= 1)
              &&
              (
                errors["user.first_Name"]
                ||
                errors["user.last_Name"]
                ||
                errors["user.email"]
                ||
                errors["user.password"]
                ||
                errors["user.phone"]
                ||
                errors["user.image"]
                ||
                errors["user.address_line_1"]
                ||
                errors["user.address_line_2"]
                ||
                errors["user.country"]
                ||
                errors["user.city"]
                ||
                errors["user.postcode"]
              )
            ) {
              setState({
                ...state,
                currentStep: 1
              });
    
              Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
              return
            }
            else if (
              (currentStep >= 2)
              &&
              (
                errors["garage.name"]
                ||
                errors["garage.about"]
                ||
                errors["garage.web_page"]
                ||
                errors["garage.phone"]
                ||
                errors["garage.email"]
                ||
                errors["garage.additional_information"]
                ||
                errors["garage.country"]
                ||
                errors["garage.city"]
                ||
                errors["garage.postcode"]
                ||
                errors["garage.address_line_1"]
                ||
                errors["garage.address_line_2"]
                ||
                errors["garage.logo"]
                ||
                errors["garage.is_mobile_garage"]
                ||
                errors["garage.wifi_available"]
                ||
                errors["garage.labour_rate"]
                ||
                errors["garage.average_time_slot"]
              )
            ) {
              setState({
                ...state,
                currentStep: 2
              });
              Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
              return
            }
            else if (
              (currentStep >= 3)
              &&
              (
                errors["service.makes"]
                ||
                errors["service.services"]
              )
            ) {
              setState({
                ...state,
                currentStep: 3
              });
    
              Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
              return
            }
            else {
              setServerSideErrors(null)
              _next()
            }
          }
          else if (error.response?.status === 401) {
            Swal.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
          }
          else {
            Swal.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        })
      })
    }else{
      apiClient().post(`${BACKEND_API}/v1.0/auth/user-register-with-garage`, {
        user: user,
        garage: garage,
        service: currentStep === 3 ? service : null
      }).then(res => {
        localStorage.setItem('token', res?.data?.user?.token);
        Swal.fire({
          title: "Success",
          html: `
                  <div style="display:flex;justify-content:center;align-items:center;width:100%;flex-direction:column;" >
                      <h2 style="font-weight:bold">Congratulation!</h2>
                      <p style="text-align:center">
                          Your account is created successfully. We send a email to: <strong> ${user?.email}</strong>. Please active your account by verifing your email address.<br>
                          <span style="color:#ff8787;">
                              NOTE: If you can't find the email in your inbox then please check to the spam folder.
                          </span>
                      </p>
                  </div>
                `,
          icon: "success"
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.setItem('user', JSON.stringify(res.data.user));
            localStorage.setItem('permissions', JSON.stringify(getPermissions(res.data.user)));
            window.location.href = `${process.env.PUBLIC_URL}/dashboard`
          }
        });
      }).catch(error => {
        setLoading(false)
        if (error.response?.status === 422) {
          const { errors } = error.response.data
          setServerSideErrors(errors)
          if (
            (currentStep >= 1)
            &&
            (
              errors["user.first_Name"]
              ||
              errors["user.last_Name"]
              ||
              errors["user.email"]
              ||
              errors["user.password"]
              ||
              errors["user.phone"]
              ||
              errors["user.image"]
              ||
              errors["user.address_line_1"]
              ||
              errors["user.address_line_2"]
              ||
              errors["user.country"]
              ||
              errors["user.city"]
              ||
              errors["user.postcode"]
            )
          ) {
            setState({
              ...state,
              currentStep: 1
            });
  
            Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
            return
          }
          else if (
            (currentStep >= 2)
            &&
            (
              errors["garage.name"]
              ||
              errors["garage.about"]
              ||
              errors["garage.web_page"]
              ||
              errors["garage.phone"]
              ||
              errors["garage.email"]
              ||
              errors["garage.additional_information"]
              ||
              errors["garage.country"]
              ||
              errors["garage.city"]
              ||
              errors["garage.postcode"]
              ||
              errors["garage.address_line_1"]
              ||
              errors["garage.address_line_2"]
              ||
              errors["garage.logo"]
              ||
              errors["garage.is_mobile_garage"]
              ||
              errors["garage.wifi_available"]
              ||
              errors["garage.labour_rate"]
              ||
              errors["garage.average_time_slot"]
            )
          ) {
            setState({
              ...state,
              currentStep: 2
            });
            Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
            return
          }
          else if (
            (currentStep >= 3)
            &&
            (
              errors["service.makes"]
              ||
              errors["service.services"]
            )
          ) {
            setState({
              ...state,
              currentStep: 3
            });
  
            Swal.fire({ title: error.response.data.message, text: "Please Try Again", icon: "warning" });
            return
          }
          else {
            setServerSideErrors(null)
            _next()
          }
        }
        else if (error.response?.status === 401) {
          Swal.fire({ title: error.response.data.message, text: "Hello!!! You do not have permission.", icon: "warning" });
        }
        else {
          Swal.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      })
    }
  };


  return (
    <Fragment>
      <Container className='p-10' fluid={true}>
        <Row>
          <Col sm='12'>
            <Container>
              <Row className='mb-5'>
                <Col className='d-flex justify-content-center'><h3 className='text-primary fw-bold'>Create Account</h3></Col>
              </Row>
              <Form className='f1' method='post'>
                <div className="f1-steps">
                  <div className="f1-progress">
                    <div className="f1-progress-line" data-now-value="49" data-number-of-steps={3} />
                  </div>
                  <div className={`f1-step ${state.currentStep === 1 ? "active" : 'activated'}`}>
                    <div className="f1-step-icon"><i className="fa fa-user" /></div>
                    <p>User Info</p>
                  </div>
                  <div className={`f1-step ${state.currentStep === 2 ? "active" : ''} ${state.currentStep > 2 ? "activated" : ''}`}>
                    <div className="f1-step-icon"><i className="fa fa-car" /></div>
                    <p>Garage Info</p>
                  </div>
                  <div className={`f1-step ${state.currentStep === 3 ? "active" : ''}`}>
                    <div className="f1-step-icon"><i className="fa fa-wrench" /></div>
                    <p>Services</p>
                  </div>
                </div>
              </Form>
              {state.currentStep === 1 && <Formstep1
                currentStep={state.currentStep}
                handleChange={handleUserChange}
                data={user}
                serverSideErrors={serverSideErrors}
              />}
              {state.currentStep === 2 && <Formstep2
                imageUrl={imageUrl}
                setPlaceholderImage={setPlaceholderImage}
                formImageUrl={formImageUrl}
                placeholderImage={placeholderImage}
                onFileChange={onFileChange}
                distanceError={distanceError}
                setPlaceAutoComplete2={setPlaceAutoComplete2}
                setPlaceAutoComplete={setPlaceAutoComplete}
                currentStep={state.currentStep}
                handleChange={handleGarageChange}
                data={garage}
                serverSideErrors={serverSideErrors}
              />}
              {state.currentStep === 3 && <Formstep3
                pathname={pathname}
                handleMakeChangeAll={handleMakeChangeAll}
                handleServiceChangeAll={handleServiceChangeAll}
                currentStep={state.currentStep}
                handleChange={handleUserChange}
                data={service}
                serverSideErrors={serverSideErrors}
                addCategory={addCategory}
                automobileCategories={automobileCategories}
                handleCategoryChange={handleCategoryChange}
                handleMakeChange={handleMakeChange}
                handleModelChange={handleModelChange}
                handleServiceChange={handleServiceChange}
                handleSubServiceChange={handleSubServiceChange}
              />}
              <div>Already have an account? <Link to={`/login`}>Login.</Link></div>
            </Container>

            <Container className='mt-3'>
              {previousButton()}
              {nextButton()}
              {submitButton()}
            </Container>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default SignUp;