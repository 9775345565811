import { Country } from "country-state-city";
import React from "react";
import { Button, Col, Container, Label, Row } from "reactstrap";

const UserView = ({ userViewData, setIsOpen }) => {
  return (
    <Container>
      <Row>
        <Col
          style={{
            overflowY: "auto",
          }}
          sm="12"
        >
          <Row className="form-row mb-2">
            {userViewData?.image && (
              <Row className="mb-5">
                <Col className="d-flex justify-content-center">
                  <img
                    className="garageDetailsLogo"
                    src={userViewData?.image}
                    alt={userViewData?.name}
                  />
                </Col>
              </Row>
            )}
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="first_Name">
                First Name
              </Label>
              <p>{userViewData.first_Name}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="last_Name">
                Last Name
              </Label>
              <p>{userViewData.last_Name}</p>
            </Col>
            <Col md="6 mb-3">
              <Label
                className={"text-uppercase"}
                htmlFor="validationCustomUsername"
              >
                Email
              </Label>
              <p>{userViewData.email}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="phone">
                Phone
              </Label>
              <p>{userViewData.phone}</p>
            </Col>
          </Row>

          <Row className="form-row mb-2">
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="country">
                Country
              </Label>
              <p>{Country.getCountryByCode(userViewData.country)?.name}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="city">
                City
              </Label>
              <p>{userViewData.city}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="postcode">
                Postcode
              </Label>
              <p>{userViewData.postcode}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="phone">
                Address Line 1
              </Label>
              <p>{userViewData.address_line_1}</p>
            </Col>
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="phone">
                Address Line 2
              </Label>
              <p>{userViewData.address_line_2}</p>
            </Col>
          </Row>
          <Row className="form-row mb-2">
            <Col md="6 mb-3">
              <Label className={"text-uppercase"} htmlFor="phone">
                Role
              </Label>
              <p>{userViewData.roles[0].name}</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button
                color="secondary"
                className="py-2 w-100 cancel-button rounded-3"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default UserView;
