import { City, Country } from "country-state-city";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../utils/apiClient";
import { BACKEND, BACKEND_API } from "../../utils/backend";
import AutoComplete from "../AutoComplete/AutoComplete";
import { BiEditAlt } from "react-icons/bi";
import { decryptionID, encryptionID } from "../../utils/encription";

const UserEdit = (props) => {
  const { string_id } = useParams();
  const id = decryptionID(string_id);

  const [serverSideErrors, setServerSideErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad1, setFirstLoad1] = useState(true);
  const [firstLoad2, setFirstLoad2] = useState(true);

  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});

  // IMAGE UPLOAD
  const [imageUrl, setImageUrl] = useState();
  const [formImageUrl, setFormImageUrl] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [placeholderImage, setPlaceholderImage] = useState("");
  const [coverPlaceholderImage, setCoverPlaceholderImage] = useState("");

  const navigate = useNavigate();
  console.log(placeAutoComplete);
  const [data, setData] = useState({
    first_Name: "",
    last_Name: "",
    email: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    postcode: "",
    role: "",
    lat: "",
    long: "",
  });

  // const [passData, setPassData] = useState({
  //   password: "",
  //   password_confirmation: "",
  //   current_password: "",
  // });

  useEffect(() => {
    setIsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/v1.0/user`)
      .then((response) => {
        setData({
          first_Name:
            response.data?.first_Name !== null ? response.data?.first_Name : "",
          last_Name:
            response.data?.last_Name !== null ? response.data?.last_Name : "",
          email: response.data?.email !== null ? response.data?.email : "",
          phone: response.data?.phone !== null ? response.data?.phone : "",
          address_line_1:
            response.data?.address_line_1 !== null
              ? response.data?.address_line_1
              : "",
          address_line_2:
            response.data?.address_line_2 !== null
              ? response.data?.address_line_2
              : "",
          country:
            response.data?.country !== null ? response.data?.country : "",
          city: response.data?.city !== null ? response.data?.city : "",
          postcode:
            response.data?.postcode !== null ? response.data?.postcode : "",
          role: response.data?.roles ? response.data?.roles[0]?.name : "",
          image: response.data?.image !== null ? response.data?.image : "",
          lat: response.data?.lat !== null ? response.data?.lat : "",
          long: response.data?.long !== null ? response.data?.long : "",
          cover_image: `https://quickreview.app/garage${
            response.data?.background_image === null
              ? response.data?.default_background_image
              : response.data?.background_image
          }`,
        });
        setPlaceholderImage(
          response.data?.image !== null ? response.data?.image : ""
        );
        setIsLoading(false);
      })
      .catch((err) => {
        SweetAlert.fire({
          title: "Failed",
          text: "Fetching Data is failed!",
          icon: "error",
        });
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (firstLoad1) {
      setFirstLoad1(false);
    } else {
      setData({
        ...data,
        country: placeAutoComplete?.country,
        city: placeAutoComplete?.locality,
        postcode: placeAutoComplete?.postal_code,
        address_line_1: placeAutoComplete?.full_address,
        lat: `${placeAutoComplete?.coordinates?.lat}`,
        long: `${placeAutoComplete?.coordinates?.lng}`,
      });
    }
  }, [placeAutoComplete]);

  useEffect(() => {
    if (firstLoad2) {
      setFirstLoad2(false);
    } else {
      setData({
        ...data,
        address_line_2: placeAutoComplete?.full_address,
      });
    }
  }, [placeAutoComplete2]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    console.log({ data });
  }, [data]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /\.(jpg|jpeg|png)$/i;

    if (!file || !allowedExtensions.test(file.name)) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Please select a valid image file (jpg, jpeg, or png).",
        icon: "warning",
      });
    } else {
      setPlaceholderImage(URL.createObjectURL(file));
      setFormImageUrl(file);
    }
  };

  const onCoverChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /\.(jpg|jpeg|png)$/i;

    if (!file || !allowedExtensions.test(file.name)) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Please select a valid image file (jpg, jpeg, or png).",
        icon: "warning",
      });
    } else {
      setCoverImageUrl(file);

      setCoverPlaceholderImage(URL.createObjectURL(file));
    }
  };

  // VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate first_Name
    if (!data?.first_Name || data?.first_Name.trim() === "") {
      newErrors.first_Name = "First name is required.";
    }

    // Validate last_Name
    if (!data?.last_Name || data?.last_Name.trim() === "") {
      newErrors.last_Name = "Last name is required.";
    }

    // Validate email
    if (!data?.email) {
      newErrors.email = "Email is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      newErrors.email = "Email is not in a valid format.";
    }

    // Validate phone
    if (!data?.phone) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{11}$/.test(data.phone)) {
      newErrors.phone = "Phone must be 11 digits.";
    }

    // Validate address_line_1
    if (!data?.address_line_1 || data?.address_line_1.trim() === "") {
      newErrors.address_line_1 = "Address is required.";
    }
    // Validate country
    if (!data?.country || data?.country.trim() === "") {
      newErrors.country = "Country is required.";
    }
    // Validate city
    if (!data?.city || data?.city.trim() === "") {
      newErrors.city = "City is required.";
    }
    // Validate Latitute
    if (!data?.lat || data?.lat.trim() === "") {
      newErrors.lat = "Latitude is required.";
    }
    // Validate longitude
    if (!data?.long || data?.long.trim() === "") {
      newErrors.long = "Longitude is required.";
    }
    // Validate postcode
    if (!data?.postcode) {
      newErrors.postcode = "Postcode is required.";
    }
    // Validate Role
    if (!data?.role || data?.role.trim() === "") {
      newErrors.role = "Role is required.";
    }

    const valuesArray = Object.keys(newErrors);

    if (valuesArray.length > 0) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Check your input fields",
        icon: "warning",
      });
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      setIsLoading(true);
      setServerSideErrors(null);
      if (coverImageUrl) {
        let Data = new FormData();
        Data.append("image", coverImageUrl, coverImageUrl.name);
        if (data?.role === "superadmin") {
          apiClient()
            .post(`${BACKEND_API}/v1.0/garage-background-image`, Data, {
              headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
              },
            })
            .then((res) => {
              navigate(`/profile/${encryptionID(id)}`);
            });
        } else {
          apiClient()
            .post(`${BACKEND_API}/v1.0/garage-background-image/by-user`, Data, {
              headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
              },
            })
            .then((res) => {
              navigate(`/profile/${encryptionID(id)}`);
            });
        }
      }
      if (formImageUrl) {
        var Data = new FormData();
        Data.append("image", formImageUrl, formImageUrl.name);
        apiClient()
          .post(`${BACKEND_API}/v1.0/user-image`, Data, {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
            },
          })
          .then((res) => {
            data.image = `${BACKEND}${res.data?.full_location}`;
            apiClient()
              .put(`${BACKEND_API}/v1.0/users/profile`, data)
              .then((response) => {
                setIsLoading(false);
                if (response.data) {
                  SweetAlert.fire({
                    title: "Success",
                    text: "User Updated Successfully!",
                    icon: "success",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate(`/profile/${encryptionID(id)}`);
                    }
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                if (error.response?.status === 422) {
                  setServerSideErrors(error.response.data.errors);
                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                } else if (error.response?.status === 401) {
                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Hello!!! You do not have permission.",
                    icon: "warning",
                  });
                } else {
                  SweetAlert.fire({
                    title: "something went wrong!!",
                    text: "Please Try Again",
                    icon: "warning",
                  });
                }
              });
          });
      } else {
        apiClient()
          .put(`${BACKEND_API}/v1.0/users/profile`, data)
          .then((response) => {
            setIsLoading(false);
            if (response.data) {
              SweetAlert.fire({
                title: "Success",
                text: "User Updated Successfully!",
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate(`/profile/${encryptionID(id)}`);
                }
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response?.status === 422) {
              setServerSideErrors(error.response.data.errors);
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
            } else if (error.response?.status === 401) {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Hello!!! You do not have permission.",
                icon: "warning",
              });
            } else {
              SweetAlert.fire({
                title: "something went wrong!!",
                text: "Please Try Again",
                icon: "warning",
              });
            }
          });
      }
    }
  };

  return (
    <Fragment>
      {/* <BreadCrumb parent="Home" subparent="Users" title="Edit Profile"/> */}
      {isLoading ? (
        <div className="loader-box w-100 d-flex justify-content-center align-items-center">
          <div className="loader-1"></div>
        </div>
      ) : (
        <Container fluid={true}>
          <div className="edit-profile">
            <Row>
              <h2 className="text-center mt-5 mb-3">Edit Profile</h2>
              <Col lg="12">
                <Form
                  className="card"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Container>
                    <Row>
                      <Col sm="12">
                        <div
                          style={{
                            background: `url("${
                              coverPlaceholderImage
                                ? coverPlaceholderImage
                                : data?.cover_image
                            }")`,
                          }}
                          className="edit-cover-photo mb-5 "
                        >
                          <label
                            htmlFor="coverImage"
                            className="bg-secondary"
                            style={{
                              width: 50,
                              height: 50,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              bottom: 40,
                              left: 12,
                            }}
                            role="button"
                          >
                            <BiEditAlt
                              className="text-error"
                              style={{ fontSize: 30 }}
                            />
                          </label>
                          <input
                            type={"file"}
                            accept={"image/x-png,image/jpg,image/jpeg"}
                            placeholder="image"
                            id="coverImage"
                            className="d-none"
                            onChange={(e) => {
                              onCoverChange(e);
                            }}
                          />
                          <div className={"imageUpDropdownContainer checkedit"}>
                            <label title="upload image" htmlFor="image">
                              <div className={"imageDropper pPic"}>
                                {placeholderImage ? (
                                  <img
                                    className={"imageUp"}
                                    alt={"profile_pic"}
                                    src={placeholderImage}
                                  />
                                ) : !imageUrl ? (
                                  <img
                                    accept={"image/jpeg, image/jpg, image/png"}
                                    className={"imageUpPlaceholder"}
                                    alt={"profile_pic"}
                                    src={
                                      "https://i.postimg.cc/rFzvBdw7/gallery.png"
                                    }
                                  />
                                ) : (
                                  <img
                                    className={"imageUp"}
                                    alt={"profile_pic"}
                                    src={`https://mughalsignandprint.co.uk/restaurant2/${imageUrl}`}
                                  />
                                )}
                                {!placeholderImage && (
                                  <>
                                    <span>Click to upload a logo</span>
                                    <span
                                      style={{
                                        fontSize: "0.6rem",
                                        color: "#aaa",
                                        textAlign: "center",
                                      }}
                                    >
                                      Only <br /> JPG, JPEG & PNG <br /> images
                                      are supported
                                    </span>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>

                          <input
                            type={"file"}
                            accept={"image/x-png,image/jpg,image/jpeg"}
                            style={{ display: "none" }}
                            placeholder="image"
                            id="image"
                            onChange={(e) => {
                              onFileChange(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                      {/* FIRST NAME  */}
                      <Col md="6">
                        <FormGroup>
                          <Input
                            data-testid="profile_update_id"
                            className="form-control border-secondary"
                            type="hidden"
                            id="id"
                            name="id"
                            onChange={handleChange}
                            defaultValue={data?.id}
                          />
                          <Label className="form-label" htmlFor="first_Name">
                            First Name*
                          </Label>
                          <Input
                            className="form-control border-secondary"
                            data-testid="profile_update_first_Name"
                            type="text"
                            id="first_Name"
                            name="first_Name"
                            onChange={handleChange}
                            defaultValue={data?.first_Name}
                          />
                          {errors?.first_Name && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.first_Name}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.first_Name ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.first_Name[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* LAST NAME  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="last_Name">
                            Last Name*
                          </Label>
                          <Input
                            data-testid="profile_update_last_Name"
                            className="form-control border-secondary"
                            type="text"
                            id="last_Name"
                            name="last_Name"
                            onChange={handleChange}
                            defaultValue={data?.last_Name}
                          />
                          {errors?.last_Name && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.last_Name}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.last_Name ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.last_Name[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* EMAIL  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="email">
                            Email*{" "}
                            <small>
                              <small>(you can't change your email)</small>
                            </small>{" "}
                          </Label>
                          <Input
                            data-testid="profile_update_email"
                            className="form-control"
                            type="email"
                            id="email"
                            disabled
                            name="email"
                            onChange={handleChange}
                            defaultValue={data?.email}
                          />
                          {errors?.email && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.email}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.email ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.email[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* PHONE  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="phone">
                            Phone*
                          </Label>
                          <Input
                            data-testid="profile_update_phone"
                            className="form-control border-secondary"
                            type="text"
                            id="phone"
                            name="phone"
                            onChange={handleChange}
                            defaultValue={data?.phone}
                          />
                          {errors?.phone && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.phone}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.phone ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.phone[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* ROLE  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="role">
                            Role*{" "}
                            <small>
                              <small>(you can't change your role)</small>
                            </small>{" "}
                          </Label>
                          <Input
                            data-testid="profile_update_role"
                            className="form-control border-secondary"
                            type="text"
                            id="role"
                            disabled
                            name="role"
                            onChange={handleChange}
                            defaultValue={data?.role}
                          />
                          {errors?.role && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.role}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.role ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.role[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>

                      {/* ADDRESS LINE 1  */}
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="address_line_1"
                          >
                            Address Line 1*
                          </Label>
                          <AutoComplete
                            onChange={handleChange}
                            defaultValue={data?.address_line_1}
                            setPlaceAutoComplete={setPlaceAutoComplete}
                            class_Name={"form-control border-secondary"}
                            name="address_line_1"
                            id={"address_line_1"}
                          />
                          {errors?.address_line_1 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.address_line_1}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.address_line_1 ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.address_line_1[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* ADDRESS LINE 2  */}
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="address_line_2"
                          >
                            Address Line 2
                          </Label>
                          <AutoComplete
                            defaultValue={data?.address_line_2}
                            setPlaceAutoComplete={setPlaceAutoComplete2}
                            class_Name={"form-control border-secondary"}
                            name="address_line_2"
                            id={"address_line_2"}
                          />
                          {errors?.address_line_2 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.address_line_2}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.address_line_2 ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.address_line_2[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* COUNTRY  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="country">
                            Country*
                          </Label>
                          <Input
                            type="text"
                            className="custom-select border border-secondary"
                            data-testid="profile_update_country"
                            name="country"
                            onChange={handleChange}
                            value={data?.country}
                          />

                          {errors?.country && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.country}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.country ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.country[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* CITY  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="city">
                            City*
                          </Label>
                          <Input
                            type="text"
                            data-testid="profile_update_city"
                            className="custom-select border border-secondary"
                            name="city"
                            value={data?.city}
                            onChange={handleChange}
                          />

                          {errors?.city && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.city}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.city ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.city[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                      {/* POST CODE  */}
                      <Col md="6">
                        <FormGroup>
                          <Label className="form-label" htmlFor="postcode">
                            Post Code*
                          </Label>
                          <Input
                            className="form-control border-secondary"
                            type="text"
                            id="postcode"
                            name="postcode"
                            data-testid="profile_update_postcode"
                            onChange={handleChange}
                            value={data?.postcode}
                          />
                          {errors?.postcode && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.postcode}
                            </div>
                          )}
                          {serverSideErrors &&
                            (!serverSideErrors?.postcode ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.postcode[0]}
                              </div>
                            ))}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="w-100 d-flex justify-content-end ">
                      <Button color="primary" onClick={onSubmit}>
                        Save Changes
                      </Button>
                    </div>
                  </Container>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default UserEdit;
