import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

// REVIEW REPORT ==================================
export const garageRattingReport = async (id, start, end) => {
  return await apiClient()
    .get(
      `${BACKEND_API}/review-new/get/questions-all-report?garage_id=${id}&start_date=${start}&end_date=${end}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
