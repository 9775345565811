import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../../layout/Breadcrumb";
import CreateBooking from "../CreateBooking";
import PackageDetails from "./PackageDetails";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import {
  getGarageDetailsList,
  getGaragePackages,
  getUserByPhone,
  postBookingDetails,
  regesterUserCustomer,
} from "../../../../api/garage_bookings";
import SweetAlert from "sweetalert2";
import ReviewSteps from "./ReviewSteps";
import UserInfo from "./UserInfo";

const BookingSteps = ({
  fetchData,
  perPage,
  setIsOpen,
  garageId,
  toggleModal,
  setLoading,
}) => {
  const [steps, setSteps] = useState(1);
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 30), 16)
  );
  const [garageDetails, setgarageDetails] = useState({});
  const [isGarageDataLoading, setIsGarageDataLoading] = useState(true);
  useEffect(() => {
    setIsGarageDataLoading(true);
    getGarageDetailsList(garageId)
      .then((res) => {
        setIsGarageDataLoading(false);
        setgarageDetails(res);
      })
      .catch((error) => {
        setIsGarageDataLoading(false);
        console.log({ error });
      });
  }, []);
  const [bookingData, setBookingData] = useState({
    garage_id: garageId,
    customer_id: "",
    coupon_code: "",
    automobile_make_id: "",
    automobile_model_id: "",
    car_registration_no: "",
    car_registration_year: "",
    additional_information: "",
    transmission: "",
    fuel: "",
    job_start_date: moment(startDate).format("YYYY-MM-DD"),
    job_start_time: moment(startDate).format("HH:mm"),
    booking_sub_service_ids: [],
    booking_garage_package_ids: [],
    packages: [],
    services: [],
    make: {},
    model: {},
    currentAmount: 0,
    discountAmount: 0,
    price: "",
  });

  const handleBooking = (customerId) => {
    if (bookingData?.customer_id === "") {
      postBookingDetails({ ...bookingData, customer_id: customerId })
        .then((response) => {
          setLoading(false);
          if (response) {
            SweetAlert.fire({
              title: "Success",
              text: "Booking Created Successfully!",
              icon: "success",
            });
            fetchData(garageId, perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            const errors = error?.response?.data?.errors;
            Object.keys(errors).forEach((field) => {
              errors[field].forEach((errorMessage, index) =>
                SweetAlert.fire({
                  title: "Error!!",
                  text: `${errorMessage}!`,
                  icon: "warning",
                })
              );
            });
          }
        });
    } else {
      postBookingDetails(bookingData)
        .then((response) => {
          setLoading(false);
          if (response) {
            SweetAlert.fire({
              title: "Success",
              text: "Booking Created Successfully!",
              icon: "success",
            });
            fetchData(garageId, perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            const errors = error?.response?.data?.errors;
            Object.keys(errors).forEach((field) => {
              errors[field].forEach((errorMessage, index) =>
                SweetAlert.fire({
                  title: "Error!!",
                  text: `${errorMessage}!`,
                  icon: "warning",
                })
              );
            });
          }
        });
    }
  };

  const [packages, setPackages] = useState([]);
  useEffect(() => {
    getGaragePackages(garageId).then((res) => {
      setPackages(res);
    });
  }, []);

  const [regData, setRegData] = useState({
    first_Name: "",
    last_Name: "",
    email: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    postcode: "",
    lat: "",
    long: "",
    email_verified_at: "",
  });

  const handleRegesterAndBooking = () => {
    if (bookingData?.customer_id) {
      handleBooking();
    } else {
      regesterUserCustomer(regData)
        .then((res) => {
          handleBooking(res?.id);
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          Object.keys(errors).forEach((field) => {
            errors[field].forEach((errorMessage, index) =>
              SweetAlert.fire({
                title: "Error!!",
                text: `${errorMessage}!`,
                icon: "warning",
              })
            );
          });
        });
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div>
                  {/* STEPER  */}
                  <div className="stepwizard">
                    <div className="stepwizard-row setup-panel">
                      <div className="stepwizard-step">
                        <Button
                          color={`${steps === 1 ? "primary" : "light"}`}
                          // onClick={() => {
                          //   setSteps(1);
                          // }}
                        >
                          1
                        </Button>
                        <p>{"Service Details"}</p>
                      </div>
                      <div className="stepwizard-step">
                        <Button
                          color={`${steps === 2 ? "primary" : "light"}`}
                          // onClick={() => {
                          //   setSteps(2);
                          // }}
                        >
                          2
                        </Button>
                        <p>{"Package Details"}</p>
                      </div>
                      <div className="stepwizard-step">
                        <Button
                          color={`${steps === 3 ? "primary" : "light"}`}
                          // onClick={() => {
                          //   setSteps(3);
                          // }}
                        >
                          3
                        </Button>
                        <p>{"Review"}</p>
                      </div>
                      {/* <div className="stepwizard-step">
                                                <Button color={`${steps === 4 ? 'primary' : 'light'}`} onClick={() => { setSteps(4) }}>4</Button>
                                                <p>{'User Info'}</p>
                                            </div> */}
                    </div>
                  </div>
                  {steps === 1 && (
                    <CreateBooking
                      isGarageDataLoading={isGarageDataLoading}
                      fetchData={fetchData}
                      perPage={perPage}
                      setIsOpen={setIsOpen}
                      garageId={garageId}
                      toggleModal={toggleModal}
                      setLoading={setLoading}
                      setSteps={setSteps}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      setStartDate={setStartDate}
                      startDate={startDate}
                      garageDetails={garageDetails}
                      setRegData={setRegData}
                      regData={regData}
                    />
                  )}
                  {steps === 2 && (
                    <PackageDetails
                      packages={packages}
                      garageId={garageId}
                      setSteps={setSteps}
                      setBookingData={setBookingData}
                      bookingData={bookingData}
                    />
                  )}
                  {steps === 3 && (
                    <ReviewSteps
                      regData={regData}
                      handleRegesterAndBooking={handleRegesterAndBooking}
                      setSteps={setSteps}
                      setBookingData={setBookingData}
                      bookingData={bookingData}
                    />
                  )}
                  {/* {steps === 4 && <UserInfo fetchData={fetchData} handleBooking={handleBooking} setSteps={setSteps} setBookingData={setBookingData} bookingData={bookingData} />} */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BookingSteps;
