import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, Container, Input, Label, Row } from "reactstrap";

const ServiceForm = (props) => {
  const [collapseId, setCollapseId] = useState([
    { id: undefined, status: false },
  ]);

  const { allTick, setAllTick, allTickMake, setAllTickMake } = props;

  useEffect(() => {
    props.data.map((el, index) => {
      setCollapseId(
        el?.automobile_makes.map((make, makeIndex) => {
          return { id: makeIndex, status: false };
        })
      );
    });
  }, []);

  if (props.currentStep !== 3) {
    return null;
  }

  return (
    <>
      <h2 className="text-center">
        Which of these services do this garage offer?
      </h2>
      {props.serverSideErrors &&
        (!props.serverSideErrors["service.0.services"] ? (
          <div
            className="valid-feedback text-center"
            style={{ display: "block" }}
          >
            {"Looks good!"}
          </div>
        ) : (
          <div
            className="invalid-feedback text-center"
            style={{ display: "block" }}
          >
            {props.serverSideErrors["service.0.services"][0]} service
          </div>
        ))}
      <div className="d-flex justify-content-center align-items-center my-3">
        <div
          sm={`w-100`}
          className={`w-50 w-xm-100 h-25 border border-primary px-2 pt-2 d-flex align-item-center rounded`}
        >
          <Label className="d-block" for={`category-all-select`}>
            <Input
              className="checkbox_animated"
              id={`category-all-select`}
              type="checkbox"
              name={`category-all-select`}
              checked={allTick}
              onChange={props.handleServiceChangeAll}
            />{" "}
            Tick/untick all Services
          </Label>
        </div>
      </div>
      {props.data.map((el, index) => {
        return (
          <div className="w-100" key={index}>
            <Input className="form-control" name="id" type="hidden" />
            <div>
              <Row>
                {el.services.map((service, serviceIndex) => {
                  return (
                    <Col md={3}>
                      <Accordion key={serviceIndex} className="mb-2">
                        <Accordion.Item eventKey={`${serviceIndex}`}>
                          <Container className="d-flex align-items-center px-0">
                            <Input
                              data-testid={`category-${index}-service-${serviceIndex}`}
                              className="checkbox_animated  mx-3"
                              id={`category-${index}-service-${serviceIndex}`}
                              value={el.services[serviceIndex].checked}
                              checked={
                                el.services[serviceIndex].checked ||
                                false ||
                                service?.sub_services.filter(
                                  (i) => i.checked === true
                                ).length > 0
                              }
                              type="checkbox"
                              name={`category-${index}-service-${serviceIndex}`}
                              onChange={props.handleServiceChange}
                            />
                            <Accordion.Header className="w-100">
                              <Label
                                className="d-block"
                                for={`category-${index}-service-${serviceIndex}`}
                              >
                                {service.name}
                              </Label>
                            </Accordion.Header>
                          </Container>

                          <Accordion.Body>
                            {service.sub_services.map(
                              (sub_service, subServiceIndex) => {
                                return (
                                  <Col md="12" key={subServiceIndex}>
                                    <Label
                                      className="d-block pl-4"
                                      for="chk-ani"
                                    >
                                      <Input
                                        data-testid={`category-${index}-service-${serviceIndex}-sub_service-${subServiceIndex}`}
                                        className="checkbox_animated"
                                        id={`category-${index}-service-${serviceIndex}-sub_service-${subServiceIndex}`}
                                        value={`category-${index}-service-${serviceIndex}-sub_service-${subServiceIndex}`}
                                        type="checkbox"
                                        name={`category-${index}-service-${serviceIndex}-sub_service-${subServiceIndex}`}
                                        checked={
                                          el.services[serviceIndex]
                                            .sub_services[subServiceIndex]
                                            .checked || false
                                        }
                                        onChange={props.handleSubServiceChange}
                                      />
                                      {sub_service.name}
                                    </Label>
                                  </Col>
                                );
                              }
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ServiceForm;
