import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";

import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";

import setLinksView from "../../utils/pagination";

import DatePicker from "react-datepicker";
import { GiHomeGarage } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import {
  GARAGE_CREATE,
  GARAGE_DELETE,
  GARAGE_UPDATE,
  GARAGE_VIEW,
} from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";
import { checkPermissions } from "../../utils/helperFunctions";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import GarageView from "./views/GarageView";
import { encryptionID } from "../../utils/encription";

const GarageList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/garages/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/garages/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };

  // modal
  const [garageViewData, setGarageViewData] = useState(null);
  const [garageViewModal, setGarageViewModal] = useState(false);
  const garageViewModaltoggle = () => setGarageViewModal(!garageViewModal);

  const viewForm = (el) => {
    setGarageViewData(el);
    garageViewModaltoggle();
  };

  // end modal
  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/garages/${id}`)
          .then((response) => {
            if (response.status === 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire(
                "Deleted!",
                "Garage has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Garage is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/garages/${urlOrPerPage}`;
    }

    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/garages/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    fetchData(perPage);
  }, []);
  const navigate = useNavigate();

  if (!permissions.includes(GARAGE_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      <Modal
        isOpen={garageViewModal}
        toggle={garageViewModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <GiHomeGarage className="icon-filter fs-1 mb-2 text-primary"></GiHomeGarage>
            <div className="d-flex">
              {" "}
              <h4 className="fw-bolder text-black-50 me-3">Garage Details</h4>
              <Button
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/garages/update/${encryptionID(
                      garageViewData.id
                    )}`
                  )
                }
                color="primary"
                size="sm"
              >
                <i className="fa fa-pencil me-2"></i>
                Edit Garage
              </Button>
            </div>
          </Col>
        </Row>
        <ModalBody>
          <GarageView
            toggleModal={garageViewModaltoggle}
            garageViewData={garageViewData}
            setIsOpen={setGarageViewModal}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="Garage Management / Garages" title="Manage Garages" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Garage Management</h5>
                    <span> Manage your Garages </span>
                  </div>
                  {checkPermissions([GARAGE_CREATE], permissions) && (
                    <Link to={`${process.env.PUBLIC_URL}/garages/create`}>
                      <Button color="primary">Create Garage</Button>
                    </Link>
                  )}
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Garage Name"}</th>
                      <th scope="col">{"Email"}</th>
                      <th scope="col">{"Owner"}</th>
                      <th scope="col">{"Owner Email"}</th>
                      <th className="text-center" scope="col">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, index) => {
                          return (
                            <tr className="Dashed" key={el?.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{el?.name}</td>
                              <td>{el?.email}</td>
                              <td>{`${el.owner?.first_Name} ${el.owner?.last_Name}`}</td>
                              <td>{el?.owner?.email}</td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [GARAGE_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => viewForm(el)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [GARAGE_UPDATE],
                                    permissions
                                  ) && (
                                    <Link
                                      to={`${
                                        process.env.PUBLIC_URL
                                      }/garages/update/${encryptionID(el.id)}`}
                                    >
                                      <Button color="primary" size="sm">
                                        <i className="fa fa-pencil"></i>
                                      </Button>
                                    </Link>
                                  )}
                                  {checkPermissions(
                                    [GARAGE_UPDATE],
                                    permissions
                                  ) && (
                                    <Link
                                      title="Garage Schedule"
                                      to={`${process.env.PUBLIC_URL}/garage/timing`}
                                    >
                                      <Button color="primary" size="sm">
                                        <i
                                          class="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    </Link>
                                  )}
                                  {JSON.parse(localStorage.getItem("user"))
                                    ?.roles[0]?.name === "superadmin" &&
                                    checkPermissions(
                                      [GARAGE_DELETE],
                                      permissions
                                    ) && (
                                      <Button
                                        onClick={() => deleteFunc(el?.id)}
                                        color="danger"
                                        size="sm"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GarageList;
