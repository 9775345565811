import moment from "moment";

export const getGarageTiming = (date) => {
  const timing = JSON.parse(localStorage.getItem("timing"));
  const selectedTiming = timing.find(
    (time) => time?.day === moment(date).day()
  );
  return {
    startingTime: selectedTiming?.opening_time,
    closingTime: selectedTiming?.closing_time,
    isClosed: selectedTiming?.is_closed,
  };
};

export const getExcludeDays = (date) => {
  const timing = JSON.parse(localStorage.getItem("timing"));

  const close = timing.find((time) => time?.day === date);

  return close?.length > 0 ? true : false;
};
