import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";

import { apiClient } from "../../utils/apiClient";
import { BACKEND, BACKEND_API } from "../../utils/backend";

import { useLocation, useNavigate } from "react-router-dom";
import { GARAGE_CREATE } from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";

import { User } from "react-feather";
import { FaCar } from "react-icons/fa";
import useGeoLocation from "../../hooks/useGeoLocation";
import Formstep1 from "../forms/FormLayout/GarageUpdateForm/Formstep1";
import Formstep2 from "../forms/FormLayout/GarageUpdateForm/Formstep2";
import Formstep3 from "../forms/FormLayout/GarageUpdateForm/Formstep3";
import ScheduleCreateGarage from "../garage_timing/ScheduleCreateGarage";
import { getGarageTiming } from "../../api/garage_timing";
import moment from "moment";

const GarageCreate = ({ history }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  let permissions = JSON.parse(localStorage.getItem("permissions"));
  const [distanceError, setDistanceError] = useState("");
  const location = useGeoLocation();
  // IMAGE UPLOAD
  const [imageUrl, setImageUrl] = useState();
  const [formImageUrl, setFormImageUrl] = useState("");
  const [placeholderImage, setPlaceholderImage] = useState("");
  const [state, setState] = useState({
    currentStep: 3,
  });
  const [user, setUser] = useState({
    first_Name: "",
    last_Name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    image: "",
    address_line_1: "N/A",
    address_line_2: "N/A",
    country: "N/A",
    city: "N/A",
    postcode: "N/A",
    lat: "",
    long: "",
    send_password: false,
  });
  const [garage, setGarage] = useState({
    name: "",
    about: "",
    web_page: "",
    phone: "",
    email: "",
    additional_information: "",
    logo: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    postcode: "",
    lat: "",
    long: "",
    is_mobile_garage: "1",
    wifi_available: "1",
    labour_rate: "",
    average_time_slot: "",
    currency: "",
    time_format: "24-hour", // 12-hour
  });
  useEffect(() => {
    setGarage({
      ...garage,
      address_line_1: placeAutoComplete?.full_address,
      country: placeAutoComplete?.country,
      city: placeAutoComplete?.locality,
      postcode: placeAutoComplete?.postal_code,
      lat: `${placeAutoComplete?.coordinates?.lat}`,
      long: `${placeAutoComplete?.coordinates?.lng}`,
      currency: `${placeAutoComplete?.currency}`,
    });
  }, [placeAutoComplete]);
  const [automobileCategories, setAutomobileCategories] = useState([]);

  const [service, setService] = useState([
    {
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    },
  ]);

  const loadAutomobileMakes = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-makes-all/${1}`)
      .then((response) => {
        let makes = response.data;
        loadServices(makes);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  useEffect(() => {
    loadAutomobileCategories();
    loadAutomobileMakes();
  }, []);

  const loadAutomobileCategories = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then((response) => {
        let categories = response.data;
        setAutomobileCategories(categories);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  const loadServices = (makes) => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/services-all/${1}`)
      .then((response) => {
        let services = response.data;
        // setAutomobileCategories(categories)
        let tempServices = JSON.parse(JSON.stringify(service));

        tempServices[0] = {
          ...tempServices[0],
          services: [
            ...services.map((el) => {
              el.checked = true;
              el.sub_services.map((sub_el) => {
                sub_el.checked = true;
                return sub_el;
              });
              return el;
            }),
          ],
          automobile_makes: [
            ...makes.map((el) => {
              el.checked = true;
              el.models.map((model) => {
                model.checked = true;
                return model;
              });
              return el;
            }),
          ],
        };
        setService(tempServices);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  // CALCULATE THE DISTANCE BETWEEN ADDRESS AND LIVE LOCATION
  function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d * 1000);
  }

  // AUTO COMPLETE ADDRESS LINE 1 CHANGE THEN IT SHOULD REPLACE THE POSTCODE AND THE ADDRESS LINE 1
  // useEffect(() => {

  // }, [placeAutoComplete]);

  // AUTO COMPLETE ADDRESS LINE @ CHANGE THEN IT SHOULD REPLACE THE ADDRESS LINE 2
  useEffect(() => {
    setGarage({
      ...garage,
      address_line_2: placeAutoComplete2?.full_address,
    });
  }, [placeAutoComplete2]);

  const addCategory = () => {
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.push({
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    });

    setService(tempServices);
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleGarageChange = (e) => {
    const { name, value } = e.target;
    setGarage({ ...garage, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const _next = () => {
    let currentStep = state.currentStep;
    currentStep = currentStep + 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const _prev = () => {
    let currentStep = state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const previousButton = () => {
    let currentStep = state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button color="secondary float-left" onClick={_prev}>
          Previous
        </Button>
      );
    }

    // ...else return nothing
    return null;
  };

  const nextButton = () => {
    let currentStep = state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 4) {
      return (
        <>
          {loading ? (
            <Button
              className="ms-3"
              color="primary float-right"
              type="button"
              disabled
            >
              Loading..
            </Button>
          ) : (
            <Button color="primary float-right ms-3" onClick={handleSubmitStep}>
              Next
            </Button>
          )}
        </>
      );
    }
    // ...else render nothing
    return null;
  };

  const submitButton = () => {
    let currentStep = state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 3) {
      return (
        <>
          {loading ? (
            <Button
              className="ms-3"
              color="primary float-right"
              type="button"
              disabled
            >
              Loading..
            </Button>
          ) : (
            <Button
              className="ms-3"
              color="primary float-right"
              type="button"
              onClick={handleSubmitStep}
            >
              Submit
            </Button>
          )}
        </>
      );
    }
    // ...else render nothing
    return null;
  };

  const handleCategoryChange = (e) => {
    let index = e.target.name.split("-")[1];
    let name = e.target.name.split("-")[2];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index] = {
      ...tempServices[index],
      [name]: e.target.value,
    };
    setService(tempServices);
  };

  const handleMakeChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].automobile_makes[makeIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].checked;
    tempServices[index].automobile_makes[makeIndex].models.forEach(
      (element) => {
        element.checked =
          tempServices[index].automobile_makes[makeIndex].checked;
        return element;
      }
    );
    setService(tempServices);
  };

  const handleModelChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let modelIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].automobile_makes[makeIndex].models[modelIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].models[modelIndex]
        .checked;
    setService(tempServices);
  };

  const handleServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];

    let tempServices = JSON.parse(JSON.stringify(service));

    tempServices[index].services[serviceIndex].checked =
      !tempServices[index].services[serviceIndex].checked;
    tempServices[index].services[serviceIndex].sub_services.forEach(
      (element) => {
        element.checked = tempServices[index].services[serviceIndex].checked;
        return element;
      }
    );
    setService(tempServices);
  };
  // SERVICE ALL CHECK
  const [allTick, setAllTick] = useState(true);
  const [allTickMake, setAllTickMake] = useState(true);

  const [isFirstTIme, setIsFirstTIme] = useState(true);

  // CHECK IS THERE IS NO SUB_SERVICE HAVE CHECK OR NOT
  function hasUncheckedSubServices(service) {
    for (const item of service) {
      if (item.sub_services) {
        for (const subService of item.sub_services) {
          if (!subService.checked) {
            return false; // Return false if any sub-service is unchecked
          }
        }
      }
    }
    return true; // Return true if all sub-services are checked
  }

  // CHECK IS THERE IS NO MODEL HAVE CHECK OR NOT
  function hasUncheckedModels(data) {
    for (const item of data) {
      if (item.models) {
        for (const model of item.models) {
          if (!model.checked) {
            return false; // Return false if any model is unchecked
          }
        }
      }
    }
    return true; // Return true if all models are checked
  }

  useEffect(() => {
    if (isFirstTIme) {
      setIsFirstTIme(false);
    } else {
      setAllTick(hasUncheckedSubServices(service[0]?.services));
      setAllTickMake(hasUncheckedModels(service[0]?.automobile_makes));
    }
  }, [service]);

  const handleSubServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];
    let subServiceIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].services[serviceIndex].sub_services[
      subServiceIndex
    ].checked =
      !tempServices[index].services[serviceIndex].sub_services[subServiceIndex]
        .checked;

    tempServices[index].services[serviceIndex].checked =
      tempServices[index].services[serviceIndex].sub_services.filter(
        (i) => i.checked === true
      ).length > 0;

    setService(tempServices);
  };

  const handleServiceChangeAll = (e) => {
    const { checked } = e.target;
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((services) => {
      services.services.map((service) => {
        service.checked = checked;
        service.sub_services.map((sub_service) => {
          sub_service.checked = checked;
          return sub_service;
        });
        return service;
      });
      return services;
    });

    setService(tempServices);
  };

  const handleMakeChangeAll = (e) => {
    const { checked } = e.target;
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((automobile_makes) => {
      automobile_makes.automobile_makes.map((make) => {
        make.checked = checked;
        make.models.map((model) => {
          model.checked = checked;
          return model;
        });
        return make;
      });
    });

    setService(tempServices);
  };

  const onFileChange = (e) => {
    setFormImageUrl(e.target.files[0]);
  };

  const [newGarageId, setNewGarageId] = useState();

  // GARAGE TIMING FUNCTIONALITIES
  const [timeError, setTimeError] = useState(false);

  const [times, setTimes] = useState([
    {
      day: 0,
      opening_time: "",
      closing_time: "",
      day_name: "Sunday",
      is_closed: 0,
    },
    {
      day: 1,
      opening_time: "",
      closing_time: "",
      day_name: "Monday",
      is_closed: 0,
    },
    {
      day: 2,
      opening_time: "",
      closing_time: "",
      day_name: "Tuesday",
      is_closed: 0,
    },

    {
      day: 3,
      opening_time: "",
      closing_time: "",
      day_name: "Wednesday",
      is_closed: 0,
    },
    {
      day: 4,
      opening_time: "",
      closing_time: "",
      day_name: "Thursday",
      is_closed: 0,
    },
    {
      day: 5,
      opening_time: "",
      closing_time: "",
      day_name: "Friday",
      is_closed: 0,
    },
    {
      day: 6,
      opening_time: "",
      closing_time: "",
      day_name: "Saturday",
      is_closed: 0,
    },
  ]);

  const onAlOpening_time = (event, value, i) => {
    let timesValue = [...times];

    const time = event.target.value;

    if (
      moment(time, "HH:mm").isBefore(
        moment(timesValue[i].closing_time, "HH:mm")
      )
    ) {
      timesValue[i].opening_time = `${time}`;
      setTimes(timesValue);
      setTimeError(false);
    } else {
      if (timesValue[i].closing_time === "") {
        timesValue[i].opening_time = `${time}`;
        setTimes(timesValue);
        setTimeError(false);
      } else {
        setTimeError(true);
        SweetAlert.fire(
          "Error!",
          `End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };

  const onAlClosing_time = (event, value, i) => {
    let timesValue = [...times];
    const time = event.target.value;

    console.log({
      value: time,
      openning_time: timesValue[i].opening_time,
    });
    if (
      moment(timesValue[i].opening_time, "HH:mm").isBefore(
        moment(time, "HH:mm")
      )
    ) {
      timesValue[i].closing_time = `${time}`;
      setTimes(timesValue);
      setTimeError(false);
    } else {
      if (timesValue[i].opening_time === "") {
        timesValue[i].closing_time = `${time}`;
        setTimes(timesValue);
        setTimeError(false);
      } else {
        setTimeError(true);
        SweetAlert.fire(
          "Error!",
          `End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };

  const handleApplyALl = (e) => {
    setTimes(
      times.map((res) => {
        return {
          ...res,
          opening_time: times[1]?.opening_time,
          closing_time: times[1]?.closing_time,
          is_closed: times[1]?.is_closed,
        };
      })
    );
  };

  const handleCheckboxChange = (index) => {
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = {
        ...newTimes[index],
        is_closed: newTimes[index].is_closed === 1 ? 0 : 1,
      };
      return newTimes;
    });
  };

  const handleSubmitStep = () => {
    setLoading(true);
    let currentStep = state.currentStep;
    if (formImageUrl) {
      var Data = new FormData();
      Data.append("image", formImageUrl, formImageUrl.name);
      apiClient()
        .post(`${BACKEND_API}/v1.0/garage-image`, Data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
          },
        })
        .then((res) => {
          garage.logo = `${BACKEND}${res.data?.full_location}`;
          apiClient()
            .post(`${BACKEND_API}/v1.0/auth/register-with-garage`, {
              user: user,
              garage: garage,
              times: times,
              service: currentStep >= 3 ? service : null,
            })
            .then((res) => {
              SweetAlert.fire({
                title: "Success",
                text: "Garage is created successfully.",
                icon: "success",
              });
              navigate("/garages/list");
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              if (error.response?.status === 422) {
                const { errors } = error.response.data;
                setServerSideErrors(errors);

                if (
                  currentStep >= 1 &&
                  (errors["user.first_Name"] ||
                    errors["user.last_Name"] ||
                    errors["user.email"] ||
                    errors["user.password"] ||
                    errors["user.phone"] ||
                    errors["user.image"] ||
                    errors["user.address_line_1"] ||
                    errors["user.address_line_2"] ||
                    errors["user.country"] ||
                    errors["user.city"] ||
                    errors["user.postcode"] ||
                    errors["user.lat"] ||
                    errors["user.long"] ||
                    errors["send_password"])
                ) {
                  setState({
                    ...state,
                    currentStep: 1,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else if (
                  currentStep >= 2 &&
                  (errors["garage.name"] ||
                    errors["garage.about"] ||
                    errors["garage.web_page"] ||
                    errors["garage.phone"] ||
                    errors["garage.email"] ||
                    errors["garage.additional_information"] ||
                    errors["garage.country"] ||
                    errors["garage.city"] ||
                    errors["garage.postcode"] ||
                    errors["garage.address_line_1"] ||
                    errors["garage.address_line_2"] ||
                    errors["garage.logo"] ||
                    errors["garage.is_mobile_garage"] ||
                    errors["garage.wifi_available"] ||
                    errors["garage.labour_rate"] ||
                    errors["garage.average_time_slot"] ||
                    errors["garage.lat"] ||
                    errors["garage.long"] ||
                    errors["garage.currency"])
                ) {
                  setState({
                    ...state,
                    currentStep: 2,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else if (
                  currentStep >= 3 &&
                  (errors["service.makes"] ||
                    errors["service.services"] ||
                    errors["service.0.automobile_makes"] ||
                    errors["service.0.services"])
                ) {
                  setState({
                    ...state,
                    currentStep: 3,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else if (
                  currentStep >= 4 &&
                  (errors["times.0.closing_time"] ||
                    errors["times.0.opening_time"] ||
                    errors["times.1.closing_time"] ||
                    errors["times.1.opening_time"] ||
                    errors["times.2.closing_time"] ||
                    errors["times.2.opening_time"] ||
                    errors["times.3.closing_time"] ||
                    errors["times.3.opening_time"] ||
                    errors["times.4.closing_time"] ||
                    errors["times.4.opening_time"] ||
                    errors["times.5.closing_time"] ||
                    errors["times.5.opening_time"] ||
                    errors["times.6.closing_time"] ||
                    errors["times.6.opening_time"])
                ) {
                  setState({
                    ...state,
                    currentStep: 4,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else {
                  setServerSideErrors(null);
                  _next();
                }
              } else if (error.response?.status === 401) {
                SweetAlert.fire({
                  title: error.response.data.message,
                  text: "Hello!!! You do not have permission.",
                  icon: "warning",
                });
              } else {
                SweetAlert.fire({
                  title: "something went wrong!!",
                  text: "Please Try Again",
                  icon: "warning",
                });
              }
            });
        })
        .catch((error) => {
          SweetAlert.fire({
            title: "Failed",
            text: "Garage Logo uploaded failed!",
            icon: "error",
          });
        });
    } else {
      apiClient()
        .post(`${BACKEND_API}/v1.0/auth/register-with-garage`, {
          user: user,
          garage: garage,
          times: times,
          service: currentStep >= 3 ? service : null,
        })
        .then((res) => {
          SweetAlert.fire({
            title: "Success",
            text: "Garage is created successfully.",
            icon: "success",
          });
          navigate("/garages/list");
          // _next();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            const { errors } = error.response.data;
            setServerSideErrors(errors);
            if (
              currentStep >= 1 &&
              (errors["user.first_Name"] ||
                errors["user.last_Name"] ||
                errors["user.email"] ||
                errors["user.password"] ||
                errors["user.phone"] ||
                errors["user.image"] ||
                errors["user.address_line_1"] ||
                errors["user.address_line_2"] ||
                errors["user.country"] ||
                errors["user.city"] ||
                errors["user.postcode"] ||
                errors["user.lat"] ||
                errors["user.long"] ||
                errors["send_password"])
            ) {
              setState({
                ...state,
                currentStep: 1,
              });
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 2 &&
              (errors["garage.name"] ||
                errors["garage.about"] ||
                errors["garage.web_page"] ||
                errors["garage.phone"] ||
                errors["garage.email"] ||
                errors["garage.additional_information"] ||
                errors["garage.country"] ||
                errors["garage.city"] ||
                errors["garage.postcode"] ||
                errors["garage.address_line_1"] ||
                errors["garage.address_line_2"] ||
                errors["garage.logo"] ||
                errors["garage.is_mobile_garage"] ||
                errors["garage.wifi_available"] ||
                errors["garage.labour_rate"] ||
                errors["garage.average_time_slot"] ||
                errors["garage.lat"] ||
                errors["garage.long"])
            ) {
              setState({
                ...state,
                currentStep: 2,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 3 &&
              (errors["service.makes"] ||
                errors["service.services"] ||
                errors["service.0.automobile_makes"] ||
                errors["service.0.services"])
            ) {
              setState({
                ...state,
                currentStep: 3,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 4 &&
              (errors["times.0.closing_time"] ||
                errors["times.0.opening_time"] ||
                errors["times.1.closing_time"] ||
                errors["times.1.opening_time"] ||
                errors["times.2.closing_time"] ||
                errors["times.2.opening_time"] ||
                errors["times.3.closing_time"] ||
                errors["times.3.opening_time"] ||
                errors["times.4.closing_time"] ||
                errors["times.4.opening_time"] ||
                errors["times.5.closing_time"] ||
                errors["times.5.opening_time"] ||
                errors["times.6.closing_time"] ||
                errors["times.6.opening_time"])
            ) {
              setState({
                ...state,
                currentStep: 4,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else {
              setServerSideErrors(null);
              _next();
            }
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const handleLocationADDress1 = (data) => {
    setPlaceAutoComplete(data);
    const dis = distance(
      location?.coordinates.lat,
      location?.coordinates.lon,
      data?.coordinates?.lat,
      data?.coordinates?.lng
    );
    if (
      dis < 500 ||
      isNaN(dis) ||
      JSON.parse(localStorage.getItem("user"))?.roles[0]?.name === "superadmin"
    ) {
      console.log({ data, garage });

      setGarage({
        ...garage,
        city: data?.locality,
        country: data?.country,
        address_line_1: data?.full_address,
        postcode: data?.postal_code,
        lat: `${data?.coordinates?.lat}`,
        long: `${data?.coordinates?.lng}`,
      });
      setDistanceError("");
    } else {
      data?.full_address && setDistanceError("You are in a wrong place.");
    }
  };

  // IF HAVE NO PERMISSION OF CREATE GARAGE
  if (!permissions.includes(GARAGE_CREATE)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* <BreadCrumb parent="Home" subparent="Garage Management / Garages" title="Manage Garages" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={handleSubmit}>
              <Card>
                <CardHeader>
                  <div>
                    <div>
                      <h5>Garage Management</h5>
                      <span> Create Garage </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <Form className="f1" method="post">
                      {/* STEPER  */}
                      <div className="f1-steps">
                        <div className="f1-progress">
                          <div
                            className="f1-progress-line"
                            data-now-value="49"
                            data-number-of-steps={4}
                          />
                        </div>
                        <div
                          className={`f1-step ${
                            state.currentStep === 1 ? "active" : "activated"
                          }`}
                        >
                          <div className="f1-step-icon">
                            <User />
                          </div>
                          <p>User Info</p>
                        </div>
                        <div
                          className={`f1-step ${
                            state.currentStep === 2 ? "active" : ""
                          } ${state.currentStep > 2 ? "activated" : ""}`}
                        >
                          <div className="f1-step-icon">
                            <FaCar />
                          </div>
                          <p>Garage Info</p>
                        </div>
                        <div
                          className={`f1-step ${
                            state.currentStep === 3 ? "active" : ""
                          } ${state.currentStep > 3 ? "activated" : ""}`}
                        >
                          <div className="f1-step-icon">
                            <i className="icon-settings"></i>
                          </div>
                          <p>Services</p>
                        </div>
                        <div
                          className={`f1-step ${
                            state.currentStep === 4 ? "active" : ""
                          }`}
                        >
                          <div className="f1-step-icon">
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                          </div>
                          <p>Timing</p>
                        </div>
                      </div>
                    </Form>
                    {/* STEP-1 */}
                    {state.currentStep === 1 && (
                      <Formstep1
                        currentStep={state.currentStep}
                        handleChange={handleUserChange}
                        data={user}
                        setData={setUser}
                        serverSideErrors={serverSideErrors}
                      />
                    )}

                    {/* STEP-2 */}
                    {state.currentStep === 2 && (
                      <Formstep2
                        imageUrl={imageUrl}
                        setPlaceholderImage={setPlaceholderImage}
                        formImageUrl={formImageUrl}
                        placeholderImage={placeholderImage}
                        onFileChange={onFileChange}
                        distanceError={distanceError}
                        setPlaceAutoComplete={setPlaceAutoComplete}
                        setPlaceAutoComplete2={setPlaceAutoComplete2}
                        handleLocationADDress1={handleLocationADDress1}
                        currentStep={state.currentStep}
                        handleChange={handleGarageChange}
                        data={garage}
                        serverSideErrors={serverSideErrors}
                      />
                    )}

                    {/* STEP-3 */}
                    {state.currentStep === 3 && (
                      <Formstep3
                        allTick={allTick}
                        setAllTick={setAllTick}
                        currentStep={state.currentStep}
                        pathname={pathname}
                        handleChange={handleUserChange}
                        handleServiceChangeAll={handleServiceChangeAll}
                        handleMakeChangeAll={handleMakeChangeAll}
                        allTickMake={allTickMake}
                        setAllTickMake={setAllTickMake}
                        data={service}
                        serverSideErrors={serverSideErrors}
                        handleServiceChange={handleServiceChange}
                        handleSubServiceChange={handleSubServiceChange}
                        handleMakeChange={handleMakeChange}
                        handleModelChange={handleModelChange}
                      />
                    )}

                    {/* STEP-4 */}
                    {state.currentStep === 4 && (
                      <ScheduleCreateGarage
                        onAlOpening_time={onAlOpening_time}
                        onAlClosing_time={onAlClosing_time}
                        handleApplyALl={handleApplyALl}
                        handleCheckboxChange={handleCheckboxChange}
                        times={times}
                        serverSideErrors={serverSideErrors}
                      />
                    )}
                  </div>
                </CardBody>
                <CardFooter>
                  {previousButton()}
                  {nextButton()}
                  {submitButton()}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GarageCreate;
