import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { default as Swal, default as SweetAlert } from "sweetalert2";
import { getGaragesByGivenToken } from "../../api/garages";
import { LOGIN, Password, YourName } from "../../constant";
import Loader from "../../layout/Loader";
import { handleResponse } from "../../services/fack.backend";
import { BACKEND_API } from "../../utils/backend";
import { getPermissions } from "../../utils/helperFunctions";

const Logins = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("admin@gmail.com");
  const [password, setPassword] = useState("12345678");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    handleResponse()
      .then((res) => {
        setIsAuthenticated(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, []);

  const loginWithJwt = (email, password) => {
    // LOGIN FUNCTION
    setLoading(true);
    Axios.post(`${BACKEND_API}/v1.0/login`, { email, password })
      .then((response) => {
        setLoading(false);
        if (response.data.data.roles[0]?.name === "garage_owner") {
          // IF USER IS A GARAGE OWNER
          getGaragesByGivenToken(response.data.data.token).then((res) => {
            // GET ALL GARAGES BY OWNER
            Swal.fire({
              icon: "question",
              iconHtml: "?",
              title: "Select A Garage",
              input: "select",
              inputOptions: res.reduce((options, garage) => {
                options[garage.id] = garage.name;
                return options;
              }, {}),
              inputPlaceholder: "Select One",
              inputValidator: (value) => {
                if (!value) {
                  // IF USER NOT SELECT ANY GARAGE
                  return "You need to select at least a Garage!";
                } else {
                  // IF USER SELECT A GARAGE
                  localStorage.setItem("token", response.data.data.token);
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.data)
                  );
                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(getPermissions(response.data.data))
                  );
                  localStorage.setItem("garageId", value);
                  window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
                }
              },
            });
          });
        } else {
          // IF USER ISN'T A GARAGE OWNER
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          localStorage.setItem(
            "permissions",
            JSON.stringify(getPermissions(response.data.data))
          );
          window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
        }
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          SweetAlert.fire({
            title: "Oops!",
            html: `
              <div style="display:flex;justify-content:center;align-items:center;width:100%;flex-direction:column;" >
                  <h2 style="font-weight:bold">Your email is not verified yet!</h2>
                  <p style="text-align:center">
                       We have already sent a email to: <strong style="color:#158DF7"> ${email}</strong>. Please active your account by verifing your email address. then come back and try to login again.<br>
                       <div STYLE="background-color:#eeeeee; margin-top:5px; border-radius:10px; padding:5px 10px;">
                        <span style="color:#333333; font-size:0.8rem;">
                            NOTE: If you can't find the email in your inbox then please check to the spam folder.
                        </span>
                       </div>
                  </p>
              </div>
              `,
            icon: "warning",
          });
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    localStorage.clear();
    window.location.href =
      "https://garage-dashboard.quickreview.app/home/true";
  });
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* {isAuthenticated ?
              <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
              :
              <div className="page-wrapper d-flex justify-content-center min-vh-100 w-100 align-items-center">
                <Row className='w-100 d-flex justify-content-center'>
                  <Col className='shadow p-3 mb-5 bg-white rounded-3 ' sm="12" md="6">
                    <Form className="theme-form">
                      <h3 className="text-center fw-bold text-primary">{LOGIN}</h3>
                      <FormGroup>
                        <Label className="col-form-label pt-0">{YourName}</Label>
                        <Input className=" border-secondary" type="text" onChange={e => setEmail(e.target.value)} defaultValue={email} required="" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className=" border-secondary" type="password" onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
                      </FormGroup>
                      <div className='d-flex justify-content-between align-items-center'>
                        <Link to={'/reset-password'}>Forgot password?</Link>
                        <div>
                          Haven't Any Account?  <a role="button" className='text-primary' href={process.env.REACT_APP_CLIENT_SITE_PUBLIC_URL}>Create One.</a>
                        </div>
                      </div>


                      <FormGroup className="form-row mt-3 mb-0">
                        {loading ?
                          <Button color="primary btn-block w-100" disabled={loading}>
                            {"LOADING..."}
                          </Button>
                          :
                          <>
                            <Button color="primary btn-block w-100" onClick={() => loginWithJwt(email, password)}>
                              {LOGIN}
                            </Button>
                          </>
                        }
                      </FormGroup>
                    </Form>
                  </Col>

                </Row>
              </div>

            } */}
        </>
      )}
    </>
  );
};

export default Logins;
