import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { User } from "react-feather";
import { FaCar } from "react-icons/fa";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { BACKEND, BACKEND_API } from "../../../utils/backend";
import { apiClient } from "../../../utils/apiClient";
import Formstep1 from "../../forms/FormLayout/GarageForm/Formstep1";
import Formstep2 from "../../forms/FormLayout/GarageForm/Formstep2";
import Formstep3 from "../../forms/FormLayout/GarageForm/Formstep3";
import { decryptionID } from "../../../utils/encription";

const GarageUpdateByOwner = ({ history }) => {
  // eslint-disable-next-line
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { string_id } = useParams();
  const id = decryptionID(string_id);

  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateMakeData, setUpdateMakeData] = useState();
  const [updateServiceData, setUpdateServiceData] = useState();

  // IMAGE UPLOAD
  const [imageUrl, setImageUrl] = useState();
  const [formImageUrl, setFormImageUrl] = useState("");
  const [placeholderImage, setPlaceholderImage] = useState("");

  const [distanceError, setDistanceError] = useState("");
  const [state, setState] = useState({ currentStep: 1 });
  const location = useGeoLocation();

  const [user, setUser] = useState({
    first_Name: "",
    last_Name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    image: "",
    address_line_1: "N/A",
    address_line_2: "N/A",
    country: "N/A",
    city: "N/A",
    postcode: "N/A",
  });

  const [garage, setGarage] = useState({
    name: "",
    about: "",
    web_page: "",
    phone: "",
    email: "",
    additional_information: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    postcode: "",
    logo: "",
    is_mobile_garage: "1",
    wifi_available: "1",
    labour_rate: "",
    average_time_slot: "",
  });

  const [automobileCategories, setAutomobileCategories] = useState([]);

  const [service, setService] = useState([
    {
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    },
  ]);

  const loadSingleUserData = async () => {
    setIsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/v1.0/garages/single/${id}`)
      .then((response) => {
        // GET AND SET USER INFO TO THE STATE
        // setUser({
        //     id: response.data.garage.owner.id,
        //     first_Name: response.data.garage.owner.first_Name,
        //     last_Name: response.data.garage.owner.last_Name,
        //     email: response.data.garage.owner.email,
        //     password: '',
        //     password_confirmation: '',
        //     phone: response.data.garage.owner.phone,
        //     image: response.data.garage.owner.image,
        //     address_line_1: response.data.garage.owner.address_line_1,
        //     address_line_2: response.data.garage.owner.address_line_2,
        //     country: response.data.garage.owner.country,
        //     city: response.data.garage.owner.city,
        //     postcode: response.data.garage.owner.postcode,
        //     lat: response.data.garage.owner.lat,
        //     long: response.data.garage.owner.long,
        // })

        setGarage({
          id: response.data.garage.id,
          name: response.data.garage.name,
          about: response.data.garage.about,
          web_page:
            response.data.garage.web_page === null
              ? ""
              : response.data.garage.web_page,
          phone:
            response.data.garage.phone === null
              ? ""
              : response.data.garage.phone,
          email: response.data.garage.email,
          additional_information: response.data.garage.additional_information,
          address_line_1: response.data.garage.address_line_1,
          address_line_2: response.data.garage.address_line_2,
          country: response.data.garage.country,
          city: response.data.garage.city,
          postcode: response.data.garage.postcode,
          lat: response.data.garage.owner.lat,
          long: response.data.garage.owner.long,
          logo: response.data.garage.logo,
          is_mobile_garage: response.data.garage.is_mobile_garage,
          wifi_available: response.data.garage.wifi_available,
          labour_rate:
            response.data.garage.labour_rate === null
              ? ""
              : response.data.garage.labour_rate,
          average_time_slot:
            response.data.garage.average_time_slot === null
              ? ""
              : response.data.garage.average_time_slot,
        });
        setImageUrl(response.data.garage.logo);
        setPlaceholderImage(response.data.garage.logo);

        setUpdateMakeData(response.data.garage.garage_automobile_makes);
        setUpdateServiceData(response.data.garage.garage_services);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      });
  };

  // GET ALL CATEGORIES
  const loadAutomobileCategories = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then((response) => {
        let categories = response.data;
        setAutomobileCategories(categories);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      });
  };

  // GET ALL MAKES
  const loadAutomobileMakes = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-makes-all/${1}`)
      .then((response) => {
        let makes = response.data;
        loadServices(makes);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      });
  };

  const loadServices = (makes) => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/services-all/${1}`)
      .then((response) => {
        let services = response.data;
        let tempServices = JSON.parse(JSON.stringify(service));
        tempServices[0] = {
          ...tempServices[0],
          services: [
            ...services.map((el) => {
              let checked = false;
              updateServiceData.map((el2) => {
                if (!checked) {
                  checked = el2.service_id === el.id ? true : false;
                  if (checked) {
                    el.sub_services.forEach((sub_el) => {
                      let sub_checked = false;
                      el2.garage_sub_services.forEach((sub_el2) => {
                        if (!sub_checked) {
                          sub_checked =
                            sub_el2.sub_service_id === sub_el.id ? true : false;
                        }
                      });
                      sub_el.checked = sub_checked;
                      return sub_el;
                    });
                  }
                }
              });
              el.checked = checked;
              return el;
            }),
          ],
          automobile_makes: [
            ...makes.map((el) => {
              let checked = false;
              updateMakeData.map((el2) => {
                if (!checked) {
                  checked = el.id === el2.automobile_make_id ? true : false;
                  if (checked) {
                    el.models = el.models.map((model) => {
                      let mod_checked = false;
                      el2.garage_automobile_models.map((model2) => {
                        if (!mod_checked) {
                          mod_checked =
                            model.id === model2.automobile_model_id
                              ? true
                              : false;
                        }
                      });
                      model.checked = mod_checked;
                      return model;
                    });
                  }
                }
              });
              el.checked = checked;
              return el;
            }),
          ],
        };
        setService(tempServices);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
        }
      });
  };

  // CALCULATE THE DISTANCE BETWEEN ADDRESS AND LIVE LOCATION
  function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d * 1000);
  }

  const addCategory = () => {
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.push({
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    });
    setService(tempServices);
  };

  // const handleUserChange = (e) => {
  //     const { name, value } = e.target;
  //     setUser({ ...user, [name]: value });
  // }

  const handleGarageChange = (e) => {
    const { name, value } = e.target;
    setGarage({ ...garage, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const _next = () => {
    let currentStep = state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const _prev = () => {
    let currentStep = state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const previousButton = () => {
    let currentStep = state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button color="secondary float-left" className="me-3" onClick={_prev}>
          Previous
        </Button>
      );
    }
    // ...else return nothing
    return null;
  };

  const nextButton = () => {
    let currentStep = state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 3) {
      return (
        <Button color="primary float-right" onClick={_next}>
          Next
        </Button>
      );
    }
    // ...else render nothing
    return null;
  };

  const submitButton = () => {
    let currentStep = state.currentStep;
    // If the current step is the last step, then render the "submit" button
    if (currentStep > 1) {
      return (
        <>
          {loading ? (
            <Button color="primary float-right" type="button" disabled>
              Loading..
            </Button>
          ) : (
            <Button
              color="primary float-right"
              type="button"
              onClick={handleSubmitStep}
            >
              Submit
            </Button>
          )}
        </>
      );
    }
    // ...else render nothing
    return null;
  };

  const handleCategoryChange = (e) => {
    let index = e.target.name.split("-")[1];
    let name = e.target.name.split("-")[2];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index] = {
      ...tempServices[index],
      [name]: e.target.value,
    };
    setService(tempServices);
  };

  const handleMakeChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].automobile_makes[makeIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].checked;
    tempServices[index].automobile_makes[makeIndex].models.forEach(
      (element) => {
        element.checked =
          tempServices[index].automobile_makes[makeIndex].checked;
        return element;
      }
    );
    setService(tempServices);
  };

  const handleModelChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let modelIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].automobile_makes[makeIndex].models[modelIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].models[modelIndex]
        .checked;
    setService(tempServices);
  };

  const handleServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];

    let tempServices = JSON.parse(JSON.stringify(service));

    tempServices[index].services[serviceIndex].checked =
      !tempServices[index].services[serviceIndex].checked;
    tempServices[index].services[serviceIndex].sub_services.forEach(
      (element) => {
        element.checked = tempServices[index].services[serviceIndex].checked;
        return element;
      }
    );
    setService(tempServices);
  };

  const handleSubServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];
    let subServiceIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].services[serviceIndex].sub_services[
      subServiceIndex
    ].checked =
      !tempServices[index].services[serviceIndex].sub_services[subServiceIndex]
        .checked;
    setService(tempServices);
  };

  const handleServiceChangeAll = (e) => {
    const { checked } = e.target;
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((services) => {
      services.services.map((service) => {
        service.checked = checked;
        service.sub_services.map((sub_service) => {
          sub_service.checked = checked;
          return sub_service;
        });
        return service;
      });
      return services;
    });
    setService(tempServices);
  };

  const handleMakeChangeAll = (e) => {
    const { checked } = e.target;
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((automobile_makes) => {
      automobile_makes.automobile_makes.map((make) => {
        make.checked = checked;
        make.models.map((model) => {
          model.checked = checked;
          return model;
        });
        return make;
      });
    });
    setService(tempServices);
  };

  const onFileChange = (e) => {
    setFormImageUrl(e.target.files[0]);
  };

  const handleSubmitStep = () => {
    let currentStep = state.currentStep;
    if (formImageUrl) {
      var Data = new FormData();
      Data.append("image", formImageUrl, formImageUrl.name);
      apiClient()
        .post(`${BACKEND_API}/v1.0/garage-image`, Data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
          },
        })
        .then((res) => {
          garage.logo = `${BACKEND}${res.data?.full_location}`;

          apiClient()
            .put(`${BACKEND_API}/v1.0/garages`, {
              // user: user,
              garage: garage,
              service: currentStep === 2 ? service : null,
            })
            .then((res) => {
              SweetAlert.fire({
                title: "Success",
                text: "Garage Updated Successfully!",
                icon: "success",
              });
              navigate("/");
            })
            .catch((error) => {
              setLoading(false);
              if (error.response?.status === 422) {
                const { errors } = error.response.data;
                setServerSideErrors(errors);
                if (
                  currentStep >= 1 &&
                  (errors["garage.name"] ||
                    errors["garage.about"] ||
                    errors["garage.web_page"] ||
                    errors["garage.phone"] ||
                    errors["garage.email"] ||
                    errors["garage.additional_information"] ||
                    errors["garage.country"] ||
                    errors["garage.city"] ||
                    errors["garage.postcode"] ||
                    errors["garage.address_line_1"] ||
                    errors["garage.address_line_2"] ||
                    errors["garage.logo"] ||
                    errors["garage.is_mobile_garage"] ||
                    errors["garage.wifi_available"] ||
                    errors["garage.labour_rate"] ||
                    errors["garage.average_time_slot"] ||
                    errors["garage.lat"] ||
                    errors["garage.long"])
                ) {
                  setState({
                    ...state,
                    currentStep: 1,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else if (
                  currentStep >= 2 &&
                  (errors["service.makes"] ||
                    errors["service.services"] ||
                    errors["service.0.automobile_makes"] ||
                    errors["service.0.services"])
                ) {
                  setState({
                    ...state,
                    currentStep: 2,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else {
                  setServerSideErrors(null);
                  _next();
                }
              } else if (error.response?.status === 401) {
                SweetAlert.fire({
                  title: error.response.data.message,
                  text: "Hello!!! You do not have permission.",
                  icon: "warning",
                });
              } else {
                // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
              }
            });
        });
    } else {
      apiClient()
        .put(`${BACKEND_API}/v1.0/garages`, {
          // user: user,
          garage: garage,
          service: currentStep === 2 ? service : null,
        })
        .then((res) => {
          SweetAlert.fire({
            title: "Success",
            text: "Garage Updated Successfully!",
            icon: "success",
          });
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            const { errors } = error.response.data;
            setServerSideErrors(errors);
            if (
              currentStep >= 1 &&
              (errors["garage.name"] ||
                errors["garage.about"] ||
                errors["garage.web_page"] ||
                errors["garage.phone"] ||
                errors["garage.email"] ||
                errors["garage.additional_information"] ||
                errors["garage.country"] ||
                errors["garage.city"] ||
                errors["garage.postcode"] ||
                errors["garage.address_line_1"] ||
                errors["garage.address_line_2"] ||
                errors["garage.logo"] ||
                errors["garage.is_mobile_garage"] ||
                errors["garage.wifi_available"] ||
                errors["garage.labour_rate"] ||
                errors["garage.average_time_slot"])
            ) {
              setState({
                ...state,
                currentStep: 1,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 2 &&
              (errors["service.makes"] ||
                errors["service.services"] ||
                errors["service.0.automobile_makes"] ||
                errors["service.0.services"])
            ) {
              setState({
                ...state,
                currentStep: 2,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else {
              setServerSideErrors(null);
              _next();
            }
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            // SweetAlert.fire({ title: "something went wrong!!", text: "Please Try Again", icon: "warning" });
          }
        });
    }
  };

  // AUTO COMPLETE ADDRESS LINE 1 CHANGE THEN IT SHOULD REPLACE THE POSTCODE AND THE ADDRESS LINE 1
  useEffect(() => {
    setGarage({
      ...garage,
      city: placeAutoComplete?.locality,
      country: placeAutoComplete?.country,
      address_line_1: placeAutoComplete?.full_address,
      postcode: placeAutoComplete?.postal_code,
      lat: `${placeAutoComplete?.coordinates?.lat}`,
      long: `${placeAutoComplete?.coordinates?.lng}`,
    });
    setDistanceError("");
  }, [placeAutoComplete]);

  // AUTO COMPLETE ADDRESS LINE @ CHANGE THEN IT SHOULD REPLACE THE ADDRESS LINE 2
  useEffect(() => {
    setGarage({
      ...garage,
      address_line_2: placeAutoComplete2?.full_address,
    });
  }, [placeAutoComplete2]);

  // LOADING SINGLE GARAGE DATA
  useEffect(() => {
    loadSingleUserData();
  }, []);

  // LOADING SINGLE GARAGE SERVICES AND MAKE-MODEL
  useEffect(() => {
    if (!isLoading) {
      loadAutomobileCategories();
      loadAutomobileMakes();
    }
  }, [isLoading]);

  return (
    <Fragment>
      {/* <BreadCrumb parent="Home" subparent="Garage Management / Garages" title="Manage Garages" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={handleSubmit}>
              <Card>
                <CardHeader>
                  <div>
                    <div>
                      <h5>Garage Management</h5>
                      <span> Create Garage </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="px-5 mb-5 my-1">
                    <Form className="f1" method="post">
                      <div className="f1-steps">
                        <div className="f1-progress">
                          <div
                            className="f1-progress-line"
                            data-now-value="49"
                            data-number-of-steps={2}
                          />
                        </div>
                        {/* <div className={`f1-step ${state.currentStep === 1 ? "active" : 'activated'}`}>
                                                    <div className="f1-step-icon"><User /></div>
                                                    <p>User Info</p>
                                                </div> */}
                        <div
                          className={`f1-step ${
                            state.currentStep === 1 ? "active" : "activated"
                          } ${state.currentStep > 2 ? "activated" : ""}`}
                        >
                          <div className="f1-step-icon">
                            <FaCar />
                          </div>
                          <p>Garage Info</p>
                        </div>
                        <div
                          className={`f1-step ${
                            state.currentStep === 2 ? "active" : ""
                          }`}
                        >
                          <div className="f1-step-icon">
                            <i className="icon-settings"></i>
                          </div>
                          <p>Services</p>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <CardText />

                  {/* {state.currentStep === 1 &&
                                        <Formstep1
                                            currentStep={state.currentStep}
                                            handleChange={handleUserChange}
                                            data={user}
                                            serverSideErrors={serverSideErrors}
                                        />} */}
                  {state.currentStep === 1 && (
                    <Formstep2
                      imageUrl={imageUrl}
                      setPlaceholderImage={setPlaceholderImage}
                      formImageUrl={formImageUrl}
                      placeholderImage={placeholderImage}
                      onFileChange={onFileChange}
                      distanceError={distanceError}
                      setPlaceAutoComplete2={setPlaceAutoComplete2}
                      setPlaceAutoComplete={setPlaceAutoComplete}
                      currentStep={state.currentStep}
                      handleChange={handleGarageChange}
                      data={garage}
                      serverSideErrors={serverSideErrors}
                    />
                  )}
                  {state.currentStep === 2 && (
                    <Formstep3
                      pathname={pathname}
                      handleMakeChangeAll={handleMakeChangeAll}
                      handleServiceChangeAll={handleServiceChangeAll}
                      currentStep={state.currentStep}
                      // handleChange={handleUserChange}
                      data={service}
                      serverSideErrors={serverSideErrors}
                      addCategory={addCategory}
                      automobileCategories={automobileCategories}
                      handleCategoryChange={handleCategoryChange}
                      handleMakeChange={handleMakeChange}
                      handleModelChange={handleModelChange}
                      handleServiceChange={handleServiceChange}
                      handleSubServiceChange={handleSubServiceChange}
                    />
                  )}
                </CardBody>
                <CardFooter>
                  {previousButton()}
                  {nextButton()}
                  {submitButton()}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GarageUpdateByOwner;
