import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "./EditQuestion.module.css";
import { apiClient } from "../../../../utils/apiClient";
import { Button } from "reactstrap";
import { BACKEND_API } from "../../../../utils/backend";
import CustomLoadingSpiner from "../../../CustomLoadingSpiner/CustomLoadingSpiner";
import SweetAlert from "sweetalert2";

const animatedComponents = makeAnimated();

export default function EditAdminQuestion({
  editQuestion,
  setPopupIsOpend,
  toggleModal,
  edit_id,
  fetchData,
}) {
  // DATA FROM API
  const [singleQuestion, setSingleQuestion] = useState({});

  // STAR & TAGS

  // QUESTION
  const [questionDetails, setQuestionDetails] = useState(
    editQuestion?.question
  );
  const [questionStatus, setQuestionStatus] = useState(1);
  // LOADING
  const [isTagsLoading, setIsTagsLoading] = useState(false);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

  // GET TAGS AND  QUESTION
  useEffect(() => {
    setIsTagsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/review-new/get/questions/${edit_id}`)
      .then((res) => {
        setSingleQuestion(res?.data);
        setIsTagsLoading(false);
      })
      .catch((err) => {
        SweetAlert.fire({
          title: "Error!",
          text: `ID: 1023 - Status: ${err?.response?.status} - ${
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Operation Failed, something is wrong!"
          } - Please call to the customer care.`,
          icon: "warning",
        });
        setIsTagsLoading(false);
      });
  }, []);

  const [errors, setErrors] = useState({});
  // VALIDATION
  const validateForm = (formData) => {
    const newErrors = {};

    // Validate data
    if (!formData.question || formData.question.trim() === "") {
      newErrors.question = "Question is required";
    }
    // ````VALIDATE is_active
    if (!formData.is_active === "") {
      newErrors.is_active = "Status is required";
    }
    setErrors(newErrors);
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // UPDATE BUTTON HANDLER
  const handleSubmit = () => {
    const questionData = {
      id: singleQuestion?.id,
      question: questionDetails,
      is_active: questionStatus,
    };
    if (validateForm(questionData)) {
      setIsSubmissionLoading(true);

      apiClient()
        .put(`${BACKEND_API}/review-new/update/questions`, questionData)
        .then((res) => {
          SweetAlert.fire({
            title: "Success!",
            text: `Question Updated Successfully!`,
            icon: "success",
          });
          setTimeout(() => {
            toggleModal();
            fetchData();
          }, 1000);
          setIsSubmissionLoading(false);
        })
        .catch((err) => {
          setIsSubmissionLoading(false);
          SweetAlert.fire({
            title: "Error!",
            text: `ID: 1023 - Status: ${err?.response?.status} - ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Operation Failed, something is wrong!"
            } - Please call to the customer care.`,
            icon: "warning",
          });
        });
    }
  };
  return (
    <div className={styles.popupContainer}>
      {isTagsLoading ? (
        <div className={styles.loadingContainer}>
          <CustomLoadingSpiner />
        </div>
      ) : (
        <div className={styles.popupMain}>
          <div>
            {/* QUESTION INFORMATIONS  */}
            <div className={styles.questionPopupTableRowContainer}>
              <div className={styles.questionPopupLeft}>Title</div>
              <div className={styles.questionPopupRight}>
                <input
                  onChange={(e) => setQuestionDetails(e.target.value)}
                  className={styles.questionPopupQuestionInput}
                  value={questionDetails}
                  type="text"
                  name="question"
                  placeholder="Question"
                />
                {errors.question && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.question}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.questionPopupTableRowContainer}>
              <div className={styles.questionPopupLeft}>Status</div>
              <div className={styles.questionPopupRight}>
                <Select
                  required
                  onChange={(e) => setQuestionStatus(e.value)}
                  name="status"
                  isSearchable
                  styles={{ outline: "none" }}
                  components={animatedComponents}
                  value={{
                    value: singleQuestion.is_active,
                    label: singleQuestion.is_active ? "Public" : "Private",
                  }}
                  options={[
                    { value: 0, label: "Private" },
                    { value: 1, label: "Public" },
                  ]}
                />
                {errors.is_active && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.is_active}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Button
            color="primary"
            className="w-100 my-5"
            disabled={isSubmissionLoading}
            onClick={handleSubmit}
          >
            {isSubmissionLoading ? "Loading..." : "Update"}
          </Button>
        </div>
      )}
    </div>
  );
}
