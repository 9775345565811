import {
  Bookmark,
  Briefcase,
  Clock,
  Home,
  Tool,
  User,
  Package,
  Star,
} from "react-feather";
import { AiFillCar, AiFillShop } from "react-icons/ai";
import { FaOilCan } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { RiCoupon2Line } from "react-icons/ri";
import {
  AUTOMOBILE_VIEW,
  BOOKING_DELETE,
  BOOKING_UPDATE,
  BOOKING_VIEW,
  COUPON_VIEW,
  EMAIL_TEMPLATE_DELETE,
  EMAIL_TEMPLATE_UPDATE,
  EMAIL_TEMPLATE_VIEW,
  FUEL_STATION_CREATE,
  FUEL_STATION_DELETE,
  FUEL_STATION_UPDATE,
  FUEL_STATION_VIEW,
  GARAGE_DELETE,
  GARAGE_PACKAGE_VIEW,
  GARAGE_TIME,
  GARAGE_UPDATE,
  GARAGE_VIEW,
  JOB_BIDS_VIEW,
  JOB_PAYMENT_CREATE,
  JOB_PAYMENT_DELETE,
  JOB_PAYMENT_UPDATE,
  JOB_PAYMENT_VIEW,
  JOB_VIEW,
  QUIESTIONS_DELETE,
  QUIESTIONS_UPDATE,
  QUIESTIONS_VIEW,
  REVIEW_CREATE,
  REVIEW_UPDATE,
  REVIEW_VIEW,
  ROLE_VIEW,
  SERVICE_VIEW,
  USER_VIEW,
} from "../../constant/permissions";
import { checkPermissions } from "../../utils/helperFunctions";
import { BiKey, BiSolidDiscount } from "react-icons/bi";
let permissions = JSON.parse(localStorage.getItem("permissions"));
let user = JSON.parse(localStorage.getItem("user"));
export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: `${process.env.PUBLIC_URL}/dashboard`,
    active: false,
    bookmark: true,
    show: true,
  },
  {
    title: "Garages",
    icon: AiFillShop,
    type: "link",
    badgeType: "primary",
    active: false,
    path: `${process.env.PUBLIC_URL}/garages/list`,
    show: checkPermissions([GARAGE_VIEW], permissions),
  },
  // {
  //   title: "Garage Timing",
  //   icon: Clock,
  //   path: `${process.env.PUBLIC_URL}/garage/timing`,
  //   type: "link",
  //   active: false,
  //   show: checkPermissions([GARAGE_TIME], permissions),
  // },
  {
    title: "Coupon",
    icon: RiCoupon2Line,
    path: `${process.env.PUBLIC_URL}/garage/coupon`,
    type: "link",
    active: false,
    show: checkPermissions([COUPON_VIEW], permissions),
  },
  {
    title: "Packages/Offers",
    icon: Package,
    path: `${process.env.PUBLIC_URL}/garage/package`,
    type: "link",
    active: false,
    show: checkPermissions([GARAGE_PACKAGE_VIEW], permissions),
  },
  {
    title: "Services",
    icon: Tool,
    type: "link",
    badgeType: "primary",
    active: false,
    path: `${process.env.PUBLIC_URL}/services/list`,
    show: checkPermissions([SERVICE_VIEW], permissions),
  },
  {
    title: "Automobiles",
    icon: AiFillCar,
    type: "sub",
    badgeType: "primary",
    active: false,
    path: `${process.env.PUBLIC_URL}/automobile-categories/list`,
    type: "link",
    show: checkPermissions([AUTOMOBILE_VIEW], permissions),
  },
  {
    title: "Fuel Stations",
    icon: FaOilCan,
    type: "sub",
    badgeType: "primary",
    active: false,
    // permissionList:[USER_CREATE,USER_UPDATE,USER_VIEW,USER_DELETE],
    show: checkPermissions(
      [
        FUEL_STATION_VIEW,
        FUEL_STATION_CREATE,
        FUEL_STATION_UPDATE,
        FUEL_STATION_DELETE,
      ],
      permissions
    ),
    children: [
      {
        path: `${process.env.PUBLIC_URL}/fuel_station/list`,
        title: "All Fuel Stations",
        type: "link",
        show: checkPermissions([FUEL_STATION_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/fuel_station/list/service`,
        title: `Fuel Station's Services`,
        type: "link",
        show: checkPermissions([FUEL_STATION_VIEW], permissions),
      },
    ],
  },
  {
    title: "Jobs",
    icon: Briefcase,
    type: "sub",
    badgeType: "primary",
    active: false,
    show: checkPermissions([JOB_VIEW], permissions),
    children: [
      {
        path: `${process.env.PUBLIC_URL}/garage/find-jobs`,
        title: "Find Jobs",
        type: "link",
        show: checkPermissions([JOB_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/garages/job/list`,
        title: `Job List`,
        type: "link",
        show: checkPermissions([JOB_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/garages/job/quotes/list`,
        title: `Quotes List`,
        type: "link",
        show: checkPermissions([JOB_BIDS_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/garage/job-payment-list`,
        title: "Job Payment List",
        type: "link",
        show: checkPermissions([JOB_VIEW], permissions),
        // show: checkPermissions([JOB_PAYMENT_VIEW], permissions),
      },
    ],
  },
  {
    title: "Users",
    icon: User,
    type: "sub",
    badgeType: "primary",
    active: false,
    // permissionList:[USER_CREATE,USER_UPDATE,USER_VIEW,USER_DELETE],
    show: checkPermissions([USER_VIEW, ROLE_VIEW], permissions),
    children: [
      {
        path: `${process.env.PUBLIC_URL}/users/list`,
        title: "All Users",
        type: "link",
        show: checkPermissions([USER_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/users/list/roles/list`,
        title: "User Roles",
        type: "link",
        show: checkPermissions([ROLE_VIEW], permissions),
      },
    ],
  },
  {
    title: "Payment Type",
    icon: HiOutlineMail,
    type: "link",
    badgeType: "primary",
    active: false,
    path: `${process.env.PUBLIC_URL}/payment-type-list`,
    // permissionList:[USER_CREATE,USER_UPDATE,USER_VIEW,USER_DELETE],
    show: checkPermissions([USER_VIEW], permissions),
  },
  {
    title: "Email Templates",
    icon: HiOutlineMail,
    type: "link",
    badgeType: "primary",
    active: false,
    path: `${process.env.PUBLIC_URL}/email_template/list`,
    // permissionList:[USER_CREATE,USER_UPDATE,USER_VIEW,USER_DELETE],
    show: checkPermissions(
      [EMAIL_TEMPLATE_DELETE, EMAIL_TEMPLATE_UPDATE, EMAIL_TEMPLATE_VIEW],
      permissions
    ),
  },
  {
    title: "Bookings",
    icon: Bookmark,
    type: "sub",
    badgeType: "primary",
    active: false,
    show: checkPermissions(
      [BOOKING_VIEW, BOOKING_UPDATE, BOOKING_DELETE],
      permissions
    ),
    children: [
      {
        path: `${process.env.PUBLIC_URL}/bookings/list`,
        title: "All Bookings",
        type: "link",
        show: checkPermissions([BOOKING_VIEW], permissions),
      },
    ],
  },
  {
    title: "Reviews",
    icon: Star,
    type: "sub",
    badgeType: "primary",
    active: false,
    show: checkPermissions(
      [
        QUIESTIONS_DELETE,
        QUIESTIONS_VIEW,
        REVIEW_UPDATE,
        QUIESTIONS_UPDATE,
        REVIEW_CREATE,
      ],
      permissions
    ),
    children: [
      {
        path: `${process.env.PUBLIC_URL}/review/question/list`,
        title: "Questions",
        type: "link",
        show: checkPermissions([QUIESTIONS_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/review/label/list`,
        title: "Labels",
        type: "link",
        show: checkPermissions([QUIESTIONS_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/review/report/list`,
        title: "Report",
        type: "link",
        show:
          user?.roles[0]?.name !== "superadmin" &&
          checkPermissions([QUIESTIONS_VIEW], permissions),
      },
      {
        path: `${process.env.PUBLIC_URL}/review/user/list`,
        title: "User Review",
        type: "link",
        show:
          user?.roles[0]?.name !== "superadmin" &&
          checkPermissions([REVIEW_VIEW], permissions),
      },
    ],
  },

  // {
  //   title: 'Dashboard', icon: Settings, type: 'sub', badgeType: 'primary', active: false, show: false, children: [
  //     { path: `${process.env.PUBLIC_URL}/dashboard`, title: 'Default', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/dashboard/hospital`, title: 'Hospital', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/dashboard/sass`, title: 'Sass', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/dashboard/crm`, title: 'Crm', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/dashboard/crypto`, title: 'Crypto', type: 'link' }
  //   ]
  // },
  // {
  //   title: 'Widgets', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/widgets/general`, title: 'General', show: true, type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/widgets/chart`, title: 'Chart', show: true, type: 'link' },
  //   ]
  // },
  // {
  //   title: 'Base', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/base/typography`, title: 'Typography', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/avatars`, title: 'Avatars', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/helperclasses`, title: 'Helper Classes', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/grid`, title: 'Grid', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/tag-pills`, title: 'Tag & pills', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/progress-bar`, title: 'Progress', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/modal`, title: 'Modal', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/alert`, title: 'Alert', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/popover`, title: 'Popover', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/tooltips`, title: 'Tooltip', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/spinner`, title: 'Spinner ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/dropdown`, title: 'Dropdown ', type: 'link', show: true, },

  //     {
  //       title: 'Tabs', type: 'sub', show: true, children: [
  //         { title: 'Bootstrap Tabs', type: 'link', path: `${process.env.PUBLIC_URL}/base/tab-bootstrap`, show: true, },
  //         { title: 'Line Tabs', type: 'link', path: `${process.env.PUBLIC_URL}/base/tab-line`, show: true, },
  //       ]
  //     },

  //     { path: `${process.env.PUBLIC_URL}/base/accordion`, title: 'Accordion', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/navs`, title: 'Navs ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/shadow`, title: 'Shadow ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/base/list`, title: 'List ', type: 'link', show: true, },

  //   ]
  // },
  // {
  //   title: 'Advance', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/advance/scrollable`, title: 'Scrollable ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/bootstrap-notify`, title: 'Bootstrap Notify ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/rating`, title: 'Rating', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/dropzone`, title: 'Dropzone', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/sweetAlert`, title: 'SweetAlert ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/tourComponent`, title: 'Tour ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/rangeSlider`, title: 'Range Slider ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/imageCropper`, title: 'Image Cropper ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/breadcrumb`, title: 'Breadcrumb ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/dragNDropComp`, title: 'Drag and Drop ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/carousel`, title: 'Owl Carousel', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/pagination`, title: 'Pagination', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/steps`, title: 'Steps ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/advance/stickyNotes`, title: 'Sticky ', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Icons', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/icons/flagIcons`, title: 'Flag Icon', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`, title: 'Fontawesome Icon ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/icons/icoIcons`, title: 'Ico Icon ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/icons/themifyIcons`, title: 'Themify Icon ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/icons/featherIcons`, title: 'Feather Icon ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/icons/weatherIcons`, title: 'Whether Icon ', type: 'link', show: true, },

  //     { path: `${process.env.PUBLIC_URL}/icons/simplelineIcon`, title: 'Simple Line Icon ', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/icons/material-design-icon`, title: 'Material Design Icon ', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/icons/pe7-icon`, title: 'Pe7 Icon', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/icons/typicons-icon`, title: 'Typicons Icon ', type: 'link' },
  //     { path: `${process.env.PUBLIC_URL}/icons/ionic-icon`, title: 'Ionic Icon ', type: 'link' },
  //   ]
  // },
  // {
  //   title: 'Buttons', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/buttons/default-btn`, title: 'Default Style ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/buttons/flatBtn`, title: 'Flat Style', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/buttons/edgeBtn`, title: 'Edge Style', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/buttons/raisedBtn`, title: 'Raised Style', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/buttons/groupBtn`, title: 'Button Group', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Gallery', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/gallery/imageGallery`, title: 'Gallery Grid ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`, title: 'Gallery Grid With Desc ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`, title: 'Masonry Gallery', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`, title: 'Masonry With Desc', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/gallery/imageHover`, title: 'Hover Effect', type: 'link', show: true, }
  //   ]
  // },
  // {
  //   title: 'Forms', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     {
  //       title: ' Form Controls ', type: 'sub', children: [
  //         { title: 'Form Validation', type: 'link', path: `${process.env.PUBLIC_URL}/forms/form-validation`, show: true, },
  //         { title: 'Basic Input', type: 'link', path: `${process.env.PUBLIC_URL}/forms/baseInput`, show: true, },
  //         { title: 'Checkbox & Radio', type: 'link', path: `${process.env.PUBLIC_URL}/forms/radio-checkbox`, show: true, },
  //         { title: 'Input Groups', type: 'link', path: `${process.env.PUBLIC_URL}/forms/inputGroup`, show: true, },
  //         { title: 'Mega Option', type: 'link', path: `${process.env.PUBLIC_URL}/forms/megaOptions`, show: true, },

  //       ]
  //     },
  //     {
  //       title: 'Form Widgets', type: 'sub', show: true, children: [
  //         { title: 'Datepicker', type: 'link', path: `${process.env.PUBLIC_URL}/form-widget/datepicker`, show: true, },
  //         { title: 'DateTimepicker', type: 'link', path: `${process.env.PUBLIC_URL}/form-widget/timepicker`, show: true, },
  //         { title: 'Typeahead', type: 'link', path: `${process.env.PUBLIC_URL}/form-widget/typeahead`, show: true, },
  //       ]
  //     },
  //     {
  //       title: 'Form Layout', type: 'sub', show: true, children: [
  //         { path: `${process.env.PUBLIC_URL}/forms/formDefault`, title: 'Form Default', type: 'link', show: true, },
  //         { path: `${process.env.PUBLIC_URL}/forms/formWizard1`, title: 'Form Wizard 1', type: 'link', show: true, },
  //         { path: `${process.env.PUBLIC_URL}/forms/formWizard2`, title: 'Form Wizard 2', type: 'link', show: true, },
  //         { path: `${process.env.PUBLIC_URL}/forms/formWizard3`, title: 'Form Wizard 3', type: 'link', show: true, },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   title: 'Tables', icon: Settings, type: 'sub', show: true, children: [
  //     {
  //       title: ' Bootstrap Table ', type: 'sub', children: [
  //         { title: 'Basic Table', type: 'link', path: `${process.env.PUBLIC_URL}/table/basic`, show: true, },
  //         { title: 'Sizing Table', type: 'link', path: `${process.env.PUBLIC_URL}/table/sizing`, show: true, },
  //         { title: 'Border Table', type: 'link', path: `${process.env.PUBLIC_URL}/table/border`, show: true, },
  //         { title: 'Styling Table', type: 'link', path: `${process.env.PUBLIC_URL}/table/styling`, show: true, },
  //       ]
  //     },
  //     {
  //       title: 'Data Tables', path: `${process.env.PUBLIC_URL}/table/datatable`, type: 'link', show: true,
  //     }
  //   ]
  // },
  // {
  //   title: 'Cards', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/cards/basicCards`, title: 'Basic Card ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/cards/creativeCards`, title: 'Creative Card ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/cards/tabCard`, title: 'Tabbed Card ', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/cards/draggingCards`, title: 'Draggable Card', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Timeline', icon: Settings, type: 'sub', show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/timelines/timeline1`, title: 'Timeline 1', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/timelines/timeline2`, title: 'Timeline 2', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Charts', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/charts/apexCharts`, type: 'link', title: 'Apex Chart', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/charts/googleChart`, type: 'link', title: 'Google Chart', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/charts/chartJs`, type: 'link', title: 'Chartjs', show: true, },
  //   ]
  // },
  // {
  //   title: 'Maps', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/map/googleMap`, type: 'link', title: 'Google Maps ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/map/LeafletMap`, type: 'link', title: 'Leaflet Maps ', show: true, },
  //   ]
  // },
  // {
  //   title: 'Editor', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/editor/ckEditor`, type: 'link', title: 'CK  Editor', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/editor/mdeEditor`, type: 'link', title: 'MDE Editor', show: true, },
  //   ]
  // },
  // {
  //   title: 'Users', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/users/userProfile`, type: 'link', title: 'Users Profile ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/users/userEdit`, type: 'link', title: 'Users Edit', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/users/userCards`, type: 'link', title: 'Users Cards', show: true, },
  //   ]
  // },
  // {
  //   title: 'Calender', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/calendar/basic-calendar`, type: 'link', title: 'Calender', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/calendar/draggable-calendar`, type: 'link', title: 'Draggable Calender', show: true, },
  //   ]
  // },
  // {
  //   title: 'Blog', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/blog/blogDetail`, title: 'Blog Details', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/blog/blogSingle`, title: 'Blog Single', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/blog/blogPost`, title: 'Add Post', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Email App', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/Email-app/emailDefault`, active: false, bookmark: true, show: true,
  // },
  // {
  //   title: 'Chat', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/Chat-app/chat`, active: false, bookmark: true, show: true,
  // },
  // {
  //   title: 'Social App', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/social-app/socialApp`, active: false, bookmark: true, show: true,
  // },
  // {
  //   title: 'Job Search', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/jobSearch/cardView`, title: 'Cards View', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/jobSearch/job-list`, title: 'List View', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/jobSearch/job-detail`, title: 'Job Details', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/jobSearch/job-apply`, title: 'Apply', type: 'link', show: true, }
  //   ]
  // },
  // {
  //   title: 'Learning', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/learning/learning-list`, title: 'Learning List', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/learning/learning-detail`, title: 'Detail Course', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'FAQ', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/faq/faqComponent`, active: false, show: true,
  // },
  // {
  //   title: 'Knowledgebase', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebaseComponent`, active: false, show: true,
  // },
  // {
  //   title: 'Support Ticket', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/support-ticket/supportTicket`, active: false, show: true,
  // },
  // {
  //   title: 'To-Do', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/todo-app/todo`, active: false, show: true,
  // },
  // {
  //   title: 'Ecommerce', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/ecommerce/product`, title: 'Product', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/ecommerce/product-detail/1`, title: 'Product Page', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/ecommerce/product-list`, title: 'Product List', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/ecommerce/payment-details`, title: 'Payment Detail', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`, title: 'Order History ', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Pricing', icon: Settings, path: `${process.env.PUBLIC_URL}/price/pricing`, type: 'link', active: false, show: true,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/sample/samplepage`, title: 'Sample Page', icon: Settings, type: 'link', active: false, show: true,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/search/searchpage`, title: 'Search Pages', icon: Settings, type: 'link', active: false, show: true,
  // },
  // {
  //   title: 'Error Pages', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error400`, title: 'Error 400', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error401`, title: 'Error 401', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error403`, title: 'Error 403', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error404`, title: 'Error 404', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error500`, title: 'Error 500', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/errors/error503`, title: 'Error 503', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Authentication', icon: Settings, type: 'sub', active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/pages/login`, type: 'link', title: 'Login Simple', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/loginWithBgImg`, type: 'link', title: 'Login With Bg Image', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/loginWithVideo`, type: 'link', title: 'Login With Bg Video', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/signup`, type: 'link', title: 'Register Simple ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/signupWithImg`, type: 'link', title: 'Register With Bg Image ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/signupWithVideo`, type: 'link', title: 'Register With Bg Video ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/unlockUser`, type: 'link', title: 'Unlock User', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/forgetPwd`, type: 'link', title: 'Forget Password ', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/resetPwd`, type: 'link', title: 'Reset Password ', show: true, }
  //   ]
  // },
  // {
  //   title: 'Coming Soon', type: 'sub', icon: Settings, active: false, show: true, children: [
  //     { path: `${process.env.PUBLIC_URL}/pages/comingsoon`, title: 'Coming Simple', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/comingsoonImg`, title: 'Coming With Bg Image', type: 'link', show: true, },
  //     { path: `${process.env.PUBLIC_URL}/pages/comingsoonVideo`, title: 'Coming With Bg Video', type: 'link', show: true, },
  //   ]
  // },
  // {
  //   title: 'Maintenance', icon: Settings, path: `${process.env.PUBLIC_URL}/pages/maintenance`, type: 'link', active: false, show: true,
  // },
];
