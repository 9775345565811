import { City, Country } from "country-state-city";

import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import AutoComplete from "../../../AutoComplete/AutoComplete";
import CurrencySelector from "../../../CurrencySelector/CurrencySelector";

const GarageStep = (props) => {
  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <>
      <div className="form-row mb-2 ">
        <Row className="w-100">
          <Col xs="12">
            <div className={"imageUpDropdownContainer"}>
              <label title="upload image" htmlFor="image">
                <div className={"imageDropper"}>
                  {props.placeholderImage ? (
                    <img
                      className={"imageUp"}
                      alt={"profile_pic"}
                      src={props.placeholderImage}
                    />
                  ) : !props.imageUrl ? (
                    <img
                      className={"imageUpPlaceholder"}
                      alt={"profile_pic"}
                      src={"https://i.postimg.cc/rFzvBdw7/gallery.png"}
                    />
                  ) : (
                    <img
                      className={"imageUp"}
                      alt={"profile_pic"}
                      src={`https://mughalsignandprint.co.uk/restaurant2/${props.imageUrl}`}
                    />
                  )}
                  {!props.placeholderImage && (
                    <>
                      <span>Click to upload a logo</span>
                      <span
                        style={{
                          fontSize: "0.6rem",
                          color: "#aaa",
                          textAlign: "center",
                        }}
                      >
                        Only <br /> JPG, JPEG & PNG <br /> images are supported
                      </span>
                    </>
                  )}
                </div>
              </label>
              <input
                type={"file"}
                accept={"image/x-png,image/jpg,image/jpeg"}
                style={{ display: "none" }}
                placeholder="image"
                id="image"
                onChange={(e) => {
                  props.onFileChange(e);
                  props.setPlaceholderImage(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        <Input className="form-control" name="id" type="hidden" />

        {/* GARAGE NAME FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="name">
            Garage Name*
          </Label>
          <Input
            className="form-control border-secondary"
            name="name"
            type="text"
            data-testid="registration_garage_name"
            onChange={props.handleChange}
            value={props.data.name}
          />

          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.name"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.name"][0]}
              </div>
            ))}
        </Col>

        {/* EMAIL FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="validationCustomUsername">
            Email
          </Label>
          <InputGroup>
            <InputGroupText>{"@"}</InputGroupText>
            <Input
              className="form-control border-secondary"
              name="email"
              type="text"
              data-testid="registration_garage_email"
              onChange={props.handleChange}
              value={props.data.email}
              // placeholder="email"
            />
            {props.serverSideErrors &&
              (!props.serverSideErrors["garage.email"] ? (
                <div className="valid-feedback" style={{ display: "block" }}>
                  {"Looks good!"}
                </div>
              ) : (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {props.serverSideErrors["garage.email"][0]}
                </div>
              ))}
          </InputGroup>
        </Col>

        {/* PHONE FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="phone">
            Phone Number
          </Label>
          <Input
            className="form-control border-secondary"
            name="phone"
            type="text"
            data-testid="registration_garage_phone"
            onChange={props.handleChange}
            value={props.data.phone}
          />

          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.phone"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.phone"][0]}
              </div>
            ))}
        </Col>

        {/* ADDRESS LINE 1 FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="phone">
            Address Line 1*
          </Label>

          <AutoComplete
            defaultValue={props.data?.address_line_1}
            setPlaceAutoComplete={props.setPlaceAutoComplete}
            class_Name={"form-control border-secondary"}
            name="address_line_1"
            id={"address"}
            // placeholder="address line 1"
          />
          {props?.distanceError !== "" && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {props?.distanceError}
            </div>
          )}
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.address_line_1"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.address_line_1"][0]}
              </div>
            ))}
        </Col>
      </div>

      <div className="form-row mb-2 w-100">
        {/* ADDRESS LINE 2 FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="phone">
            Address Line 2
          </Label>
          <AutoComplete
            defaultValue={props.data?.address_line_2}
            setPlaceAutoComplete={props.setPlaceAutoComplete2}
            class_Name={"form-control border-secondary"}
            name="address_line_1"
            id={"address"}
            // placeholder="address line 2"
          />
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.address_line_2"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.address_line_2"][0]}
              </div>
            ))}
        </Col>

        {/* POST CODE FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="postcode">
            Postcode*
          </Label>
          <Input
            className="form-control border-secondary"
            data-testid="registration_garage_postcode"
            name="postcode"
            type="text"
            onChange={props.handleChange}
            value={props.data.postcode}
          />

          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.postcode"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.postcode"][0]}
              </div>
            ))}
        </Col>

        {/* COUNTRY FIELD  */}
        <Col md="6 mb-3" xs="12">
          <FormGroup>
            <Label className="" htmlFor="country">
              Country*
            </Label>
            <Input
              type="text"
              className="custom-select border-secondary"
              data-testid="registration_garage_country"
              name="country"
              value={props.data.country ? props.data.country : ""}
              onChange={props.handleChange}
            />
            {props.serverSideErrors &&
              (!props.serverSideErrors["garage.country"] ? (
                <div className="valid-feedback" style={{ display: "block" }}>
                  {"Looks good!"}
                </div>
              ) : (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {props.serverSideErrors["garage.country"][0]}
                </div>
              ))}
          </FormGroup>
        </Col>

        {/* CURRENCY  */}
        <Col md="6 mb-3" xs="12">
          <FormGroup>
            <Label className="" htmlFor="country">
              Currency*
            </Label>
            {console.log({ curr: props.data.currency })}
            <CurrencySelector
              className="custom-select border-secondary"
              data_testid="registration_garage_currency"
              name="currency"
              value={props.data.currency ? props.data.currency : ""}
              onChange={props.handleChange}
              placeholder=""
            />
            {props.serverSideErrors &&
              (!props.serverSideErrors["garage.currency"] ? (
                <div className="valid-feedback" style={{ display: "block" }}>
                  {"Looks good!"}
                </div>
              ) : (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {props.serverSideErrors["garage.currency"][0]}
                </div>
              ))}
          </FormGroup>
        </Col>

        {/* CITY FIELD  */}
        <Col md="6 mb-3" xs="12">
          <FormGroup>
            <Label className="" htmlFor="city">
              City*
            </Label>
            <Input
              type="text"
              data-testid="registration_garage_city"
              className="custom-select border-secondary"
              name="city"
              value={props.data.city}
              onChange={props.handleChange}
            />
            {props.serverSideErrors &&
              (!props.serverSideErrors["garage.city"] ? (
                <div className="valid-feedback" style={{ display: "block" }}>
                  {"Looks good!"}
                </div>
              ) : (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {props.serverSideErrors["garage.city"][0]}
                </div>
              ))}
          </FormGroup>
        </Col>
      </div>
    </>
  );
};

export default GarageStep;
