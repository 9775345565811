import React, { useState } from "react";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import IconSelect from "../components/IconSelect";

const FuelStationServiceForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  fuelStationUpdateData,
  setIsOpen,
}) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(
    type === "update"
      ? {
          id: fuelStationUpdateData?.id,
          name: fuelStationUpdateData?.name ? fuelStationUpdateData?.name : "",
          icon: fuelStationUpdateData?.icon ? fuelStationUpdateData?.icon : "",
        }
      : {
          name: "",
          icon: "",
        }
  );

  const onSubmit = () => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      apiClient()
        .put(`${BACKEND_API}/v1.0/fuel-station-services`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Fuel Station Service Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "Something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }

    if (type === "create") {
      apiClient()
        .post(`${BACKEND_API}/v1.0/fuel-station-services`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Fuel Station Service Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "Something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const [selectedIcon, setSelectedIcon] = useState("");

  const handleChangeIcon = (e) => {
    const { name, value } = e.target;
    setSelectedIcon(value);
    setData({ ...data, [name]: value });
  };

  return (
    <Row>
      <Col sm="12">
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <Container>
              <Container className="form-row mb-2">
                {type === "update" && (
                  <input
                    className="form-control border-secondary"
                    data-testid="fuel_station_service_id"
                    name="id"
                    type="hidden"
                    defaultValue={fuelStationUpdateData?.id}
                  />
                )}
                {/* FUEL STATION SERVICE NAME  */}
                <Col sm="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Service Name*
                    </Label>
                  )}
                  <input
                    id="name"
                    className="form-control border-secondary"
                    data-testid="fuel_station_service_name"
                    name="name"
                    type="text"
                    placeholder="Service Name*"
                    onChange={handleChange}
                    value={
                      fuelStationUpdateData?.name
                        ? fuelStationUpdateData?.name
                        : data?.name
                    }
                  />

                  {serverSideErrors &&
                    (!serverSideErrors?.name ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.name[0]}
                      </div>
                    ))}
                </Col>
                <Col sm="12 mb-3">
                  <Label className="text-uppercase fw-bolder">
                    Service Icon*
                  </Label>
                  <div
                    style={{
                      height: "200px",
                      overflowY: "auto",
                    }}
                  >
                    <IconSelect
                      name={"icon"}
                      defaultValue={data?.icon}
                      dataTestid="fuel_station_icon"
                      onChange={handleChangeIcon}
                      selectedIcon={selectedIcon}
                    />
                  </div>
                  {serverSideErrors &&
                    (!serverSideErrors?.icon ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.icon[0]}
                      </div>
                    ))}
                </Col>
              </Container>

              <Row className="mb-3">
                <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                  <Button
                    color="primary"
                    className="w-100 py-2 rounded-3"
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    className="py-2 w-100 cancel-button rounded-3 text-secondary"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    color=""
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default FuelStationServiceForm;
