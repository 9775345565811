import { useState } from "react";
import Select, { components } from "react-select";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" className="me-2" checked={isSelected} />
      {children}
    </components.Option>
  );
};

export default function PackageServiceDropdown({
  serviceList,
  searchData,
  setSearchData,
}) {
  const dropDownData = serviceList.map((res) => {
    const data = {
      value: res?.id,
      label: res?.name,
    };
    return data;
  });
  console.log(
    dropDownData.filter((res) => searchData.serviceIds.includes(res.value))
  );
  return (
    <div>
      <Select
        value={
          searchData.serviceIds.length > 0
            ? dropDownData.filter((res) =>
                searchData.serviceIds.includes(res.value)
              )
            : []
        }
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(options) => {
          if (Array.isArray(options)) {
            setSearchData({
              ...searchData,
              serviceIds: options.map((opt) => opt.value),
            });
          }
        }}
        options={dropDownData}
        components={{
          Option: InputOption,
        }}
        placeholder="Select Services*"
        className="w-100"
        maxMenuHeight={150}
      />
      {/* <pre>{JSON.stringify({ selected: selectedOptions }, null, 2)}</pre> */}
    </div>
  );
}
