import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import Switch from "react-switch";

const CreateCoupon = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  couponUpdateData,
  setIsOpen,
  garageId,
  currency,
}) => {
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    setData({ ...data, coupon_start_date: moment(date).format("YYYY-MM-DD") });
  };

  const setEndDate = (date) => {
    setendDate(date);
    setData({ ...data, coupon_end_date: moment(date).format("YYYY-MM-DD") });
  };
  const [data, setData] = useState(
    type === "update"
      ? {
          id: couponUpdateData?.id,
          garage_id: garageId,
          name: couponUpdateData?.name ? couponUpdateData?.name : "",
          code: couponUpdateData?.code ? couponUpdateData?.code : "",
          discount_type: couponUpdateData?.discount_type
            ? couponUpdateData?.discount_type
            : "percentage",
          discount_amount: couponUpdateData?.discount_amount
            ? couponUpdateData?.discount_amount
            : "",
          min_total: couponUpdateData?.min_total
            ? couponUpdateData?.min_total
            : "",
          max_total: couponUpdateData?.max_total
            ? couponUpdateData?.max_total
            : "",
          redemptions: couponUpdateData?.redemptions
            ? couponUpdateData?.redemptions
            : "",
          coupon_start_date: couponUpdateData?.coupon_start_date
            ? couponUpdateData?.coupon_start_date
            : "",
          coupon_end_date: couponUpdateData?.coupon_end_date
            ? couponUpdateData?.coupon_end_date
            : "",
          is_auto_apply: couponUpdateData?.is_auto_apply
            ? couponUpdateData?.is_auto_apply
            : false,
          is_active: couponUpdateData?.is_active
            ? couponUpdateData?.is_active
            : false,
        }
      : {
          garage_id: garageId,
          name: "",
          code: "",
          discount_type: "percentage",
          discount_amount: "",
          min_total: "",
          max_total: "",
          redemptions: "",
          coupon_start_date: "",
          coupon_end_date: "",
          is_auto_apply: false,
          is_active: false,
        }
  );

  //   VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    // Validate garage_id
    if (!data?.garage_id) {
      newErrors.garage_id = "Invalid Garage is required.";
    }

    // Validate COUPON NAME
    if (!data?.name || data.name.trim() === "") {
      newErrors.name = "Coupon name is required.";
    }
    // Validate COUPON CODE
    if (!data?.code || data.code.trim() === "") {
      newErrors.code = "Coupon code is required.";
    }

    // Validate discount_amount
    if (
      !data?.discount_amount ||
      parseInt(data?.discount_amount) === 0 ||
      parseInt(data?.discount_amount) < 0
    ) {
      newErrors.discount_amount = "Discount amount is required.";
    }

    // Validate coupon_start_date
    if (!startDate || startDate === null) {
      newErrors.coupon_start_date = "Start date is required.";
    }
    // Validate coupon_end_date
    if (!endDate || endDate === null) {
      newErrors.coupon_end_date = "End date is required.";
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      setServerSideErrors(null);
      if (type === "update") {
        const alldata = { ...data, garage_id: garageId };
        apiClient()
          .put(`${BACKEND_API}/v1.0/coupons`, alldata)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              SweetAlert.fire({
                title: "Success",
                text: "Coupon Updated Successfully!",
                icon: "success",
              });
              fetchData(perPage);
              toggleModal();
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response?.status === 422) {
              setServerSideErrors(error.response.data.errors);
              const errors = error?.response?.data?.errors;
              Object.keys(errors).forEach((field) => {
                errors[field].forEach((errorMessage, index) =>
                  SweetAlert.fire({
                    title: errorMessage,
                    text: "Please Try Again",
                    icon: "warning",
                  })
                );
              });
            } else if (error.response?.status === 401) {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Hello!!! You do not have permission.",
                icon: "warning",
              });
            } else {
              SweetAlert.fire({
                title: "something went wrong!!",
                text: "Please Try Again",
                icon: "warning",
              });
            }
          });
      }

      if (type === "create") {
        const alldata = {
          ...data,
          garage_id: garageId,
          coupon_start_date: moment(startDate).format("YYYY-MM-DD"),
          coupon_end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        apiClient()
          .post(`${BACKEND_API}/v1.0/coupons`, alldata)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              SweetAlert.fire({
                title: "Success",
                text: "Coupon Created Successfully!",
                icon: "success",
              });
              fetchData(perPage);
              toggleModal();
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response?.status === 422) {
              setServerSideErrors(error.response.data.errors);
              const errors = error?.response?.data?.errors;
              Object.keys(errors).forEach((field) => {
                errors[field].forEach((errorMessage, index) =>
                  SweetAlert.fire({
                    title: errorMessage,
                    text: "Please Try Again",
                    icon: "warning",
                  })
                );
              });
            } else if (error.response?.status === 401) {
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Hello!!! You do not have permission.",
                icon: "warning",
              });
            } else {
              SweetAlert.fire({
                title: "something went wrong!!",
                text: "Please Try Again",
                icon: "warning",
              });
            }
          });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const disablePastDates = (date) => {
    return date > new Date(); // Disable dates before today
  };

  return (
    <Row>
      <Col sm="12">
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <Container>
              <Container className="form-row mb-2">
                {/* FUEL STATION NAME  */}
                <Col sm="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Coupon Name*
                    </Label>
                  )}
                  <input
                    placeholder="Coupon Name*"
                    id="name"
                    className="form-control border-secondary"
                    data-testid="coupon_name"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    defaultValue={
                      couponUpdateData?.name ? couponUpdateData?.name : ""
                    }
                    required
                  />
                  {errors?.name && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.name}
                    </div>
                  )}
                </Col>
                <Col sm="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Coupon Code*
                    </Label>
                  )}
                  <input
                    placeholder="Coupon Code*"
                    id="code"
                    className="form-control border-secondary"
                    data-testid="coupon_code"
                    name="code"
                    type="text"
                    onChange={handleChange}
                    defaultValue={
                      couponUpdateData?.code ? couponUpdateData?.code : ""
                    }
                    required
                  />
                  {errors?.code && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.code}
                    </div>
                  )}
                </Col>
                <Col sm="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Discount Type*
                    </Label>
                  )}
                  <Input
                    type="select"
                    name="discount_type"
                    id="discount_type"
                    data-testid="discount_type"
                    className="form-control border-secondary"
                    required
                    onChange={handleChange}
                    defaultValue={
                      couponUpdateData?.discount_type
                        ? couponUpdateData?.discount_type
                        : "percentage"
                    }
                  >
                    <option disabled>Discount Type*</option>
                    <option value={"percentage"}>Percentage</option>
                    <option value={"fixed"}>Fixed</option>
                  </Input>
                  {errors?.discount_type && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors?.nadiscount_typeme}
                    </div>
                  )}
                </Col>
                <Col sm="12">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Discount Amount*
                    </Label>
                  )}
                  <>
                    <input
                      placeholder="Discount Amount*"
                      id="discount_amount"
                      style={{
                        paddingLeft: "45px",
                      }}
                      className="form-control border-secondary"
                      data-testid="discount_amount"
                      name="discount_amount"
                      type="number"
                      onChange={handleChange}
                      defaultValue={
                        couponUpdateData?.discount_amount
                          ? couponUpdateData?.discount_amount
                          : ""
                      }
                      required
                    />
                    <span className="currency_input fw-bold">{currency}</span>
                  </>
                  {errors?.discount_amount && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "-15px",
                      }}
                    >
                      {errors?.discount_amount}
                    </div>
                  )}
                </Col>
                <Col sm="12">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      {" "}
                      Minimum Repair Amount
                    </Label>
                  )}
                  <>
                    <input
                      placeholder="Minimum Repair Amount"
                      id="min_total"
                      style={{
                        paddingLeft: "45px",
                      }}
                      className="form-control border-secondary"
                      data-testid="min_total"
                      name="min_total"
                      type="number"
                      onChange={handleChange}
                      defaultValue={
                        couponUpdateData?.min_total
                          ? couponUpdateData?.min_total
                          : ""
                      }
                      required
                    />
                    <span className="currency_input fw-bold">{currency}</span>
                  </>
                  {errors?.min_total && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "-15px",
                      }}
                    >
                      {errors?.min_total}
                    </div>
                  )}
                </Col>
                <Col sm="12">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      {" "}
                      Maximum Repair Amount
                    </Label>
                  )}
                  <>
                    <input
                      placeholder="Maximum Repair Amount"
                      id="max_total"
                      style={{
                        paddingLeft: "45px",
                      }}
                      className="form-control  border-secondary"
                      data-testid="max_total"
                      name="max_total"
                      type="number"
                      onChange={handleChange}
                      defaultValue={
                        couponUpdateData?.max_total
                          ? couponUpdateData?.max_total
                          : ""
                      }
                      required
                    />
                    <span className="currency_input fw-bold">{currency}</span>
                  </>
                  {errors?.max_total && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "-15px",
                      }}
                    >
                      {errors?.max_total}
                    </div>
                  )}
                </Col>
                <Col sm="12">
                  {type === "update" && (
                    <Label className="text-uppercase fw-bolder" htmlFor="name">
                      Total Number of Coupons Allowed
                    </Label>
                  )}
                  <>
                    <input
                      placeholder="Total Number of Coupons Allowed"
                      id="redemptions"
                      className="form-control border-secondary"
                      data-testid="redemptions"
                      name="redemptions"
                      type="number"
                      onChange={handleChange}
                      defaultValue={
                        couponUpdateData?.redemptions
                          ? couponUpdateData?.redemptions
                          : ""
                      }
                      required
                    />
                  </>
                  {errors?.redemptions && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.redemptions}
                    </div>
                  )}
                </Col>
                <Col sm="12 mb-3">
                  <Row style={{ marginTop: "10px" }}>
                    <Col md="6">
                      <Label
                        className="text-uppercase fw-bolder"
                        htmlFor="name"
                      >
                        Coupon Start Date*
                      </Label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits border-secondary "
                        selected={startDate}
                        onChange={setStartDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        filterDate={disablePastDates}
                        minDate={new Date()}
                      />
                      {errors?.coupon_start_date && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors?.coupon_start_date}
                        </div>
                      )}
                    </Col>

                    <Col md="6">
                      <Label
                        className="text-uppercase fw-bolder"
                        htmlFor="name"
                      >
                        Coupon End Date*
                      </Label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        filterDate={disablePastDates}
                        className="form-control digits ml-md-2 border-secondary"
                        selected={endDate}
                        onChange={setEndDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      {errors?.coupon_end_date && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors?.coupon_end_date}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col sm="12 mb-3">
                  <Row>
                    <Col md="6">
                      <Switch
                        checked={data.is_auto_apply}
                        onChange={() => {
                          setData({
                            ...data,
                            is_auto_apply: !data.is_auto_apply,
                          });
                        }}
                        className="react-switch"
                      />
                      <Label>Auto Apply</Label>
                    </Col>

                    <Col md="6">
                      <div>
                        <Switch
                          checked={data.is_active}
                          onChange={() => {
                            setData({ ...data, is_active: !data.is_active });
                          }}
                          className="react-switch"
                        />
                        <span>Active</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Container>
            </Container>
          )}

          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button
                color="primary"
                className="w-100 py-2 rounded-3"
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                className="py-2 w-100 cancel-button rounded-3 text-secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
                color=""
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateCoupon;
