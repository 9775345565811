import { Country } from 'country-state-city';
import moment from 'moment';
import React, { useState } from 'react';
import { AiOutlineFieldTime, AiOutlineInfoCircle } from 'react-icons/ai';
import { Badge, Button, Col, Container, Label, Row } from 'reactstrap';


const JobBidView = ({ viewData, setIsOpen }) => {
    const [stepNo, setStepNo] = useState(1);
    let currency = localStorage.getItem("currency");

    return (
        <Row className=''>
            <Col sm="12">
                {/* TAB BUTTONS CONTAINER  */}
                <Row>
                    <Container className='d-flex justify-content-start align-items-center gap-2 w-100 p-0'>
                        <div
                            onClick={() => setStepNo(1)}
                            role='button'
                            className={`py-2 px-3 border-bottom  ${stepNo === 1 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
                            <AiOutlineInfoCircle />Job Bids Details
                        </div>
                        <div
                            onClick={() => setStepNo(2)}
                            role='button'
                            className={`py-2 px-3 border-bottom ${stepNo === 2 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
                            <AiOutlineFieldTime /> Customer Details
                        </div>
                    </Container>
                </Row>

                {/* DETAILS TAB  */}
                {stepNo === 1 &&
                    <Container className='tab-1 mt-3'>
                        <Row className="form-row mb-2">
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="garage_Name">Services</Label>
                                <ul>
                                    {viewData?.pre_booking?.pre_booking_sub_services?.map((res, index) => (
                                        <li>{index + 1}. {res?.sub_service?.name}</li>
                                    ))}
                                </ul>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="garage_Name">Price</Label>
                                <p>{currency}{viewData?.price}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="owner_Name">Make</Label>
                                <p>{viewData?.pre_booking?.automobile_make?.name}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="email">Model</Label>
                                <p>{viewData?.pre_booking?.automobile_model?.name}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Job start Date</Label>
                                <p>{viewData?.job_start_date}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Job start Time</Label>
                                <p>{moment(viewData?.job_start_time, "HH:mm:ss").format('h:mm A')}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Job End Time</Label>
                                <p>{moment(viewData?.job_end_time, "HH:mm:ss").format('h:mm A')}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Status</Label>
                                <p>{viewData?.status}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Car Registration No</Label>
                                <p>{viewData?.pre_booking?.car_registration_no}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Additional Information</Label>
                                <p>{viewData?.pre_booking?.additional_information}</p>
                            </Col>
                        </Row>
                    </Container>
                }

                {/* SCHEDULE TAB  */}
                {stepNo === 2 &&
                    <Container className='tab-1 mt-3'>
                        <Row className="form-row mb-2">
                            {viewData?.pre_booking?.logo && <Row className='mb-5'>
                                <Col className='d-flex justify-content-center'>
                                    <img className='garageDetailsLogo' src={viewData?.pre_booking?.customer?.image} alt={viewData?.pre_booking?.customer?.first_Name} />
                                </Col>
                            </Row>}
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="garage_Name">Customer Name</Label>
                                <p>{viewData?.pre_booking?.customer?.first_Name} {viewData?.pre_booking?.customer?.last_Name}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="email">Email</Label>
                                <p>{viewData?.pre_booking?.customer?.email}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Phone</Label>
                                <p>{viewData?.pre_booking?.customer?.phone}</p>
                            </Col>
                        </Row>

                        <div className='form-row mb-2'>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="country">Country</Label>
                                <p>{viewData?.pre_booking?.customer?.country}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="city">City</Label>
                                <p>{viewData?.pre_booking?.customer?.city}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="postcode">Postcode</Label>
                                <p>{viewData?.pre_booking?.customer?.postcode}</p>
                            </Col>
                            <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Address Line 1</Label>
                                <p>{viewData?.pre_booking?.customer?.address_line_1}</p>
                            </Col>
                            {viewData?.pre_booking?.address_line_2 && <Col md="6 mb-3">
                                <Label className={"text-uppercase fw-bold"} htmlFor="phone">Address Line 2</Label>
                                <p>{viewData?.pre_booking?.customer?.address_line_2}</p>
                            </Col>}
                        </div>
                    </Container>
                }

                <Row className='mb-3'>
                    <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                        <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Cancel</Button>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}

export default JobBidView