import React from "react";
import { Container } from "reactstrap";
import DataCollectorDashboard from "./DataCollectorDashboard";
import GarageOwnerDashboard from "./GarageOwnerDashboard";
import SuperAdminDashboard from "./SuperAdminDashboard";

export default function Dashboard() {
  const user = JSON.parse(localStorage.getItem("user"));
  const sassTopCard = [
    {
      id: 1,
      title: "New Jobs in Your Area",
      total: "80,00,566",
      monthly: "80,000",
      weekly: "566",
      class: "primary",
    },
    {
      id: 2,
      title: "Quotes to Job ( This Month )",
      total: "60,02,52",
      monthly: "60,000",
      weekly: "252",
      class: "secondary",
    },
    {
      id: 3,
      title: "Total Job Won",
      total: "89,000",
      monthly: "88,640",
      weekly: "360",
      class: "info",
    },
    {
      id: 4,
      title: "Total completed Bookings",
      total: "89,000",
      monthly: "88,640",
      weekly: "360",
      class: "success",
    },
    {
      id: 5,
      title: "Upcoming Booking ( This Month )",
      total: "89,000",
      monthly: "88,640",
      weekly: "360",
      class: "warning",
    },
    {
      id: 6,
      title: "Affiliation Expiry ( This Month )",
      total: "89,000",
      monthly: "88,640",
      weekly: "360",
      class: "warning",
    },
  ];
  const sassSmallChartData = {
    series: [
      {
        data: [200, 400, 300, 100, 250],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
        padding: {
          top: -15,
          bottom: -10,
          left: -10,
          right: 15,
        },
      },
      colors: ["#ffffff"],
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    },
  };
  return (
    <Container>
      {user?.roles[0]?.name === "superadmin" && <SuperAdminDashboard />}
      {user?.roles[0]?.name === "garage_owner" && <GarageOwnerDashboard />}
      {user?.roles[0]?.name === "data_collector" && <DataCollectorDashboard />}
    </Container>
  );
}
