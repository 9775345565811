import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../../utils/apiClient";
import { BACKEND_API } from "../../../../utils/backend";
import CustomLoadingSpiner from "../../../CustomLoadingSpiner/CustomLoadingSpiner";

const LabelList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const garage_id = JSON.parse(localStorage.getItem("garageId"));
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const handleTageEditChange = (e) => {
    setEditValue(e.target.value);
  };
  const editForm = (id) => {
    setEditValue("");
    setEditId(id);
  };

  const fetchData = () => {
    setLoading(true);
    apiClient()
      .get(`${BACKEND_API}/review-new/get/tags?garage_id=${garage_id}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        SweetAlert.fire({
          title: error.response.data.message,
          text: "Please Try Again",
          icon: "warning",
        });
      });
  };

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/review-new/delete/tags/${id}`)
          .then((response) => {
            console.log(response.status);
            console.log(response.data.ok);
            console.log(response);
            if (response.status === 200 && response.data.message === "ok") {
              fetchData();
              SweetAlert.fire("Deleted!", "Label has been deleted.", "success");
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Label is safe");
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [createLabel, setCreateLabel] = useState(false);

  const filteredData = data?.filter((fil) => fil.is_default === 1);

  const rows = [];
  for (let i = 0; i < filteredData.length; i += 2) {
    const chunk = filteredData.slice(i, i + 2);

    const row = (
      <tr className="Dashed" key={i}>
        {chunk.map((el, j) => (
          <React.Fragment key={j}>
            <th scope="row">{i + j + 1}</th>
            <td>{el.tag}</td>
          </React.Fragment>
        ))}
      </tr>
    );

    rows.push(row);
  }

  // Using the State hook to declare our tags variable and setTags to update the variable.
  const [tags, setTags] = React.useState([]);
  let tagInput = useRef(null);
  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);

    // Call the defined function setTags which will replace tags with the new value.
    setTags(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setTags([...tags, val]);
      tagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const alldata = { tags: tags };
    if (garage_id) {
      apiClient()
        .post(
          `${BACKEND_API}/review-new/create/tags/multiple/${garage_id}`,
          alldata
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Label Created Successfully!",
              icon: "success",
            });
            setTags([]);
            setCreateLabel(false);
            fetchData();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    } else {
      apiClient()
        .post(
          `${BACKEND_API}/review-new/create/tags/multiple/by/admin`,
          alldata
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Label Created Successfully!",
              icon: "success",
            });
            setTags([]);
            setCreateLabel(false);
            fetchData();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  const onEditSave = () => {
    setLoading(true);
    const alldata = { id: editId, tag: editValue };
    apiClient()
      .put(`${BACKEND_API}/review-new/update/tags`, alldata)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          SweetAlert.fire({
            title: "Success",
            text: "Label Updated Successfully!",
            icon: "success",
          });
          setEditId("");
          setEditValue("");
          fetchData();
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 422) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        } else if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  const adminRows = [];
  for (let i = 0; i < filteredData.length; i += 2) {
    const chunk = filteredData.slice(i, i + 2);

    const row = (
      <tr className="Dashed" key={i}>
        {chunk.map((el, j) => (
          <React.Fragment key={j}>
            <th scope="row">{i + j + 1}</th>
            <td>
              {" "}
              {editId === el.id ? (
                <input
                  className={`rounded p-2 border ${
                    editValue === "" ? "border-danger" : "border-success"
                  } `}
                  onChange={handleTageEditChange}
                  defaultValue={el.tag}
                  type="text"
                  name=""
                  id=""
                />
              ) : (
                <span>{el.tag}</span>
              )}
            </td>
            <td>
              <div className="text-start d-flex gap-1 justify-content-start">
                {editId === el.id ? (
                  <Button
                    disabled={editValue === ""}
                    title="Save Label"
                    onClick={onEditSave}
                    color="success"
                    size="sm"
                  >
                    <i className="fa fa-save"></i>
                  </Button>
                ) : (
                  <Button
                    title="Edit Label"
                    onClick={() => editForm(el.id)}
                    color="primary"
                    size="sm"
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                )}

                <Button
                  title="Delete Label"
                  onClick={() => deleteFunc(el.id)}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </div>
            </td>
          </React.Fragment>
        ))}
      </tr>
    );

    adminRows.push(row);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Fragment>
      <Container fluid={true}>
        {garage_id ? (
          <Row>
            <Col sm="12" md="6">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5>Label Management</h5>
                    <span>Manage Labels</span>
                  </div>
                  <Button color="primary" onClick={() => setCreateLabel(true)}>
                    Create Label
                  </Button>
                </CardHeader>
                {createLabel && (
                  <div className="mx-5 my-5">
                    <p>Please use "Enter" button to add a label</p>
                    <div className="input-tag">
                      <ul className="input-tag__tags">
                        {tags.map((tag, i) => (
                          <li key={tag}>
                            {tag}
                            <button
                              type="button"
                              onClick={() => {
                                removeTag(i);
                              }}
                            >
                              +
                            </button>
                          </li>
                        ))}

                        <li className="input-tag__tags__input">
                          <input
                            type="text"
                            onKeyDown={inputKeyDown}
                            ref={(c) => {
                              tagInput = c;
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Button
                        disabled={tags.length === 0}
                        className="mt-3"
                        color="primary"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        className="mt-3 ms-2"
                        color="danger"
                        onClick={() => setCreateLabel(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                <div className="table-responsive px-4">
                  <Table>
                    <thead>
                      <tr className="Dashed">
                        <th scope="col">{"#"}</th>
                        <th></th>
                        <th scope="col">{"Label"}</th>
                        <th></th>
                        <th scope="col">{"Options"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={7}>
                            <CustomLoadingSpiner />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <td colSpan={7}>
                              <p>No Data Available</p>
                            </td>
                          ) : (
                            data
                              ?.filter((fil) => fil.is_default !== 1)
                              .map((el, i) => {
                                return (
                                  <tr className="Dashed" key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td></td>
                                    <td>
                                      {editId === el.id ? (
                                        <input
                                          className={`rounded p-2 border ${
                                            editValue === ""
                                              ? "border-danger"
                                              : "border-success"
                                          } `}
                                          onChange={handleTageEditChange}
                                          defaultValue={el.tag}
                                          type="text"
                                          name=""
                                          id=""
                                        />
                                      ) : (
                                        <span> {el.tag}</span>
                                      )}
                                    </td>
                                    <td></td>
                                    <td>
                                      <div className="text-start d-flex gap-1 justify-content-start">
                                        {editId === el.id ? (
                                          <Button
                                            disabled={editValue === ""}
                                            title="Save Label"
                                            onClick={onEditSave}
                                            color="success"
                                            size="sm"
                                          >
                                            <i className="fa fa-save"></i>
                                          </Button>
                                        ) : (
                                          <Button
                                            title="Edit Label"
                                            onClick={() => editForm(el.id)}
                                            color="primary"
                                            size="sm"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </Button>
                                        )}

                                        <Button
                                          title="Delete Label"
                                          onClick={() => deleteFunc(el.id)}
                                          color="danger"
                                          size="sm"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
            <Col sm="12" md="6">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5>Default Label</h5>
                  </div>
                </CardHeader>
                <div className="table-responsive px-4">
                  <Table>
                    <thead>
                      <tr className="Dashed">
                        <th scope="col">{"#"}</th>
                        <th scope="col">{"Label"}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={7}>
                            <CustomLoadingSpiner />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <td colSpan={7}>
                              <p>No Data Available</p>
                            </td>
                          ) : (
                            rows
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm="12" md="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5>Label Management</h5>
                    <span> Manage Labels</span>
                  </div>

                  <Button color="primary" onClick={() => setCreateLabel(true)}>
                    Create Label
                  </Button>
                </CardHeader>
                {createLabel && (
                  <div className="mx-5 my-5">
                    <p>Please use "Enter" button to add a label</p>

                    <div className="input-tag">
                      <ul className="input-tag__tags">
                        {tags.map((tag, i) => (
                          <li key={tag}>
                            {tag}
                            <button
                              type="button"
                              onClick={() => {
                                removeTag(i);
                              }}
                            >
                              +
                            </button>
                          </li>
                        ))}
                        <li className="input-tag__tags__input">
                          <input
                            type="text"
                            onKeyDown={inputKeyDown}
                            ref={(c) => {
                              tagInput = c;
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Button
                        disabled={tags.length === 0}
                        className="mt-3"
                        color="primary"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        className="mt-3 ms-2"
                        color="danger"
                        onClick={() => setCreateLabel(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                <div className="table-responsive px-4">
                  <Table>
                    <thead>
                      <tr className="Dashed">
                        <th scope="col">{"#"}</th>
                        <th scope="col">{"Label"}</th>
                        <th scope="col">{"Options"}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={7}>
                            <CustomLoadingSpiner />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <td colSpan={7}>
                              <p>No Data Available</p>
                            </td>
                          ) : (
                            adminRows
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default LabelList;
