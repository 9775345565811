import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Callback from "../auth/callback";
import Logins from "../pages/authentication/Login";
import SignUp from "../pages/authentication/SignUp";

import Resetpwd from "../pages/authentication/Resetpwd";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";
import LoginWithJwt from "../pages/authentication/LoginWithJwt";


const Router = () => {
  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Suspense>
          <Routes>
            <Route path={"/"} element={<PrivateRoute />}>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}`}
                element={
                  <Navigate
                    to={`${process.env.PUBLIC_URL}/dashboard`}
                  />
                }
              />
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/callback`}
              render={() => <Callback />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Logins />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/reset-password`}
              element={<Resetpwd />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              element={<SignUp />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login/client-site`}
              element={<LoginWithJwt />}
            />
            {authRoutes.map(({ path, element }, i) => (
              <Route path={path} element={element} key={i} />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default Router;
