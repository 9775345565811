// Function to get the currency code for a given country code.
export const getCurrencyForCountry = (countryCode) => {
  const countryCurrencyMap = {
    US: "USD", // United States Dollar
    CA: "CAD", // Canadian Dollar
    MX: "MXN", // Mexican Peso
    GB: "GBP", // British Pound Sterling
    DE: "EUR", // Euro
    FR: "EUR", // Euro
    ES: "EUR", // Euro
    IT: "EUR", // Euro
    JP: "JPY", // Japanese Yen
    CN: "CNY", // Chinese Yuan
    IN: "INR", // Indian Rupee
    AU: "AUD", // Australian Dollar
    NZ: "NZD", // New Zealand Dollar
    BR: "BRL", // Brazilian Real
    AR: "ARS", // Argentine Peso
    CL: "CLP", // Chilean Peso
    ZA: "ZAR", // South African Rand
    RU: "RUB", // Russian Ruble
    KR: "KRW", // South Korean Won
    HK: "HKD", // Hong Kong Dollar
    SG: "SGD", // Singapore Dollar
    TW: "TWD", // New Taiwan Dollar
    TH: "THB", // Thai Baht
    MY: "MYR", // Malaysian Ringgit
    ID: "IDR", // Indonesian Rupiah
    PH: "PHP", // Philippine Peso
    VN: "VND", // Vietnamese Đồng
    AE: "AED", // United Arab Emirates Dirham
    SA: "SAR", // Saudi Riyal
    QA: "QAR", // Qatari Riyal
    KW: "KWD", // Kuwaiti Dinar
    OM: "OMR", // Omani Rial
    LB: "LBP", // Lebanese Pound
    EG: "EGP", // Egyptian Pound
    JO: "JOD", // Jordanian Dinar
    TR: "TRY", // Turkish Lira
    IR: "IRR", // Iranian Rial
    IQ: "IQD", // Iraqi Dinar
    LY: "LYD", // Libyan Dinar
    DZ: "DZD", // Algerian Dinar
    MA: "MAD", // Moroccan Dirham
    TN: "TND", // Tunisian Dinar
    SD: "SDG", // Sudanese Pound
    KE: "KES", // Kenyan Shilling
    NG: "NGN", // Nigerian Naira
    ZM: "ZMW", // Zambian Kwacha
    ET: "ETB", // Ethiopian Birr
    UG: "UGX", // Ugandan Shilling
    GH: "GHS", // Ghanaian Cedi
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    SN: "XOF", // West African CFA Franc
    CI: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    NE: "XOF", // West African CFA Franc
    BF: "XOF", // West African CFA Franc
    ML: "XOF", // West African CFA Franc
    MR: "MRO", // Mauritanian Ouguiya
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    SL: "SLL", // Sierra Leonean Leone
    CI: "XOF", // West African CFA Franc
    GH: "GHS", // Ghanaian Cedi
    BF: "XOF", // West African CFA Franc
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    CM: "XAF", // Central African CFA Franc
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    GQ: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    AO: "AOA", // Angolan Kwanza
    BW: "BWP", // Botswanan Pula
    ZW: "ZWL", // Zimbabwean Dollar
    MU: "MUR", // Mauritian Rupee
    SC: "SCR", // Seychellois Rupee
    NZ: "NZD", // New Zealand Dollar
    FJ: "FJD", // Fijian Dollar
    PG: "PGK", // Papua New Guinean Kina
    SB: "SBD", // Solomon Islands Dollar
    WS: "WST", // Samoan Tala
    TO: "TOP", // Tongan Pa'anga
    TV: "TVD", // Tuvaluan Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    VU: "VUV", // Vanuatu Vatu
    NC: "XPF", // CFP Franc
    PF: "XPF", // CFP Franc
    WF: "XPF", // CFP Franc
    KZ: "KZT", // Kazakhstani Tenge
    KG: "KGS", // Kyrgystani Som
    TJ: "TJS", // Tajikistani Somoni
    TM: "TMT", // Turkmenistani Manat
    UZ: "UZS", // Uzbekistani Som
    AM: "AMD", // Armenian Dram
    AZ: "AZN", // Azerbaijani Manat
    GE: "GEL", // Georgian Lari
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    RS: "RSD", // Serbian Dinar
    ME: "EUR", // Euro (Used in Montenegro)
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    HR: "HRK", // Croatian Kuna
    SI: "EUR", // Euro (Used in Slovenia)
    MK: "MKD", // Macedonian Denar
    AL: "ALL", // Albanian Lek
    XK: "EUR", // Euro (Used in Kosovo)
    YU: "YUM", // Yugoslav Dinar (No longer in use)
    MK: "MKD", // Macedonian Denar
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    SM: "EUR", // Euro (Used in San Marino)
    VA: "EUR", // Euro (Used in Vatican City)
    IS: "ISK", // Icelandic Króna
    NO: "NOK", // Norwegian Krone
    SE: "SEK", // Swedish Krona
    FO: "DKK", // Danish Krone (Used in Faroe Islands)
    GL: "DKK", // Danish Krone (Used in Greenland)
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    RU: "RUB", // Russian Ruble
    KZ: "KZT", // Kazakhstani Tenge
    TM: "TMT", // Turkmenistani Manat
    UZ: "UZS", // Uzbekistani Som
    KG: "KGS", // Kyrgystani Som
    TJ: "TJS", // Tajikistani Somoni
    AF: "AFN", // Afghan Afghani
    PK: "PKR", // Pakistani Rupee
    NP: "NPR", // Nepalese Rupee
    IN: "INR", // Indian Rupee
    BD: "BDT", // Bangladeshi Taka
    LK: "LKR", // Sri Lankan Rupee
    MV: "MVR", // Maldivian Rufiyaa
    BN: "BND", // Brunei Dollar
    MY: "MYR", // Malaysian Ringgit
    PG: "PGK", // Papua New Guinean Kina
    SB: "SBD", // Solomon Islands Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    VU: "VUV", // Vanuatu Vatu
    NC: "XPF", // CFP Franc
    PF: "XPF", // CFP Franc
    WF: "XPF", // CFP Franc
    WS: "WST", // Samoan Tala
    TO: "TOP", // Tongan Pa'anga
    TV: "TVD", // Tuvaluan Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    KI: "AUD", // Australian Dollar (Used in Kiribati)
    MH: "USD", // United States Dollar (Used in Marshall Islands)
    FM: "USD", // United States Dollar (Used in Federated States of Micronesia)
    PW: "USD", // United States Dollar (Used in Palau)
    TL: "USD", // United States Dollar (Used in East Timor)
    WS: "WST", // Samoan Tala
    TO: "TOP", // Tongan Pa'anga
    TV: "TVD", // Tuvaluan Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    KI: "AUD", // Australian Dollar (Used in Kiribati)
    MH: "USD", // United States Dollar (Used in Marshall Islands)
    FM: "USD", // United States Dollar (Used in Federated States of Micronesia)
    PW: "USD", // United States Dollar (Used in Palau)
    TL: "USD", // United States Dollar (Used in East Timor)
    NP: "NPR", // Nepalese Rupee
    BT: "BTN", // Bhutanese Ngultrum
    PK: "PKR", // Pakistani Rupee
    AF: "AFN", // Afghan Afghani
    IR: "IRR", // Iranian Rial
    IQ: "IQD", // Iraqi Dinar
    SY: "SYP", // Syrian Pound
    LB: "LBP", // Lebanese Pound
    CY: "EUR", // Euro (Used in Cyprus)
    TR: "TRY", // Turkish Lira
    JO: "JOD", // Jordanian Dinar
    IL: "ILS", // Israeli New Shekel
    PS: "ILS", // Israeli New Shekel
    SA: "SAR", // Saudi Riyal
    YE: "YER", // Yemeni Rial
    OM: "OMR", // Omani Rial
    AE: "AED", // United Arab Emirates Dirham
    KW: "KWD", // Kuwaiti Dinar
    QA: "QAR", // Qatari Riyal
    BH: "BHD", // Bahraini Dinar
    EG: "EGP", // Egyptian Pound
    SD: "SDG", // Sudanese Pound
    SS: "SSP", // South Sudanese Pound
    ER: "ERN", // Eritrean Nakfa
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    ET: "ETB", // Ethiopian Birr
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    AO: "AOA", // Angolan Kwanza
    GW: "XOF", // West African CFA Franc
    GN: "GNF", // Guinean Franc
    LR: "LRD", // Liberian Dollar
    CI: "XOF", // West African CFA Franc
    SL: "SLL", // Sierra Leonean Leone
    SN: "XOF", // West African CFA Franc
    GM: "GMD", // Gambian Dalasi
    MR: "MRU", // Mauritanian Ouguiya
    EH: "MAD", // Moroccan Dirham (Used in Western Sahara)
    EH: "MAD", // Moroccan Dirham (Used in Western Sahara)
    ML: "XOF", // West African CFA Franc
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BF: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    NG: "NGN", // Nigerian Naira
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    CM: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    UG: "UGX", // Ugandan Shilling
    KE: "KES", // Kenyan Shilling
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    TZ: "TZS", // Tanzanian Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    EG: "EGP", // Egyptian Pound
    LY: "LYD", // Libyan Dinar
    SD: "SDG", // Sudanese Pound
    ET: "ETB", // Ethiopian Birr
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    CM: "XAF", // Central African CFA Franc
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    AO: "AOA", // Angolan Kwanza
    BI: "BIF", // Burundian Franc
    RW: "RWF", // Rwandan Franc
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    UG: "UGX", // Ugandan Shilling
    KE: "KES", // Kenyan Shilling
    SO: "SOS", // Somali Shilling
    DJ: "DJF", // Djiboutian Franc
    ET: "ETB", // Ethiopian Birr
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    CM: "XAF", // Central African CFA Franc
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    AO: "AOA", // Angolan Kwanza
    BI: "BIF", // Burundian Franc
    RW: "RWF", // Rwandan Franc
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MW: "MWK", // Malawian Kwacha
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    ZA: "ZAR", // South African Rand
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZW: "ZWL", // Zimbabwean Dollar
    AO: "AOA", // Angolan Kwanza
    KE: "KES", // Kenyan Shilling
    TZ: "TZS", // Tanzanian Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    ET: "ETB", // Ethiopian Birr
    ER: "ERN", // Eritrean Nakfa
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    ML: "XOF", // West African CFA Franc
    BF: "XOF", // West African CFA Franc
    CI: "XOF", // West African CFA Franc
    BF: "XOF", // West African CFA Franc
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    GN: "GNF", // Guinean Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    ML: "XOF", // West African CFA Franc
    MR: "MRO", // Mauritanian Ouguiya
    SL: "SLL", // Sierra Leonean Leone
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    EH: "MAD", // Moroccan Dirham (Used in Western Sahara)
    MA: "MAD", // Moroccan Dirham
    ES: "EUR", // Euro (Used in Spain)
    PT: "EUR", // Euro (Used in Portugal)
    AD: "EUR", // Euro (Used in Andorra)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    BE: "EUR", // Euro (Used in Belgium)
    LU: "EUR", // Euro (Used in Luxembourg)
    NL: "EUR", // Euro (Used in Netherlands)
    IE: "EUR", // Euro (Used in Ireland)
    IT: "EUR", // Euro (Used in Italy)
    MT: "EUR", // Euro (Used in Malta)
    GR: "EUR", // Euro (Used in Greece)
    CY: "EUR", // Euro (Used in Cyprus)
    AT: "EUR", // Euro (Used in Austria)
    SI: "EUR", // Euro (Used in Slovenia)
    HR: "HRK", // Croatian Kuna
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    ME: "EUR", // Euro (Used in Montenegro)
    MK: "MKD", // Macedonian Denar
    AL: "ALL", // Albanian Lek
    XK: "EUR", // Euro (Used in Kosovo)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    CZ: "CZK", // Czech Koruna
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    SI: "EUR", // Euro (Used in Slovenia)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    DE: "EUR", // Euro (Used in Germany)
    DK: "DKK", // Danish Krone
    SE: "SEK", // Swedish Krona
    NO: "NOK", // Norwegian Krone
    FI: "EUR", // Euro (Used in Finland)
    RU: "RUB", // Russian Ruble
    EE: "EUR", // Euro (Used in Estonia)
    LV: "EUR", // Euro (Used in Latvia)
    LT: "EUR", // Euro (Used in Lithuania)
    PL: "PLN", // Polish Złoty
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    AT: "EUR", // Euro (Used in Austria)
    CZ: "CZK", // Czech Koruna
    SI: "EUR", // Euro (Used in Slovenia)
    IT: "EUR", // Euro (Used in Italy)
    HR: "HRK", // Croatian Kuna
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    ME: "EUR", // Euro (Used in Montenegro)
    XK: "EUR", // Euro (Used in Kosovo)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    SI: "EUR", // Euro (Used in Slovenia)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    DE: "EUR", // Euro (Used in Germany)
    DK: "DKK", // Danish Krone
    SE: "SEK", // Swedish Krona
    NO: "NOK", // Norwegian Krone
    FI: "EUR", // Euro (Used in Finland)
    RU: "RUB", // Russian Ruble
    EE: "EUR", // Euro (Used in Estonia)
    LV: "EUR", // Euro (Used in Latvia)
    LT: "EUR", // Euro (Used in Lithuania)
    PL: "PLN", // Polish Złoty
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    AT: "EUR", // Euro (Used in Austria)
    CZ: "CZK", // Czech Koruna
    SI: "EUR", // Euro (Used in Slovenia)
    IT: "EUR", // Euro (Used in Italy)
    HR: "HRK", // Croatian Kuna
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    ME: "EUR", // Euro (Used in Montenegro)
    XK: "EUR", // Euro (Used in Kosovo)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    CZ: "CZK", // Czech Koruna
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    SI: "EUR", // Euro (Used in Slovenia)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    IE: "EUR", // Euro (Used in Ireland)
    IT: "EUR", // Euro (Used in Italy)
    MT: "EUR", // Euro (Used in Malta)
    GR: "EUR", // Euro (Used in Greece)
    CY: "EUR", // Euro (Used in Cyprus)
    AT: "EUR", // Euro (Used in Austria)
    SI: "EUR", // Euro (Used in Slovenia)
    HR: "HRK", // Croatian Kuna
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    ME: "EUR", // Euro (Used in Montenegro)
    XK: "EUR", // Euro (Used in Kosovo)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    CZ: "CZK", // Czech Koruna
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    AT: "EUR", // Euro (Used in Austria)
    SI: "EUR", // Euro (Used in Slovenia)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    IE: "EUR", // Euro (Used in Ireland)
    IT: "EUR", // Euro (Used in Italy)
    MT: "EUR", // Euro (Used in Malta)
    GR: "EUR", // Euro (Used in Greece)
    CY: "EUR", // Euro (Used in Cyprus)
    AT: "EUR", // Euro (Used in Austria)
    SI: "EUR", // Euro (Used in Slovenia)
    HR: "HRK", // Croatian Kuna
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    ME: "EUR", // Euro (Used in Montenegro)
    XK: "EUR", // Euro (Used in Kosovo)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    CZ: "CZK", // Czech Koruna
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    SI: "EUR", // Euro (Used in Slovenia)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    IE: "EUR", // Euro (Used in Ireland)
    IT: "EUR", // Euro (Used in Italy)
    MT: "EUR", // Euro (Used in Malta)
    GR: "EUR", // Euro (Used in Greece)
    CY: "EUR", // Euro (Used in Cyprus)
    TR: "TRY", // Turkish Lira
    SY: "SYP", // Syrian Pound
    LB: "LBP", // Lebanese Pound
    IL: "ILS", // Israeli New Shekel
    PS: "ILS", // Israeli New Shekel
    JO: "JOD", // Jordanian Dinar
    SA: "SAR", // Saudi Riyal
    YE: "YER", // Yemeni Rial
    OM: "OMR", // Omani Rial
    AE: "AED", // United Arab Emirates Dirham
    QA: "QAR", // Qatari Riyal
    KW: "KWD", // Kuwaiti Dinar
    IQ: "IQD", // Iraqi Dinar
    IR: "IRR", // Iranian Rial
    AZ: "AZN", // Azerbaijani Manat
    AM: "AMD", // Armenian Dram
    GE: "GEL", // Georgian Lari
    TR: "TRY", // Turkish Lira
    CY: "EUR", // Euro (Used in Cyprus)
    GR: "EUR", // Euro (Used in Greece)
    MK: "MKD", // Macedonian Denar
    AL: "ALL", // Albanian Lek
    XK: "EUR", // Euro (Used in Kosovo)
    ME: "EUR", // Euro (Used in Montenegro)
    BA: "BAM", // Bosnia-Herzegovina Convertible Mark
    HR: "HRK", // Croatian Kuna
    SI: "EUR", // Euro (Used in Slovenia)
    RS: "RSD", // Serbian Dinar
    BG: "BGN", // Bulgarian Lev
    RO: "RON", // Romanian Leu
    MD: "MDL", // Moldovan Leu
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    PL: "PLN", // Polish Złoty
    CZ: "CZK", // Czech Koruna
    SK: "EUR", // Euro (Used in Slovakia)
    HU: "HUF", // Hungarian Forint
    AT: "EUR", // Euro (Used in Austria)
    LI: "CHF", // Swiss Franc (Used in Liechtenstein)
    CH: "CHF", // Swiss Franc
    LU: "EUR", // Euro (Used in Luxembourg)
    BE: "EUR", // Euro (Used in Belgium)
    NL: "EUR", // Euro (Used in Netherlands)
    DE: "EUR", // Euro (Used in Germany)
    IT: "EUR", // Euro (Used in Italy)
    MT: "EUR", // Euro (Used in Malta)
    MC: "EUR", // Euro (Used in Monaco)
    FR: "EUR", // Euro (Used in France)
    ES: "EUR", // Euro (Used in Spain)
    PT: "EUR", // Euro (Used in Portugal)
    AD: "EUR", // Euro (Used in Andorra)
    SM: "EUR", // Euro (Used in San Marino)
    VA: "EUR", // Euro (Used in Vatican City)
    SM: "EUR", // Euro (Used in San Marino)
    VA: "EUR", // Euro (Used in Vatican City)
    IS: "ISK", // Icelandic Króna
    NO: "NOK", // Norwegian Krone
    SE: "SEK", // Swedish Krona
    FO: "DKK", // Danish Krone (Used in Faroe Islands)
    GL: "DKK", // Danish Krone (Used in Greenland)
    UA: "UAH", // Ukrainian Hryvnia
    BY: "BYN", // Belarusian Ruble
    RU: "RUB", // Russian Ruble
    KZ: "KZT", // Kazakhstani Tenge
    TM: "TMT", // Turkmenistani Manat
    UZ: "UZS", // Uzbekistani Som
    KG: "KGS", // Kyrgystani Som
    TJ: "TJS", // Tajikistani Somoni
    AF: "AFN", // Afghan Afghani
    PK: "PKR", // Pakistani Rupee
    NP: "NPR", // Nepalese Rupee
    IN: "INR", // Indian Rupee
    BD: "BDT", // Bangladeshi Taka
    LK: "LKR", // Sri Lankan Rupee
    MV: "MVR", // Maldivian Rufiyaa
    BN: "BND", // Brunei Dollar
    MY: "MYR", // Malaysian Ringgit
    PG: "PGK", // Papua New Guinean Kina
    SB: "SBD", // Solomon Islands Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    VU: "VUV", // Vanuatu Vatu
    NC: "XPF", // CFP Franc
    PF: "XPF", // CFP Franc
    WF: "XPF", // CFP Franc
    WS: "WST", // Samoan Tala
    TO: "TOP", // Tongan Pa'anga
    TV: "TVD", // Tuvaluan Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    KI: "AUD", // Australian Dollar (Used in Kiribati)
    MH: "USD", // United States Dollar (Used in Marshall Islands)
    FM: "USD", // United States Dollar (Used in Federated States of Micronesia)
    PW: "USD", // United States Dollar (Used in Palau)
    TL: "USD", // United States Dollar (Used in East Timor)
    WS: "WST", // Samoan Tala
    TO: "TOP", // Tongan Pa'anga
    TV: "TVD", // Tuvaluan Dollar
    NR: "AUD", // Australian Dollar (Used in Nauru)
    KI: "AUD", // Australian Dollar (Used in Kiribati)
    MH: "USD", // United States Dollar (Used in Marshall Islands)
    FM: "USD", // United States Dollar (Used in Federated States of Micronesia)
    PW: "USD", // United States Dollar (Used in Palau)
    TL: "USD", // United States Dollar (Used in East Timor)
    NP: "NPR", // Nepalese Rupee
    BT: "BTN", // Bhutanese Ngultrum
    PK: "PKR", // Pakistani Rupee
    AF: "AFN", // Afghan Afghani
    IR: "IRR", // Iranian Rial
    IQ: "IQD", // Iraqi Dinar
    SY: "SYP", // Syrian Pound
    LB: "LBP", // Lebanese Pound
    CY: "EUR", // Euro (Used in Cyprus)
    TR: "TRY", // Turkish Lira
    JO: "JOD", // Jordanian Dinar
    IL: "ILS", // Israeli New Shekel
    PS: "ILS", // Israeli New Shekel
    SA: "SAR", // Saudi Riyal
    YE: "YER", // Yemeni Rial
    OM: "OMR", // Omani Rial
    AE: "AED", // United Arab Emirates Dirham
    KW: "KWD", // Kuwaiti Dinar
    QA: "QAR", // Qatari Riyal
    BH: "BHD", // Bahraini Dinar
    EG: "EGP", // Egyptian Pound
    SD: "SDG", // Sudanese Pound
    SS: "SSP", // South Sudanese Pound
    ER: "ERN", // Eritrean Nakfa
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    ET: "ETB", // Ethiopian Birr
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    AO: "AOA", // Angolan Kwanza
    BW: "BWP", // Botswanan Pula
    NA: "NAD", // Namibian Dollar
    ZA: "ZAR", // South African Rand
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    KM: "KMF", // Comorian Franc
    SC: "SCR", // Seychellois Rupee
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MZ: "MZN", // Mozambican Metical
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    UG: "UGX", // Ugandan Shilling
    KE: "KES", // Kenyan Shilling
    ET: "ETB", // Ethiopian Birr
    ER: "ERN", // Eritrean Nakfa
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    CM: "XAF", // Central African CFA Franc
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    AO: "AOA", // Angolan Kwanza
    BI: "BIF", // Burundian Franc
    RW: "RWF", // Rwandan Franc
    UG: "UGX", // Ugandan Shilling
    KE: "KES", // Kenyan Shilling
    TZ: "TZS", // Tanzanian Shilling
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZA: "ZAR", // South African Rand
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    KM: "KMF", // Comorian Franc
    SC: "SCR", // Seychellois Rupee
    MZ: "MZN", // Mozambican Metical
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZA: "ZAR", // South African Rand
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    KE: "KES", // Kenyan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    NE: "XOF", // West African CFA Franc
    TG: "XOF", // West African CFA Franc
    BJ: "XOF", // West African CFA Franc
    LR: "LRD", // Liberian Dollar
    SL: "SLL", // Sierra Leonean Leone
    GM: "GMD", // Gambian Dalasi
    GN: "GNF", // Guinean Franc
    SN: "XOF", // West African CFA Franc
    GW: "XOF", // West African CFA Franc
    CV: "CVE", // Cape Verdean Escudo
    ST: "STD", // São Tomé and Príncipe Dobra
    GH: "GHS", // Ghanaian Cedi
    NG: "NGN", // Nigerian Naira
    CM: "XAF", // Central African CFA Franc
    CF: "XAF", // Central African CFA Franc
    TD: "XAF", // Central African CFA Franc
    GA: "XAF", // Central African CFA Franc
    CG: "XAF", // Central African CFA Franc
    CD: "CDF", // Congolese Franc
    AO: "AOA", // Angolan Kwanza
    BI: "BIF", // Burundian Franc
    RW: "RWF", // Rwandan Franc
    UG: "UGX", // Ugandan Shilling
    KE: "KES", // Kenyan Shilling
    TZ: "TZS", // Tanzanian Shilling
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZA: "ZAR", // South African Rand
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    MZ: "MZN", // Mozambican Metical
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZA: "ZAR", // South African Rand
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    KE: "KES", // Kenyan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    DJ: "DJF", // Djiboutian Franc
    SO: "SOS", // Somali Shilling
    ET: "ETB", // Ethiopian Birr
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    SC: "SCR", // Seychellois Rupee
    KM: "KMF", // Comorian Franc
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    LS: "LSL", // Lesotho Loti
    SZ: "SZL", // Swazi Lilangeni
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    NA: "NAD", // Namibian Dollar
    BW: "BWP", // Botswanan Pula
    ZA: "ZAR", // South African Rand
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MZ: "MZN", // Mozambican Metical
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
    TZ: "TZS", // Tanzanian Shilling
    MU: "MUR", // Mauritian Rupee
    MW: "MWK", // Malawian Kwacha
    ZM: "ZMW", // Zambian Kwacha
    MG: "MGA", // Malagasy Ariary
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    ZM: "ZMW", // Zambian Kwacha
    ZW: "ZWL", // Zimbabwean Dollar
    KE: "KES", // Kenyan Shilling
    UG: "UGX", // Ugandan Shilling
    RW: "RWF", // Rwandan Franc
    BI: "BIF", // Burundian Franc
  };
  // Look up the currency for the provided country code.
  const currency = countryCurrencyMap[countryCode];
  if (currency) {
    return currency;
  } else {
    // Return a default currency or handle the case when the country is not in the mapping.
    return "GBP"; // Default to United States Dollar
  }
};
