import { City, Country } from "country-state-city";

import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import AutoComplete from "../../../AutoComplete/AutoComplete";

const AdditionalInfoForm = (props) => {
  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <>
      <div className="form-row mb-2 w-100">
        {/* ABOUT FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="about">
            About
          </Label>
          <textarea
            className="form-control border-secondary"
            name="about"
            rows={5}
            data-testid="registration_garage_about"
            onChange={props.handleChange}
            defaultValue={props.data.about ? props.data.about : ""}
            // placeholder="about"
          />
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.about"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.about"][0]}
              </div>
            ))}
        </Col>

        {/* ADDITIONAL INFORMATION  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="additional_information">
            Additional Information
          </Label>
          <textarea
            className="form-control border-secondary"
            name="additional_information"
            rows={5}
            data-testid="registration_garage_additional_information"
            onChange={props.handleChange}
            defaultValue={
              props.data.additional_information
                ? props.data.additional_information
                : ""
            }
            // placeholder="additional information"
          />
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.additional_information"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.additional_information"][0]}
              </div>
            ))}
        </Col>
      </div>

      <div className="form-row mb-2 w-100">
        {/* WEB PAGE  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="web_page">
            Web Page
          </Label>
          <Input
            className="form-control border-secondary"
            name="web_page"
            type="text"
            data-testid="registration_garage_web_page"
            onChange={props.handleChange}
            value={props.data.web_page}
            // placeholder="web page"
          />
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.web_page"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.web_page"][0]}
              </div>
            ))}
        </Col>

        {/* TIME FORMAT  FIELD  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="time_format">
            Time Format
          </Label>
          <Input
            className="form-control border-secondary"
            data-testid="registration_garage_time_format"
            name="time_format"
            type="select"
            onChange={props.handleChange}
            value={props.data.time_format}
          >
            <option value={"24-hour"}>24-Hour</option>
            <option value={"12-hour"}>12-Hour</option>
          </Input>
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.time_format"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.time_format"][0]}
              </div>
            ))}
        </Col>

        {/* MOBILE GARAGE FIELD  */}
        <Input className="form-control" name="id" type="hidden" />
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="is_mobile_garage">
            Mobile Garage
          </Label>
          <Input
            className="form-control border-secondary"
            data-testid="registration_garage_is_mobile_garage"
            name="is_mobile_garage"
            type="select"
            onChange={props.handleChange}
            value={props.data.is_mobile_garage}
          >
            <option value={1}>yes</option>
            <option value={0}>no</option>
          </Input>
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.is_mobile_garage"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.is_mobile_garage"][0]}
              </div>
            ))}
        </Col>

        {/* WIFI AVAILABLE  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="wifi_available">
            Wifi Available
          </Label>
          <Input
            className="form-control border-secondary"
            data-testid="registration_garage_wifi_available"
            name="wifi_available"
            type="select"
            onChange={props.handleChange}
            value={props.data.wifi_available}
          >
            <option value={1}>yes</option>
            <option value={0}>no</option>
          </Input>
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.wifi_available"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.wifi_available"][0]}
              </div>
            ))}
        </Col>

        {/* LABOUR RATE  */}
        <Col md="6 mb-3" xs="12">
          <Label className="" htmlFor="labour_rate">
            Labour Rate
          </Label>
          <Input
            className="form-control border-secondary"
            data-testid="registration_garage_labour_rate"
            name="labour_rate"
            type="text"
            // placeholder="labour rate"
            onChange={props.handleChange}
            value={props.data.labour_rate}
          />
          {props.serverSideErrors &&
            (!props.serverSideErrors["garage.labour_rate"] ? (
              <div className="valid-feedback" style={{ display: "block" }}>
                {"Looks good!"}
              </div>
            ) : (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {props.serverSideErrors["garage.labour_rate"][0]}
              </div>
            ))}
        </Col>
      </div>
    </>
  );
};

export default AdditionalInfoForm;
