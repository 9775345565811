import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";

import { apiClient } from "../../utils/apiClient";
import { BACKEND, BACKEND_API } from "../../utils/backend";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GARAGE_CREATE } from "../../constant/permissions";

import useGeoLocation from "../../hooks/useGeoLocation";
import Error401 from "../../pages/errors/Error401";

import { User } from "react-feather";
import { FaCar } from "react-icons/fa";
import Formstep1 from "../forms/FormLayout/GarageForm/Formstep1";
import Formstep2 from "../forms/FormLayout/GarageForm/Formstep2";
import Formstep3 from "../forms/FormLayout/GarageForm/Formstep3";
import {
  AiOutlineCar,
  AiOutlineInfoCircle,
  AiOutlineSetting,
} from "react-icons/ai";
import AdditionalInfoForm from "../forms/FormLayout/GarageForm/AdditionalInfoForm";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import AutomobieForm from "../forms/FormLayout/GarageUpdateForm/MakeAndModelForm";
import ServiceForm from "../forms/FormLayout/GarageUpdateForm/ServiceForm";
import MakeAndModelForm from "../forms/FormLayout/GarageUpdateForm/MakeAndModelForm";
import { decryptionID } from "../../utils/encription";

const GarageUpdate = ({ history }) => {
  // eslint-disable-next-line
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { string_id } = useParams();
  const id = decryptionID(string_id);

  const [placeAutoComplete, setPlaceAutoComplete] = useState({});
  const [placeAutoComplete2, setPlaceAutoComplete2] = useState({});
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateMakeData, setUpdateMakeData] = useState();
  const [updateServiceData, setUpdateServiceData] = useState();

  // IMAGE UPLOAD
  const [imageUrl, setImageUrl] = useState();
  const [formImageUrl, setFormImageUrl] = useState("");
  const [placeholderImage, setPlaceholderImage] = useState("");

  const [distanceError, setDistanceError] = useState("");
  const [state, setState] = useState({ currentStep: 1 });
  const location = useGeoLocation();

  const [garage, setGarage] = useState({
    name: "",
    about: "",
    web_page: "",
    phone: "",
    email: "",
    additional_information: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    city: "",
    postcode: "",
    logo: "",
    is_mobile_garage: "1",
    wifi_available: "1",
    labour_rate: "",
    average_time_slot: "",
    currency: "",
    time_format: "24-hour", // 12-hour
  });
  useEffect(() => {
    console.log({ garage });
  }, [garage]);
  const [automobileCategories, setAutomobileCategories] = useState([]);

  const [service, setService] = useState([
    {
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    },
  ]);

  const loadSingleUserData = async () => {
    setIsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/v1.0/garages/single/${id}`)
      .then((response) => {
        setGarage({
          id: response?.data?.garage?.id,
          name: response?.data?.garage?.name,
          about: response?.data?.garage?.about,
          web_page:
            response?.data?.garage?.web_page === null
              ? ""
              : response.data.garage.web_page,
          phone:
            response?.data?.garage?.phone === null
              ? ""
              : response?.data?.garage?.phone,
          email: response?.data?.garage?.email,
          additional_information:
            response?.data?.garage?.additional_information,
          address_line_1: response?.data?.garage?.address_line_1,
          address_line_2: response?.data?.garage?.address_line_2,
          country: response?.data?.garage?.country,
          city: response?.data?.garage?.city,
          postcode: response?.data?.garage?.postcode,
          lat: response?.data?.garage?.owner?.lat,
          long: response?.data?.garage?.owner?.long,
          logo: response?.data?.garage?.logo,
          is_mobile_garage: response?.data?.garage?.is_mobile_garage,
          wifi_available: response?.data?.garage?.wifi_available,
          labour_rate:
            response?.data?.garage?.labour_rate === null
              ? ""
              : response?.data?.garage?.labour_rate,
          average_time_slot:
            response?.data?.garage?.average_time_slot === null
              ? ""
              : response?.data?.garage?.average_time_slot,
          currency: response?.data?.garage?.currency,
          time_format: response?.data?.garage?.time_format,
        });
        setImageUrl(response.data.garage.logo);
        setPlaceholderImage(response.data.garage.logo);

        setUpdateMakeData(response.data.garage.garage_automobile_makes);
        setUpdateServiceData(response.data.garage.garage_services);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "Something went wrong!!",
            text: "Data was not loaded. Please try again",
            icon: "warning",
          });
        }
      });
  };

  // GET ALL CATEGORIES
  const loadAutomobileCategories = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then((response) => {
        let categories = response.data;
        setAutomobileCategories(categories);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "Something went wrong!!",
            text: "Data was not loaded. Please try again",
            icon: "warning",
          });
        }
      });
  };

  // GET ALL MAKES
  const loadAutomobileMakes = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-makes-all/${1}`)
      .then((response) => {
        let makes = response.data;
        loadServices(makes);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "Something went wrong!!",
            text: "Data was not loaded. Please try again",
            icon: "warning",
          });
        }
      });
  };

  const loadServices = (makes) => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/services-all/${1}`)
      .then((response) => {
        let services = response.data;
        let tempServices = JSON.parse(JSON.stringify(service));
        tempServices[0] = {
          ...tempServices[0],
          services: [
            ...services.map((el) => {
              let checked = false;
              updateServiceData.map((el2) => {
                if (!checked) {
                  checked = el2.service_id === el.id ? true : false;
                  if (checked) {
                    el.sub_services.forEach((sub_el) => {
                      let sub_checked = false;
                      el2.garage_sub_services.forEach((sub_el2) => {
                        if (!sub_checked) {
                          sub_checked =
                            sub_el2.sub_service_id === sub_el.id ? true : false;
                        }
                      });
                      sub_el.checked = sub_checked;
                      return sub_el;
                    });
                  }
                }
              });
              el.checked = checked;
              return el;
            }),
          ],
          automobile_makes: [
            ...makes.map((el) => {
              let checked = false;
              updateMakeData.map((el2) => {
                if (!checked) {
                  checked = el.id === el2.automobile_make_id ? true : false;
                  if (checked) {
                    el.models = el.models.map((model) => {
                      let mod_checked = false;
                      el2.garage_automobile_models.map((model2) => {
                        if (!mod_checked) {
                          mod_checked =
                            model.id === model2.automobile_model_id
                              ? true
                              : false;
                        }
                      });
                      model.checked = mod_checked;
                      return model;
                    });
                  }
                }
              });
              el.checked = checked;
              return el;
            }),
          ],
        };
        setService(tempServices);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "Something went wrong!!",
            text: "Data was not loaded. Please try again",
            icon: "warning",
          });
        }
      });
  };

  // CALCULATE THE DISTANCE BETWEEN ADDRESS AND LIVE LOCATION
  function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d * 1000);
  }

  const addCategory = () => {
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.push({
      automobile_category_id: "1",
      services: [],
      automobile_makes: [],
    });
    setService(tempServices);
  };

  const handleGarageChange = (e) => {
    const { name, value } = e.target;
    setGarage({ ...garage, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const _next = () => {
    let currentStep = state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const _prev = () => {
    let currentStep = state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    setState({
      ...state,
      currentStep: currentStep,
    });
  };

  const handleCategoryChange = (e) => {
    let index = e.target.name.split("-")[1];
    let name = e.target.name.split("-")[2];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index] = {
      ...tempServices[index],
      [name]: e.target.value,
    };
    setService(tempServices);
  };

  const handleMakeChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let tempServices = JSON.parse(JSON.stringify(service));
    console.log({ make: tempServices[index].automobile_makes[makeIndex] });
    tempServices[index].automobile_makes[makeIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].checked;

    tempServices[index].automobile_makes[makeIndex].models.forEach(
      (element) => {
        element.checked =
          tempServices[index].automobile_makes[makeIndex].checked;
        return element;
      }
    );

    setService(tempServices);
  };

  // SUB SERVICE CHECK UNCHECK HANDLE
  const handleSubServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];
    let subServiceIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].services[serviceIndex].sub_services[
      subServiceIndex
    ].checked =
      !tempServices[index].services[serviceIndex].sub_services[subServiceIndex]
        .checked;

    tempServices[index].services[serviceIndex].checked =
      tempServices[index].services[serviceIndex].sub_services.filter(
        (i) => i.checked === true
      ).length > 0;

    setService(tempServices);
  };

  // MODEL CHECK UNCHECK HANDLE
  const handleModelChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let makeIndex = name.split("-")[3];
    let modelIndex = name.split("-")[5];
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices[index].automobile_makes[makeIndex].models[modelIndex].checked =
      !tempServices[index].automobile_makes[makeIndex].models[modelIndex]
        .checked;

    tempServices[index].automobile_makes[makeIndex].checked =
      tempServices[index].automobile_makes[makeIndex].models.filter(
        (i) => i.checked === true
      ).length > 0;
    setService(tempServices);
  };

  const handleServiceChange = (e) => {
    const { name } = e.target;
    let index = name.split("-")[1];
    let serviceIndex = name.split("-")[3];

    let tempServices = JSON.parse(JSON.stringify(service));

    tempServices[index].services[serviceIndex].checked =
      !tempServices[index].services[serviceIndex].checked;

    tempServices[index].services[serviceIndex].sub_services.forEach(
      (element) => {
        element.checked = tempServices[index].services[serviceIndex].checked;
        return element;
      }
    );
    setService(tempServices);
  };

  // SERVICE ALL CHECK
  const [allTick, setAllTick] = useState(true);
  const [allTickMake, setAllTickMake] = useState(true);

  const [isFirstTIme, setIsFirstTIme] = useState(true);

  // CHECK IS THERE IS NO SUB_SERVICE HAVE CHECK OR NOT
  function hasUncheckedSubServices(service) {
    for (const item of service) {
      if (item.sub_services) {
        for (const subService of item.sub_services) {
          if (!subService.checked) {
            return false; // Return false if any sub-service is unchecked
          }
        }
      }
    }
    return true; // Return true if all sub-services are checked
  }
  // CHECK IS THERE IS NO MODEL HAVE CHECK OR NOT
  function hasUncheckedModels(data) {
    console.log({ data });
    for (const item of data) {
      if (item.models) {
        for (const model of item.models) {
          if (!model.checked) {
            console.log("one model is unchecked");
            return false; // Return false if any model is unchecked
          }
        }
      }
    }
    return true; // Return true if all models are checked
  }
  useEffect(() => {
    if (isFirstTIme) {
      setIsFirstTIme(false);
    } else {
      setAllTick(hasUncheckedSubServices(service[0]?.services));
      setAllTickMake(hasUncheckedModels(service[0]?.automobile_makes));
    }
  }, [service]);

  const handleServiceChangeAll = (e) => {
    const { checked } = e.target;

    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((services) => {
      services.services.map((service) => {
        service.checked = checked;
        service.sub_services.map((sub_service) => {
          sub_service.checked = checked;
          return sub_service;
        });
        return service;
      });
      return services;
    });

    setService(tempServices);
  };

  const handleMakeChangeAll = (e) => {
    const { checked } = e.target;
    let tempServices = JSON.parse(JSON.stringify(service));
    tempServices.map((automobile_makes) => {
      automobile_makes.automobile_makes.map((make) => {
        make.checked = checked;
        make.models.map((model) => {
          model.checked = checked;
          return model;
        });
        return make;
      });
    });

    setService(tempServices);
  };

  const onFileChange = (e) => {
    setFormImageUrl(e.target.files[0]);
  };

  const [isLoadingSubmition, setIsLoadingSubmition] = useState(false);
  const handleSubmitStep = () => {
    setIsLoadingSubmition(true);
    let currentStep = state.currentStep;
    if (formImageUrl) {
      var Data = new FormData();
      Data.append("image", formImageUrl, formImageUrl.name);
      apiClient()
        .post(`${BACKEND_API}/v1.0/garage-image`, Data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${Data._boundary}`,
          },
        })
        .then((res) => {
          garage.logo = `${BACKEND}${res.data?.full_location}`;

          apiClient()
            .put(`${BACKEND_API}/v1.0/garages/separate`, {
              // user: user,
              garage: garage,
              service:
                state?.currentStep === 4
                  ? service
                  : [
                      {
                        automobile_category_id: "1",
                        services: service[0]?.services,
                        automobile_makes: [],
                      },
                    ],
            })
            .then((res) => {
              SweetAlert.fire({
                title: "Success",
                text: "Garage Updated Successfully!",
                icon: "success",
              });
              setIsLoadingSubmition(false);
              navigate("/garages/list");
            })
            .catch((error) => {
              setLoading(false);
              setIsLoadingSubmition(false);
              if (error.response?.status === 422) {
                const { errors } = error.response.data;
                setServerSideErrors(errors);
                console.log("garage error");

                if (
                  currentStep >= 1 &&
                  (errors["garage.name"] ||
                    errors["garage.about"] ||
                    errors["garage.web_page"] ||
                    errors["garage.phone"] ||
                    errors["garage.email"] ||
                    errors["garage.additional_information"] ||
                    errors["garage.country"] ||
                    errors["garage.city"] ||
                    errors["garage.postcode"] ||
                    errors["garage.address_line_1"] ||
                    errors["garage.address_line_2"] ||
                    errors["garage.logo"] ||
                    errors["garage.is_mobile_garage"] ||
                    errors["garage.wifi_available"] ||
                    errors["garage.labour_rate"] ||
                    errors["garage.average_time_slot"] ||
                    errors["garage.lat"] ||
                    errors["garage.long"])
                ) {
                  console.log("garage error");

                  setState({
                    ...state,
                    currentStep: 1,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else if (
                  currentStep >= 3 &&
                  (errors["service.makes"] ||
                    errors["service"] ||
                    errors["service.services"] ||
                    errors["service.0.automobile_makes"] ||
                    errors["service.0.services"])
                ) {
                  console.log("service error");

                  setState({
                    ...state,
                    currentStep: 3,
                  });

                  SweetAlert.fire({
                    title: error.response.data.message,
                    text: "Please Try Again",
                    icon: "warning",
                  });
                  return;
                } else {
                  setServerSideErrors(null);
                  _next();
                }
              } else if (error.response?.status === 401) {
                SweetAlert.fire({
                  title: error.response.data.message,
                  text: "Hello!!! You do not have permission.",
                  icon: "warning",
                });
              }
            });
        });
    } else {
      apiClient()
        .put(`${BACKEND_API}/v1.0/garages/separate`, {
          garage: garage,
          service:
            state?.currentStep === 4
              ? service
              : [
                  {
                    automobile_category_id: "1",
                    services: service[0]?.services,
                    automobile_makes: [],
                  },
                ],
        })
        .then((res) => {
          setIsLoadingSubmition(false);
          SweetAlert.fire({
            title: "Success",
            text: "Garage Updated Successfully!",
            icon: "success",
          });
          navigate("/garages/list");
        })
        .catch((error) => {
          setIsLoadingSubmition(false);
          setLoading(false);
          if (error.response?.status === 422) {
            const { errors } = error.response.data;
            setServerSideErrors(errors);
            console.log({ errors });

            if (
              currentStep >= 1 &&
              (errors["garage.name"] ||
                errors["garage.email"] ||
                errors["garage.phone"] ||
                errors["garage.address_line_1"] ||
                errors["garage.address_line_2"] ||
                errors["garage.postcode"] ||
                errors["garage.country"] ||
                errors["garage.currency"] ||
                errors["garage.city"] ||
                errors["garage.web_page"] ||
                errors["garage.about"] ||
                errors["garage.additional_information"] ||
                errors["garage.is_mobile_garage"] ||
                errors["garage.logo"] ||
                errors["garage.labour_rate"] ||
                errors["garage.wifi_available"] ||
                errors["garage.average_time_slot"])
            ) {
              setState({
                ...state,
                currentStep: 1,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 2 &&
              (errors["garage.web_page"] ||
                errors["garage.about"] ||
                errors["garage.additional_information"] ||
                errors["garage.is_mobile_garage"] ||
                errors["garage.logo"] ||
                errors["garage.labour_rate"] ||
                errors["garage.wifi_available"] ||
                errors["garage.average_time_slot"])
            ) {
              setState({
                ...state,
                currentStep: 2,
              });

              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else if (
              currentStep >= 3 &&
              (errors["service"] ||
                errors["service.makes"] ||
                errors["service.0.automobile_makes"] ||
                errors["service.services"] ||
                errors["service.0.services"])
            ) {
              setState({
                ...state,
                currentStep: 3,
              });
              SweetAlert.fire({
                title: error.response.data.message,
                text: "Please Try Again",
                icon: "warning",
              });
              return;
            } else {
              setServerSideErrors(null);
              _next();
            }
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  // AUTO COMPLETE ADDRESS LINE 1 CHANGE THEN IT SHOULD REPLACE THE POSTCODE AND THE ADDRESS LINE 1
  useEffect(() => {
    setGarage({
      ...garage,
      postcode: placeAutoComplete?.postal_code,
      address_line_1: placeAutoComplete?.full_address,
      city: placeAutoComplete?.locality,
      long: `${placeAutoComplete?.coordinates?.lng}`,
      lat: `${placeAutoComplete?.coordinates?.lat}`,
      country: placeAutoComplete?.country,
      currency: placeAutoComplete?.currency,
    });
    setDistanceError("");
  }, [placeAutoComplete]);

  // AUTO COMPLETE ADDRESS LINE @ CHANGE THEN IT SHOULD REPLACE THE ADDRESS LINE 2
  useEffect(() => {
    setGarage({
      ...garage,
      address_line_2: placeAutoComplete2?.full_address,
    });
  }, [placeAutoComplete2]);

  // LOADING SINGLE GARAGE DATA
  useEffect(() => {
    loadSingleUserData();
  }, []);

  // LOADING SINGLE GARAGE SERVICES AND MAKE-MODEL
  useEffect(() => {
    if (!isLoading) {
      loadAutomobileCategories();
      loadAutomobileMakes();
    }
  }, [isLoading]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={handleSubmit}>
              <Card>
                <CardHeader>
                  <div>
                    <div>
                      <h5>Garage Management</h5>
                      <span> Update Garage </span>
                    </div>
                  </div>
                </CardHeader>
                <>
                  {isLoading ? (
                    <CustomLoadingSpiner />
                  ) : (
                    <>
                      <CardBody>
                        <div className="px-5 mb-5 my-1">
                          <Form className="f1" method="post">
                            <div className="f1-steps">
                              <div className="f1-progress">
                                <div
                                  className="f1-progress-line"
                                  data-now-value="49"
                                  data-number-of-steps={2}
                                />
                              </div>

                              <div
                                className={`f1-step f1-step-update-new  ${
                                  state.currentStep === 1 ? "active" : ""
                                } ${state.currentStep > 1 ? "activated" : ""}`}
                              >
                                <div className="f1-step-icon">
                                  <FaCar />
                                </div>
                                <p>Garage Info</p>
                              </div>
                              <div
                                className={`f1-step f1-step-update-new  ${
                                  state.currentStep === 2 ? "active" : ""
                                } ${state.currentStep > 2 ? "activated" : ""}`}
                              >
                                <div className="f1-step-icon">
                                  <AiOutlineInfoCircle />
                                </div>
                                <p>Additional Information</p>
                              </div>
                              <div
                                className={`f1-step f1-step-update-new  ${
                                  state.currentStep === 3 ? "active" : ""
                                } ${state.currentStep > 2 ? "activated" : ""}`}
                              >
                                <div className="f1-step-icon">
                                  <AiOutlineSetting />
                                </div>
                                <p>Services</p>
                              </div>
                              <div
                                className={`f1-step f1-step-update-new  ${
                                  state.currentStep === 4 ? "active" : ""
                                } ${state.currentStep > 4 ? "activated" : ""}`}
                              >
                                <div className="f1-step-icon">
                                  <AiOutlineCar />
                                </div>
                                <p>Make & Model</p>
                              </div>
                            </div>
                          </Form>
                        </div>
                        <CardText />
                        {state.currentStep === 1 && (
                          <Formstep2
                            imageUrl={imageUrl}
                            setPlaceholderImage={setPlaceholderImage}
                            formImageUrl={formImageUrl}
                            placeholderImage={placeholderImage}
                            onFileChange={onFileChange}
                            distanceError={distanceError}
                            setPlaceAutoComplete2={setPlaceAutoComplete2}
                            setPlaceAutoComplete={setPlaceAutoComplete}
                            currentStep={state.currentStep}
                            handleChange={handleGarageChange}
                            data={garage}
                            serverSideErrors={serverSideErrors}
                          />
                        )}
                        {state.currentStep === 2 && (
                          <AdditionalInfoForm
                            pathname={pathname}
                            handleMakeChangeAll={handleMakeChangeAll}
                            handleServiceChangeAll={handleServiceChangeAll}
                            currentStep={state.currentStep}
                            handleChange={handleGarageChange}
                            data={garage}
                            serverSideErrors={serverSideErrors}
                            addCategory={addCategory}
                            automobileCategories={automobileCategories}
                            handleCategoryChange={handleCategoryChange}
                            handleMakeChange={handleMakeChange}
                            handleModelChange={handleModelChange}
                            handleServiceChange={handleServiceChange}
                            handleSubServiceChange={handleSubServiceChange}
                          />
                        )}
                        {state.currentStep === 3 && (
                          <ServiceForm
                            allTick={allTick}
                            setAllTick={setAllTick}
                            pathname={pathname}
                            allTickMake={allTickMake}
                            setAllTickMake={setAllTickMake}
                            handleMakeChangeAll={handleMakeChangeAll}
                            handleServiceChangeAll={handleServiceChangeAll}
                            currentStep={state.currentStep}
                            data={service}
                            serverSideErrors={serverSideErrors}
                            addCategory={addCategory}
                            automobileCategories={automobileCategories}
                            handleCategoryChange={handleCategoryChange}
                            handleMakeChange={handleMakeChange}
                            handleModelChange={handleModelChange}
                            handleServiceChange={handleServiceChange}
                            handleSubServiceChange={handleSubServiceChange}
                          />
                        )}
                        {state.currentStep === 4 && (
                          <MakeAndModelForm
                            allTick={allTick}
                            setAllTick={setAllTick}
                            pathname={pathname}
                            allTickMake={allTickMake}
                            setAllTickMake={setAllTickMake}
                            handleMakeChangeAll={handleMakeChangeAll}
                            handleServiceChangeAll={handleServiceChangeAll}
                            currentStep={state.currentStep}
                            data={service}
                            serverSideErrors={serverSideErrors}
                            addCategory={addCategory}
                            automobileCategories={automobileCategories}
                            handleCategoryChange={handleCategoryChange}
                            handleMakeChange={handleMakeChange}
                            handleModelChange={handleModelChange}
                            handleServiceChange={handleServiceChange}
                            handleSubServiceChange={handleSubServiceChange}
                          />
                        )}
                      </CardBody>
                      <CardFooter>
                        {state.currentStep === 1 && (
                          <div className="d-flex justify-content-end">
                            <Button
                              disabled={isLoadingSubmition}
                              color="primary d-flex justify-content-end"
                              onClick={handleSubmitStep}
                            >
                              {isLoadingSubmition ? "Loading..." : "Next"}
                            </Button>
                          </div>
                        )}
                        {state.currentStep === 2 && (
                          <div className="d-flex justify-content-between">
                            <Button
                              disabled={isLoadingSubmition}
                              color="secondary float-left"
                              className="me-3"
                              onClick={_prev}
                            >
                              {isLoadingSubmition ? "Loading..." : "Previous"}
                            </Button>
                            <Button
                              disabled={isLoadingSubmition}
                              color="primary d-flex justify-content-end"
                              onClick={handleSubmitStep}
                            >
                              {isLoadingSubmition ? "Loading..." : "Next"}
                            </Button>
                          </div>
                        )}
                        {state.currentStep === 3 && (
                          <div className="d-flex justify-content-between">
                            <Button
                              color="secondary float-left"
                              className="me-3"
                              onClick={_prev}
                            >
                              Previous
                            </Button>
                            <>
                              {loading ? (
                                <Button
                                  color="primary float-right"
                                  type="button"
                                  disabled
                                >
                                  Loading..
                                </Button>
                              ) : (
                                <Button
                                  color="primary float-right"
                                  type="button"
                                  onClick={handleSubmitStep}
                                >
                                  Submit
                                </Button>
                              )}
                            </>
                          </div>
                        )}
                      </CardFooter>
                    </>
                  )}
                </>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GarageUpdate;
