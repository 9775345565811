import moment from 'moment';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { Next, Previous } from '../../../../constant';

const ReviewSteps = ({ regData, bookingData, setSteps, handleRegesterAndBooking }) => {
    return (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <th scope="row">Customer Name</th>
                        <td>{regData?.last_Name} {regData?.last_Name}</td>
                        <th scope="row">Customer Phone</th>
                        <td>{regData?.phone}</td>
                    </tr>
                    <tr>
                        <th scope="row">Services</th>
                        <td>{bookingData?.services?.map((res, i) =>
                            <p><span className='fw-bold'>{i + 1}.</span> {res?.label}</p>
                        )}</td>
                        <th scope="row">Make</th>
                        <td>{bookingData?.make?.label}</td>
                    </tr>
                    <tr>
                        <th scope="row">Model</th>
                        <td>{bookingData?.model?.label}</td>
                        <th scope="row">Packages</th>
                        <td>

                            {bookingData?.packages?.map((res, i) =>
                                <p><span className='fw-bold'>{i + 1}.</span> {res?.name}</p>
                            )}</td>
                    </tr>
                    <tr>
                        <th scope="row">Booking Start Time</th>
                        <td>{bookingData?.job_start_date}</td>
                        <th scope="row">Booking Start Time</th>
                        <td>{bookingData?.job_start_time}</td>
                    </tr>
                    <tr>
                        <th scope="row">Car Reg</th>
                        <td>{bookingData?.car_registration_no}</td>
                        <th scope="row">Additional Information</th>
                        <td>{bookingData?.additional_information}</td>
                    </tr>
                    {
                        bookingData?.coupon_code !== '' && <>
                            <tr>
                                <th scope="row">Coupon</th>
                                <td>{bookingData?.coupon_code}</td>
                                <th scope="row">Current Price</th>
                                <td>{bookingData?.currentAmount}</td>
                            </tr>
                            <tr>
                                <th scope="row">Discount Price</th>
                                <td>{bookingData?.discountAmount}</td>
                            </tr></>
                    }

                </tbody>
            </Table>
            <div className="text-end btn-mb mt-3">
                <div className="text-end btn-mb mt-3">
                    <Button color='primary' className='me-3' onClick={() => setSteps(prev => prev - 1)}>{Previous}</Button>
                    <Button color='primary' onClick={handleRegesterAndBooking}>Book</Button>
                </div>
            </div>
        </div>
    );
};

export default ReviewSteps;