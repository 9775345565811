import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import { decryptionID, encryptionID } from "../../utils/encription";

const ChangePass = () => {
  const { string_id } = useParams();
  const id = decryptionID(string_id);

  const [serverSideErrors, setServerSideErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [passData, setPassData] = useState({
    password: "",
    password_confirmation: "",
    current_password: "",
  });

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPassData({ ...passData, [name]: value });
  };

  // VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate cPass
    if (
      !passData?.current_password ||
      passData?.current_password.trim() === ""
    ) {
      newErrors.current_password = "Currnet password is required";
    } else {
      if (passData?.current_password.length < 6) {
        newErrors.current_password = "Password must be at least 6 digit";
      }
    }

    // Validate password
    if (!passData?.password || passData?.password.trim() === "") {
      newErrors.password = "Password is required";
    } else {
      if (passData?.password.length < 6) {
        newErrors.password = "Password must be at least 6 digit";
      }
    }

    // Validate password_confirmation
    if (
      !passData?.password_confirmation ||
      passData?.password_confirmation.trim() === ""
    ) {
      newErrors.password_confirmation = "Password confirmation is required";
    } else {
      if (passData?.password_confirmation.length < 6) {
        newErrors.password_confirmation =
          "Password confirmation must be at least 6 digit";
      }
    }

    const valuesArray = Object.keys(newErrors);

    if (valuesArray.length > 0) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Check your input fields",
        icon: "warning",
      });
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onChangePassword = () => {
    if (validateForm()) {
      setIsLoading(true);
      setServerSideErrors(null);
      apiClient()
        .patch(`${BACKEND_API}/auth/changepassword`, passData)
        .then((response) => {
          setIsLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Password Changed!",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/profile/${encryptionID(id)}`);
              }
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error?.response?.data?.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 400) {
            setServerSideErrors({
              current_password: [error?.response?.data?.message],
            });
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <Fragment>
      {/* <BreadCrumb parent="Home" subparent="Users" title="Edit Profile"/> */}
      {isLoading ? (
        <div className="loader-box w-100 d-flex justify-content-center align-items-center">
          <div className="loader-1"></div>
        </div>
      ) : (
        <Container fluid={true}>
          <div className="edit-profile">
            <Row>
              <h2 className="text-center mt-5 mb-5">Change Password</h2>
              <Col lg="12">
                <Form
                  className="card"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Container>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="current_password"
                          >
                            Current Password
                          </Label>
                          <Input
                            className="form-control border-secondary"
                            data-testid="profile_update_current_password"
                            type="text"
                            id="current_password"
                            name="current_password"
                            onChange={handlePassChange}
                            defaultValue={passData?.current_password}
                          />
                          {serverSideErrors &&
                            (!serverSideErrors?.current_password ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.current_password[0]}
                              </div>
                            ))}
                          {!serverSideErrors &&
                            errors &&
                            errors?.current_password && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors?.current_password}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label className="form-label" htmlFor="password">
                            Password
                          </Label>
                          <Input
                            data-testid="profile_update_password"
                            className="form-control border-secondary"
                            type="text"
                            id="password"
                            name="password"
                            onChange={handlePassChange}
                            defaultValue={passData?.password}
                          />
                          {serverSideErrors &&
                            (!serverSideErrors?.password ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.password[0]}
                              </div>
                            ))}
                          {!serverSideErrors && errors && errors?.password && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors?.password}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="password_confirmation"
                          >
                            Password Confirmation
                          </Label>
                          <Input
                            data-testid="profile_update_password_confirmation"
                            className="form-control border-secondary"
                            type="text"
                            id="password_confirmation"
                            name="password_confirmation"
                            onChange={handlePassChange}
                            defaultValue={passData?.password_confirmation}
                          />
                          {serverSideErrors &&
                            (!serverSideErrors?.password_confirmation ? (
                              <div
                                className="valid-feedback"
                                style={{ display: "block" }}
                              >
                                Looks good!
                              </div>
                            ) : (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {serverSideErrors?.password_confirmation[0]}
                              </div>
                            ))}
                          {!serverSideErrors &&
                            errors &&
                            errors?.password_confirmation && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors?.password_confirmation}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="w-100 d-flex justify-content-end ">
                      <Button color="primary" onClick={onChangePassword}>
                        Change Password
                      </Button>
                    </div>
                  </Container>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default ChangePass;
