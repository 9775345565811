import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { apiClient } from '../../utils/apiClient';
import { BACKEND_API } from '../../utils/backend';


const Resetpwd = (props) => {
  const [data, setData] = useState({
    email: ""
  });

  const onSubmit = () => {
    apiClient().post(`${BACKEND_API}/forgetpassword`, data).then(res => {
      console.log({res})
      SweetAlert.fire({ title: "Success", text: `${res.data?.message.charAt(0).toUpperCase()}${res.data?.message.slice(1)}`, icon: "success" });
    }).catch(error => {
      SweetAlert.fire({ title: "Failed", text: `${error?.response?.data?.message.charAt(0).toUpperCase()}${error?.response?.data?.message.slice(1)}`, icon: "error" });
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }
  return (
    <div className="page-wrapper">
      <Container fluid={true}>
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12" className="p-0">
              <Link to={'/login'}>Go Back</Link>
              <div className="auth-innerright auth-minibox">
                <div className="authentication-box auth-minibox1">
                  <div className="text-center">
                    <img width={'100px'} src={require("../../assets/images/logo/business-logo.png")} alt="" />
                  </div>
                  <Card className="mt-4 p-4">
                    <Form className="theme-form" onSubmit={(e) => { e.preventDefault() }}>
                      <h5 className="f-16 mb-3 f-w-600 text-center">Reset Your Password</h5>
                      <FormGroup>
                        <Input className="form-control border-secondary" name='email' onChange={handleChange} type="email" placeholder='Your Email' />
                      </FormGroup>

                      <Col sm='12' className='w-100'>
                        <Button onClick={onSubmit} className='w-100' color="primary">Reset</Button>
                      </Col>
                    </Form>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Resetpwd;