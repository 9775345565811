import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getGarageOwnerAllDashboardData = async () => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/${garage_id}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}

export const getDataCollectorAllDashboardData = async () => {
    return await apiClient().get(`${BACKEND_API}/v1.0/data-collector-dashboard`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getSuperAdminAllDashboardData = async () => {
    return await apiClient().get(`${BACKEND_API}/v1.0/superadmin-dashboard`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}

export const getJobsInYourArea = async (start_date, end_date) => {
    const garage_id = localStorage.getItem('garageId')

    let dateQuerry = ``
    if (start_date && end_date) {
        dateQuerry = `?start_date=2019-06-29&end_date=2019-06-29`
    }
    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/jobs-in-area/${garage_id}${dateQuerry}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getJobApplications = async () => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/jobs-application/${garage_id}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getWinedJobApplications = async () => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/winned-jobs-application/${garage_id}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getCompletedBookings = async () => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/completed-bookings/${garage_id}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getUpcommingJobs = async (duration) => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/upcoming-jobs/${garage_id}/${duration}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}
export const getExpiredAfiliation = async (duration) => {
    const garage_id = localStorage.getItem('garageId')

    return await apiClient().get(`${BACKEND_API}/v1.0/garage-owner-dashboard/expiring-affiliations/${garage_id}/${duration}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error
        })
}