import axios from "axios";

export const apiClient = () => {
  const token = localStorage.getItem("token");

  const instance = axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return instance;
};

export const apiClientWithToken = (token) => {
  const instance = axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return instance;
};
