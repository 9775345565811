import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import Router from './route'
import store from './store'

const App = () => {
    return (
        <Fragment>
            <Provider store={store}>
                <Router />
            </Provider>
        </Fragment>
    )
}

export default App;