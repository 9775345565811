import React from "react";
import { Input } from "reactstrap";

const currencyOptions = [
  { value: "", title: "Select Currency" },
  { value: "USD", title: "US dollar" },
  { value: "EUR", title: "Euro" },
  { value: "JPY", title: "Japanese yen" },
  { value: "GBP", title: "Pound sterling" },
  { value: "AED", title: "United Arab Emirates dirham" },
  { value: "AFN", title: "Afghan afghani" },
  { value: "ALL", title: "Albanian lek" },
  { value: "AMD", title: "Armenian dram" },
  { value: "ANG", title: "Netherlands Antillean guilder" },
  { value: "AOA", title: "Angolan kwanza" },
  { value: "ARS", title: "Argentine peso" },
  { value: "AUD", title: "Australian dollar" },
  { value: "AWG", title: "Aruban florin" },
  { value: "AZN", title: "Azerbaijani manat" },
  { value: "BAM", title: "Bosnia and Herzegovina convertible mark" },
  { value: "BBD", title: "Barbadian dollar" },
  { value: "BDT", title: "Bangladeshi taka" },
  { value: "BGN", title: "Bulgarian lev" },
  { value: "BHD", title: "Bahraini dinar" },
  { value: "BIF", title: "Burundian franc" },
  { value: "BMD", title: "Bermudian dollar" },
  { value: "BND", title: "Brunei dollar" },
  { value: "BOB", title: "Bolivian boliviano" },
  { value: "BRL", title: "Brazilian real" },
  { value: "BSD", title: "Bahamian dollar" },
  { value: "BTN", title: "Bhutanese ngultrum" },
  { value: "BWP", title: "Botswana pula" },
  { value: "BYN", title: "Belarusian ruble" },
  { value: "BZD", title: "Belize dollar" },
  { value: "CAD", title: "Canadian dollar" },
  { value: "CDF", title: "Congolese franc" },
  { value: "CHF", title: "Swiss franc" },
  { value: "CLP", title: "Chilean peso" },
  { value: "CNY", title: "Chinese yuan" },
  { value: "COP", title: "Colombian peso" },
  { value: "CRC", title: "Costa Rican colón" },
  { value: "CUC", title: "Cuban convertible peso" },
  { value: "CUP", title: "Cuban peso" },
  { value: "CVE", title: "Cape Verdean escudo" },
  { value: "CZK", title: "Czech koruna" },
  { value: "DJF", title: "Djiboutian franc" },
  { value: "DKK", title: "Danish krone" },
  { value: "DOP", title: "Dominican peso" },
  { value: "DZD", title: "Algerian dinar" },
  { value: "EGP", title: "Egyptian pound" },
  { value: "ERN", title: "Eritrean nakfa" },
  { value: "ETB", title: "Ethiopian birr" },
  { value: "EUR", title: "EURO" },
  { value: "FJD", title: "Fijian dollar" },
  { value: "FKP", title: "Falkland Islands pound" },
  { value: "GBP", title: "British pound" },
  { value: "GEL", title: "Georgian lari" },
  { value: "GGP", title: "Guernsey pound" },
  { value: "GHS", title: "Ghanaian cedi" },
  { value: "GIP", title: "Gibraltar pound" },
  { value: "GMD", title: "Gambian dalasi" },
  { value: "GNF", title: "Guinean franc" },
  { value: "GTQ", title: "Guatemalan quetzal" },
  { value: "GYD", title: "Guyanese dollar" },
  { value: "HKD", title: "Hong Kong dollar" },
  { value: "HNL", title: "Honduran lempira" },
  { value: "HRK", title: "Croatian kuna" },
  { value: "HTG", title: "Haitian gourde" },
  { value: "HUF", title: "Hungarian forint" },
  { value: "IDR", title: "Indonesian rupiah" },
  { value: "ILS", title: "Israeli new shekel" },
  { value: "IMP", title: "Manx pound" },
  { value: "INR", title: "Indian rupee" },
  { value: "IQD", title: "Iraqi dinar" },
  { value: "IRR", title: "Iranian rial" },
  { value: "ISK", title: "Icelandic króna" },
  { value: "JEP", title: "Jersey pound" },
  { value: "JMD", title: "Jamaican dollar" },
  { value: "JOD", title: "Jordanian dinar" },
  { value: "JPY", title: "Japanese yen" },
  { value: "KES", title: "Kenyan shilling" },
  { value: "KGS", title: "Kyrgyzstani som" },
  { value: "KHR", title: "Cambodian riel" },
  { value: "KID", title: "Kiribati dollar" },
  { value: "KMF", title: "Comorian franc" },
  { value: "KPW", title: "North Korean won" },
  { value: "KRW", title: "South Korean won" },
  { value: "KWD", title: "Kuwaiti dinar" },
  { value: "KYD", title: "Cayman Islands dollar" },
  { value: "KZT", title: "Kazakhstani tenge" },
  { value: "LAK", title: "Lao kip" },
  { value: "LBP", title: "Lebanese pound" },
  { value: "LKR", title: "Sri Lankan rupee" },
  { value: "LRD", title: "Liberian dollar" },
  { value: "LSL", title: "Lesotho loti" },
  { value: "LYD", title: "Libyan dinar" },
  { value: "MAD", title: "Moroccan dirham" },
  { value: "MDL", title: "Moldovan leu" },
  { value: "MGA", title: "Malagasy ariary" },
  { value: "MKD", title: "Macedonian denar" },
  { value: "MMK", title: "Burmese kyat" },
  { value: "MNT", title: "Mongolian tögrög" },
  { value: "MOP", title: "Macanese pataca" },
  { value: "MRU", title: "Mauritanian ouguiya" },
  { value: "MUR", title: "Mauritian rupee" },
  { value: "MVR", title: "Maldivian rufiyaa" },
  { value: "MWK", title: "Malawian kwacha" },
  { value: "MXN", title: "Mexican peso" },
  { value: "MYR", title: "Malaysian ringgit" },
  { value: "MZN", title: "Mozambican metical" },
  { value: "NAD", title: "Namibian dollar" },
  { value: "NGN", title: "Nigerian naira" },
  { value: "NIO", title: "Nicaraguan córdoba" },
  { value: "NOK", title: "Norwegian krone" },
  { value: "NPR", title: "Nepalese rupee" },
  { value: "NZD", title: "New Zealand dollar" },
  { value: "OMR", title: "Omani rial" },
  { value: "PAB", title: "Panamanian balboa" },
  { value: "PEN", title: "Peruvian sol" },
  { value: "PGK", title: "Papua New Guinean kina" },
  { value: "PHP", title: "Philippine peso" },
  { value: "PKR", title: "Pakistani rupee" },
  { value: "PLN", title: "Polish złoty" },
  { value: "PRB", title: "Transnistrian ruble" },
  { value: "PYG", title: "Paraguayan guaraní" },
  { value: "QAR", title: "Qatari riyal" },
  { value: "RON", title: "Romanian leu" },
  { value: "RSD", title: "Serbian dinar" },
  { value: "RUB", title: "Russian ruble" },
  { value: "RWF", title: "Rwandan franc" },
  { value: "SAR", title: "Saudi riyal" },
  { value: "SEK", title: "Swedish krona" },
  { value: "SGD", title: "Singapore dollar" },
  { value: "SHP", title: "Saint Helena pound" },
  { value: "SLL", title: "Sierra Leonean leone" },
  { value: "SLS", title: "Somaliland shilling" },
  { value: "SOS", title: "Somali shilling" },
  { value: "SRD", title: "Surinamese dollar" },
  { value: "SSP", title: "South Sudanese pound" },
  { value: "STN", title: "São Tomé and Príncipe dobra" },
  { value: "SYP", title: "Syrian pound" },
  { value: "SZL", title: "Swazi lilangeni" },
  { value: "THB", title: "Thai baht" },
  { value: "TJS", title: "Tajikistani somoni" },
  { value: "TMT", title: "Turkmenistan manat" },
  { value: "TND", title: "Tunisian dinar" },
  { value: "TOP", title: "Tongan paʻanga" },
  { value: "TRY", title: "Turkish lira" },
  { value: "TTD", title: "Trinidad and Tobago dollar" },
  { value: "TVD", title: "Tuvaluan dollar" },
  { value: "TWD", title: "New Taiwan dollar" },
  { value: "TZS", title: "Tanzanian shilling" },
  { value: "UAH", title: "Ukrainian hryvnia" },
  { value: "UGX", title: "Ugandan shilling" },
  { value: "USD", title: "United States dollar" },
  { value: "UYU", title: "Uruguayan peso" },
  { value: "UZS", title: "Uzbekistani soʻm" },
  { value: "VES", title: "Venezuelan bolívar soberano" },
  { value: "VND", title: "Vietnamese đồng" },
  { value: "VUV", title: "Vanuatu vatu" },
  { value: "WST", title: "Samoan tālā" },
  { value: "XAF", title: "Central African CFA franc" },
  { value: "XCD", title: "Eastern Caribbean dollar" },
  { value: "XOF", title: "West African CFA franc" },
  { value: "XPF", title: "CFP franc" },
  { value: "ZAR", title: "South African rand" },
  { value: "ZMW", title: "Zambian kwacha" },
  { value: "ZWB", title: "Zimbabwean bonds" },
];

export default function CurrencySelector({
  onChange,
  value,
  defaultValue,
  name,
  className,
  data_testid,
}) {
  return (
    <Input
      data-testid={data_testid}
      className={className}
      type="select"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      id="currencyList"
      name={name}
    >
      {currencyOptions.map((option) => (
        <option key={option.value} value={option.value} title={option.title}>
          {option.value} ({option.title})
        </option>
      ))}
    </Input>
  );
}
