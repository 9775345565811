import React, { useEffect, useState } from "react";
import ApexChart from 'react-apexcharts';
import { BiCertification, BiLoader, BiNetworkChart } from "react-icons/bi";
import { BsBookmarkCheck } from "react-icons/bs";
import { FiInbox } from "react-icons/fi";
import { TbFileCertificate } from "react-icons/tb";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getGarageOwnerAllDashboardData } from "../../api/dashboard";
import CustomLoadingSpiner from "../../components/CustomLoadingSpiner/CustomLoadingSpiner";


export default function GarageOwnerDashboard() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    getGarageOwnerAllDashboardData() // FETCHING ALL DATA
      .then(data => {
        setData(data);
        setIsLoading(false)
      })
  }, [])

  const structuredData = [
    {
      id: 1,
      title: "New Jobs in Your Area",
      total: data?.pre_bookings?.total_count,
      monthly: data?.pre_bookings?.this_month_data_count,
      weekly: data?.pre_bookings?.this_week_data_count,
      class: "primary",
      Icon: TbFileCertificate
    },
    {
      id: 2,
      title: "Job Applications",
      total: data?.applied_jobs?.total_count,
      monthly: data?.applied_jobs?.this_month_data_count,
      weekly: data?.applied_jobs?.this_week_data_count,
      class: "warning",
      Icon: FiInbox
    },
    {
      id: 3,
      title: "Total Job Won",
      total: data?.winned_jobs?.total_data_count,
      monthly: data?.winned_jobs?.this_month_data_count,
      weekly: data?.winned_jobs?.this_week_data_count,
      class: "info",
      Icon: BiCertification
    },
    {
      id: 4,
      title: "Total completed Bookings",
      total: data?.completed_bookings?.total_data_count,
      monthly: data?.completed_bookings?.this_month_data_count,
      weekly: data?.completed_bookings?.this_week_data_count,
      class: "success",
      Icon: BsBookmarkCheck
    },
    {
      id: 5,
      title: "Upcoming Booking ",
      total: data?.upcoming_jobs?.total_data_count,
      monthly: data?.upcoming_jobs?.this_month_data_count,
      weekly: data?.upcoming_jobs?.this_week_data_count,
      class: "warning",
      Icon: BiLoader
    },
    {
      id: 6,
      title: "Affiliation Expiry",
      total: data?.affiliation_expirings?.total_data_count,
      monthly: data?.affiliation_expirings?.this_month_data_count,
      weekly: data?.affiliation_expirings?.this_week_data_count,
      class: "secondary",
      Icon: BiNetworkChart
    },
  ]

  const sassSmallChartData = {
    series: [{
      data: [200, 400, 300, 100, 300, 100, 250]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '30%',
        }
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
        padding: {
          top: -15,
          bottom: -10,
          left: -10,
          right: 15

        }
      },
      colors: ['#ffffff'],
      xaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false
        }
      }
    }
  }



  return (
    <>
      {isLoading ? <CustomLoadingSpiner />
        :
        <>
          <CardHeader className="mb-5">
            <div className='d-flex justify-content-start align-items-center'>
              <div>
                <h5>Garage Owner Dashboard</h5><span> Explore Your Data </span>
              </div>
            </div>
          </CardHeader>
          <Row className="sass-top-cards">
            {structuredData.map(data => (
              <Col xs="12" sm="6" md="4" lg="4" key={data.id}>
                <Card className="sass-widgets o-hidden shadow shadow-showcase">
                  <CardBody className="p-0 h-100">
                    <div className="media d-flex position-relative">
                      <div className="media-body flex-grow-1">
                        <p className="f-w-600">{data.title}</p>
                        <h2 className="f-w-600 mb-0">{data.total}</h2>
                      </div>
                      {/* <div className="setting-dot d-inline-block">
                        <div className={`setting-bg setting-bg-${data.class}`}>
                          <i className={`fa fa-spin fa-cog font-${data.class}`}></i>
                        </div>
                      </div> */}
                      <data.Icon className={`position-absolute end-0 top-0 font-${data.class}`} style={{
                        fontSize: '100px',
                        opacity: '0.2'
                      }} />
                    </div>

                    <div className={`bg-gradient-${data.class} footer-shape`}>
                      <div className="sass-footer">
                        {/* <p className="mb-0 d-inline-block me-3">{data.monthly}</p><span><span className="d-inline-block"><i className="fa fa-sort-up me-4"></i></span></span> */}
                        {/* WEEKLY DATA  */}
                        <p className={`mb-0 d-inline-block ps-4 me-3`}>{data.weekly}</p>

                        {/* ARROW  */}
                        <span className="down-arrow-align">
                          <span className="d-inline-block">
                            <i className="fa fa-sort-down"></i>
                          </span>
                        </span>

                        {/* BAR CHART  */}
                        <div className="small-sass">
                          <ApexChart options={sassSmallChartData.options} series={sassSmallChartData.series} type='bar' width={80} height={60} />
                        </div>
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      }
    </>

  );
}
