import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";

const SubServiceForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  service,
  setIsOpen,
  subServiceUpdateData,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { register, handleSubmit, setValue, errors } = useForm();
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "update") {
      const fields = ["id", "name", "description", "is_fixed_price"];
      fields.forEach((field) => {
        setValue(field, subServiceUpdateData[field]);
      });
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      data.is_fixed_price = parseInt(data.is_fixed_price);
      apiClient()
        .put(`${BACKEND_API}/v1.0/sub-services`, data)
        .then((response) => {
          setLoading(false);
          if (response?.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Sub Service Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status === 422) {
            setServerSideErrors(error?.response?.data?.errors);
            SweetAlert.fire({
              title: error?.response?.data?.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error?.response?.data?.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
    if (type === "create") {
      apiClient()
        .post(`${BACKEND_API}/v1.0/sub-services`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Sub Service Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <Row>
      <Col>
        <Container>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            {loading ? (
              <CustomLoadingSpiner />
            ) : (
              <div className="form-row mb-2">
                {type === "update" && (
                  <input
                    className="form-control border-secondary"
                    name="id"
                    type="hidden"
                    {...register("id", { required: false })}
                  />
                )}
                <Col md="12 mb-3">
                  <p className="text-uppercase">Service : {service?.name}</p>
                  <input
                    id="service_id"
                    className="form-control border-secondary"
                    name="service_id"
                    type="hidden"
                    defaultValue={service?.id}
                    {...register("service_id", { required: false })}
                  />
                </Col>

                <Col md="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase" htmlFor="name">
                      Sub Service Name*
                    </Label>
                  )}
                  <input
                    id="name"
                    className="form-control border-secondary"
                    name="name"
                    type="text"
                    placeholder="Sub Service Name*"
                    {...register("name", { required: false })}
                  />

                  <span>{errors?.name && "Please provide name"}</span>

                  {serverSideErrors &&
                    (!serverSideErrors?.name ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.name[0]}
                      </div>
                    ))}
                </Col>

                <Col md="12 mb-3">
                  {type === "update" && (
                    <Label className="text-uppercase" htmlFor="description">
                      description
                    </Label>
                  )}
                  <textarea
                    rows={5}
                    id="description"
                    placeholder="Description"
                    className="form-control border-secondary"
                    name="description"
                    type="textarea"
                    {...register("description", { required: false })}
                  />
                  <span>
                    {errors?.description && "Please provide description"}
                  </span>
                  {serverSideErrors &&
                    (!serverSideErrors?.description ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        Looks good!
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {serverSideErrors?.description[0]}
                      </div>
                    ))}

                  {/* {type === "update" && <Label className='text-uppercase mt-3' htmlFor="is_fixed_price">Fixed price*</Label>}
                    <select
                      id='is_fixed_price'
                      className={`form-control border-secondary ${type !== "update" && "mt-2"}`}
                      name="is_fixed_price"
                      type='number'
                      {...register('is_fixed_price', { required: false })}
                    >
                      <option value="">Is The Price Is Fixed?*</option>
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </select>
                    <span>{errors?.is_fixed_price && 'Please provide price type'}</span>

                    {serverSideErrors && (
                      !serverSideErrors?.is_fixed_price ? (
                        <div className="valid-feedback" style={{ display: "block" }}>Looks good!</div>
                      ) : (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {serverSideErrors?.is_fixed_price[0]}
                        </div>
                      ))} */}
                </Col>

                {user.roles[0]?.name === "garage_owner" && (
                  <Col sm={"12"}>
                    <input className="form-control border-secondary" />
                  </Col>
                )}
              </div>
            )}
            <Row className="mb-3">
              <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                <Button color="primary" className="w-100 py-2 rounded-3">
                  Submit
                </Button>
                <Button
                  className="py-2 w-100 cancel-button rounded-3"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  color=""
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
};

export default SubServiceForm;
