import React, { useEffect, useState } from "react";
import ApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getSuperAdminAllDashboardData } from "../../api/dashboard";


export default function SuperAdminDashboard() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    getSuperAdminAllDashboardData() // FETCHING ALL DATA
      .then(data => {
        console.log(data);
        setData(data);
        setIsLoading(false)
      })
  }, []);
  const sassTopCard = [
    {
      id: 1,
      title: "Total Jobs",
      total: data?.overall_jobs?.total_data_count,
      monthly: data?.overall_jobs?.this_month_data_count,
      weekly: data?.overall_jobs?.this_week_data_count,
      class: "primary",
    },
    {
      id: 2,
      title: "Total Garages",
      total: data?.garages?.total_data_count,
      monthly: data?.garages?.this_month_data_count,
      weekly: data?.garages?.this_week_data_count,
      class: "warning",
    },
    {
      id: 3,
      title: "Total Bookings",
      total: data?.overall_bookings?.total_data_count,
      monthly: data?.overall_bookings?.this_month_data_count,
      weekly: data?.overall_bookings?.this_week_data_count,
      class: "info",
    },
    {
      id: 4,
      title: "Total Customer Jobs",
      total: data?.overall_customer_jobs?.total_data_count,
      monthly: data?.overall_customer_jobs?.this_month_data_count,
      weekly: data?.overall_customer_jobs?.this_week_data_count,
      class: "success",
    },
    {
      id: 5,
      title: "Total Fuel Stations ",
      total: data?.fuel_stations?.total_data_count,
      monthly: data?.fuel_stations?.this_month_data_count,
      weekly: data?.fuel_stations?.this_week_data_count,
      class: "warning",
    },
    {
      id: 6,
      title: "Total Customers",
      total: data?.customers?.total_data_count,
      monthly: data?.customers?.this_month_data_count,
      weekly: data?.customers?.this_week_data_count,
      class: "secondary",
    },
    {
      id: 7,
      title: "Total Services",
      total: data?.overall_services?.total_data_count,
      monthly: data?.overall_services?.this_month_data_count,
      weekly: data?.overall_services?.this_week_data_count,
      class: "secondary",
    },
  ]
  const sassSmallChartData = {
    series: [{
      data: [200, 400, 300, 100, 250]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
        }
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
        padding: {
          top: -15,
          bottom: -10,
          left: -10,
          right: 15

        }
      },
      colors: ['#ffffff'],
      xaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false
        }
      }
    }
  }
  return (
    <>
      <CardHeader className="mb-5">
        <div className='d-flex justify-content-start align-items-center'>
          <div>
            <h5>Superadmin Dashboard</h5><span> Explore Your Data </span>
          </div>
        </div>
      </CardHeader>
      <Row className="sass-top-cards">
        {sassTopCard.map(data => (
          <Col xs="12" sm="6" md="4" lg="4" key={data.id}>
            <Card className="sass-widgets o-hidden shadow shadow-showcase">
              <CardBody className="p-0 h-100">
                <div className="media d-flex">
                  <div className="media-body flex-grow-1">
                    <p className="f-w-600">{data.title}</p>
                    <h2 className="f-w-600 mb-0">{data.total}</h2>
                  </div>
                  <div className="setting-dot d-inline-block">
                    <div className={`setting-bg setting-bg-${data.class}`}>
                      <i className={`fa fa-spin fa-cog font-${data.class}`}></i>
                    </div>
                  </div>
                </div>

                <div className={`bg-gradient-${data.class} footer-shape`}>
                  <div className="sass-footer">
                    <p className="mb-0 d-inline-block me-3">{data.monthly}</p><span><span className="d-inline-block"><i className="fa fa-sort-up me-4"></i></span></span>
                    <p className={`mb-0 d-inline-block b-l-${data.class} ps-4 me-3`}>{data.weekly}</p><span className="down-arrow-align"><span className="d-inline-block"><i className="fa fa-sort-down"></i></span></span>
                    <div className="small-sass">
                      <ApexChart options={sassSmallChartData.options} series={sassSmallChartData.series} type='bar' width={60} height={45} />
                    </div>
                  </div>
                </div>

              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
