import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, Container, Input, Label, Row } from "reactstrap";

const MakeAndModelForm = (props) => {
  const [collapseId, setCollapseId] = useState([
    { id: undefined, status: false },
  ]);

  const { allTick, setAllTick, allTickMake, setAllTickMake } = props;

  useEffect(() => {
    props.data.map((el, index) => {
      setCollapseId(
        el?.automobile_makes.map((make, makeIndex) => {
          return { id: makeIndex, status: false };
        })
      );
    });
  }, []);

  if (props.currentStep !== 4) {
    return null;
  }

  return (
    <>
      {props.data.map((el, index) => {
        return (
          <div className="w-100" key={index}>
            {/* automobile_makes */}
            {props.pathname !== "/registration" && (
              <div className="mt-5">
                <h2 className="text-center">
                  Which of these make do this garage offer?
                </h2>
                {props.serverSideErrors &&
                  (!props.serverSideErrors["service.0.automobile_makes"] ? (
                    <div className="valid-feedback text-center d-block">
                      {"Looks good!"}
                    </div>
                  ) : (
                    <div className="invalid-feedback text-center d-block">
                      {props.serverSideErrors["service.0.automobile_makes"][0]}{" "}
                      make
                    </div>
                  ))}
                <div className="d-flex justify-content-center align-items-center my-3">
                  <div className="w-50 w-xm-100 h-25 border border-primary px-2 pt-2 d-flex align-item-center rounded">
                    <Label className="d-block" for={`category-all-select`}>
                      <Input
                        className="checkbox_animated"
                        id={`category-all-select`}
                        type="checkbox"
                        name={`category-all-select`}
                        checked={allTickMake}
                        onChange={props.handleMakeChangeAll}
                      />{" "}
                      Tick/untick all Makes
                    </Label>
                  </div>
                </div>

                <div>
                  <Row>
                    {el.automobile_makes.map((make, makeIndex) => {
                      return (
                        <Col md={3}>
                          <Accordion key={makeIndex} className="mb-2">
                            <Accordion.Item eventKey={`${makeIndex}`}>
                              <Container className="d-flex align-items-center px-0">
                                <Input
                                  className="checkbox_animated mx-3"
                                  id={`category-${index}-make-${makeIndex}`}
                                  value={el.automobile_makes[makeIndex].checked}
                                  checked={
                                    el.automobile_makes[makeIndex].checked ||
                                    false ||
                                    make?.models.filter(
                                      (i) => i.checked === true
                                    ).length > 0
                                  }
                                  type="checkbox"
                                  name={`category-${index}-make-${makeIndex}`}
                                  onChange={props.handleMakeChange}
                                />
                                <Accordion.Header className="w-100">
                                  <Label
                                    className="card-title"
                                    for={`category-${index}-make-${makeIndex}`}
                                  >
                                    {make.name}
                                  </Label>
                                </Accordion.Header>
                              </Container>

                              <Accordion.Body>
                                {make.models.map((model, modelIndex) => {
                                  return (
                                    <Col md="12" key={modelIndex}>
                                      <Label
                                        className="d-block pl-4"
                                        for="chk-ani"
                                      >
                                        <Input
                                          className="checkbox_animated"
                                          id={`category-${index}-make-${makeIndex}-model-${modelIndex}`}
                                          value={`category-${index}-make-${makeIndex}-model-${modelIndex}`}
                                          type="checkbox"
                                          name={`category-${index}-make-${makeIndex}-model-${modelIndex}`}
                                          checked={
                                            el.automobile_makes[makeIndex]
                                              .models[modelIndex].checked ||
                                            false
                                          }
                                          onChange={props.handleModelChange}
                                        />
                                        {model.name}
                                      </Label>
                                    </Col>
                                  );
                                })}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MakeAndModelForm;
