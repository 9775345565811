import moment from "moment";
import { useEffect, useState } from "react";
import { Bell, ThumbsUp } from "react-feather";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  deleteNotification,
  getNotification,
  getNotificationForSuperAdmin,
  putNotification,
} from "../../api/notification";
import useOutsideDropdown from "../../services/useOutsideDropdown";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";

const BellDropdown = () => {
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideDropdown(false);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [notificationLength, setNotificationLength] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const garageId = JSON.parse(localStorage.getItem("garageId"));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (
      user?.roles[0]?.name === "superadmin" ||
      user?.roles[0]?.name === "data_collector"
    ) {
      getNotificationForSuperAdmin(garageId, perPage, pageNo)
        .then((res) => {
          setNotifications(res?.data);
          setNotificationLength(res?.total_unread_messages);
        })
        .catch((err) => {
          toast.error("Notification have a error!");
        });
    } else {
      getNotification(garageId, perPage, pageNo)
        .then((res) => {
          setNotifications(res?.data);
          setNotificationLength(res?.total_unread_messages);
        })
        .catch((err) => {
          toast.error("Notification have a error!");
        });
    }
  }, [location, garageId, perPage, pageNo]);

  const handleRedirection = (type, link) => {
    console.log({ type, link });
    const splitedLinkArray = link.split("/");
    console.log(splitedLinkArray);
    const customer_id = splitedLinkArray[1];
    const booking_id = splitedLinkArray[2];
    setIsComponentVisible(!isComponentVisible);
    switch (type) {
      case "booking_created_by_client":
        navigate(
          `/bookings/list?customer_id=${customer_id}&&booking_id=${booking_id}`
        );
        break;
      default:
        break;
    }
  };
  const [isHovered, setIsHovered] = useState(null);

  // Function to handle mouse enter (hover) event
  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  // Function to handle mouse leave (unhover) event
  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  const fetchNotification = () => {
    if (
      user?.roles[0]?.name === "superadmin" ||
      user?.roles[0]?.name === "data_collector"
    ) {
      getNotificationForSuperAdmin(garageId, perPage, pageNo)
        .then((res) => {
          setNotifications(res?.data);
          setNotificationLength(res?.total_unread_messages);
        })
        .catch((err) => {
          toast.error("Notification have a error!");
        });
    } else {
      getNotification(garageId, perPage, pageNo)
        .then((res) => {
          setNotifications(res?.data);
          setNotificationLength(res?.total_unread_messages);
        })
        .catch((err) => {
          toast.error("Notification have a error!");
        });
    }
  };
  // Function to handle the delete button click
  const handleDelete = (id) => {
    deleteNotification(id).then((res) => {
      fetchNotification();
    });
  };
  const handleRedirectNotification = (data) => {
    putNotification({ notification_ids: [data?.id] }).then((res) => {
      console.log({ NotiData: data });
      setIsComponentVisible(false);
      fetchNotification(perPage, pageNo);
      if (data?.booking_id !== null) {
        navigate(`/bookings/list`);
      } else if (data?.pre_booking_id !== null) {
        navigate(`/garages/job/quotes/list`);
      } else if (data?.job_id !== null) {
        navigate(`/garages/job/list`);
      }
    });
  };

  return (
    <>
      <div
        ref={ref}
        className={`dropdown notification-menu  ${
          isComponentVisible && "show"
        }`}
      >
        <span
          className="position-relative"
          role="button"
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);
          }}
        >
          <Bell />
          {notificationLength > 0 && (
            <span className="customNotification">
              {notificationLength > 99 ? "99+" : notificationLength}
            </span>
          )}
        </span>
        <div className={`p-0 dropdown-menu ${isComponentVisible && "show"} `}>
          <ul className="notification-dropdown">
            <li className="gradient-primary-1">
              <h6>Notification</h6>
              <span>{`You have ${notificationLength} unread notifications`}</span>
            </li>

            <div className="notificationContainer">
              {notifications.map((notification, index) => (
                <li
                  key={index}
                  role="button"
                  className={`${
                    notification?.status === "unread"
                      ? "bg-primary text-light"
                      : "bg-transparent text-dark"
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="media">
                    <div
                      onClick={() => handleRedirectNotification(notification)}
                      className="media-body"
                      style={{ textAlign: "left" }}
                    >
                      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>
                        {notification?.template_string}
                      </h6>
                      <p
                        className="mb-0 fst-italic text-muted"
                        style={{ fontSize: "10px", fontWeight: 200 }}
                      >
                        {moment(notification?.created_at)
                          .subtract(1, "days")
                          .calendar()}
                      </p>
                    </div>
                    {/* Delete button */}
                    <div onClick={() => handleDelete(notification?.id)}>
                      <AiOutlineDelete style={{ color: "red" }} />
                    </div>
                  </div>
                </li>
              ))}
            </div>
            <li className="bg-light txt-dark h-100">
              <div className="d-flex justify-content-between align-items-center  h-100">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setPageNo(pageNo !== 0 ? pageNo - 1 : 1)}
                >
                  Prev
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setPageNo(notifications.length > 0 ? pageNo + 1 : pageNo)
                  }
                >
                  Next
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BellDropdown;
