import React, { Fragment } from 'react';
import { Col, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import styles from "./UserStep.module.css";

const
    Formstep1 = (props) => {
        if (props.currentStep !== 1) {
            return null;
        }
        return (
            <Fragment>
                <div className="form-row mb-2">
                    <Input className="form-control" name="id" type="hidden" />

                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="first_Name">First Name*</Label>
                        <Input
                            className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.first_Name"] ? "border-danger" : "border-secondary"}`}
                            name="first_Name"
                            type="text"

                            onChange={props.handleChange}
                            value={props.data.first_Name}
                        />

                        {props.serverSideErrors && (
                            !props.serverSideErrors["user.first_Name"] ? (
                                <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                            ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.first_Name"][0]}</div>)
                        )}

                    </Col>
                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="last_Name">Last Name*</Label>
                        <Input
                            className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.last_Name"] ? "border-danger" : "border-secondary"}`}
                            name="last_Name"
                            type="text"
                            onChange={props.handleChange}
                            value={props.data.last_Name}
                        />

                        {props.serverSideErrors ? (
                            !props.serverSideErrors["user.last_Name"] ? (
                                <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                            ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.last_Name"][0]}</div>)
                        )
                            : (null)}
                    </Col>
                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="validationCustomUsername">Email*</Label>
                        <InputGroup>
                            <InputGroupText>{"@"}</InputGroupText>
                            <Input
                                className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.email"] ? "border-danger" : "border-secondary"}`}
                                name="email"
                                type="text"
                                onChange={props.handleChange}
                                value={props.data.email}
                            />

                            {props.serverSideErrors ? (
                                !props.serverSideErrors["user.email"] ? (
                                    <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                                ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.email"][0]}</div>)
                            )
                                : (null)}
                        </InputGroup>
                    </Col>
                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="phone">Phone Number*</Label>
                        <Input
                            className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.phone"] ? "border-danger" : "border-secondary"}`}
                            name="phone"
                            type="number"
                            onChange={props.handleChange}
                            value={props.data.phone}
                        />

                        {props.serverSideErrors ? (
                            !props.serverSideErrors["user.phone"] ? (
                                <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                            ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.phone"][0]}</div>)
                        )
                            : (null)}
                    </Col>
                </div>

                <div className="form-row mb-2">
                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="password">
                            Password* <small><small>( password must have minimum 8 character )</small></small>
                        </Label>
                        <Input
                            className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.password"] ? "border-danger" : "border-secondary"}`}
                            name="password"
                            type="password"
                            onChange={props.handleChange}
                            value={props.data.password}
                        />

                        {props.serverSideErrors && (
                            !props.serverSideErrors["user.password"] ? (
                                <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                            ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.password"][0]}</div>)
                        )}
                    </Col>
                    <Col md="6 mb-3" xs="12">
                        <Label className='' htmlFor="password_confirmation">Confirm Password*</Label>
                        <Input
                            className={`${styles.numberField} form-control border ${props.serverSideErrors && props.serverSideErrors["user.password"] ? "border-danger" : "border-secondary"}`}
                            id="password_confirmation"
                            name="password_confirmation"
                            type="password"
                            onChange={props.handleChange}
                            value={props.data.password_confirmation}
                        />

                        {props.serverSideErrors ? (
                            !props.serverSideErrors["user.password"] ? (
                                <div className="valid-feedback" style={{ display: "block" }}>{"Looks good!"}</div>
                            ) : (<div className="invalid-feedback" style={{ display: "block" }}>{props.serverSideErrors["user.password"][0]}</div>)
                        )
                            : (null)}
                    </Col>
                </div>
            </Fragment>
        )
    }

export default Formstep1