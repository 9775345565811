/* eslint-disable import/no-anonymous-default-export */
import { AUTH_STORE_USER_DATA } from "../actionTypes";

const INIT_STATE = {
    userData: {},
    loading: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTH_STORE_USER_DATA:
            return { ...state, loading: false, userData: action };
        default: return { ...state };
    }
}