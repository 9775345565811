import React from "react";
import { Button, Input } from "reactstrap";

export default function Status({ data, toggleModal, handleStatusChange }) {
  return (
    <div className="d-flex flex-column gap-1 position-relative">
      <Button
        onClick={toggleModal}
        style={{
          position: "absolute",
          top: "-100px",
          right: 0,
          width: 30,
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
        className="position-absolute"
      >
        X
      </Button>
      {data?.status !== "active" && (
        <Button
          onClick={() => {
            toggleModal();
            handleStatusChange("active", data?.id);
          }}
          color="primary"
        >
          Active
        </Button>
      )}
      {data?.status !== "pending" && (
        <Button
          onClick={() => {
            toggleModal();
            handleStatusChange("pending", data?.id);
          }}
          color="warning"
        >
          Pending
        </Button>
      )}
      {data?.status !== "cancelled" && (
        <Button
          onClick={() => {
            handleStatusChange("cancelled", data?.id);
            toggleModal();
          }}
          color="danger"
        >
          Cancelled
        </Button>
      )}
      {data?.status !== "completed" && (
        <Button
          onClick={() => {
            toggleModal();
            handleStatusChange("completed", data?.id);
          }}
          color="success"
        >
          Completed
        </Button>
      )}
    </div>
  );
}
