import axios from "axios";
import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getOwnersAllGarages = async () => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/garages/by-garage-owner/all`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGaragesByGivenToken = async (token) => {
  return axios
    .get(`${BACKEND_API}/v1.0/garages/by-garage-owner/all`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const toggleGarageActivation = async (id) => {
  return apiClient()
    .put(`${BACKEND_API}/v1.0/garages/toggle-active`, {
      id: id,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
