import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  Row,
  Table,
} from "reactstrap";

import DatePicker from "react-datepicker";
import SweetAlert from "sweetalert2";

import { Link } from "react-router-dom";
import {
  EMAIL_TEMPLATE_UPDATE,
  EMAIL_TEMPLATE_VIEW,
} from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import { checkPermissions } from "../../../utils/helperFunctions";
import setLinksView from "../../../utils/pagination";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import TemplateView from "./TemplateView";
import { encryptionID } from "../../../utils/encription";

const EmailTemplateList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [userCreateModal, setUserCreateModal] = useState(false);
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/email-templates/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/email-templates/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  // modal
  const [templateViewData, setTemplateViewData] = useState(null);
  const [userViewModal, setUserViewModal] = useState(false);
  const userViewModaltoggle = () => setUserViewModal(!userViewModal);

  const viewForm = (el, id) => {
    userViewModaltoggle();
    setTemplateViewData(el, id);
  };

  // end modal
  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/email-templates/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire(
                "Deleted!",
                "Template has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Template is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/email-templates/${urlOrPerPage}`;
    }
    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 422) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Please Try Again",
            icon: "warning",
          });
        } else if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/email-templates/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchData(perPage);
  }, []);

  if (!permissions.includes(EMAIL_TEMPLATE_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <h5>Email Templates</h5>
                  <span> Manage your Templates </span>
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th className="col-1" scope="col">
                        {"#"}
                      </th>
                      <th className="col-9" scope="col">
                        {"Type"}
                      </th>
                      <th className="col-2 text-center" scope="col">
                        {"Action"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data?.map((el, i) => {
                          return (
                            <tr className="Dashed" key={el.id}>
                              <th className="col-1" scope="row">
                                {i + 1}
                              </th>
                              <td className="col-9 text-capitalize">
                                {el.type.split("_").join(" ")}
                              </td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [EMAIL_TEMPLATE_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => viewForm(el, el.id)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [EMAIL_TEMPLATE_UPDATE],
                                    permissions
                                  ) && (
                                    <Link
                                      to={`/email_template/update/${encryptionID(
                                        el.id
                                      )}`}
                                    >
                                      <Button color="primary" size="sm">
                                        <i className="fa fa-pencil"></i>
                                      </Button>
                                    </Link>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links &&
                        links.map((el, index, arr) =>
                          setLinksView(
                            el,
                            index,
                            arr,
                            fetchData,
                            current_page,
                            lastPage
                          )
                        )}
                    </Pagination>
                  </div>
                </div>

                {/* <Row className="">
                  <Col sm="2" className="text-center my-1"></Col>

                  <Col></Col>
                  <Col sm="2 text-center my-1"></Col>
                </Row> */}
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* =========================== ALL MODALS =============================  */}

      <Modal isOpen={userViewModal} toggle={userViewModaltoggle} size="lg">
        <ModalHeader toggle={userViewModaltoggle} className="text-center">
          Email Template
        </ModalHeader>
        <ModalBody>
          <TemplateView
            toggle={userViewModaltoggle}
            data={templateViewData}
          ></TemplateView>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EmailTemplateList;
