import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { Button, Col, Input, Label, Row } from "reactstrap";
import {
  getGarageDetailsList,
  getGarageModelWithMake,
  getUserByPhone,
  postBookingDetails,
} from "../../../api/garage_bookings";
import moment from "moment";
import { Next } from "../../../constant";
import AutoComplete from "../../AutoComplete/AutoComplete";
import SweetAlert from "sweetalert2";

const animatedComponents = makeAnimated();
const CreateBooking = ({
  setRegData,
  regData,
  perPage,
  setLoading,
  toggleModal,
  garageDetails,
  setSteps,
  bookingData,
  setBookingData,
  startDate,
  setStartDate,
  isGarageDataLoading,
}) => {
  const garageId = localStorage.getItem("garageId");

  const [modelList, setModelList] = useState([]);
  const [isMakeLoading, setIsMakeLoading] = useState(false);
  const [error, setError] = useState(false);

  // VALIDATION
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate first_Name
    if (!regData?.first_Name || regData?.first_Name.trim() === "") {
      newErrors.first_Name = "First name is required.";
    }
    // Validate last_Name
    if (!regData?.last_Name || regData?.last_Name.trim() === "") {
      newErrors.last_Name = "Last name is required.";
    }
    // Validate phone
    if (!regData?.phone) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{11}$/.test(regData.phone)) {
      newErrors.phone = "Phone must be 11 digits.";
    }

    // // Validate Role
    if (regData?.email_verified_at === "") {
      // Validate email
      if (!regData?.email) {
        newErrors.email = "Email is required.";
      } else if (!/^\S+@\S+\.\S+$/.test(regData.email)) {
        newErrors.email = "Email is not in a valid format.";
      }
    }

    // Validate car_registration_no
    if (!bookingData?.car_registration_no) {
      newErrors.car_registration_no = "Registration No is required.";
    }

    // Validate make
    if (Object.keys(bookingData?.make).length === 0) {
      newErrors.make = "Make is required.";
    }
    // Validate model
    if (Object.keys(bookingData?.model).length === 0) {
      newErrors.model = "Model is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      SweetAlert.fire({
        title: "Validation Alert!!",
        text: "Check your input fields",
        icon: "warning",
      });
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleNextButton = () => {
    if (validateForm()) {
      setSteps((prev) => prev + 1);
    }
  };
  const onChangePlaceAutoComplete = (value) => {
    setRegData((prevData) => ({
      ...prevData,
      address_line_1: value?.full_address,
      address_line_2: value?.full_address,
      country: value?.country,
      city: value?.locality,
      postcode: value?.postal_code,
      lat: `${value?.coordinates?.lat}`,
      long: `${value?.coordinates?.lng}`,
    }));
  };

  const handlePhoneUser = (e) => {
    if (e.target.value.length > 2) {
      getUserByPhone(e.target.value)
        .then((res) => {
          if (res?.length > 0) {
            setRegData(res[0]);
            if (res[0]?.booking !== null) {
              setBookingData({
                ...bookingData,
                customer_id: res[0]?.id,
                make: {
                  value: res[0]?.booking?.automobile_make?.id,
                  label: res[0]?.booking?.automobile_make?.name,
                },
                model: {
                  value: res[0]?.booking?.automobile_model?.id,
                  label: res[0]?.booking?.automobile_model?.name,
                },
                automobile_model_id: res[0]?.booking?.automobile_model_id,
                automobile_make_id: res[0]?.booking?.automobile_make_id,
                car_registration_no: res[0]?.booking?.car_registration_no,
              });
            } else {
              setBookingData({ ...bookingData, customer_id: res[0]?.id });
            }
          }
        })
        .catch((err) => {
          SweetAlert.fire({
            title: "Error!!",
            text: `${err.response.data.message}. Please Regester`,
            icon: "warning",
          });
          setRegData({
            first_Name: "",
            last_Name: "",
            email: "",
            phone: e.target.value,
            address_line_1: "",
            address_line_2: "",
            country: "",
            city: "",
            postcode: "",
            lat: "",
            long: "",
            email_verified_at: "",
          });
        });
    }
  };

  return (
    <div>
      <form action="">
        <div className="form-row mb-2">
          {/* PHONE  */}
          <Col md="6 mb-3">
            <Label className="" htmlFor="phone">
              Phone Number*
            </Label>
            <Input
              className={`form-control border-secondary`}
              value={regData?.phone}
              onBlur={handlePhoneUser}
              onChange={(e) =>
                setRegData({ ...regData, phone: e.target.value })
              }
              name="phone"
              type="number"
            />
            {errors?.phone && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.phone}
              </div>
            )}
          </Col>

          {/* FIRST NAME  */}
          <Col md="6 mb-3">
            <Label className="" htmlFor="first_Name">
              First Name*
            </Label>
            <Input
              className="form-control border-secondary"
              value={regData?.first_Name}
              onChange={(e) =>
                setRegData({ ...regData, first_Name: e.target.value })
              }
              name="first_Name"
              type="text"
            />
            {errors?.first_Name && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.first_Name}
              </div>
            )}
          </Col>

          {/* LAST NAME  */}
          <Col md="6 mb-3">
            <Label className="" htmlFor="last_Name">
              Last Name*
            </Label>
            <Input
              className="form-control border-secondary"
              value={regData?.last_Name}
              onChange={(e) =>
                setRegData({ ...regData, last_Name: e.target.value })
              }
              name="last_Name"
              type="text"
            />
            {errors?.last_Name && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.last_Name}
              </div>
            )}
          </Col>

          {regData?.email_verified_at === "" ? (
            <>
              {/* Email  */}
              <Col md="6 mb-3">
                <Label className="" htmlFor="validationCustomUsername">
                  Email*
                </Label>
                <Input
                  className="form-control border-secondary"
                  value={regData?.email}
                  onChange={(e) =>
                    setRegData({ ...regData, email: e.target.value })
                  }
                  name="email"
                  type="text"
                />
                {errors?.email && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors?.email}
                  </div>
                )}
              </Col>

              {/* Address  */}
              <Col md="12 mb-3">
                <Label className="" htmlFor="phone">
                  Address
                </Label>
                <AutoComplete
                  defaultValue={regData?.address_line_1}
                  setPlaceAutoComplete={onChangePlaceAutoComplete}
                  class_Name={"form-control border-secondary"}
                  name="address_line_1"
                  id={"address"}
                  placeholder="address"
                />
              </Col>
            </>
          ) : (
            // ADDRESS
            <Col md="6 mb-3">
              <Label className="" htmlFor="phone">
                Address
              </Label>
              <AutoComplete
                defaultValue={regData?.address_line_1}
                setPlaceAutoComplete={onChangePlaceAutoComplete}
                class_Name={"form-control border-secondary"}
                name="address_line_1"
                id={"address"}
                placeholder="Address"
              />
            </Col>
          )}
        </div>

        <div className="form-row mb-2">
          {/* SERVICE  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Services</Label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              isLoading={isGarageDataLoading}
              options={
                garageDetails?.garage_sub_services
                  ? garageDetails?.garage_sub_services.length > 0
                    ? garageDetails?.garage_sub_services?.map((res) => {
                        const data = {
                          value: res?.sub_service?.id,
                          label: res?.sub_service?.name,
                        };
                        return data;
                      })
                    : []
                  : []
              }
              value={bookingData?.services}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setBookingData({
                    ...bookingData,
                    services: options,
                    booking_sub_service_ids: options.map((opt) => opt.value),
                  });
                }
              }}
              placeholder="Select Car Services"
            />

            {errors?.services && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.services}
              </div>
            )}
          </Col>

          {/* CAR REG  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Car Reg*</Label>
            <Input
              type="text"
              value={bookingData.car_registration_no}
              onChange={(e) =>
                setBookingData({
                  ...bookingData,
                  car_registration_no: e.target.value,
                })
              }
              className="form-control border-secondary"
              placeholder="Car Reg"
            />
            {errors?.car_registration_no && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.car_registration_no}
              </div>
            )}
          </Col>

          {/* MAKE  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Make*</Label>
            <Select
              isLoading={isGarageDataLoading}
              components={animatedComponents}
              options={garageDetails?.garage_automobile_makes?.map((res) => {
                const data = {
                  value: res?.automobile_make?.id,
                  label: res?.automobile_make?.name,
                };
                return data;
              })}
              value={bookingData?.make}
              onChange={(options) => {
                setBookingData({
                  ...bookingData,
                  make: options,
                  automobile_make_id: options.value,
                });
                setIsMakeLoading(true);
                getGarageModelWithMake(garageId, options.value)
                  .then((res) => {
                    setModelList(res);
                    setIsMakeLoading(false);
                  })
                  .catch((error) => {
                    console.log({ error });
                    setIsMakeLoading(false);
                  });
              }}
              placeholder="Select Car Make*"
            />
            {errors?.make && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.make}
              </div>
            )}
          </Col>

          {/* MODEL  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Model*</Label>
            <Select
              isLoading={isMakeLoading}
              isDisabled={!bookingData?.automobile_make_id}
              components={animatedComponents}
              onChange={(options) => {
                setBookingData({
                  ...bookingData,
                  model: options,
                  automobile_model_id: options.value,
                });
              }}
              value={bookingData?.model}
              options={modelList?.map((res) => {
                const data = {
                  value: res?.automobile_model?.id,
                  label: res?.automobile_model?.name,
                };
                return data;
              })}
              placeholder="Select Car Model*"
            />
            {errors?.model && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.model}
              </div>
            )}
          </Col>

          {/* DATE  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Select Booking Date and Time</Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setBookingData({
                  ...bookingData,
                  job_start_date: moment(date).format("YYYY-MM-DD"),
                  job_start_time: moment(date).format("HH:mm"),
                });
              }}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              className="date-time-picker-popup form-control border-secondary"
            />
            {(errors?.job_start_date || errors?.job_start_time) && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors?.job_start_date || errors?.job_start_time}
              </div>
            )}
          </Col>

          {/* Additional Info  */}
          <Col md="6 mb-3">
            <Label htmlFor="">Additional Information</Label>
            <textarea
              value={bookingData.additional_information}
              onChange={(e) =>
                setBookingData({
                  ...bookingData,
                  additional_information: e.target.value,
                })
              }
              className="form-control border-secondary"
              placeholder="Extra Notes"
              name=""
              id=""
              rows="2"
            ></textarea>
          </Col>
        </div>

        <div className="text-end btn-mb">
          <Button color="primary" onClick={handleNextButton}>
            {Next}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateBooking;
