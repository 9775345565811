import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { ContactUs, Email, Location } from "../../constant";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import { decryptionID, encryptionID } from "../../utils/encription";

const UserProfile = (props) => {
  const { string_id } = useParams();
  const id = decryptionID(string_id);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/user`)
      .then((response) => {
        setUser(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [id]);
  const dynamicImageUrl = ``;
  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <Container fluid={true}>
          <div className="user-profile">
            <Row>
              <Col sm="12">
                <Card className="card hovercard text-center">
                  <CardHeader
                    style={{
                      background: `url("https://quickreview.app/garage${
                        user?.background_image === null
                          ? user?.default_background_image
                          : user?.background_image
                      }")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "400px",
                    }}
                  ></CardHeader>
                  <div
                    style={{
                      marginTop: "40px",
                    }}
                    className="user-image"
                  >
                    <div className="avatar">
                      <img
                        style={{
                          background: "#fff",
                          boxShadow: "1px 1px 10px #ddd",
                        }}
                        className="image-object-fit-cover"
                        alt={user?.name}
                        src={
                          user?.image
                            ? user?.image
                            : "https://i.ibb.co/02q5FpM/avater.png"
                        }
                      />
                    </div>
                    <Link
                      to={`/profile/edit/${encryptionID(id)}`}
                      className="icon-wrapper"
                      data-intro="Edit Profile"
                    >
                      <i className="icofont icofont-pencil-alt-5"></i>
                    </Link>
                  </div>
                  <div className="info">
                    <Row>
                      <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                        <Row>
                          <Col md="6">
                            <div className="ttl-info text-start">
                              <h6>
                                <i className="fa fa-envelope"></i> {Email}
                              </h6>
                              <span>{user?.email}</span>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="ttl-info text-start ttl-sm-mb-0">
                              <h6>
                                <i className="fa fa-calendar"></i>   {"Since"}
                              </h6>
                              <span>
                                {moment(user?.created_at).format("MMM Do YY")}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                        <div className="user-designation">
                          <div className="title">
                            <a target="_blank" href="#javascript">
                              {user?.first_Name} {user?.last_Name}
                            </a>
                          </div>
                          <div className="desc mt-2">
                            {user?.roles[0]?.name.split("_").join(" ")}
                          </div>
                        </div>
                      </Col>
                      <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                        <Row>
                          <Col md="6">
                            <div className="ttl-info text-start ttl-xs-mt">
                              <h6>
                                <i className="fa fa-phone"></i>   {ContactUs}
                              </h6>
                              <span>{user?.phone}</span>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="ttl-info text-start ttl-sm-mb-0">
                              <h6>
                                <i className="fa fa-location-arrow"></i>   
                                {Location}
                              </h6>
                              <span>
                                {user?.address_line_1
                                  ? user?.address_line_1
                                  : "N/A"}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    {/* <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a href="https://facebook.com/"><i className="fa fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="https://myaccount.google.com/"><i className="fa fa-google-plus"></i></a></li>
                      <li className="list-inline-item"><a href="https://twitter.com/login"><i className="fa fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="https://www.instagram.com/"><i className="fa fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="https://rss.app/"><i className="fa fa-rss"></i></a></li>
                    </ul>
                  </div> */}
                    {/* <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-end border-right">
                        <div className="follow-num counter">{"25869"}</div><span>{Follower}</span>
                      </Col>
                      <Col col="6" className="text-md-start">
                        <div className="follow-num counter">{"659887"}</div><span>{Following}</span>
                      </Col>
                    </Row>
                  </div> */}
                  </div>
                </Card>
              </Col>

              {/* <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle me-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{"you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet."}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                      <a href="#javascript">
                        <Media className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                      </a>
                    </div>
                  </div>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pe-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ms-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle me-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{"you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet."}</p>
                  <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pe-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ms-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle me-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source .Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source"}</p>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pe-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ms-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle me-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pe-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ms-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="6">
                      <p>{"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consecteturContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur"}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> */}
            </Row>
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default UserProfile;
