import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap'
import { MENUITEMS } from './sidebar/menu'
const Breadcrumbs = (props) => {
    // eslint-disable-next-line
    const [breadcrumb, setBreadcrumb] = useState(props);
    // eslint-disable-next-line
    const [mainmenu, setMainMenu] = useState(MENUITEMS);

 

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
  
            document.querySelector(".filled-bookmark").classList.remove('is-open');
            document.querySelector(".page-wrapper").classList.remove("offcanvas-bookmark");
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        mainmenu.filter(menuItems => {
            if (menuItems.bookmark) {
          
            }
            return menuItems

        });
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [mainmenu, escFunction]);


    return (
        <Container fluid={true}>
            <div className="page-header">
                <Row>
                    <Col lg='6'>
                        <h3>{breadcrumb.title}</h3>
                        <Breadcrumb>
                            <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/dashboard`}>{breadcrumb.parent}</Link></BreadcrumbItem>
                            <BreadcrumbItem>{breadcrumb.subparent}</BreadcrumbItem>
                            <BreadcrumbItem active>{breadcrumb.title}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default Breadcrumbs
