import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";

import { BACKEND_API } from "../../../utils/backend";

import setLinksView from "../../../utils/pagination";

import moment from "moment";
import DatePicker from "react-datepicker";
import { GiHomeGarage } from "react-icons/gi";
import { useLocation } from "react-router";
import SweetAlert from "sweetalert2";
import { getGarageList } from "../../../api/findJobs";
import {
  BOOKING_DELETE,
  BOOKING_UPDATE,
  BOOKING_VIEW,
} from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { checkPermissions } from "../../../utils/helperFunctions";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import BookingView from "./BookingView";
import ChangeStatus from "./ChangeStatus";
import CreateBooking from "./CreateBooking";
import BookingSteps from "./BookingSteps/BookingSteps";
import { MdPublishedWithChanges } from "react-icons/md";

const GarageBookings = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const location = useLocation();

  const locSearch = new URLSearchParams(location.search);

  const customer_id = locSearch.get("customer_id");
  const booking_id = locSearch.get("booking_id");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [garageId, setGarageId] = useState("");
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/bookings/${garageId}/${perPage}?start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/bookings/${garageId}/${perPage}?start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  // modal
  const [garageViewData, setGarageViewData] = useState(null);
  const [garageViewModal, setGarageViewModal] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [createBooking, setCreateBooking] = useState(false);

  const garageViewModaltoggle = () => setGarageViewModal(!garageViewModal);
  const bookingStatusModaltoggle = () => setBookingStatus(!bookingStatus);
  const createookingModaltoggle = () => setCreateBooking(!createBooking);

  const viewForm = (el) => {
    setGarageViewData(el);
    garageViewModaltoggle();
  };

  // end modal
  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/bookings/${garageId}/${id}`)
          .then((response) => {
            if (response.status === 200 && response.data.ok) {
              fetchData(garageId, perPage);
              SweetAlert.fire(
                "Deleted!",
                "Booking has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Garage is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (garageId, urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/v1.0/bookings/${garageId}/${urlOrPerPage}`;
    }

    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [bookingDetails, setBookingDetails] = useState("");
  const changeBookingStatus = (data) => {
    setBookingStatus(true);
    setBookingId(data?.id);
    setBookingDetails({
      job_start_date: new Date(data?.job_start_date),
      job_end_time: moment(data?.job_start_time, "HH:mm:ss").format("HH:mm"),
      job_start_time: moment(data?.job_end_time, "HH:mm:ss").format("HH:mm"),
      price: data?.price,
      discount_type: data?.discount_type,
      discount_amount: data?.discount_amount,
    });
  };

  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      garageId,
      `${BACKEND_API}/v1.0/bookings/${garageId}/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    getGarageList().then((res) => {
      setGarageId(res[0]?.id);
      fetchData(res[0]?.id, perPage);
    });
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const matchedNotificationData = data.filter(
        (el) => el.id === parseInt(booking_id)
      );
      if (matchedNotificationData.length > 0) {
        viewForm(matchedNotificationData[0]);
      }
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (!permissions.includes(BOOKING_VIEW)) {
    return <Error401 />;
  }
  return (
    <Fragment>
      {/* BOOKING DETAILS PREVIEW  */}
      <Modal
        isOpen={garageViewModal}
        toggle={garageViewModaltoggle}
        size="lg"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <GiHomeGarage className="icon-filter fs-1 mb-2 text-primary"></GiHomeGarage>
            <h4 className="fw-bolder text-black-50">Booking Details Preview</h4>
          </Col>
        </Row>
        <ModalBody>
          <BookingView
            toggleModal={garageViewModaltoggle}
            viewData={garageViewData}
            setIsOpen={setGarageViewModal}
          />
        </ModalBody>
      </Modal>

      {/* CHANGE BOOKING STATUS  */}
      <Modal
        isOpen={bookingStatus}
        toggle={bookingStatusModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <MdPublishedWithChanges className="icon-filter fs-1 mb-2 text-primary"></MdPublishedWithChanges>
            <h4 className="fw-bolder text-black-50">Change Status</h4>
          </Col>
        </Row>
        <ModalBody>
          <ChangeStatus
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            setIsOpen={setBookingStatus}
            bookingId={bookingId}
            garageId={garageId}
            toggleModal={bookingStatusModaltoggle}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
        </ModalBody>
      </Modal>

      {/* CREATE BOOKING MODAL  */}
      <Modal
        isOpen={createBooking}
        toggle={createookingModaltoggle}
        size="xl"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <h4 className="fw-bolder text-secondary">Create Booking</h4>
          </Col>
        </Row>

        <ModalBody>
          <BookingSteps
            fetchData={fetchData}
            perPage={perPage}
            setIsOpen={setCreateBooking}
            garageId={garageId}
            toggleModal={createookingModaltoggle}
            setLoading={setLoading}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="Garage Management / Garages" title="Manage Garages" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Booking Management</h5>
                    <span> Manage your Bookings </span>
                  </div>
                  <Button
                    color="primary"
                    onClick={() => setCreateBooking(true)}
                  >
                    Create Booking
                  </Button>
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Customer Name"}</th>
                      <th scope="col">{"Packages"}</th>
                      <th scope="col">{"Services"}</th>
                      <th scope="col">{"Make"}</th>
                      <th scope="col">{"Model"}</th>
                      <th scope="col">{"Status"}</th>
                      <th className="text-center" scope="col">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, index) => {
                          return (
                            <tr className="Dashed" key={el?.id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {el?.customer?.first_Name}{" "}
                                {el?.customer?.last_Name}
                              </td>
                              <td>
                                {el?.booking_packages?.length > 0 &&
                                  el?.booking_packages?.map((res) => (
                                    <li>{res?.garage_package?.name}</li>
                                  ))}
                              </td>
                              <td>
                                {el?.booking_sub_services?.map((res, i) => (
                                  <span>
                                    {i !== 0 && ","}
                                    {res?.sub_service?.name}
                                  </span>
                                ))}
                              </td>
                              <td>{`${el.automobile_make?.name}`}</td>
                              <td>{el?.automobile_model?.name}</td>
                              <td>
                                {el?.status
                                  ? el?.status.split("_").join(" ")
                                  : ""}
                              </td>

                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [BOOKING_VIEW],
                                    permissions
                                  ) && (
                                    <Button
                                      title="view"
                                      onClick={() => viewForm(el)}
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Button>
                                  )}

                                  {checkPermissions(
                                    [BOOKING_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      title="reject"
                                      onClick={() => changeBookingStatus(el)}
                                      color="warning"
                                      size="sm"
                                    >
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  )}

                                  {checkPermissions(
                                    [BOOKING_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => changeBookingStatus(el)}
                                      color="primary"
                                      title="edit"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}

                                  {checkPermissions(
                                    [BOOKING_DELETE],
                                    permissions
                                  ) && (
                                    <Button
                                      title="delete"
                                      onClick={() => deleteFunc(el?.id)}
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GarageBookings;
