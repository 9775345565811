import React from 'react';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';

const PackageView = ({ setIsOpen, isLoading, viewData }) => {
    return (
        <Row className='px-3'>
            <Col sm="12">
                {isLoading ? <CustomLoadingSpiner /> :
                    <>
                        <Container className='tab-1 mt-3'>
                            <Row className="form-row mb-2">
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase fw-bold"} htmlFor="garage_Name">Package Name</Label>
                                    <p>{viewData?.name}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase fw-bold"} htmlFor="email">Services</Label>
                                    <ul>
                                        {viewData.garage_package_sub_services?.map((res, index) => (
                                            <li>{index + 1}. {res?.sub_service?.name}</li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase fw-bold"} htmlFor="phone">Price</Label>
                                    <p>{viewData?.price}</p>
                                </Col>
                            </Row>

                            <div className='form-row mb-2'>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase fw-bold"} htmlFor="country">Description</Label>
                                    <p>{viewData?.description}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase fw-bold"} htmlFor="phone">Status</Label>
                                    <p>{viewData?.is_active === 1 ? "Active" : "Not Active"}</p>
                                </Col>
                            </div>
                        </Container>
                    </>
                }


                <Row className='mb-3'>
                    <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                        <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PackageView;