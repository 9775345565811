import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import setLinksView from "../../utils/pagination";

import DatePicker from "react-datepicker";
import { useParams } from "react-router";
import SweetAlert from "sweetalert2";
import {
  AUTOMOBILE_CREATE,
  AUTOMOBILE_DELETE,
  AUTOMOBILE_UPDATE,
  AUTOMOBILE_VIEW,
} from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";
import { checkPermissions } from "../../utils/helperFunctions";

import { VscSymbolMisc } from "react-icons/vsc";
import { Link } from "react-router-dom";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import AutomobileModelVariantForm from "./forms/AutomobileModelVariantForm";
import { decryptionID, encryptionID } from "../../utils/encription";

const AutomobileModelVariantList = () => {
  const { string_id } = useParams();
  const id = decryptionID(string_id);

  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [automobileModel, setAutomobileModel] = useState(null);

  // get single data
  useEffect(() => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-models/single/get/${id}`)
      .then((response) => {
        setAutomobileModel(response.data);
      })
      .catch((error) => {});
  }, [id]);

  // end get single data
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/automobile-model-variants/${id}/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/automobile-model-variants/${id}/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  // modal
  const [
    automobileModelVariantCreateModal,
    setAutomobileModelVariantCreateModal,
  ] = useState(false);
  const automobileModelVariantCreateModaltoggle = () =>
    setAutomobileModelVariantCreateModal(!automobileModelVariantCreateModal);

  const [
    automobileModelVariantUpdateData,
    setAutomobileModelVariantUpdateData,
  ] = useState(null);
  const [automobileCategoryUpdateModal, setAutomobileCategoryUpdateModal] =
    useState(false);
  const automobileCategoryUpdateModaltoggle = () =>
    setAutomobileCategoryUpdateModal(!automobileCategoryUpdateModal);
  const editForm = (el) => {
    automobileCategoryUpdateModaltoggle();
    setAutomobileModelVariantUpdateData(el);
  };

  // end modal

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/automobile-model-variants/${id}`)
          .then((response) => {
            if (response.status === 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire(
                "Deleted!",
                "Automobile Model Variant has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("Automobile Model Variant is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }

    //  setData(null)
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
      // url = urlOrPerPage.replace("http", http);
    } else {
      url = `${BACKEND_API}/v1.0/automobile-model-variants/${id}/${urlOrPerPage}`;
    }
    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/automobile-model-variants/${id}/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    fetchData(perPage);
  }, []);

  if (!permissions.includes(AUTOMOBILE_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* ================================== MODALS ============================  */}
      <Modal
        isOpen={automobileModelVariantCreateModal}
        toggle={automobileModelVariantCreateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <VscSymbolMisc className="icon-settings fs-1 mb-2 text-primary"></VscSymbolMisc>
            <h4 className="fw-bolder text-secondary">
              Create Automobile Model Variant
            </h4>
          </Col>
        </Row>
        <ModalBody>
          <AutomobileModelVariantForm
            toggleModal={automobileModelVariantCreateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            automobileModel={automobileModel}
            type="create"
            setIsOpen={setAutomobileModelVariantCreateModal}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={automobileCategoryUpdateModal}
        toggle={automobileCategoryUpdateModaltoggle}
        size="md"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <VscSymbolMisc className="icon-settings fs-1 mb-2 text-primary"></VscSymbolMisc>
            <h4 className="fw-bolder text-secondary">
              Update Automobile Model Variant
            </h4>
          </Col>
        </Row>

        <ModalBody>
          <AutomobileModelVariantForm
            toggleModal={automobileCategoryUpdateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            automobileModel={automobileModel}
            automobileModelVariantUpdateData={automobileModelVariantUpdateData}
            setIsOpen={setAutomobileCategoryUpdateModal}
          />
        </ModalBody>
      </Modal>

      {/* ========================== Main ============================  */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="pb-3">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Automobile Model Variant Management</h5>
                    <span> Manage your Automobile Model Variant </span>
                    <br />
                    <br />
                    <Row>
                      <Col sm="12">
                        <span className="my-1 fs-6">
                          Category Name :{" "}
                          <span className="text-primary">
                            {automobileModel?.make?.category?.name}
                          </span>
                        </span>
                        <br />
                        <span className="my-1 fs-6">
                          Make:{" "}
                          <span className="text-primary">
                            {automobileModel?.make?.name}
                          </span>
                        </span>
                        <br />
                        <span className="my-1 fs-6">
                          Model:{" "}
                          <span className="text-primary">
                            {automobileModel?.name}
                          </span>
                        </span>
                        <br />
                        <span className="my-1 fs-6">
                          Model Variant:{" "}
                          <span className="text-primary">All</span>
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {checkPermissions([AUTOMOBILE_CREATE], permissions) ? (
                      <Button
                        color="primary"
                        onClick={automobileModelVariantCreateModaltoggle}
                      >
                        Create Model Variant of {automobileModel?.name}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Name"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Category"}</th>
                      <th scope="col">{"Make"}</th>
                      <th scope="col">{"Model"}</th>
                      <th scope="col" className="text-center">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, i) => {
                          return (
                            <tr className="Dashed" key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{el.name}</td>
                              <td>{el.description}</td>
                              <td>{el.model?.make?.category?.name}</td>
                              <td>{el.model?.make?.name}</td>
                              <td>{el.model?.name}</td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  {checkPermissions(
                                    [AUTOMOBILE_VIEW],
                                    permissions
                                  ) && (
                                    <Link
                                      to={`${
                                        process.env.PUBLIC_URL
                                      }/automobile-model-variant/single/${encryptionID(
                                        el.id
                                      )}`}
                                    >
                                      <Button color="success" size="sm">
                                        <i className="fa fa-eye"></i>
                                      </Button>
                                    </Link>
                                  )}
                                  {checkPermissions(
                                    [AUTOMOBILE_UPDATE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => editForm(el)}
                                      color="primary"
                                      size="sm"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                  {checkPermissions(
                                    [AUTOMOBILE_DELETE],
                                    permissions
                                  ) && (
                                    <Button
                                      onClick={() => deleteFunc(el.id)}
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AutomobileModelVariantList;
