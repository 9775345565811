import { apiClient, apiClientWithToken } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getGarageTiming = async (id) => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/garage-times/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getGarageTimingUsingToken = async (id, token) => {
  return await apiClientWithToken(token)
    .get(`${BACKEND_API}/v1.0/garage-times/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
