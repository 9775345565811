import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../layout/Loader';

const Callback = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth0()
  useEffect(() => {
    if (user) {
      localStorage.setItem("auth0_profile", JSON.stringify(user))
      navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
  })
  return (
    <div>
      <Loader />
    </div>
  );
}
export default Callback;