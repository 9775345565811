import React, { useState } from 'react';
import { Maximize } from 'react-feather';
import { useSelector } from 'react-redux';
import BellDropdown from './BellDropdown';
import Search from './Search';
import UserActivity from './UserActivity';

const Rightbar = () => {
  const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
  const [isFullScreen, setIsFullScreen] = useState(false);

  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
        setIsFullScreen(true)
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        setIsFullScreen(true)
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        setIsFullScreen(true)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
        setIsFullScreen(false)
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        setIsFullScreen(false)
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
        setIsFullScreen(false)
      }
    }
  }
  return (
    <div className="nav-right col pull-right right-menu">
      <ul className={`nav-menus justify-content-end ${mobileRightTog ? 'open' : ''}`}>
        <li>
          <Search />
        </li>
        <li>
          <BellDropdown />
        </li>
        <li>
          <span role='button' onClick={goFull} className="text-dark" title={`${isFullScreen ? "Exit From Full Screen" : "Go To Full Screen"}`}><Maximize /></span>
        </li>
        <li>
          <UserActivity />
        </li>
      </ul>
    </div>
  )
}


export default Rightbar