export const GARAGE_CREATE = "garage_create";
export const GARAGE_UPDATE = "garage_update";
export const GARAGE_VIEW = "garage_view";
export const GARAGE_DELETE = "garage_delete";

export const GARAGE_GALLERY_CREATE = "garage_gallery_create";
export const GARAGE_GALLERY_UPDATE = "garage_gallery_update";
export const GARAGE_GALLERY_VIEW = "garage_gallery_view";
export const GARAGE_GALLERY_DELETE = "garage_gallery_delete";

export const AUTOMOBILE_CREATE = "automobile_create";
export const AUTOMOBILE_UPDATE = "automobile_update";
export const AUTOMOBILE_VIEW = "automobile_view";
export const AUTOMOBILE_DELETE = "automobile_delete";

export const SERVICE_CREATE = "service_create";
export const SERVICE_UPDATE = "service_update";
export const SERVICE_VIEW = "service_view";
export const SERVICE_DELETE = "service_delete";
export const GARAGE_SERVICE_PRICE_CREATE = "garage_service_price_create";
export const GARAGE_SERVICE_PRICE_UPDATE = "garage_service_price_update";

export const SUB_SERVICE_CREATE = "sub_service_create";
export const SUB_SERVICE_UPDATE = "sub_service_update";
export const SUB_SERVICE_VIEW = "sub_service_view";
export const SUB_SERVICE_DELETE = "sub_service_delete";

export const FUEL_STATION_CREATE = "fuel_station_create";
export const FUEL_STATION_UPDATE = "fuel_station_update";
export const FUEL_STATION_VIEW = "fuel_station_view";
export const FUEL_STATION_DELETE = "fuel_station_delete";

export const USER_CREATE = "user_create";
export const USER_UPDATE = "user_update";
export const USER_VIEW = "user_view";
export const USER_DELETE = "user_delete";

export const ROLE_CREATE = "role_create";
export const ROLE_UPDATE = "role_update";
export const ROLE_VIEW = "role_view";
export const ROLE_DELETE = "role_delete";

export const EMAIL_TEMPLATE_CREATE = "template_create";
export const EMAIL_TEMPLATE_UPDATE = "template_update";
export const EMAIL_TEMPLATE_VIEW = "template_view";
export const EMAIL_TEMPLATE_DELETE = "template_delete";
export const GARAGE_TIME = "garage_times_view";

export const BOOKING_VIEW = "booking_view";
export const BOOKING_UPDATE = "booking_update";
export const BOOKING_DELETE = "booking_delete";

export const JOB_CREATE = "job_create";
export const JOB_UPDATE = "job_update";
export const JOB_VIEW = "job_view";
export const JOB_DELETE = "job_delete";

export const JOB_PAYMENT_CREATE = "job_payment_create";
export const JOB_PAYMENT_UPDATE = "job_payment_update";
export const JOB_PAYMENT_VIEW = "job_payment_view";
export const JOB_PAYMENT_DELETE = "job_payment_delete";

export const COUPON_CREATE = "coupon_create";
export const COUPON_UPDATE = "coupon_update";
export const COUPON_VIEW = "coupon_view";
export const COUPON_DELETE = "coupon_delete";

export const GARAGE_PACKAGE_CREATE = "garage_package_create";
export const GARAGE_PACKAGE_UPDATE = "garage_package_update";
export const GARAGE_PACKAGE_VIEW = "garage_package_view";
export const GARAGE_PACKAGE_DELETE = "garage_package_delete";

export const REVIEW_CREATE = "review_create";
export const QUIESTIONS_CREATE = "questions_create";
export const QUIESTIONS_UPDATE = "questions_update";
export const REVIEW_UPDATE = "review_update";
export const REVIEW_VIEW = "review_view";
export const QUIESTIONS_VIEW = "questions_view";
export const QUIESTIONS_DELETE = "questions_delete";

export const JOB_BIDS_CREATE = "job_bids_create";
export const JOB_BIDS_UPDATE = "job_bids_update";
export const JOB_BIDS_VIEW = "job_bids_view";
export const JOB_BIDS_DELETE = "job_bids_delete";
