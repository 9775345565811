import React, { useEffect, useState } from "react";
import { AiFillCar } from "react-icons/ai";
import GoogleMapReact from "google-map-react";
import JobsCard from "./JobsCard";
import {
  getAllJobs,
  getGarageList,
  getJobs,
  getMakeList,
  getServiceList,
} from "../../../api/findJobs";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";

const ApplyJobs = () => {
  const [location, setLocation] = React.useState({});

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          console.error(error);
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const defaultProps = {
    center: {
      lat: location?.latitude,
      lng: location?.longitude,
    },
    zoom: 13,
  };
  const [showDetails, setShowDetails] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  const [garageList, setgarageList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [searchData, setSearchData] = useState({
    serviceIds: "",
    makeIds: "",
    town_or_postal_code: "",
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const fetchAllJobs = (id, page) => {
    setIsDataLoading(true);
    getAllJobs(id, page)
      .then((jobRes) => {
        console.log({ jobRes });
        setJobsList(jobRes);
        setIsDataLoading(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    getGarageList().then((res) => {
      setgarageList(res);
      fetchAllJobs(res[0]?.id, 100);
    });
    getServiceList().then((res) => {
      setServiceList(res);
    });
    getMakeList().then((res) => {
      setMakeList(res);
    });
  }, []);

  const findJobhandler = () => {
    getJobs(
      garageList.length !== 0 && garageList[0]?.id,
      100,
      searchData.serviceIds,
      searchData.makeIds,
      searchData.town_or_postal_code
    ).then((jobRes) => {
      setJobsList(jobRes);
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const AnyReactComponent = ({ text }) => (
    <div
      style={{
        position: "relative",
        bottom: 0,
        width: 40,
        height: 10,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <div class="pinForJob">
        <AiFillCar
          style={{
            position: "absolute",
            top: "20%",
            right: "20%",
            zIndex: "100",
            transform: "rotate(-46deg)",
            color: "red",
            fontSize: 5,
          }}
          className="fs-4"
        />
      </div>

      {showDetails && (
        <div
          style={{
            textAlign: "center",
            bottom: "50px",
            right: "50%",
            transform: "translateX(50%)",
            position: "absolute",
            fontWeight: "bold",
            color: "red",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );

  return (
    <>
      {isDataLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <div>
          {defaultProps.center.lat !== undefined &&
            defaultProps.center.lng !== undefined && (
              // GOOGLE MAP
              <div
                style={{
                  height: "350px",
                  width: "97%",
                  border: 0,
                  position: "absolute",
                  top: "0px",
                  marginBottom: "100px",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: `${process.env.REACT_APP_GOOGLE_MAP_API}`,
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  {jobsList?.data.length > 0 &&
                    jobsList?.data?.map((job, i) => (
                      <AnyReactComponent
                        key={i}
                        lat={job?.lat}
                        lng={job?.long}
                        text="My home"
                      />
                    ))}
                </GoogleMapReact>
              </div>
            )}

          {/* JOB CARDS  */}
          <JobsCard
            serviceList={serviceList}
            makeList={makeList}
            setSearchData={setSearchData}
            searchData={searchData}
            findJobhandler={findJobhandler}
            jobsList={jobsList}
            garageList={garageList}
            getAllJobs={fetchAllJobs}
          />
        </div>
      )}
    </>
  );
};

export default ApplyJobs;
