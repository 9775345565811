import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "./EditQuestion.module.css";
import { apiClient } from "../../../../utils/apiClient";
import { Button } from "reactstrap";
import { BACKEND_API } from "../../../../utils/backend";
import CustomLoadingSpiner from "../../../CustomLoadingSpiner/CustomLoadingSpiner";
import SweetAlert from "sweetalert2";

const animatedComponents = makeAnimated();

export default function EditQuestion({
  editQuestion,
  setPopupIsOpend,
  toggleModal,
  garage_id,
  edit_id,
  fetchData,
}) {
  // DATA FROM API
  const [singleQuestion, setSingleQuestion] = useState({});
  const [linkedTags, setLinkedTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  // TYPE
  const [questionType, setQuestionType] = useState("");

  // STAR & TAGS
  const [star1, setStar1] = useState({ star_id: 1, tags: [] });
  const [star2, setStar2] = useState({ star_id: 2, tags: [] });
  const [star3, setStar3] = useState({ star_id: 3, tags: [] });
  const [star4, setStar4] = useState({ star_id: 4, tags: [] });
  const [star5, setStar5] = useState({ star_id: 5, tags: [] });
  // DEFAULT STAR & TAGS
  const [defaultTags1, setDefaultTags1] = useState([]);
  const [defaultTags2, setDefaultTags2] = useState([]);
  const [defaultTags3, setDefaultTags3] = useState([]);
  const [defaultTags4, setDefaultTags4] = useState([]);
  const [defaultTags5, setDefaultTags5] = useState([]);
  // QUESTION
  const [questionDetails, setQuestionDetails] = useState(
    editQuestion?.question
  );
  const [questionStatus, setQuestionStatus] = useState(1);
  // LOADING
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [isTagsLoading, setIsTagsLoading] = useState(true);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [isAllDone, setIsAllDone] = useState(true);
  // ERRORS
  const [errors, setErrors] = useState({
    star1: "",
    star2: "",
    star3: "",
    star4: "",
    star5: "",
  });

  // SUBMISSION TRIGER
  const [isSubmited, setIsSubmited] = useState(false);

  // GET ALL STAR'S TAGS
  const handleChangeForStar1 = (selectedOption) => {
    setStar1({
      star_id: 1,
      tags: selectedOption.map((singleTag) => {
        return { tag_id: singleTag?.value };
      }),
    });
    setIsSubmited(false);
    setErrors({
      star1: "",
      star2: "",
      star3: "",
      star4: "",
      star5: "",
    });
  };

  const handleChangeForStar2 = (selectedOption) => {
    setStar2({
      star_id: 2,
      tags: selectedOption.map((singleTag) => {
        return { tag_id: singleTag?.value };
      }),
    });
    setIsSubmited(false);
    setErrors({
      star1: "",
      star2: "",
      star3: "",
      star4: "",
      star5: "",
    });
  };

  const handleChangeForStar3 = (selectedOption) => {
    setStar3({
      star_id: 3,
      tags: selectedOption.map((singleTag) => {
        return { tag_id: singleTag?.value };
      }),
    });
    setIsSubmited(false);
    setErrors({
      star1: "",
      star2: "",
      star3: "",
      star4: "",
      star5: "",
    });
  };

  const handleChangeForStar4 = (selectedOption) => {
    setStar4({
      star_id: 4,
      tags: selectedOption.map((singleTag) => {
        return { tag_id: singleTag?.value };
      }),
    });
    setIsSubmited(false);
    setErrors({
      star1: "",
      star2: "",
      star3: "",
      star4: "",
      star5: "",
    });
  };

  const handleChangeForStar5 = (selectedOption) => {
    setStar5({
      star_id: 5,
      tags: selectedOption.map((singleTag) => {
        return { tag_id: singleTag?.value };
      }),
    });
    setIsSubmited(false);
    setErrors({
      star1: "",
      star2: "",
      star3: "",
      star4: "",
      star5: "",
    });
  };

  // GET TAGS AND  QUESTION
  useEffect(() => {
    setIsTagsLoading(true);
    apiClient()
      .get(`${BACKEND_API}/review-new/get/tags?garage_id=${garage_id}`)
      .then((res) => {
        setAllTags(res?.data);
        apiClient()
          .get(`${BACKEND_API}/review-new/get/questions/${edit_id}`)
          .then((res) => {
            setQuestionStatus(res?.data?.is_active);
            setQuestionType(res?.data?.type);
            setSingleQuestion(res?.data);
            setStar1({
              star_id: 1,
              tags: res?.data?.stars
                ?.find((res) => res.value === 1)
                ?.tags.map((singleTag) => {
                  return { tag_id: singleTag?.id };
                }),
            });
            setStar2({
              star_id: 2,
              tags: res?.data?.stars
                ?.find((res) => res.value === 2)
                ?.tags.map((singleTag) => {
                  return { tag_id: singleTag?.id };
                }),
            });
            setStar3({
              star_id: 3,
              tags: res?.data?.stars
                ?.find((res) => res.value === 3)
                ?.tags.map((singleTag) => {
                  return { tag_id: singleTag?.id };
                }),
            });
            setStar4({
              star_id: 4,
              tags: res?.data?.stars
                ?.find((res) => res.value === 4)
                ?.tags.map((singleTag) => {
                  return { tag_id: singleTag?.id };
                }),
            });
            setStar5({
              star_id: 5,
              tags: res?.data?.stars
                ?.find((res) => res.value === 5)
                ?.tags.map((singleTag) => {
                  return { tag_id: singleTag?.id };
                }),
            });

            setDefaultTags1(
              res?.data?.stars
                ?.find((res) => res.value === 1)
                ?.tags.map((tag) => {
                  return { value: tag?.id, label: tag?.tag };
                })
            );
            setDefaultTags2(
              res?.data?.stars
                ?.find((res) => res.value === 2)
                ?.tags.map((tag) => {
                  return { value: tag?.id, label: tag?.tag };
                })
            );
            setDefaultTags3(
              res?.data?.stars
                ?.find((res) => res.value === 3)
                ?.tags.map((tag) => {
                  return { value: tag?.id, label: tag?.tag };
                })
            );
            setDefaultTags4(
              res?.data?.stars
                ?.find((res) => res.value === 4)
                ?.tags.map((tag) => {
                  return { value: tag?.id, label: tag?.tag };
                })
            );
            setDefaultTags5(
              res?.data?.stars
                ?.find((res) => res.value === 5)
                ?.tags.map((tag) => {
                  return { value: tag?.id, label: tag?.tag };
                })
            );
          })
          .then(() => {
            setIsAllDone(false);
            apiClient()
              .get(
                `${BACKEND_API}/review-new/get/questions-all?garage_id=${garage_id}`
              )
              .then((res) => {
                setLinkedTags(
                  res.filter((data) => data.id === edit_id)[0]?.stars
                );
                setIsTagsLoading(false);
              })
              .catch((err) => {
                //   toast.error(`ID: 1023 - Status: ${err?.response?.status} - ${err?.response?.data?.message ? err?.response?.data?.message : "Operation Failed, something is wrong!"} - Please call to the customer care.
                // `);
                setIsTagsLoading(false);
              });
          })
          .catch((err) => {
            SweetAlert.fire({
              title: "Error!",
              text: `ID: 1023 - Status: ${err?.response?.status} - ${
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "Operation Failed, something is wrong!"
              } - Please call to the customer care.`,
              icon: "warning",
            });
          });
      })
      .catch((err) => {
        SweetAlert.fire({
          title: "Error!",
          text: `ID: 1023 - Status: ${err?.response?.status} - ${
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Operation Failed, something is wrong!"
          } - Please call to the customer care.`,
          icon: "warning",
        });
        setIsTagsLoading(false);
      });
  }, [edit_id, garage_id]);

  // HANDELING ERRORS
  useEffect(() => {
    if (isSubmited) {
      setErrors({
        star1:
          star1.tags.length !== 0 ? "" : "* At least one tag have to select!",
        star2:
          star2.tags.length !== 0 ? "" : "* At least one tag have to select!",
        star3:
          star3.tags.length !== 0 ? "" : "* At least one tag have to select!",
        star4:
          star4.tags.length !== 0 ? "" : "* At least one tag have to select!",
        star5:
          star5.tags.length !== 0 ? "" : "* At least one tag have to select!",
      });
    }
  }, [isSubmited]);

  // MAKE CUSTOM OBJECT FROM  ALL TAGS
  var option = allTags.map((res) => {
    const object = {
      value: res.id,
      label: res.tag,
    };
    return object;
  });

  // UPDATE BUTTON HANDLER
  const handleSubmit = () => {
    setIsSubmited(true);
    const questionData = {
      id: singleQuestion?.id,
      question: questionDetails,
      garage_id: garage_id,
      is_active: questionStatus,
      type: questionType,
    };
    var linkData = {
      question_id: singleQuestion?.id,
      stars: [],
    };

    star1.tags.length !== 0 && linkData.stars.push(star1);
    star2.tags.length !== 0 && linkData.stars.push(star2);
    star3.tags.length !== 0 && linkData.stars.push(star3);
    star4.tags.length !== 0 && linkData.stars.push(star4);
    star5.tags.length !== 0 && linkData.stars.push(star5);

    // IF ALL STAR HAVE AT LEAST 1 VALUE
    if (
      star1.tags.length !== 0 &&
      star2.tags.length !== 0 &&
      star3.tags.length !== 0 &&
      star4.tags.length !== 0 &&
      star5.tags.length !== 0
    ) {
      setIsSubmissionLoading(true);
      apiClient()
        .put(`${BACKEND_API}/review-new/update/questions`, questionData)
        .then((res) => {
          // IF QUESTION UPDATE DONE
          if (res?.data?.id) {
            apiClient()
              .post(
                `${BACKEND_API}/review-new/owner/update/questions?_method=PATCH`,
                linkData
              )
              .then((res) => {
                // IF LINKED DONE
                if (res?.data?.message) {
                  SweetAlert.fire({
                    title: "Success!",
                    text: `Question Updated Successfully!`,
                    icon: "success",
                  });
                  setTimeout(() => {
                    toggleModal();
                    fetchData();
                  }, 1000);
                  setIsSubmissionLoading(false);
                }
              })
              .catch((err) => {
                SweetAlert.fire({
                  title: "Error!",
                  text: `ID: 1023 - Status: ${err?.response?.status} - ${
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Operation Failed, something is wrong!"
                  } - Please call to the customer care.`,
                  icon: "warning",
                });
              });
          }
        })
        .catch((err) => {
          SweetAlert.fire({
            title: "Error!",
            text: `ID: 1023 - Status: ${err?.response?.status} - ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Operation Failed, something is wrong!"
            } - Please call to the customer care.`,
            icon: "warning",
          });
        });
    } else {
      setIsSubmissionLoading(false);
    }
  };
  return (
    <div className={styles.popupContainer}>
      {isTagsLoading ? (
        <div className={styles.loadingContainer}>
          <CustomLoadingSpiner />
        </div>
      ) : (
        <div className={styles.popupMain}>
          <div>
            {/* QUESTION INFORMATIONS  */}
            <div className={styles.questionPopupTableRowContainer}>
              <div className={styles.questionPopupLeft}>Title</div>
              <div className={styles.questionPopupRight}>
                <input
                  onChange={(e) => setQuestionDetails(e.target.value)}
                  className={styles.questionPopupQuestionInput}
                  defaultValue={editQuestion?.question}
                  type="text"
                  name="question"
                  placeholder="Question"
                />
              </div>
            </div>
            <div className={styles.questionPopupTableRowContainer}>
              <div className={styles.questionPopupLeft}>Status</div>
              <div className={styles.questionPopupRight}>
                <Select
                  required
                  onChange={(e) => setQuestionStatus(e.value)}
                  name="status"
                  isSearchable
                  styles={{ outline: "none" }}
                  components={animatedComponents}
                  defaultValue={{
                    value: singleQuestion.is_active,
                    label: singleQuestion.is_active ? "Public" : "Private",
                  }}
                  options={[
                    { value: 0, label: "Private" },
                    { value: 1, label: "Public" },
                  ]}
                />
              </div>
            </div>
            <br />

            {/* RATING TYPE  */}
            <div>
              <div className={styles.typeTitle}>Select Emotion</div>
              <div
                className={`${styles.typeButtonContainer} flex-col sm:flex-row gap-1`}
              >
                <Button
                  color={questionType === "star" ? "danger" : "primary"}
                  className={`w-full mb-1`}
                  onClick={() => {
                    setQuestionType("star");
                  }}
                >
                  Star ⭐
                </Button>
                <Button
                  color={questionType === "emoji" ? "danger" : "primary"}
                  className={`w-full mb-1`}
                  onClick={() => {
                    setQuestionType("emoji");
                  }}
                >
                  Emoji 🙂
                </Button>
                <Button
                  color={questionType === "numbers" ? "danger" : "primary"}
                  className={`w-full mb-1`}
                  onClick={() => {
                    setQuestionType("numbers");
                  }}
                >
                  Numbers 🔢
                </Button>
                <Button
                  color={questionType === "heart" ? "danger" : "primary"}
                  className={`w-full mb-1`}
                  onClick={() => {
                    setQuestionType("heart");
                  }}
                >
                  Heart 🧡
                </Button>
              </div>
            </div>
            <br />

            {/* TAG INFORMATIONS  */}
            <div className={styles.questionPopupTableRowContainer}>
              <div className={styles.questionPopupLeft}>Ratings</div>
              <div className={styles.questionPopupRight}>Labels</div>
            </div>

            {!isTagsLoading && !isAllDone && (
              <>
                <div className={styles.questionPopupTableRowContainer}>
                  <div className={styles.questionPopupLeft}>
                    {questionType === "star"
                      ? "⭐"
                      : questionType === "emoji"
                      ? "🙂"
                      : questionType === "heart"
                      ? "🧡"
                      : "1"}
                  </div>
                  <div className={styles.questionPopupRight}>
                    <span className={styles.errorMessage}>
                      {errors.star1 !== "" && errors.star1}
                    </span>
                    {option.length === 0 ? (
                      "loading..."
                    ) : (
                      <Select
                        required
                        onChange={handleChangeForStar1}
                        styles={{ outline: "none" }}
                        components={animatedComponents}
                        placeholder={"Select Labels"}
                        isMulti
                        defaultValue={defaultTags1}
                        options={option}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.questionPopupTableRowContainer}>
                  <div className={styles.questionPopupLeft}>
                    {questionType === "star"
                      ? "⭐⭐"
                      : questionType === "emoji"
                      ? "🙂🙂"
                      : questionType === "heart"
                      ? "🧡🧡"
                      : "1 2"}
                  </div>
                  <div className={styles.questionPopupRight}>
                    <span className={styles.errorMessage}>
                      {errors.star2 !== "" && errors.star2}
                    </span>
                    {option.length === 0 ? (
                      "loading..."
                    ) : (
                      <Select
                        required
                        onChange={handleChangeForStar2}
                        styles={{ outline: "none" }}
                        components={animatedComponents}
                        placeholder={"Select Labels"}
                        isMulti
                        defaultValue={defaultTags2}
                        options={option}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.questionPopupTableRowContainer}>
                  <div className={styles.questionPopupLeft}>
                    {questionType === "star"
                      ? "⭐⭐⭐"
                      : questionType === "emoji"
                      ? "🙂🙂🙂"
                      : questionType === "heart"
                      ? "🧡🧡🧡"
                      : "1 2 3"}
                  </div>
                  <div className={styles.questionPopupRight}>
                    <span className={styles.errorMessage}>
                      {errors.star3 !== "" && errors.star3}
                    </span>
                    {option.length === 0 ? (
                      "loading..."
                    ) : (
                      <Select
                        required
                        onChange={handleChangeForStar3}
                        styles={{ outline: "none" }}
                        components={animatedComponents}
                        placeholder={"Select Labels"}
                        isMulti
                        defaultValue={defaultTags3}
                        options={option}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.questionPopupTableRowContainer}>
                  <div className={styles.questionPopupLeft}>
                    {questionType === "star"
                      ? "⭐⭐⭐⭐"
                      : questionType === "emoji"
                      ? "🙂🙂🙂🙂"
                      : questionType === "heart"
                      ? "🧡🧡🧡🧡"
                      : "1 2 3 4"}
                  </div>
                  <div className={styles.questionPopupRight}>
                    <span className={styles.errorMessage}>
                      {errors.star4 !== "" && errors.star4}
                    </span>
                    {option.length === 0 ? (
                      "loading..."
                    ) : (
                      <Select
                        required
                        onChange={handleChangeForStar4}
                        styles={{ outline: "none" }}
                        components={animatedComponents}
                        placeholder={"Select Labels"}
                        isMulti
                        defaultValue={defaultTags4}
                        options={option}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.questionPopupTableRowContainer}>
                  <div className={styles.questionPopupLeft}>
                    {questionType === "star"
                      ? "⭐⭐⭐⭐⭐"
                      : questionType === "emoji"
                      ? "🙂🙂🙂🙂🙂"
                      : questionType === "heart"
                      ? "🧡🧡🧡🧡🧡"
                      : "1 2 3 4 5"}
                  </div>
                  <div className={styles.questionPopupRight}>
                    <span className={styles.errorMessage}>
                      {errors.star5 !== "" && errors.star5}
                    </span>
                    {option.length === 0 ? (
                      "loading..."
                    ) : (
                      <Select
                        required
                        onChange={handleChangeForStar5}
                        styles={{ outline: "none" }}
                        components={animatedComponents}
                        placeholder={"Select Labels"}
                        isMulti
                        defaultValue={defaultTags5}
                        options={option}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <Button
            color="primary"
            className="w-100 my-5"
            disabled={isSubmissionLoading}
            onClick={handleSubmit}
          >
            {isSubmissionLoading ? "Loading..." : "Update"}
          </Button>
        </div>
      )}
    </div>
  );
}
