import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getGarageDetailsList = async (id) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/client/garages/service-model-details/${id}`).then(response => {
        return response.data;
    }).catch(error => {

    })
}
export const getGarageModelWithMake = async (id, makeId) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/client/garages/garage-automobile-models/${id}/${makeId}`).then(response => {
        return response.data;
    }).catch(error => {

    })
}

export const getGaragePackages = async (id) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/garage-packages/get/all/${id}`).then(response => {
        return response.data;
    }).catch(error => {

    })
}
export const getUserByPhone = async (phone) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/customer-users/get-by-phone/${phone}`).then(response => {
        return response.data;
    })
}
export const couponValidation = async (garageid, code, amount) => {
    return await apiClient().get(`${BACKEND_API}/v1.0/client/coupons/get-discount/${garageid}/${code}/${amount}`).then(response => {
        return response.data;
    }).catch(error => {

    })
}

export const postBookingDetails = async (data) => {
    return await apiClient().post(`${BACKEND_API}/v1.0/bookings`, data).then(response => {
        return response.data;
    })
}
export const regesterUserCustomer = async (data) => {
    return await apiClient().put(`${BACKEND_API}/v1.0/customer-users`, data).then(response => {
        return response.data;
    })
}
