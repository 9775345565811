import moment from "moment";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { applyJobData } from "../../../api/findJobs";
import CustomTimeSelector from "../../fuel_station_management/components/CustomTImeSelector";
import CustomTImeSelectorTenMinInterval from "../../fuel_station_management/components/CustomTImeSelectorTenMinInterval";
import {
  getExcludeDays,
  getGarageTiming,
} from "../../../utils/GetGarageTimingFromDateSelection";
import { useNavigate } from "react-router";

function ApplyJobModal({
  modal,
  toggle,
  garageId,
  jobId,
  getAllJobs,
  jobDataForApplingJob,
  isFrom,
}) {
  const navigate = useNavigate();
  let currency = localStorage.getItem("currency");
  const [startDate, setstartDate] = useState(new Date());
  const [submitData, setSubmitData] = useState({
    garage_id: garageId,
    pre_booking_id: jobId,
    price: "",
    job_start_date: startDate,
    job_start_time: "",
    job_end_time: "",
    offer_template: "",
  });

  const startTime = (e) => {
    if (
      moment(e.target.value, "HH:mm").isBefore(
        moment(submitData?.job_end_time, "HH:mm")
      )
    ) {
      setSubmitData({ ...submitData, job_start_time: e.target.value });
    } else {
      if (submitData?.job_end_time === "") {
        setSubmitData({ ...submitData, job_start_time: e.target.value });
      } else {
        setSubmitData({ ...submitData, job_start_time: "" });
        SweetAlert.fire(
          "Error!",
          `Job End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };
  const endTime = (e) => {
    if (
      moment(submitData?.job_start_time, "HH:mm").isBefore(
        moment(e.target.value, "HH:mm")
      )
    ) {
      setSubmitData({ ...submitData, job_end_time: e.target.value });
    } else {
      if (submitData?.job_start_time === "") {
        setSubmitData({ ...submitData, job_end_time: e.target.value });
      } else {
        setSubmitData({ ...submitData, job_end_time: "" });

        SweetAlert.fire(
          "Error!",
          `Job End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };

  // VALIDATE TIME FUNCTIONALITY
  const [timeRange, setTimeRange] = useState({
    startingTime: "",
    closingTime: "",
    isClosed: 0,
  });

  useEffect(() => {
    setTimeRange(getGarageTiming(startDate));
    console.log({ date: startDate });
  }, [startDate]);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // Validate price
    if (!submitData?.price) {
      newErrors.price = "Price is required.";
    } else if (submitData.price.length === 0) {
      newErrors.price = "Price is required.";
    }
    // Validate START DATE
    if (!submitData?.job_start_date) {
      newErrors.job_start_date = "Job start date is required.";
    } else if (submitData.job_start_date === "") {
      newErrors.job_start_date = "Job start date is required.";
    } else if (timeRange?.isClosed) {
      newErrors.job_start_date = "Selected day is offday for your garage.";
    }
    // Validate START TIME
    if (!submitData?.job_start_time) {
      newErrors.job_start_time = "Job start time is required.";
    } else if (submitData.job_start_time === "") {
      newErrors.job_start_time = "Job start time is required.";
    }
    // Validate END TIME
    if (!submitData?.job_end_time) {
      newErrors.job_end_time = "Job end time is required.";
    } else if (submitData.job_end_time === "") {
      newErrors.job_end_time = "Job end time is required.";
    }
    // Validate END TIME
    if (
      !submitData?.offer_template ||
      submitData?.offer_template.trim() === ""
    ) {
      newErrors.offer_template = "Note is required.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const [isFormLoading, setFormLoading] = useState(false);

  const applyJobSubmit = () => {
    const data = { ...submitData, pre_booking_id: jobId, garage_id: garageId };

    if (validateForm()) {
      setFormLoading(true);
      applyJobData(data)
        .then((res) => {
          if ((isFrom = "list")) {
            getAllJobs && getAllJobs(garageId, 100);
            SweetAlert.fire("Applied!", `Successfully applied job.`, "success");
            toggle();
          } else {
            navigate("/garage/find-jobs");
            SweetAlert.fire("Applied!", `Successfully applied job.`, "success");
            toggle();
          }
          setFormLoading(false);

          SweetAlert.fire("Applied!", `Successfully applied job.`, "success");
          toggle();
        })
        .catch((err) => {
          setFormLoading(false);

          if (err?.response?.data?.errors) {
            const errors = err?.response?.data?.errors;
            Object.keys(errors).forEach((field) => {
              errors[field].forEach((errorMessage, index) =>
                SweetAlert.fire({
                  title: err?.response?.data?.message,
                  text: `${errorMessage}`,
                  icon: "warning",
                })
              );
            });
          } else {
            SweetAlert.fire({
              title: "Error!",
              text: `${err?.response?.data?.message}`,
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Apply Quote</ModalHeader>
        <ModalBody>
          <Container className="form-row mb-2">
            <Col md="6 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="closing_time"
              >
                Job Start Date
              </Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control digits border-secondary "
                selected={startDate ? startDate : null}
                onChange={(date) => {
                  if (date !== null) {
                    setstartDate(date);
                    setSubmitData({
                      ...submitData,
                      job_start_date: moment(date).format("YYYY-MM-DD"),
                      job_start_time: "",
                      job_end_time: "",
                    });
                  }
                }}
                selectsStart
                startDate={startDate}
              />
              {errors?.job_start_date && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_start_date}
                </p>
              )}
            </Col>

            {/* JOB STARTING TIME  */}
            <Col sm="6 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="job_start_time"
              >
                Job Start Time
              </Label>
              <CustomTImeSelectorTenMinInterval
                restrictionTime={timeRange}
                onChange={startTime}
                value={submitData.job_start_time}
                dataTestid="job_start_time"
                name={"job_start_time"}
                id={"job_start_time"}
                required={false}
                className={"form-control border-secondary"}
              />
              {errors?.job_start_time && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_start_time}
                </p>
              )}
            </Col>

            {/* JOB CLOSING TIME  */}
            <Col sm="6 mb-3">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="job_end_time"
              >
                Job End Time
              </Label>
              <CustomTImeSelectorTenMinInterval
                restrictionTime={timeRange}
                onChange={endTime}
                value={submitData.job_end_time}
                dataTestid="job_end_time"
                className={"form-control border-secondary"}
                name={"job_end_time"}
                id={"job_end_time"}
                required={false}
              />
              {errors?.job_end_time && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.job_end_time}
                </p>
              )}
            </Col>

            {/* PRICE  */}
            <Col className="w-100" md="6">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="closing_time"
              >
                Quote Price
              </Label>
              <input
                onChange={(e) =>
                  setSubmitData({ ...submitData, price: e.target.value })
                }
                placeholder="Enter Job Price"
                className="w-100 ps-5 pe-3 py-2 rounded border border-1 border-secondary"
                type="number"
              />
              <span className="currency_input_apply_job fw-bold">
                {currency}
              </span>
              {errors?.price && (
                <p className="text-danger -mt-25 text-left">{errors?.price}</p>
              )}
            </Col>

            <Col className="w-100" md="12">
              <Label
                className="text-uppercase fw-bolder"
                htmlFor="closing_time"
              >
                Note
              </Label>
              <textarea
                name=""
                id=""
                rows="3"
                onChange={(e) =>
                  setSubmitData({
                    ...submitData,
                    offer_template: e.target.value,
                  })
                }
                placeholder="Enter Notes"
                className="px-3 py-2 rounded border border-1 border-secondary"
                style={{
                  width: "100%",
                }}
              />
              {errors?.offer_template && (
                <p className="text-danger -mt-25 text-left">
                  {errors?.offer_template}
                </p>
              )}
            </Col>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isFormLoading}
            color="primary"
            onClick={applyJobSubmit}
          >
            {isFormLoading ? "Loading..." : "Submit Quote"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              setSubmitData({
                garage_id: garageId,
                pre_booking_id: jobId,
                price: "",
                job_start_date: startDate,
                job_start_time: "",
                job_end_time: "",
                offer_template: "",
              });

              setErrors([]);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ApplyJobModal;
