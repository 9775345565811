import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";

import { BACKEND_API } from "../../../utils/backend";

import setLinksView from "../../../utils/pagination";

import moment from "moment";
import DatePicker from "react-datepicker";
import { Briefcase } from "react-feather";
import SweetAlert from "sweetalert2";
import { getGarageList } from "../../../api/findJobs";
import { BOOKING_DELETE, BOOKING_VIEW } from "../../../constant/permissions";
import Error401 from "../../../pages/errors/Error401";
import { apiClient } from "../../../utils/apiClient";
import { checkPermissions } from "../../../utils/helperFunctions";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";
import { MdStarRate } from "react-icons/md";
import SingleUserRating from "../review_management/Review/RatingTable/SingleUserRating";

const UserReview = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [garageId, setGarageId] = useState("");
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/review-new/get/questions-all-report-by-user/${perPage}?garage_id=${garageId}&&start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      garageId,
      `${BACKEND_API}/review-new/get/questions-all-report-by-user/${perPage}?garage_id=${garageId}&&start_date=${moment(
        startDataFinal
      ).format("YYYY-MM-DD")}&&end_date=${moment(endDataFinal).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  // modal
  const [garageViewData, setGarageViewData] = useState(null);
  const [garageViewModal, setGarageViewModal] = useState(false);
  const [bookingStatus, setJobStatus] = useState(false);

  const garageViewModaltoggle = () => setGarageViewModal(!garageViewModal);
  const bookingStatusModaltoggle = () => setJobStatus(!bookingStatus);

  const viewForm = (el) => {
    setGarageViewData(el);
    garageViewModaltoggle();
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (garageId, urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
    } else {
      url = `${BACKEND_API}/review-new/get/questions-all-report-by-user/${urlOrPerPage}?garage_id=${garageId}`;
    }

    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const [jobId, setJobId] = useState({});
  const handleChangeStatus = (data) => {
    setJobStatus(true);
    setJobId(data);
  };

  useEffect(() => {
    getGarageList().then((res) => {
      setGarageId(res[0]?.id);
      fetchData(res[0]?.id, perPage);
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  console.log({ data });

  if (!permissions.includes(BOOKING_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      <Modal
        isOpen={garageViewModal}
        toggle={garageViewModaltoggle}
        size="xl"
        className="rounded-xl"
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <div className="d-flex">
              <MdStarRate className="icon-filter fs-1 mb-2 text-primary"></MdStarRate>
              <MdStarRate className="icon-filter fs-1 mb-2 text-primary"></MdStarRate>
              <MdStarRate className="icon-filter fs-1 mb-2 text-primary"></MdStarRate>
              <MdStarRate className="icon-filter fs-1 mb-2 text-primary"></MdStarRate>
              <MdStarRate className="icon-filter fs-1 mb-2 text-primary"></MdStarRate>
            </div>
            <h4 className="fw-bolder text-black-50">
              User Review Details Preview
            </h4>
          </Col>
        </Row>
        <ModalBody>
          <SingleUserRating reviewDetails={garageViewData} />
        </ModalBody>
      </Modal>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>User Review Report</h5>
                  </div>
                </div>
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12"></Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"User Name"}</th>
                      <th scope="col">{"Date"}</th>
                      <th scope="col">{"Total Rating"}</th>
                      <th className="text-center" scope="col">
                        {"Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, index) => {
                          return (
                            <tr className="Dashed" key={el?.id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {el?.first_Name} {el?.last_Name}
                              </td>
                              <td>
                                {moment(el?.review_created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              <td>
                                <MdStarRate />
                                {(
                                  el?.review_info?.part1?.total_rating * 1
                                ).toFixed(2)}
                              </td>
                              <td>
                                <div className="text-end d-flex gap-1 justify-content-center">
                                  <Button
                                    title="View Job"
                                    onClick={() => viewForm(el)}
                                    color="success"
                                    size="sm"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links
                        ? links.map((el, index, arr) =>
                            setLinksView(
                              el,
                              index,
                              arr,
                              fetchData,
                              current_page,
                              lastPage
                            )
                          )
                        : null}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserReview;
