import React, { useState } from "react";
import { Card } from "react-bootstrap";
import {
  AiFillCar,
  AiFillTool,
  AiOutlineBarcode,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineGlobal,
  AiOutlineUser,
} from "react-icons/ai";
import {
  Alert,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
} from "reactstrap";
import MakeDropdown from "./MakeDropdown";
import ServiceDropdown from "./ServiceDropdown";
import moment from "moment";
import { MdArrowDropDown, MdLocationOn } from "react-icons/md";
import ApplyJobModal from "./ApplyJobModal";
import { useNavigate } from "react-router";
import { encryptionID } from "../../../utils/encription";
const JobsCard = ({
  serviceList,
  makeList,
  searchData,
  setSearchData,
  findJobhandler,
  jobsList,
  garageList,
  getAllJobs,
}) => {
  function getDistanceFromLatLonInKm(lat2, lon2) {
    const earthRadiusKm = 6371;

    const dLat = deg2rad(lat2 * 1 - garageList[0]?.lat * 1);
    const dLon = deg2rad(lon2 * 1 - garageList[0]?.long * 1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(garageList[0]?.lat * 1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadiusKm * c;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [showService, setShowService] = useState({
    show: false,
    index: "",
  });

  const [jobId, setJobId] = useState("");
  const [jobDataForApplingJob, setJobDataForApplingJob] = useState({});
  const onApplyJobClick = (id, data) => {
    setJobId(id);
    setJobDataForApplingJob(data);
    if (id) {
      toggle();
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <ApplyJobModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        jobId={jobId}
        jobDataForApplingJob={jobDataForApplingJob}
        garageId={garageList[0]?.id}
        getAllJobs={getAllJobs}
        isFrom="list"
      />
      {jobsList?.data?.length === 0 ? (
        <div
          style={{
            position: "absolute",
            top: "400px",
            display: "block",
            width: "100%",
            paddingRight: "70px",
          }}
        >
          <div className="row mx-2 row-cols-1 row-cols-lg-4 g-2 g-lg-3 job-filter mb-3 w-100">
            <div className="col mt-0 ">
              <ServiceDropdown
                serviceList={serviceList}
                setSearchData={setSearchData}
                searchData={searchData}
              />
            </div>
            <div className="col mt-0 ">
              <MakeDropdown
                makeList={makeList}
                setSearchData={setSearchData}
                searchData={searchData}
              />
            </div>
            <div className="col mt-0 ">
              <input
                onChange={(e) =>
                  setSearchData({
                    ...searchData,
                    town_or_postal_code: e.target.value,
                  })
                }
                placeholder="Town or Postcode"
                className="w-100 px-3 py-2 rounded border border-1"
                type="text"
              />
            </div>
            <div className="col mt-0 ">
              <button
                onClick={findJobhandler}
                className="btn btn-primary py-2 w-100"
              >
                FInd Jobs
              </button>
            </div>
          </div>
          <Alert color="danger">
            <p className="text-center"> No Jobs Found Please Try Different!</p>
          </Alert>
        </div>
      ) : (
        jobsList?.data?.length !== 0 && (
          <div
            style={{
              position: "absolute",
              top: "400px",
              display: "block",
              width: "95%",
            }}
          >
            <div className="row mx-2 row-cols-1 row-cols-lg-4 g-2 g-lg-3 job-filter mb-3 w-100">
              <div className="col mt-0 ">
                <ServiceDropdown
                  serviceList={serviceList}
                  setSearchData={setSearchData}
                  searchData={searchData}
                />
              </div>
              <div className="col mt-0 ">
                <MakeDropdown
                  makeList={makeList}
                  setSearchData={setSearchData}
                  searchData={searchData}
                />
              </div>
              <div className="col mt-0 ">
                <input
                  onChange={(e) =>
                    setSearchData({
                      ...searchData,
                      town_or_postal_code: e.target.value,
                    })
                  }
                  placeholder="Town or Postcode"
                  className="w-100 px-3 py-2 rounded border border-1"
                  type="text"
                />
              </div>
              <div className="col mt-0 ">
                <button
                  onClick={findJobhandler}
                  className="btn btn-primary py-2 w-100"
                >
                  FInd Jobs
                </button>
              </div>
            </div>

            <div className="container" style={{ marginBottom: "50px" }}>
              <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-2 g-lg-3 w-100">
                {jobsList?.data?.map((res, i) => (
                  <div className="col">
                    <Card className="my-2 job-card" color="secondary" outline>
                      <CardHeader className="job-card-header">
                        <div className="job-card-main-head py-3 pb-1">
                          <h6 className="text-center fw-bold ">
                            {res?.automobile_make?.name} -{" "}
                            {res?.automobile_model?.name}
                          </h6>
                          <p className="text-center fw-bold ">
                            {moment(res?.job_start_date).calendar(null, {
                              sameDay: "ll [Today]",
                              nextDay: "ll [Tomorrow]",
                              nextWeek: "ll dddd",
                              lastDay: "ll [Yesterday]",
                              lastWeek: "ll dddd",
                              sameElse: "ll dddd",
                            })}
                          </p>
                        </div>
                        <div className="d-flex">
                          {res?.garage_applied === 1 ? (
                            <span className=" d-flex align-items-center gap-2 new-job-noti bg-secondary">
                              <AiFillTool style={{ fontSize: 15 }} /> YOU
                              ALREADY APPLIED
                            </span>
                          ) : res?.job_bids_count === 0 ? (
                            <span className=" d-flex align-items-center gap-2  new-job-noti bg-success">
                              <AiFillTool style={{ fontSize: 15 }} /> NEW JOB -
                              APPLY NOW
                            </span>
                          ) : (
                            <span className="  d-flex align-items-center gap-2 new-job-noti bg-warning">
                              <AiFillTool style={{ fontSize: 15 }} />{" "}
                              {res?.job_bids_count} GARAGES HAVE APPLIED
                            </span>
                          )}
                          <div class="triangle-left"></div>
                        </div>
                        <div className="fw-bold my-3">
                          <p className="mb-0 d-flex justify-content-center align-items-center apply-job-title">
                            TO START{" "}
                            <AiOutlineClockCircle className="fs-6 mx-2" />{" "}
                            {res.job_end_date
                              ? moment(res.job_end_date).format("ll")
                              : "NO DEADLINE"}{" "}
                          </p>
                          {/* <p className='mb-0  d-flex justify-content-center align-items-center apply-job-title'>COMMISSION <AiFillTool className='fs-6 mx-2' /> £12.50 + £2.50 VAT</p> */}
                        </div>
                      </CardHeader>
                      <CardBody className="job-card-body">
                        <div className="w-100 d-flex justify-content-center">
                          <table>
                            <tbody>
                              <tr>
                                <th className="th-Align">CUSTOMER</th>
                                <td className="id-icon-w">
                                  <AiOutlineUser />
                                </td>
                                <td>
                                  {res?.customer?.first_Name}{" "}
                                  {res?.customer?.last_Name}
                                </td>
                              </tr>
                              <tr>
                                <th className="th-Align">SERVICE</th>
                                <td className="id-icon-w">
                                  <AiFillTool />
                                </td>
                                <td>
                                  {res?.pre_booking_sub_services.length > 0 &&
                                    res?.pre_booking_sub_services?.slice(
                                      0,
                                      1
                                    )[0]?.sub_service?.name}
                                </td>
                              </tr>
                              <tr>
                                <th className="th-Align">REGISTRATION</th>
                                <td className="id-icon-w">
                                  <AiOutlineBarcode />
                                </td>
                                <td>{res?.car_registration_no}</td>
                              </tr>
                              <tr>
                                <th className="th-Align">MAKE - MODEL</th>
                                <td className="id-icon-w">
                                  <AiFillCar />
                                </td>
                                <td>
                                  {res?.automobile_make?.name} -{" "}
                                  {res?.automobile_model?.name}
                                </td>
                              </tr>
                              <tr>
                                <th className="th-Align">LOCATION</th>
                                <td className="id-icon-w">
                                  <MdLocationOn />
                                </td>
                                <td>
                                  {res?.city},{res?.country}
                                </td>
                              </tr>
                              <tr>
                                <th className="th-Align">DISTANCE</th>
                                <td className="id-icon-w">
                                  <AiOutlineGlobal />
                                </td>
                                <td>
                                  {getDistanceFromLatLonInKm(
                                    res?.lat,
                                    res?.long
                                  ).toFixed(2)}{" "}
                                  Km
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                      <CardFooter className="applyJob-card-footer">
                        {res?.garage_applied === 0 ? (
                          <div className="d-flex justify-content-between align-items-center ">
                            <button
                              onClick={() => onApplyJobClick(res?.id, res)}
                              className="w-100 apply-job-btn me-3"
                            >
                              Apply For Quote
                            </button>
                            <button
                              onClick={() =>
                                navigate(
                                  `/view_job/${encryptionID(
                                    garageList[0]?.id
                                  )}/${encryptionID(res?.id)}`
                                )
                              }
                              className="w-100 apply-job-btn2"
                            >
                              View Job
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-end align-items-center ">
                            <button
                              onClick={() =>
                                navigate(
                                  `/view_job/${encryptionID(
                                    garageList[0]?.id
                                  )}/${encryptionID(res?.id)}`
                                )
                              }
                              className="w-50 apply-job-btn2"
                            >
                              View Job
                            </button>
                          </div>
                        )}
                      </CardFooter>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default JobsCard;
