import { css } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  Pagination,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import {
  USER_CREATE,
  USER_DELETE,
  USER_UPDATE,
  USER_VIEW,
} from "../../constant/permissions";
import Error401 from "../../pages/errors/Error401";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import { checkPermissions } from "../../utils/helperFunctions";
import setLinksView from "../../utils/pagination";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import UserForm from "./forms/UserForm";
import UserView from "./vews/UserView";

const UserList = () => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [total, setTotal] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [current_page, set_current_page] = useState(0);
  const [startDate, setstartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setendDate] = useState(new Date());

  const setStartDate = (date) => {
    setstartDate(date);
    let startDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(endDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/users/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  const setEndDate = (date) => {
    setendDate(date);
    let startDataFinal = new Date(startDate)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let endDataFinal = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    fetchData(
      `${BACKEND_API}/v1.0/users/${perPage}?start_date=${startDataFinal}&&end_date=${endDataFinal}`
    );
  };
  // modal
  const [userCreateModal, setUserCreateModal] = useState(false);
  const userCreateModaltoggle = () => setUserCreateModal(!userCreateModal);

  const [userUpdateData, setUserUpdateData] = useState(null);
  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const userUpdateModaltoggle = () => setUserUpdateModal(!userUpdateModal);
  const editForm = (el) => {
    userUpdateModaltoggle();
    setUserUpdateData(el);
  };

  const [userViewData, setUserViewData] = useState(null);
  const [userViewModal, setUserViewModal] = useState(false);
  const userViewModaltoggle = () => setUserViewModal(!userViewModal);

  const viewForm = (el) => {
    userViewModaltoggle();
    setUserViewData(el);
  };

  // end modal

  const deleteFunc = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        apiClient()
          .delete(`${BACKEND_API}/v1.0/users/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.ok) {
              fetchData(perPage);
              SweetAlert.fire("Deleted!", "User has been deleted.", "success");
            }
          })
          .catch((error) => {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          });
      } else {
        SweetAlert.fire("User is safe");
      }
    });
  };

  const handlePerPage = (e) => {
    const newValue = parseInt(e.target.value);
    setPerPage(newValue);
    fetchData(newValue);
  };

  const fetchData = (urlOrPerPage, useLoading = true) => {
    if (useLoading) {
      setLoading(true);
    }

    //  setData(null)
    let url;
    if (typeof urlOrPerPage === "string") {
      url = urlOrPerPage;
      // url = urlOrPerPage.replace("http", http);
    } else {
      url = `${BACKEND_API}/v1.0/users/${urlOrPerPage}`;
    }
    apiClient()
      .get(url)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setLinks(response.data.links);
        set_current_page(response.data.current_page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [searchKey, setSearchKey] = useState("");
  const searchFunc = (e) => {
    setSearchKey(e.target.value);
    fetchData(
      `${BACKEND_API}/v1.0/users/${perPage}?search_key=${e.target.value}`,
      false
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchData(perPage);
  }, []);

  const override = css`
    border-color: black;
  `;

  if (!permissions.includes(USER_VIEW)) {
    return <Error401 />;
  }

  return (
    <Fragment>
      {/* =========================== ALL MODALS =============================  */}
      <Modal
        isOpen={userCreateModal}
        toggle={userCreateModaltoggle}
        size="md"
        className={"rounded-xl"}
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <i className="icon-pencil-alt fs-1 mb-2 text-primary"></i>
            <h4 className="fw-bolder text-secondary">Create User</h4>
          </Col>
        </Row>
        <ModalBody>
          <UserForm
            toggleModal={userCreateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="create"
            setIsOpen={setUserCreateModal}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={userUpdateModal}
        toggle={userUpdateModaltoggle}
        size="md"
        className={"rounded-xl"}
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <i className="icon-pencil-alt fs-1 mb-2 text-primary"></i>
            <h4 className="fw-bolder text-secondary">Update User</h4>
          </Col>
        </Row>
        <ModalBody>
          <UserForm
            toggleModal={userUpdateModaltoggle}
            fetchData={fetchData}
            perPage={perPage}
            type="update"
            userUpdateData={userUpdateData}
            setIsOpen={setUserUpdateModal}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={userViewModal}
        toggle={userViewModaltoggle}
        size="md"
        className={"rounded-xl"}
      >
        <Row className="m-0 p-0 border-0 position-relative">
          <Col className="p-30 12 d-flex justify-content-center align-items-center flex-column w-100">
            <i className="icon-user fs-1 mb-2 text-primary"></i>
            <h4 className="fw-bolder text-secondary">Single User's Details</h4>
          </Col>
        </Row>
        <ModalBody>
          <UserView
            toggleModal={userViewModaltoggle}
            userViewData={userViewData}
            setIsOpen={setUserViewModal}
          />
        </ModalBody>
      </Modal>

      {/* <BreadCrumb parent="Home" subparent="User Management / Users" title="Manage Users" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <div>
                  <h5>User Management</h5>
                  <span> Manage your Users </span>
                </div>
                {checkPermissions([USER_CREATE], permissions) ? (
                  <Button color="primary" onClick={userCreateModaltoggle}>
                    Create User
                  </Button>
                ) : null}
              </CardHeader>
              <CardHeader className="px-0">
                <Row>
                  <Col lg="8" md="6" sm="12" xs="12">
                    <Form className="w-100 search-form">
                      <Input
                        className="h-100 form-control-plaintext border-secondary w-100"
                        type="search"
                        placeholder="Search.."
                        onChange={searchFunc}
                        value={searchKey}
                        autoFocus
                      />
                    </Form>
                  </Col>
                  <Col lg="4 mt-0" md="6 mt-0" sm="12 mt-0" xs="12 mt-1">
                    <Row>
                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits border-secondary "
                          selected={startDate}
                          onChange={setStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Col>

                      <Col md="6">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits ml-md-2 border-secondary"
                          selected={endDate}
                          onChange={setEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className="table-responsive px-4">
                <Table>
                  <thead>
                    <tr className="Dashed">
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"First Name"}</th>
                      <th scope="col">{"Last Name"}</th>
                      <th scope="col">{"email"}</th>
                      <th scope="col">{"phone"}</th>
                      <th scope="col">{"role"}</th>
                      <th scope="col">{"actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <CustomLoadingSpiner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data.map((el, i) => {
                          return (
                            <tr className="Dashed" key={el.id}>
                              <th scope="row">{i + 1}</th>
                              <td>{el.first_Name}</td>
                              <td>{el.last_Name}</td>
                              <td>{el.email}</td>
                              <td>{el.phone}</td>
                              <td>
                                {el.roles.map((el2) => {
                                  return <span key={el2.id}>{el2.name} </span>;
                                })}
                              </td>
                              <td className="text-end d-flex gap-1">
                                {checkPermissions([USER_VIEW], permissions) && (
                                  <Button
                                    onClick={() => viewForm(el)}
                                    color="success"
                                    size="sm"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Button>
                                )}
                                {checkPermissions(
                                  [USER_UPDATE],
                                  permissions
                                ) && (
                                  <Button
                                    onClick={() => editForm(el)}
                                    color="primary"
                                    size="sm"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Button>
                                )}
                                {checkPermissions(
                                  [USER_DELETE],
                                  permissions
                                ) && (
                                  <Button
                                    onClick={() => deleteFunc(el.id)}
                                    color="danger"
                                    size="sm"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardHeader>
                <div>
                  <div>
                    <div className="number">
                      {from} - {to} of {total}
                    </div>
                  </div>

                  <div>
                    <div className="items">
                      <label>Item per page</label>{" "}
                      <select onChange={handlePerPage} value={perPage}>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.6)",
                    }}
                    sm="12"
                    md="8"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      {links &&
                        links.map((el, index, arr) =>
                          setLinksView(
                            el,
                            index,
                            arr,
                            fetchData,
                            current_page,
                            lastPage
                          )
                        )}
                    </Pagination>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserList;
