import { apiClient } from "../utils/apiClient";
import { BACKEND_API } from "../utils/backend";

export const getJobs = async (
  garage_id,
  perPage,
  sub_service_ids,
  automobile_make_ids,
  country_or_postcode
) => {
  return await apiClient()
    .get(
      `${BACKEND_API}/v1.0/pre-bookings/${garage_id}/${perPage}?sub_service_ids[]=${sub_service_ids}&automobile_make_ids[]=${automobile_make_ids}&country_or_postcode=${country_or_postcode}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};
export const getAllJobs = async (garage_id, perPage) => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/pre-bookings/${garage_id}/${perPage}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const getGarageList = async () => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/garages/by-garage-owner/all`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const getServiceList = async () => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/services-all/1`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};
export const getSubServiceList = async () => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/sub-services-all`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};
export const getSubServiceListByGarageId = async (id) => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/garage-sub-services-all/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getMakeList = async () => {
  return await apiClient()
    .get(`${BACKEND_API}/v2.0/automobile-makes-all/1`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};
export const applyJobData = async (data) => {
  return await apiClient()
    .post(`${BACKEND_API}/v1.0/job-bids`, data)
    .then((response) => {
      return response;
    });
};
export const editJobBidData = async (data) => {
  return await apiClient()
    .put(`${BACKEND_API}/v1.0/job-bids`, data)
    .then((response) => {
      return response;
    });
};
export const getSingleJob = async (garage_id, id) => {
  return await apiClient()
    .get(`${BACKEND_API}/v1.0/pre-bookings/single/${garage_id}/${id}`)
    .then((response) => {
      return response.data;
    });
};
