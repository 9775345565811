import React, { useState } from "react";
import TimeField from "react-simple-timefield";
import { Button, Input, Table } from "reactstrap";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import { getGarageTiming } from "../../api/garage_timing";
import { useEffect } from "react";
import { getGarageList } from "../../api/findJobs";
import Swal from "sweetalert2";
import Switch from "react-switch";
import CustomTimeSelector from "../fuel_station_management/components/CustomTImeSelector";
import moment from "moment";
import SweetAlert from "sweetalert2";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";
import { useNavigate } from "react-router";

export default function Schedule() {
  const [timeError, setTimeError] = useState(false);
  const [isUpdated, setIsUpdated] = useState(Math.random());
  const jwt = JSON.parse(localStorage.getItem("user"));
  const config = {
    headers: { Authorization: `Bearer ${jwt.token}` },
  };

  const [times, setTimes] = useState([
    {
      day: 0,
      opening_time: "",
      closing_time: "",
      day_name: "Sunday",
      is_closed: 0,
    },
    {
      day: 1,
      opening_time: "",
      closing_time: "",
      day_name: "Monday",
      is_closed: 0,
    },
    {
      day: 2,
      opening_time: "",
      closing_time: "",
      day_name: "Tuesday",
      is_closed: 0,
    },

    {
      day: 3,
      opening_time: "",
      closing_time: "",
      day_name: "Wednesday",
      is_closed: 0,
    },
    {
      day: 4,
      opening_time: "",
      closing_time: "",
      day_name: "Thursday",
      is_closed: 0,
    },
    {
      day: 5,
      opening_time: "",
      closing_time: "",
      day_name: "Friday",
      is_closed: 0,
    },
    {
      day: 6,
      opening_time: "",
      closing_time: "",
      day_name: "Saturday",
      is_closed: 0,
    },
  ]);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [garageId, setGarageId] = useState("");

  useEffect(() => {
    setIsDataLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getGarageList()
      .then((res) => {
        setGarageId(res[0]?.id);
        getGarageTiming(res[0]?.id)
          .then((time) => {
            if (time?.length !== 0) {
              setTimes(time?.sort((a, b) => a.day - b.day));
              localStorage.setItem(
                "timing",
                JSON.stringify(time?.sort((a, b) => a.day - b.day))
              );
              console.log({ object: time?.sort((a, b) => a.day - b.day) });
              setIsDataLoading(false);
            }
          })
          .catch((err) => {
            const errors = err?.response?.data?.errors;
            Object.keys(errors).forEach((field) => {
              errors[field].forEach((errorMessage, index) =>
                SweetAlert.fire({
                  title: "Error!!",
                  text: `${errorMessage}!`,
                  icon: "warning",
                })
              );
            });
            setIsDataLoading(false);
          });
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        Object.keys(errors).forEach((field) => {
          errors[field].forEach((errorMessage, index) =>
            SweetAlert.fire({
              title: "Error!!",
              text: `${errorMessage}!`,
              icon: "warning",
            })
          );
        });
        setIsDataLoading(false);
      });
  }, [isUpdated]);

  const onAlOpening_time = (event, value, i) => {
    let timesValue = [...times];
    const time = event.target.value;
    if (
      moment(time, "HH:mm").isBefore(
        moment(timesValue[i].closing_time, "HH:mm")
      )
    ) {
      timesValue[i].opening_time = `${time}`;
      setTimes(timesValue);
      setTimeError(false);
    } else {
      if (timesValue[i].closing_time === "") {
        timesValue[i].opening_time = `${time}`;
        setTimes(timesValue);
        setTimeError(false);
      } else {
        setTimeError(true);
        SweetAlert.fire(
          "Error!",
          `End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };

  const onAlClosing_time = (event, value, i) => {
    let timesValue = [...times];
    const time = event.target.value;
    if (
      moment(timesValue[i].opening_time, "HH:mm").isBefore(
        moment(time, "HH:mm")
      )
    ) {
      timesValue[i].closing_time = `${time}`;
      setTimes(timesValue);
      setTimeError(false);
    } else {
      if (timesValue[i].closing_time === "") {
        timesValue[i].closing_time = `${time}`;
        setTimes(timesValue);
        setTimeError(false);
      } else {
        setTimeError(true);
        SweetAlert.fire(
          "Error!",
          `End Time cannot be less than start time!.`,
          "error"
        );
      }
    }
  };

  const handleApplyALl = (e) => {
    setTimes(
      times.map((res) => {
        return {
          ...res,
          opening_time: times[1]?.opening_time,
          closing_time: times[1]?.closing_time,
          is_closed: times[1]?.is_closed,
        };
      })
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSave = () => {
    const data = {
      garage_id: garageId,
      times,
    };
    console.log({ data });
    setIsLoading(true);
    apiClient()
      .patch(`${BACKEND_API}/v1.0/garage-times`, data, config)
      .then((res) => {
        if (res) {
          getGarageTiming(garageId).then((time) => {
            if (time?.length !== 0) {
              setIsUpdated(Math.random());
              Swal.fire({
                title: "Success",
                text: "Timing Updated successfully!",
                icon: "success",
              });
            }
            setIsLoading(false);
          });
          setIsLoading(false);
          navigate("/garages/list");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        const errors = err?.response?.data?.errors;
        Object.keys(errors).forEach((field) => {
          errors[field].forEach((errorMessage, index) =>
            SweetAlert.fire({
              title: "Error!!",
              text: `${errorMessage}!`,
              icon: "warning",
            })
          );
        });
      });
  };

  const handleCheckboxChange = (index) => {
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = {
        ...newTimes[index],
        is_closed: newTimes[index].is_closed === 1 ? 0 : 1,
      };
      return newTimes;
    });
  };

  return (
    <div class="container">
      {isDataLoading ? (
        <CustomLoadingSpiner />
      ) : (
        <div class="row">
          <div class="col-12 col-md-1 w-100 w-md-50">
            <h1 className="text-center">Garage Schedule</h1>
            {/* FOR MOBILE  */}
            <div id="mobileTiming" className="d-block w-100 w-md-50">
              {/* MONDAY  */}
              <div className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom  pb-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold my-0">Monday</h5>
                  {/* APPLY TO ALL  */}
                  <div className="mt-2 d-flex justify-content-start align-items-center gap-3">
                    <button
                      onClick={handleApplyALl}
                      style={{
                        border: "none",
                      }}
                      className="mt-2 fw-bold px-3 py-2 rounded  bg-primary"
                      htmlFor="apply-to-all"
                    >
                      Apply to all
                    </button>
                  </div>
                </div>

                {/* MAIN CONTAINER  */}
                <div className="d-flex align-items-end gap-5">
                  {/* START  */}
                  <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                    <span className="fw-bold d-block w-50 text-center">
                      Start At
                    </span>
                    <CustomTimeSelector
                      everyClass={`text-center`}
                      onChange={(event, value) =>
                        onAlOpening_time(event, value, 1)
                      }
                      dataTestid="job_start_time"
                      name={"job_start_time"}
                      id={"job_start_time"}
                      required={false}
                      className={"form-control border-secondary w-100"}
                      value={times[1]?.opening_time}
                    />
                  </div>

                  {/* UNTILL  */}
                  <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                    <span className="fw-bold d-block w-50 text-center">
                      Untill
                    </span>
                    <CustomTimeSelector
                      everyClass={`text-center`}
                      value={times[1]?.closing_time}
                      onChange={(event, value) =>
                        onAlClosing_time(event, value, 1)
                      }
                      dataTestid="job_start_time"
                      name={"job_start_time"}
                      id={"job_start_time"}
                      required={false}
                      className={"form-control border-secondary w-100"}
                    />
                  </div>

                  {/* IS CLOSED  */}
                  <div className="d-flex gap-5 align-items-end justify-content-center">
                    <span className="fw-bold d-block w-25 ">Closed?</span>
                    <Switch
                      checked={times[1].is_closed}
                      onChange={() => handleCheckboxChange(1)}
                      className="react-switch scale-25"
                    />
                  </div>
                </div>
              </div>

              {times
                ?.sort((a, b) => a.day - b.day)
                ?.map((singleDay, index) => (
                  <>
                    {index > 1 && (
                      <div
                        className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom  pb-5"
                        key={index}
                      >
                        <h5 className="fw-bold">
                          {/* {index}.{" "} */}
                          {singleDay.day === 2
                            ? "Tuesday"
                            : singleDay.day === 3
                            ? "Wednesday"
                            : singleDay.day === 4
                            ? "Thursday"
                            : singleDay.day === 5
                            ? "Friday"
                            : singleDay.day === 6
                            ? "Saturday"
                            : "Sunday"}
                        </h5>

                        {/* MAIN CONTAINER  */}
                        <div className="d-flex align-items-end gap-5">
                          {/* START  */}
                          <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                            <span className="fw-bold d-block w-50 text-center">
                              Start At
                            </span>

                            <CustomTimeSelector
                              everyClass={`text-center`}
                              onChange={(event, value) =>
                                onAlOpening_time(event, value, index)
                              }
                              dataTestid="job_start_time"
                              name={"job_start_time"}
                              id={"job_start_time"}
                              required={false}
                              className={"form-control border-secondary w-100"}
                              value={singleDay.opening_time}
                            />
                          </div>

                          <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                            <span className="fw-bold d-block w-50 text-center">
                              Untill
                            </span>
                            <CustomTimeSelector
                              everyClass={`text-center`}
                              onChange={(event, value) =>
                                onAlClosing_time(event, value, index)
                              }
                              dataTestid="job_start_time"
                              name={"job_start_time"}
                              id={"job_start_time"}
                              required={false}
                              className={"form-control border-secondary w-100"}
                              value={singleDay.closing_time}
                            />
                          </div>

                          {/* CLOSED  */}
                          <div className="d-flex gap-5 align-items-end justify-content-center">
                            <span className="fw-bold d-block w-25 ">
                              Closed?
                            </span>
                            <Switch
                              checked={times[index].is_closed}
                              onChange={() => handleCheckboxChange(index)}
                              className="react-switch"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}

              {/* SUNDAY  */}
              <div className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom pb-5">
                <h5 className="fw-bold">Sunday</h5>

                {/* MAIN CONTAINER  */}
                <div className="d-flex align-items-end gap-5">
                  {/* START  */}
                  <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                    <span className="fw-bold d-block w-50 text-center">
                      Start At
                    </span>
                    <CustomTimeSelector
                      everyClass={`text-center`}
                      onChange={(event, value) =>
                        onAlOpening_time(event, value, 0)
                      }
                      dataTestid="job_start_time"
                      name={"job_start_time"}
                      id={"job_start_time"}
                      required={false}
                      className={"form-control border-secondary w-100"}
                      value={times[0]?.opening_time}
                    />
                  </div>

                  {/* UNTILL  */}
                  <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                    <span className="fw-bold d-block w-50 text-center">
                      Untill
                    </span>
                    <CustomTimeSelector
                      everyClass={`text-center`}
                      value={times[0]?.closing_time}
                      onChange={(event, value) =>
                        onAlClosing_time(event, value, 0)
                      }
                      dataTestid="job_start_time"
                      name={"job_start_time"}
                      id={"job_start_time"}
                      required={false}
                      className={"form-control border-secondary w-100"}
                    />
                  </div>

                  {/* CLLOSED  */}
                  <div className="d-flex gap-5 align-items-end justify-content-center">
                    <span className="fw-bold d-block w-25 ">Closed?</span>
                    <Switch
                      checked={times[0].is_closed}
                      onChange={() => handleCheckboxChange(0)}
                      className="react-switch scale-25"
                    />
                  </div>
                </div>
              </div>

              {/* Buttons  */}
              <div className="my-5">
                <Button disabled={isLoading} color="primary" onClick={onSave}>
                  {isLoading ? "Loading..." : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
