import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { AiOutlineDelete, AiOutlineFieldTime, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiCloudUpload } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { Gallery, Item } from 'react-photoswipe-gallery';
import { Badge, Button, Col, Container, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { apiClient } from '../../../utils/apiClient';
import { BACKEND, BACKEND_API } from '../../../utils/backend';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';

const FuelStationView = ({ fuelStationViewData, setIsOpen }) => {
  const randColor = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger'
  ]
  const [stepNo, setStepNo] = useState(1);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changed, setChanged] = useState(Math.random());
  const [subStep, setSubStep] = useState(0);

  useEffect(() => {
    apiClient().get(`${BACKEND_API}/v1.0/fuel-stations-galleries/${fuelStationViewData?.id}`)
      .then(res => {
        setImages(res?.data?.fuel_station_galleries)
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        Swal.fire({ title: "API Error", text: 'Some API errors occurs.', icon: 'error' })
      })
  }, [fuelStationViewData, subStep, changed])

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = ({ meta, file }, status) => {
  }

  const onSubmit = (files, allFiles) => {
    setFormSubmitLoading(true)
    let data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append("images[]", files[i]?.file, files[i]?.file?.name);
    }
    apiClient().post(`${BACKEND_API}/v1.0/fuel-stations-galleries/${fuelStationViewData?.id}`, data, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }).then((response) => {
      Swal.fire({ title: 'Success', text: 'Image uploaded successfully!', icon: 'success' });
      setFormSubmitLoading(false)
      setSubStep(0)

    }).catch((error) => {
      setFormSubmitLoading(false)
      Swal.fire({ title: 'Failed', text: 'Image upload failed!', icon: 'error' })
    });
    allFiles.forEach(f => f.remove())
  }

  const handleDelete = (imageId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient().delete(`${BACKEND_API}/v1.0/fuel-stations-galleries/${fuelStationViewData?.id}/${imageId}`).then(res => {
          Swal.fire({ title: 'Success', text: 'Image deleted successfully!', icon: 'success' });
          setChanged(Math.random())
        }).catch(err => {
          Swal.fire({ title: 'Failed', text: 'Image deletion failed!', icon: 'error' })
        })
      }
    })
  }

  return (
    <Row className='px-3'>
      <Col sm="12">
        {/* TAB BUTTONS CONTAINER  */}
        <Row className='mb-3'>
          <Container className='d-flex justify-content-start align-items-center gap-2 w-100 p-0'>
            <div
              onClick={() => setStepNo(1)}
              role='button'
              className={`py-2 px-3 border-bottom  ${stepNo === 1 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <AiOutlineInfoCircle /> Details
            </div>
            <div
              onClick={() => { setStepNo(2); setSubStep(0) }}
              role='button'
              className={`py-2 px-3 border-bottom ${stepNo === 2 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <AiOutlineFieldTime /> Schedule
            </div>
            <div
              onClick={() => { setStepNo(3); setSubStep(0) }}
              role='button'
              className={`py-2 px-3 border-bottom ${stepNo === 3 && "border-primary text-primary"} text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}>
              <TfiGallery /> Gallery
            </div>
          </Container>
        </Row>
        {isLoading ? <CustomLoadingSpiner /> :
          <>
            {/* DETAILS TAB  */}
            {stepNo === 1 &&
              <Container>
                <Container className="form-row mb-2">
                  <Col md="12 mb-3">
                    <Label className={"text-uppercase fw-bolder"} htmlFor="first_Name">Name</Label>
                    <p>{fuelStationViewData.name}</p>
                  </Col>
                  <Col md="12 mb-3">
                    <Label className={"text-uppercase fw-bolder"} htmlFor="last_Name">Description</Label>
                    <p>{fuelStationViewData.description}</p>
                  </Col>
                  <Col xs="12 mb-3">
                    <Label className={"text-uppercase fw-bolder"} htmlFor="validationCustomUsername">Address</Label>
                    <p>{fuelStationViewData.address}</p>
                  </Col>
                </Container>
              </Container>}
              
            {/* SCHEDULE TAB  */}
            {stepNo === 2 &&
              <Container className='my-3'>
                <Col md="6 mb-3">
                  <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Openning Time</Label>
                  <p>
                    {fuelStationViewData.opening_time.split(":")[0] === "00" ? // If the time is 00
                      <>
                        12:{fuelStationViewData.opening_time.split(":")[1]} AM
                      </>
                      :
                      parseInt(fuelStationViewData.opening_time.split(":")[0]) === 12 ? // If the time is 12
                        <>
                          {`${fuelStationViewData.opening_time.split(":")[0]}:${fuelStationViewData.opening_time.split(":")[1]} PM`}
                        </>
                        :
                        parseInt(fuelStationViewData.opening_time.split(":")[0]) > 12 ?  // If the time is greater than 12
                          <>
                            {`${parseInt(fuelStationViewData.opening_time.split(":")[0]) - 12}:${fuelStationViewData.opening_time.split(":")[1]} PM`}
                          </>
                          :
                          <>
                            {`${fuelStationViewData.opening_time.split(":")[0]}:${fuelStationViewData.opening_time.split(":")[1]} AM`}
                          </>}
                  </p>
                </Col>
                <Col md="6 mb-3">
                  <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Closing Time</Label>
                  <p>
                    {fuelStationViewData.closing_time.split(":")[0] === "00" ? // If the time is 00
                      <>
                        12:{fuelStationViewData.closing_time.split(":")[1]} AM
                      </>
                      :
                      parseInt(fuelStationViewData.closing_time.split(":")[0]) === 12 ? // If the time is 12
                        <>
                          {`${fuelStationViewData.closing_time.split(":")[0]}:${fuelStationViewData.closing_time.split(":")[1]} PM`}
                        </>
                        :
                        parseInt(fuelStationViewData.closing_time.split(":")[0]) > 12 ?  // If the time is greater than 12
                          <>
                            {`${parseInt(fuelStationViewData.closing_time.split(":")[0]) - 12}:${fuelStationViewData.closing_time.split(":")[1]} PM`}
                          </>
                          :
                          <>
                            {`${fuelStationViewData.closing_time.split(":")[0]}:${fuelStationViewData.closing_time.split(":")[1]} AM`}
                          </>}
                  </p>
                </Col>

                <Col xs="12 mb-3">
                  <Label className={"text-uppercase fw-bolder"} htmlFor="phone">Available Services</Label>
                  <Col sm="12" xl="12">
                    {fuelStationViewData?.options.map((item, i) => (
                      <Badge role='button' className='fs-7 my-1' key={i} color={randColor[i > 5 ? (i % 6) : i]} pill>
                        <div className='d-flex justify-content-center align-items-center gap-2'>
                          <i className={item?.option?.icon} /> {item?.option?.name}
                        </div>
                      </Badge>
                    ))}
                  </Col>
                </Col>
              </Container>}

            {/* GALLERY TAB  */}
            {stepNo === 3 &&
              <Container className='tab-2 p-0 mt-3 min-h-300' >
                <div className='w-100 h-25 d-flex justify-content-between align-items-center py-0 '>
                  <Button onClick={() => setSubStep(1)} color='primary' className=' d-flex justify-content-center align-items-center'>
                    <BiCloudUpload className='fs-5' /> Upload Image
                  </Button>
                  <Button onClick={() => setSubStep(2)} color='danger' className=' d-flex justify-content-center align-items-center'>
                    <MdDelete className='fs-5' /> Delete Image
                  </Button>
                </div>
                {isLoading ? <CustomLoadingSpiner />
                  :
                  <>
                    {/* IMAGE VIEW CONTAINER  */}
                    {subStep === 0 &&
                      <div className="px-4 my-3 min-h-300">
                        <Row className='garage-gallery-container' >
                          {images?.length > 0 ? (
                            <Col sm="12">
                              <div className="row my-gallery">
                                <div className="pswp-gallery row mx-0 p-0" id="my-test-gallery">
                                  <Gallery className="gallery-grid">
                                    {images.map((data, key) => (
                                      <Item
                                        key={key}
                                        original={`${BACKEND}${data?.image}`}
                                        thumbnail={`${BACKEND}${data?.image}`}
                                        id={`image-${key}`}
                                      >
                                        {({ ref, open }) => (
                                          <figure className="col-xl-3 col-sm-6 gallery-image-figure"

                                            key={key}>
                                            <div className='gallery-image-container'>
                                              <img
                                                ref={ref}
                                                onClick={open}
                                                src={`${BACKEND}${data?.image}`}
                                                alt="Gallery"
                                                className="img-thumbnail image-object-fit-cover h-100"
                                              />
                                            </div>
                                          </figure>
                                        )}
                                      </Item>
                                    ))}
                                  </Gallery>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <Container className='d-flex justify-content-center align-items-center h-50 w-100'>
                              <h3>No Image Found!</h3>
                            </Container>
                          )}
                        </Row>
                      </div>}

                    {/* IMAGE DROPZONE  */}
                    {subStep === 1 &&
                      <Col xs="12 d-flex align-items-center justify-content-center min-h-300 my-3">
                        {formSubmitLoading ?
                          <CustomLoadingSpiner /> :
                          <div className="dz-message needsclick mb-3 w-100">
                            <Dropzone
                              inputContent="Drop Images Here"
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              onSubmit={onSubmit}
                              accept="image/*"
                            />
                          </div>
                        }
                      </Col>}

                    {/* IMAGE DELETE CONTAINER  */}
                    {subStep === 2 &&
                      <Col className="px-4 my-3">
                        {isLoading ?
                          (
                            <CustomLoadingSpiner />
                          )
                          :
                          <Row>
                            <Col sm="12">
                              <div id="aniimated-thumbnials" className="row my-gallery gallery">
                                <Row className='h-300 overflowY-scroll mb-4'>
                                  {images.length > 0 ? images.map((image, i) => (
                                    <Col
                                      sm="12"
                                      md="6"
                                      role='button'
                                      className='d-flex justify-content-center align-items-center overflow-hidden my-2 '
                                    >
                                      <div className='galleryImageContainer position-relative d-flex justify-content-center align-items-center'>
                                        <div
                                          className='galleryImageButton position-absolute w-100 h-100 d-flex justify-content-center align-items-center '
                                        >
                                          <button
                                            onClick={() => handleDelete(image?.id)}
                                            className='gallery-image-delete-button'>
                                            <AiOutlineDelete className='fs-4 text-light' />
                                          </button>
                                        </div>
                                        <img
                                          className='image-object-fit-cover'
                                          src={`${BACKEND}${image?.image}`}
                                          alt="" />
                                      </div>
                                    </Col>
                                  )) : (
                                    <Col className="d-flex justify-content-center align-items-center h-50 w-100">
                                      <h3>No Image Found!</h3>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        }
                      </Col>}
                  </>}
              </Container>}
          </>
        }


        <Row className='mb-3'>
          <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
            <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Cancel</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FuelStationView