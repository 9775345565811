import React from "react";
import { Input, Table } from "reactstrap";
import Switch from "react-switch";
import CustomTimeSelector from "../fuel_station_management/components/CustomTImeSelector";

export default function ScheduleCreateGarage({
  onAlOpening_time,
  onAlClosing_time,
  handleApplyALl,
  handleCheckboxChange,
  times,
  serverSideErrors,
}) {
  return (
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-1 w-100 w-md-50">
          <h1 className="text-center">Garage Schedule</h1>
          {/* FOR MOBILE  */}
          <div id="mobileTiming" className="d-block w-100 w-md-50">
            {/* MONDAY  */}
            <div className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom  pb-5">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="fw-bold my-0">Monday</h5>

                {/* APPLY TO ALL  */}
                <div className="mt-2 d-flex justify-content-start align-items-center gap-3">
                  <button
                    onClick={handleApplyALl}
                    style={{
                      border: "none",
                    }}
                    className="mt-2 fw-bold px-3 py-2 rounded  bg-primary"
                    htmlFor="apply-to-all"
                  >
                    Apply to all
                  </button>
                </div>
              </div>

              {/* MAIN CONTAINER  */}
              <div className="d-flex align-items-end gap-5">
                {/* START  */}
                <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                  <span className="fw-bold d-block w-50 text-center">
                    Start At
                  </span>
                  <CustomTimeSelector
                    everyClass={`text-center`}
                    onChange={(event, value) =>
                      onAlOpening_time(event, value, 1)
                    }
                    dataTestid="job_start_time"
                    name={"job_start_time"}
                    id={"job_start_time"}
                    required={false}
                    className={"form-control border-secondary w-100"}
                    value={times[1]?.opening_time}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors["times.1.opening_time"] ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Time is not valid"}
                      </div>
                    ))}
                </div>

                {/* UNTILL  */}
                <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                  <span className="fw-bold d-block w-50 text-center">
                    Untill
                  </span>
                  <CustomTimeSelector
                    everyClass={`text-center`}
                    value={times[1]?.closing_time}
                    onChange={(event, value) =>
                      onAlClosing_time(event, value, 1)
                    }
                    dataTestid="job_start_time"
                    name={"job_start_time"}
                    id={"job_start_time"}
                    required={false}
                    className={"form-control border-secondary w-100"}
                  />
                  {serverSideErrors &&
                    (!serverSideErrors["times.1.opening_time"] ? (
                      <div
                        className="valid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Looks good!"}
                      </div>
                    ) : (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {"Time is not valid"}
                      </div>
                    ))}
                </div>

                {/* IS CLOSED  */}
                <div className="d-flex gap-3 align-items-end justify-content-center">
                  <span className="fw-bold d-block w-50 ">Closed?</span>
                  <Switch
                    checked={times[1].is_closed}
                    onChange={() => handleCheckboxChange(1)}
                    className="react-switch scale-25"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {times
          ?.sort((a, b) => a.day - b.day)
          ?.map((singleDay, index) => (
            <>
              {index > 1 && (
                <div
                  className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom  pb-5"
                  key={index}
                >
                  <h5 className="fw-bold">
                    {singleDay.day === 2
                      ? "Tuesday"
                      : singleDay.day === 3
                      ? "Wednesday"
                      : singleDay.day === 4
                      ? "Thursday"
                      : singleDay.day === 5
                      ? "Friday"
                      : singleDay.day === 6
                      ? "Saturday"
                      : "Sunday"}
                  </h5>

                  {/* MAIN CONTAINER  */}
                  <div className="d-flex align-items-end gap-5">
                    {/* START  */}
                    <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                      <span className="fw-bold d-block w-50 text-center">
                        Start At
                      </span>
                      <CustomTimeSelector
                        everyClass={`text-center`}
                        onChange={(event, value) =>
                          onAlOpening_time(event, value, index)
                        }
                        dataTestid="job_start_time"
                        name={"job_start_time"}
                        id={"job_start_time"}
                        required={false}
                        className={"form-control border-secondary w-100"}
                        value={singleDay.opening_time}
                      />
                      {serverSideErrors &&
                        (!serverSideErrors[
                          `times.${singleDay.day}.opening_time`
                        ] ? (
                          <div
                            className="valid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Looks good!"}
                          </div>
                        ) : (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Time is not valid"}
                          </div>
                        ))}
                    </div>

                    {/* UNTILL  */}
                    <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
                      <span className="fw-bold d-block w-50 text-center">
                        Untill
                      </span>

                      <CustomTimeSelector
                        everyClass={`text-center`}
                        onChange={(event, value) =>
                          onAlClosing_time(event, value, index)
                        }
                        dataTestid="job_start_time"
                        name={"job_start_time"}
                        id={"job_start_time"}
                        required={false}
                        className={"form-control border-secondary w-100"}
                        value={singleDay.closing_time}
                      />
                      {serverSideErrors &&
                        (!serverSideErrors[
                          `times.${singleDay.day}.opening_time`
                        ] ? (
                          <div
                            className="valid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Looks good!"}
                          </div>
                        ) : (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {"Time is not valid"}
                          </div>
                        ))}
                    </div>

                    {/* CLOSED  */}
                    <div className="d-flex gap-3 align-items-end justify-content-center">
                      <span className="fw-bold d-block w-50">Closed?</span>
                      <Switch
                        checked={times[index].is_closed}
                        onChange={() => handleCheckboxChange(index)}
                        className="react-switch"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}

        {/* SUNDAY  */}
        <div className="d-flex flex-column gap-3 mt-3 py-3 px-3 border-bottom pb-5">
          <h5 className="fw-bold">Sunday</h5>

          {/* MAIN CONTAINER  */}
          <div className="d-flex align-items-end gap-5">
            {/* START  */}
            <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
              <span className="fw-bold d-block w-50 text-center">Start At</span>

              <CustomTimeSelector
                everyClass={`text-center`}
                onChange={(event, value) => onAlOpening_time(event, value, 0)}
                dataTestid="job_start_time"
                name={"job_start_time"}
                id={"job_start_time"}
                required={false}
                className={"form-control border-secondary w-100"}
                value={times[0]?.opening_time}
              />

              {serverSideErrors &&
                (!serverSideErrors["times.0.opening_time"] ? (
                  <div className="valid-feedback" style={{ display: "block" }}>
                    {"Looks good!"}
                  </div>
                ) : (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {"Time is not valid"}
                  </div>
                ))}
            </div>
            {/* UNTILL  */}
            <div className="d-flex gap-1 align-items-center flex-column justify-content-center w-50">
              <span className="fw-bold d-block w-50 text-center">Untill</span>
              <CustomTimeSelector
                everyClass={`text-center`}
                value={times[0]?.closing_time}
                onChange={(event, value) => onAlClosing_time(event, value, 0)}
                dataTestid="job_start_time"
                name={"job_start_time"}
                id={"job_start_time"}
                required={false}
                className={"form-control border-secondary w-100"}
              />
              {serverSideErrors &&
                (!serverSideErrors["times.0.closing_time"] ? (
                  <div className="valid-feedback" style={{ display: "block" }}>
                    {"Looks good!"}
                  </div>
                ) : (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {"Time is not valid"}
                  </div>
                ))}
            </div>

            {/* CLLOSED  */}
            <div className="d-flex gap-3 align-items-end justify-content-center">
              <span className="fw-bold d-block w-50">Closed?</span>
              <Switch
                checked={times[0].is_closed}
                onChange={() => handleCheckboxChange(0)}
                className="react-switch scale-25"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
