import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Card, CardBody, CardHeader, Col, Container, Row, } from "reactstrap";
import { IMAGE_GALLERY } from "../../constant";
import BreadCrumb from "../../layout/Breadcrumb";
import { BACKEND } from "../../utils/backend";

const ImageGalleryForJobs = ({ imageAllData }) => {

    let imagesData = [
        { width: 150, height: 150, index: 0 },
        { width: 150, height: 150, index: 2 },
        { width: 150, height: 150, index: 1 },
        { width: 150, height: 150, index: 3 },
        { width: 150, height: 150, index: 8 },
        { width: 150, height: 150, index: 5 },
        { width: 150, height: 150, index: 4 },
        { width: 150, height: 150, index: 9 },
        { width: 150, height: 150, index: 6 },
        { width: 150, height: 150, index: 7 },
        { width: 150, height: 150, index: 11 },
        { width: 150, height: 150, index: 10 },
    ];
    const images = require.context("../../assets/images", true);
    const dynamicImage = (image) => {
        return images(`./${image}`);
    };
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    {imageAllData.length > 0 ? (
                        <Col sm="12">
                            <h5 className="fw-bold">Images:</h5>
                            <Gallery>
                                {imageAllData.map((data, key) => (
                                    <Item
                                        key={key}
                                        original={`${BACKEND}${data}`}
                                        thumbnail={`${BACKEND}${data}`}
                                        width="1024"
                                        height="768"
                                        id={`image-${key}`}
                                    >
                                        {({ ref, open }) => (
                                            <figure className="col-xl-3 col-sm-6" key={key}>
                                                <img
                                                    ref={ref}
                                                    onClick={open}
                                                    src={`${BACKEND}${data}`}
                                                    alt="Gallery"
                                                    className="img-thumbnail"
                                                />
                                            </figure>
                                        )}
                                    </Item>
                                ))}
                            </Gallery>
                        </Col>
                    ) : (
                        ""
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};

export default ImageGalleryForJobs;