import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { apiClient } from "../../../utils/apiClient";
import { BACKEND_API } from "../../../utils/backend";
import CustomLoadingSpiner from "../../CustomLoadingSpiner/CustomLoadingSpiner";

const JobPaymentForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  paymentUpdateData,
  setIsOpen,
  currency,
}) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [automobileCategories, setAutomobileCategories] = useState([]);
  const formValues = watch();

  //  GET ALL DATE ON UPDATE
  useEffect(() => {
    loadJobs();
    if (type === "update") {
      const fields = ["id", "job_id", "garage_id", "payment_type_id", "amount"];
      fields.forEach((field) => setValue(field, paymentUpdateData[field]));
      setValue("category", paymentUpdateData.category?.name);
    }
    return () => {};
  }, []);

  const loadJobs = () => {
    apiClient()
      .get(`${BACKEND_API}/v1.0/automobile-categories/get/all`)
      .then((response) => {
        setAutomobileCategories(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          SweetAlert.fire({
            title: error.response.data.message,
            text: "Hello!!! You do not have permission.",
            icon: "warning",
          });
        } else {
          SweetAlert.fire({
            title: "something went wrong!!",
            text: "Please Try Again",
            icon: "warning",
          });
        }
      });
  };

  // HANDLE SUBMIT
  const onSubmit = (data) => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      apiClient()
        .patch(`${BACKEND_API}/v1.0/jobs/payment`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Service Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }

    if (type === "create") {
      apiClient()
        .patch(`${BACKEND_API}/v1.0/jobs/payment`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            SweetAlert.fire({
              title: "Success",
              text: "Service Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            SweetAlert.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            SweetAlert.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <Row>
      <Container>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <div>
              {/* JOB SELECTION ROW  */}
              <Row className="mb-1">
                <Col md="12">
                  <FormGroup className="">
                    {/* JOB SELECTION  */}
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="automobile_category_id"
                      >
                        Select Job*
                      </Label>
                    )}

                    <select
                      id="job_id"
                      type="select"
                      className={`form-control border-secondary custom-select ${
                        errors?.jobId && "is-invalid"
                      }`}
                      name="job_id"
                      {...register("job_id", {
                        required: false,
                      })}
                      value={
                        formValues.job_id
                          ? formValues.job_id
                          : paymentUpdateData?.job_id
                      }
                      onChange={(e) => setValue("job_id", e.target.value)}
                    >
                      <option value="">{"Select a Job*"}</option>
                      {automobileCategories.map((el) => {
                        return (
                          <option value={el.id} key={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>

                    {/* ERROR MESSAGE */}
                    {serverSideErrors &&
                      (!serverSideErrors.job_id ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.job_id[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>

              {/* JOB PAYMENT TYPE SELECTION ROW  */}
              <Row className="mb-1">
                <Col md="12">
                  <FormGroup className="">
                    {/* PAYMENT TYPE SELECTION  */}
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="automobile_category_id"
                      >
                        Select A PAUMENT TYPE*
                      </Label>
                    )}

                    <select
                      id="payment_type_id"
                      type="select"
                      className={`form-control border-secondary custom-select ${
                        errors?.jobId && "is-invalid"
                      }`}
                      name="payment_type_id"
                      {...register("payment_type_id", {
                        required: false,
                      })}
                      value={
                        formValues.payment_type_id
                          ? formValues.payment_type_id
                          : paymentUpdateData?.payment_type_id
                      }
                      onChange={(e) =>
                        setValue("payment_type_id", e.target.value)
                      }
                    >
                      <option value="">{"Select A Payment Type*"}</option>
                      {automobileCategories.map((el) => {
                        return (
                          <option value={el.id} key={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>

                    {/* ERROR MESSAGE */}
                    {serverSideErrors &&
                      (!serverSideErrors.payment_type_id ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.payment_type_id[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>

              {/* GARAGE ID & PAYMENT AMOUNT ROW  */}
              <Row className="mb-1">
                {/* GARAGE ID  */}
                <input
                  name="garage_id"
                  type="hidden"
                  className={`form-control custom-select border-secondary ${
                    errors?.garage_id && "is-invalid"
                  }`}
                  {...register("garage_id", { required: false })}
                  value={formValues?.garage_id}
                  onChange={(e) => setValue("garage_id", e.target.value)}
                />

                {/* PAYMENT AMOUNT  */}
                <Col md="12 mb-1">
                  <FormGroup className="mb-1">
                    {type === "update" && (
                      <Label className="text-uppercase" htmlFor="amount">
                        Payment Amount*
                      </Label>
                    )}
                    <input
                      id="amount"
                      placeholder="Payment Amount*"
                      {...register("amount", { required: false })}
                      className="form-control border-secondary"
                      style={{
                        paddingLeft: "45px",
                      }}
                      name="amount"
                      type="number"
                    />
                    <span className="currency_input fw-bold">{currency}</span>
                    <span>
                      {errors?.amount && "Please provide payment amount"}
                    </span>
                    {serverSideErrors &&
                      (!serverSideErrors?.amount ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors?.amount[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}

          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button color="primary" className="w-100 py-2 rounded-3">
                Submit
              </Button>
              <Button
                className="py-2 w-100 cancel-button rounded-3 text-secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
                color=""
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Row>
  );
};

export default JobPaymentForm;
