import React, { useState } from "react";
import { AiOutlineFieldTime, AiOutlineInfoCircle } from "react-icons/ai";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { TbDiscount2 } from "react-icons/tb";

const BookingView = ({ viewData, setIsOpen }) => {
  const [stepNo, setStepNo] = useState(1);
  let currency = localStorage.getItem("currency");

  console.log({ viewData });
  return (
    <Row className="">
      <Col sm="12">
        {/* TAB BUTTONS CONTAINER  */}
        <Row>
          <Container className="d-flex justify-content-start align-items-center gap-2 w-100 p-0">
            {/* Booking Details */}
            <div
              title="Booking Details"
              onClick={() => setStepNo(1)}
              role="button"
              className={`py-2 px-3 border-bottom  ${
                stepNo === 1 && "border-primary text-primary"
              } text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}
            >
              <AiOutlineInfoCircle />{" "}
              <span className="d-none d-md-block">Booking Details</span>
            </div>

            {/* Customer Details  */}
            <div
              title="Customer Details"
              onClick={() => setStepNo(2)}
              role="button"
              className={`py-2 px-3 border-bottom ${
                stepNo === 2 && "border-primary text-primary"
              } text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}
            >
              <AiOutlineFieldTime />{" "}
              <span className="d-none d-md-block">Customer Details</span>
            </div>

            {/* Coupon Details  */}
            <div
              title="Coupon Details"
              onClick={() => setStepNo(3)}
              role="button"
              className={`py-2 px-3 border-bottom ${
                stepNo === 3 && "border-primary text-primary"
              } text-center mr-2 d-flex align-items-center gap-2 fs-6 fw-500`}
            >
              <TbDiscount2 />{" "}
              <span className="d-none d-md-block">Coupon Details</span>
            </div>
          </Container>
        </Row>

        {/* DETAILS TAB  */}
        {stepNo === 1 && (
          <Container className="tab-1 mt-3">
            <h4 className="text-center d-block d-md-none mb-4">
              Booking Details
            </h4>
            <Row className="form-row mb-2">
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Services
                </Label>
                <p className="fw-bold">
                  {viewData?.booking_sub_services?.map((res, i) => (
                    <span>
                      {i !== 0 && ","}
                      {res?.sub_service?.name}
                    </span>
                  ))}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Packages
                </Label>
                <p className="fw-bold">
                  {viewData?.booking_packages?.map((res, i) => (
                    <span>
                      {i !== 0 && ","}
                      {res?.garage_package?.name}
                    </span>
                  ))}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Price
                </Label>
                <p className="fw-bold">
                  {currency}
                  {viewData?.price}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="owner_Name">
                  Make
                </Label>
                <p className="fw-bold">{viewData.automobile_make?.name}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="email">
                  Model
                </Label>
                <p className="fw-bold">{viewData?.automobile_model?.name}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Job start Date
                </Label>
                <p className="fw-bold">{viewData?.job_start_date}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Job start Time
                </Label>
                <p className="fw-bold">{viewData?.job_start_time}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Status
                </Label>
                <p className="fw-bold">{viewData?.status}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Car Registration No
                </Label>
                <p className="fw-bold">{viewData?.car_registration_no}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Additional Information
                </Label>
                <p className="fw-bold">{viewData?.additional_information}</p>
              </Col>
            </Row>
          </Container>
        )}

        {/* SCHEDULE TAB  */}
        {stepNo === 2 && (
          <Container className="tab-1 mt-3">
            <h4 className="text-center d-block d-md-none mb-4">
              Customer Details
            </h4>
            <Row className="form-row mb-2">
              {viewData?.logo && (
                <Row className="mb-5">
                  <Col className="d-flex justify-content-center">
                    <img
                      className="garageDetailsLogo"
                      src={viewData?.customer?.image}
                      alt={viewData?.customer?.first_Name}
                    />
                  </Col>
                </Row>
              )}
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Customer Name
                </Label>
                <p className="fw-bold">
                  {viewData?.customer?.first_Name}{" "}
                  {viewData?.customer?.last_Name}
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="email">
                  Email
                </Label>
                <p className="fw-bold">{viewData?.customer?.email}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Phone
                </Label>
                <p className="fw-bold">{viewData?.customer?.phone}</p>
              </Col>
            </Row>

            <Row className="form-row mb-2">
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="country">
                  Country
                </Label>
                <p className="fw-bold">{viewData?.customer?.country}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="city">
                  City
                </Label>
                <p className="fw-bold">{viewData?.customer?.city}</p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="postcode">
                  Postcode
                </Label>
                <p className="fw-bold">{viewData?.customer?.postcode}</p>
              </Col>

              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="phone">
                  Address Line 1
                </Label>
                <p className="fw-bold">{viewData?.customer?.address_line_1}</p>
              </Col>
              {viewData?.address_line_2 && (
                <Col md="6 mb-3">
                  <Label className={"text-uppercase"} htmlFor="phone">
                    Address Line 2
                  </Label>
                  <p className="fw-bold">
                    {viewData?.customer?.address_line_2}
                  </p>
                </Col>
              )}
            </Row>
          </Container>
        )}

        {/* COUPON  */}
        {stepNo === 3 && (
          <Container className="tab-1 mt-3">
            <h4 className="text-center d-block d-md-none mb-4">
              Coupon Details
            </h4>
            <Row className="form-row mb-2">
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Coupon Code
                </Label>
                <p className="fw-bold">
                  <span>
                    {viewData?.coupon_code ? viewData?.coupon_code : "N/A"}
                  </span>
                </p>
              </Col>
              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Coupon Type
                </Label>
                <p className="fw-bold">
                  <span>{viewData?.coupon_discount_type}</span>
                </p>
              </Col>

              <Col md="6 mb-3">
                <Label className={"text-uppercase"} htmlFor="garage_Name">
                  Discount Amount
                </Label>
                <p className="fw-bold">
                  {currency} {viewData?.coupon_discount_amount}
                </p>
              </Col>
            </Row>
          </Container>
        )}
        <Row className="mb-3">
          <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
            <Button
              className="py-2 w-100 cancel-button rounded-3"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BookingView;
