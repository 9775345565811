import React from 'react';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import CustomLoadingSpiner from '../../CustomLoadingSpiner/CustomLoadingSpiner';
import moment from 'moment';

const CouponView = ({ setIsOpen, isLoading, viewData }) => {
    return (
        <Row className='px-3'>
            <Col sm="12">
                {isLoading ? <CustomLoadingSpiner /> :
                    <>
                        <Container className='tab-1 mt-3'>
                            <Row className="form-row mb-2">
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="garage_Name">Coupon Name</Label>
                                    <p className="fw-bold">{viewData?.name}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="email">Coupon Code</Label>
                                    <p className="fw-bold">{viewData?.code}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Discount Type</Label>
                                    <p className="fw-bold">{viewData?.discount_type}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="country">Discount Amount</Label>
                                    <p className="fw-bold">{viewData?.discount_amount}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="city">Minimum Total</Label>
                                    <p className="fw-bold">{viewData?.min_total}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="postcode">Maximum Total</Label>
                                    <p className="fw-bold">{viewData?.max_total}</p>
                                </Col>
                            </Row>

                            <div className='form-row mb-2'>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Redemptions</Label>
                                    <p className="fw-bold">{viewData?.redemptions}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Coupon Start Date</Label>
                                    <p className="fw-bold">{moment(viewData?.coupon_start_date).format('DD-MM-YYYY')}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Coupon End Date</Label>
                                    <p className="fw-bold">{moment(viewData?.coupon_end_date).format('DD-MM-YYYY')}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Auto Apply?</Label>
                                    <p className="fw-bold">{viewData?.is_auto_apply === 1 ? "yes" : "No"}</p>
                                </Col>
                                <Col md="6 mb-3">
                                    <Label className={"text-uppercase"} htmlFor="phone">Active?</Label>
                                    <p className="fw-bold">{viewData?.is_active === 1 ? "Active" : "Not Active"}</p>
                                </Col>
                            </div>
                        </Container>
                    </>
                }


                <Row className='mb-3'>
                    <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
                        <Button className='py-2 w-100 cancel-button rounded-3' onClick={() => { setIsOpen(false) }}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CouponView;