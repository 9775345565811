import React from "react";
import styles from "./RatingTagCardWithCounter.module.css";


export default function RatingTagCardWithCounter({ tag, count }) {

  return (
    <div className={styles.main}>
      <div className={styles.tag}>{tag}</div>
      <div className={styles.count}>{count}</div>
    </div>
  );
}
