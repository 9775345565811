import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiClient } from "../../utils/apiClient";
import { BACKEND_API } from "../../utils/backend";
import Swal from "sweetalert2";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CustomLoadingSpiner from "../CustomLoadingSpiner/CustomLoadingSpiner";

const PaymentTypeForm = ({
  toggleModal,
  fetchData,
  perPage,
  type,
  paymentTypeUpdateData,
  setIsOpen,
  currency,
}) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [serverSideErrors, setServerSideErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const formValues = watch();
  console.log({ paymentTypeUpdateData, formValues });
  //  GET ALL DATE ON UPDATE
  useEffect(() => {
    if (type === "update") {
      const fields = ["id", "name", "description", "is_active"];
      fields.forEach((field) => setValue(field, paymentTypeUpdateData[field]));
    }
    return () => {};
  }, []);

  // HANDLE SUBMIT
  const onSubmit = (data) => {
    setLoading(true);
    setServerSideErrors(null);
    if (type === "update") {
      apiClient()
        .put(`${BACKEND_API}/v1.0/payment-types`, {
          id: data?.id,
          name: data?.name,
          description: data?.description,
          is_active: data?.is_active,
        })
        .then((response) => {
          setLoading(false);
          if (response.data) {
            Swal.fire({
              title: "Success",
              text: "Payment Type Updated Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            Swal.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            Swal.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            Swal.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }

    if (type === "create") {
      apiClient()
        .post(`${BACKEND_API}/v1.0/payment-types`, data)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            Swal.fire({
              title: "Success",
              text: "Service Created Successfully!",
              icon: "success",
            });
            fetchData(perPage);
            toggleModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 422) {
            setServerSideErrors(error.response.data.errors);
            Swal.fire({
              title: error.response.data.message,
              text: "Please Try Again",
              icon: "warning",
            });
          } else if (error.response?.status === 401) {
            Swal.fire({
              title: error.response.data.message,
              text: "Hello!!! You do not have permission.",
              icon: "warning",
            });
          } else {
            Swal.fire({
              title: "something went wrong!!",
              text: "Please Try Again",
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <Row>
      <Container>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          {loading ? (
            <CustomLoadingSpiner />
          ) : (
            <div>
              {/* NAME ROW  */}
              <Row className="mb-1">
                <Col md="12">
                  <FormGroup className="">
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="automobile_category_id"
                      >
                        Name*
                      </Label>
                    )}
                    {console.log({ errors })}
                    <input
                      name="name"
                      type="text"
                      placeholder="Name*"
                      className={`form-control custom-select border-secondary ${
                        errors?.name && "is-invalid"
                      }`}
                      {...register("name", { required: true })}
                      value={formValues?.name}
                      onChange={(e) => setValue("name", e.target.value)}
                    />

                    {/* ERROR MESSAGE */}
                    {serverSideErrors &&
                      (!serverSideErrors.name ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.name[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>

              {/* DESCRIPTION ROW  */}
              <Row className="mb-1">
                <Col md="12">
                  <FormGroup className="">
                    {/* PAYMENT TYPE SELECTION  */}
                    {type === "update" && (
                      <Label
                        className="text-uppercase"
                        htmlFor="automobile_category_id"
                      >
                        Description*
                      </Label>
                    )}

                    <textarea
                      name="description"
                      placeholder="Description*"
                      className={`form-control custom-select border-secondary ${
                        errors?.description && "is-invalid"
                      }`}
                      {...register("description", { required: true })}
                      value={formValues?.description}
                      onChange={(e) => setValue("description", e.target.value)}
                    />
                    {/* ERROR MESSAGE */}
                    {serverSideErrors &&
                      (!serverSideErrors.description ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.description[0]}
                        </div>
                      ))}

                    {/* PAYMENT IS ACTIVE SELECTION  */}
                    {type === "update" && (
                      <Label
                        className="text-uppercase mt-3"
                        htmlFor="automobile_category_id"
                      >
                        Is Active*
                      </Label>
                    )}
                    <Input
                      placeholder="Is Active?*"
                      className={`form-control custom-select border-secondary ${
                        type === "update" ? "" : " mt-3"
                      } ${errors?.is_active && "is-invalid"}`}
                      {...register("is_active", { required: true })}
                      value={formValues?.is_active}
                      onChange={(e) => setValue("is_active", e.target.value)}
                      type="select"
                      name="is_active"
                    >
                      <option value={""}>Is Active?</option>
                      <option value={1}>Active</option>
                      <option value={0}>Deactive</option>
                    </Input>
                    {/* ERROR IS ACTIVE */}
                    {serverSideErrors &&
                      (!serverSideErrors.is_active ? (
                        <div
                          className="valid-feedback"
                          style={{ display: "block" }}
                        >
                          Looks good!
                        </div>
                      ) : (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {serverSideErrors.is_active[0]}
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}

          <Row className="mb-3">
            <Col className="text-end btn-mb 12 d-flex justify-content-center align-items-center flex-column gap-2">
              <Button color="primary" className="w-100 py-2 rounded-3">
                Submit
              </Button>
              <Button
                className="py-2 w-100 cancel-button rounded-3 text-secondary"
                onClick={() => {
                  setIsOpen(false);
                }}
                color=""
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Row>
  );
};

export default PaymentTypeForm;
